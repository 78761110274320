import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { TimetablesComponent } from './timetables/timetables.component';
import { AddTimeslotComponent } from './timeslot/addtimeslot/addtimeslot.component';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-timetable',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    TimeslotComponent,
    TimetablesComponent,
    AddTimeslotComponent,
    RouterModule
  ],
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent {
  showForm: boolean = false;
  constructor(private router: Router) {}

  toggleForm() {
    this.router.navigate(['/addtimeslots']);
  }
  toggletableForm() {
    this.router.navigate(['/addtimetable']);
  }
  closeForm(){
    this.showForm = false;
  }

}
