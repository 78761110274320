import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, NgModule, OnInit } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth, endOfMonth, format } from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarA11y,
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarModule,
  CalendarUtils,
  CalendarView,
  DateAdapter,
  CalendarEventTitleFormatter,
  CalendarCommonModule,
  CalendarMonthModule
} from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { I18nPluralPipe } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ThemePalette } from '@angular/material/core';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PermissionService } from '../../../components/Authentication/services/permission.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#55AB58',
    secondary: '#55AB58',
  },
};

const Representaion_data: any = [
  {
    title: 'Present',
    color: '#55AB58'
  },
  {
    title: 'Absent',
    color: '#EA4C4C'
  },
  {
    title: 'Holidays',
    color: '#D4D813'
  },
  {
    title: 'Weekly offs',
    color: '#7BB3F0'
  },
  {
    title: 'Leave Applied',
    color: '#FE6BDE'
  },
]

const smaple_json = [
  {
    "id": 10,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "10",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 11,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "11",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 12,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "12",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 13,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "13",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 14,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "14",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 15,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "15",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 16,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "16",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 17,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "17",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 18,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "18",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 19,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "19",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 20,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "20",
    "month": "03",
    "year": "2024",
    "status": "ABSENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 9,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "09",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "subjects_attendance": [
      {
        "subject_name": "maths",
        "status": "PRESENT"
      },
      {
        "subject_name": "phy",
        "status": "PRESENT"
      },
      {
        "subject_name": "eng",
        "status": "PRESENT"
      },
      {
        "subject_name": "tel",
        "status": "ABSENT"
      },
      {
        "subject_name": "hin",
        "status": "PRESENT"
      },
    ],
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 8,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "08",
    "month": "03",
    "year": "2024",
    "status": "ABSENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 7,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "07",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "subjects_attendance": [
      {
        "subject_name": "maths",
        "status": "PRESENT"
      },
      {
        "subject_name": "phy",
        "status": "PRESENT"
      },
      {
        "subject_name": "eng",
        "status": "PRESENT"
      },
      {
        "subject_name": "tel",
        "status": "ABSENT"
      },
      {
        "subject_name": "hin",
        "status": "ABSENT"
      },
    ],
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 6,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "06",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "subjects_attendance": [
      {
        "subject_name": "maths",
        "status": "ABSENT"
      },
      {
        "subject_name": "phy",
        "status": "PRESENT"
      },
      {
        "subject_name": "eng",
        "status": "PRESENT"
      },
      {
        "subject_name": "tel",
        "status": "ABSENT"
      },
      {
        "subject_name": "hin",
        "status": "PRESENT"
      },
    ],
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 5,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "05",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "subjects_attendance": [
      {
        "subject_name": "maths",
        "status": "PRESENT"
      },
      {
        "subject_name": "phy",
        "status": "PRESENT"
      },
      {
        "subject_name": "eng",
        "status": "ABSENT"
      },
      {
        "subject_name": "tel",
        "status": "ABSENT"
      },
      {
        "subject_name": "hin",
        "status": "PRESENT"
      },
    ],
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 4,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "04",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 3,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "03",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 2,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "02",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  },
  {
    "id": 1,
    "student_id": 34,
    "course_id": 1,
    "semister_id": 1,
    "date": "01",
    "month": "03",
    "year": "2024",
    "status": "PRESENT",
    "start_time": "10AM",
    "end_time": "5PM"
  }
]

@Component({
  selector: 'app-studentattendance',
  standalone: false,
  templateUrl: './studentattendance.component.html',
  styleUrl: './studentattendance.component.scss',
  providers: [I18nPluralPipe, CalendarEventTitleFormatter, { provide: DateAdapter, useFactory: adapterFactory }, CalendarDateFormatter, CalendarUtils, CalendarA11y]
})
export class StudentattendanceComponent {
  console = console;
  private year: number = new Date().getFullYear();
  private month: number = new Date().getMonth();
  @ViewChild('modalContent')
  modalContent!: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  Representaion_data: any = Representaion_data;
  color = 'green'
  modalData!: {
    action: string;
    event: CalendarEvent;
  };
  status: string = '';
  actions: CalendarEventAction[] = [
    {
      label: 'sjdkf',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: 'snkfd',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  attendance_percentage: any;
  actiondata: any;

  constructor(
    private apiservice: ApiservicesService,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    this.getstudentattendancedetails()
  }
  ngOnInit(): void {
    // this.getattendancedetails()   
  }

  yearmonth(event: Date) {
    this.events = [];
    let formated = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(event);
    this.console.log(">>>>>>date p ", event)
    this.year = event.getFullYear();
    this.month = event.getMonth();
    this.getstudentattendancedetails();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  getstudentattendancedetails() {
    const monthEnd = endOfMonth(this.viewDate);
    // const month = format(monthEnd, 'yyyy-mm');
    this.apiservice.getstudentattendancedetails(this.year, this.month + 1).subscribe((res: any) => {
      console.log("hit api attendance")
      this.attendance_percentage = res.attendance_percentage;
      // if(res.code === 200 && res.status === true){
      console.log("attendance", res)
      // res.rows.forEach((item: any, index:number) => {
      res.attendance_data?.forEach((items: any) => {
        // if (items?.status === 'PRESENT') {
        // items?.attendance?.forEach((item: any, index: number) => {
        this.events.push({
          start: startOfDay(JSON.stringify(items?.year + '-' + items?.month + '-' + items?.date)),
          // end: endOfDay(item.date),
          title: items?.subject_name,
          // status: item.status,
          color: items?.status === 'PRESENT' ? colors.green : colors.red,
          draggable: false,
          actions: this.actions,
          meta: {
            id: items.id,
            entity: 'Show contains',
            start_time: items?.start_time,
            end_time: items?.end_time,
            subject_attendance: items?.status,
            status: items?.status,
            notes: items?.notes ? items?.notes : ''
          }
          // });
        });
        // }
        // else {
        //   // items.forEach((item: any, index:number) => {
        //   this.events.push({
        //     start: startOfDay(JSON.stringify(items.year + '-' + items.month + '-' + items.date)),
        //     // end: endOfDay(item.date),
        //     title: ' show contains',
        //     // status: item.status,
        //     color: items?.status === 'PRESENT' ? colors.green : colors.red,
        //     draggable: false,
        //     actions: this.actions,
        //     meta: {
        //       id: items?.id,
        //       entity: 'Show contains',
        //       start_time: items?.start_time,
        //       end_time: items?.end_time,
        //       status: items?.status,
        //       subject_attendance: items?.status,
        //       notes: items?.notes ? items?.notes : ''
        //     }
        //   });

        //   // });
        // }
      })

      res.weekoffs.forEach((item: any, index: number) => {
        this.console.log("week off data", item)
        this.events.push({
          // id : index,
          start: startOfDay(item.date),
          // end: endOfDay(item.end_date),
          title: 'Week Off',
          color: colors.blue,
          draggable: false,
          actions: this.actions,
          meta: {
            // id: item.id,
            entity: 'Week Off',
            // start_time: item.start_time,
            // end_time: item.end_time,
            status: 'WEEKOFF',
            daytype: 'WEEKOFF',
            notes: 'Week Off'
          }
        });
      });

      res?.holidays?.forEach((item: any, index: number) => {
        this.events.push({
          start: startOfDay(item.date),
          // end: endOfDay(item.date),
          title: item.holiday_type,
          color: colors.yellow,
          draggable: false,
          actions: this.actions,
          meta: {
            id: item.id,
            entity: 'Show contains',
            start_time: item.start_time,
            end_time: item.end_time,
            status: item.status,
            daytype: 'HOLIDAY',
            notes: item?.holiday_name
          }
        });
      });

      res?.leaves_data?.forEach((item: any, index: number) => {
        this.events.push({
          start: startOfDay(item.start_date),
          end: endOfDay(item.end_date),
          title: item.leave_type,
          color: colors.pink,
          draggable: false,
          actions: this.actions,
          meta: {
            id: item.id,
            entity: 'Show contains',
            start_time: item.start_time,
            end_time: item.end_time,
            status: item.status,
            daytype: 'ABSENT',
            notes: item?.leave_type
          }
        });
      });
      this.console.log(">>>>>>>>events", this.events)
      this.refresh.next(true);
      // }
    })
  }
}

@NgModule({
  declarations: [
    StudentattendanceComponent
  ],
  exports: [StudentattendanceComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    })
  ],
})
export class EmployeeAtendanceModule { }