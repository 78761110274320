import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ShiftComponent } from './shifttimings/shift/shift.component';
import { WeekoffComponent } from './weekoff/weekoff/weekoff.component';
import { HolidaysComponent } from './holidays/holidays/holidays.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddshiftComponent } from './shifttimings/addshift/addshift.component';
import { AddholidaysComponent } from './holidays/addholidays/addholidays.component';
import { AddweekoffComponent } from './weekoff/addweekoff/addweekoff.component';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { PermissionService } from '../../../components/Authentication/services/permission.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-workingdays',
  standalone: true,
  imports: [MatTabsModule, ShiftComponent, WeekoffComponent, HolidaysComponent, MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './workingdays.component.html',
  styleUrls: ['./workingdays.component.scss'],
  providers: [ApiservicesService, ShiftComponent]
})
export class WorkingdaysComponent implements OnInit{
  tabactiondata: any;
  canCreate: boolean = false;
  canView: boolean = false;
  shiftpermissions: any;
  Holidaypermissions: any;
  weekoffpermissions: any;

  constructor(
    public dialog: MatDialog, 
    apiservice: ApiservicesService, 
    private permissionservice: PermissionService, 
    private loadshiftdata : ShiftComponent
  ) { }

  ngOnInit(): void {
    this.tabactiondata = this.permissionservice.gettabactiondata();
    console.log(">>>>>>>>>> tab action data", this.tabactiondata);
    this.shiftpermissions = this.tabactiondata.find((items:any) =>  items.match_key === "SHIFTS")
    this.Holidaypermissions = this.tabactiondata.find((items:any) =>  items.match_key === "HOLIDAYS")
    this.weekoffpermissions = this.tabactiondata.find((items:any) =>  items.match_key === "WEEKOFF")
    this.tabactiondata.map((action:any) => {
      this.permissionservice.actionsdata = action.permissionservice;
    })
  }
  

  openShiftDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '25%';
    dialogConfig.minHeight = '30vh';
    const dialogRef = this.dialog.open(AddshiftComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadshiftdata.getShiftdetails();
    });
  }
  openholidayDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '25%' ;
    dialogConfig.minHeight = '30vh' ;
    const dialogRef = this.dialog.open(AddholidaysComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openweekoffDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '25%';
    dialogConfig.minHeight = '30vh';
  
    const dialogRef = this.dialog.open(AddweekoffComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // Refresh the weekoff data if needed
    });
  }
}
