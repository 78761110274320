import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private _showsToast$ = new BehaviorSubject<boolean>(false);
  private _toastMessage$ = new BehaviorSubject<string>('');
  private _toastState$ = new BehaviorSubject<string>('toast-class');

  showsToast$ = this._showsToast$.asObservable();
  toastMessage$ = this._toastMessage$.asObservable();
  toastState$ = this._toastState$.asObservable();

  showToast(state: 'success' | 'warning' | 'danger' = 'success', message: string,) {
    this._toastMessage$.next(message);
    this._toastState$.next(`${state}-toast`);
    this._showsToast$.next(true);

    setTimeout(() => {
      this.dismissToast();
    }, 3000); // Auto dismiss after 3 seconds
  }

  dismissToast() {
    this._showsToast$.next(false);
  }
}
