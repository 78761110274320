import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormsModule
} from '@angular/forms';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DatacontrolService } from '../../../../Services/controllers/datacontrol.service';
import { Observable, Subscription, map } from 'rxjs';
import { RemoveUnderscorePipe } from "../../../../components/Utilites/Pipe/remove-underscore.pipe";
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { LeavesummaryService } from '../../../../inputfileds/form_services/leavesummary/leavesummary.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { PaginationComponent } from '../../../../components/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-leave-summary',
  standalone: true,
  imports: [
    DynamicFormQuestionComponent,
    MatTableModule,
    MatCheckboxModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    PaginationComponent,
    MatPaginatorModule,
    RemoveUnderscorePipe
  ],
  templateUrl: './leave-summary.component.html',
  styleUrl: './leave-summary.component.scss',
  providers: [ApiservicesService, QuestionControlService]
})
export class LeaveSummaryComponent {

  leaverequestdata: any = [];
  displayedColumns: string[] = ['select', 'emp_name', 'designation', 'department', 'allotted_leaves', 'used_leaves', 'actions']; //'select' for adding checkbox, 
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  requesttype: any;
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  leavesummaryfilterform!: FormGroup;
  apirequest: any;
  total_count: any;
  paginationdata: any;
  datepipe = new DatePipe('en-US');
  private subscription: Subscription | undefined;

  constructor(
    private apisevice: ApiservicesService,
    private qcs: QuestionControlService,
    private router: Router,
    public aRoute: ActivatedRoute,
    private services: LeavesummaryService,
  ) {
    this.questions$ = this.services.getleavesummaryform();
    this.questions$.subscribe((q) => (this.questions = q));
    // this.requestservice.getleaverequestcontrol.subscribe(req =>{
    //   this.requesttype = req
    // });
    // this.requesttype = this.aRoute.snapshot.queryParamMap.get('ReqestType')
    // if (typeof this.requesttype === undefined) {
    //   this.backnavigation()
    // } else {
    //   this.getrequestdata(this.requesttype)
    // }
  }

  ngOnInit(): void {
    this.leavesummaryfilterform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
    this.leavesummaryfilterform.controls['start_date'].setValue(this.datepipe.transform(new Date, 'yyyy-MM-dd'));
    this.leavesummaryfilterform.controls['end_date'].setValue(this.datepipe.transform(new Date, 'yyyy-MM-dd'));
    this.leavesummaryfilterform.controls['employee_id'].setValue("");
    this.leavesummaryfilterform.controls['leave_type_id'].setValue("0");
    this.leavesummaryfilterform.controls['department_id'].setValue("0");


    this.subscription = this.services.notifyleavesummaryComponent$.subscribe(() => {
      this.getrequestdata()  // Call the component function
    });

    this.getrequestdata()
  }

  submit() {
    console.log("submitb values", this.leavesummaryfilterform.getRawValue());
  }

  getrequestdata() {
    this.apirequest = {
      'start_date': this.datepipe.transform(this.leavesummaryfilterform.value.start_date, 'yyyy-MM-dd'),
      'end_date': this.datepipe.transform(this.leavesummaryfilterform.value.end_date, 'yyyy-MM-dd'),
      'employee_name': this.leavesummaryfilterform.value.employee_id,
      // 'department_id' : this.leavesummaryfilterform.value.department_id,
      // 'leave_type_id' : this.leavesummaryfilterform.value.leave_type_id,
    }

    if (this.leavesummaryfilterform.value.department_id != 0) {
      this.apirequest = { ...this.apirequest, department_id: this.leavesummaryfilterform.value.department_id }
    } else if (this.leavesummaryfilterform.value.leave_type_id != 0) {
      this.apirequest = { ...this.apirequest, leave_type_id: this.leavesummaryfilterform.value.leave_type_id }
    }

    this.apisevice.getleavesummary(this.paginationdata, this.apirequest).subscribe((res) => {
      this.leaverequestdata = res?.data;
      console.log("assigned leave data", res)
      this.dataSource = new MatTableDataSource<any>(res?.data);
      this.total_count = res.total_count;
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  isSticky(column: string): boolean {
    return column === 'col6' ? true : false;
  }

  allactionrequestapi() {
    // this.actionrequestapi(this.selection.selected)
    let employee_data = [];
    employee_data = this.selection.selected.map((items: any) => { return items.employee_id })
    this.actionleaveallotapi(employee_data);
  }

  actionrequestapi(id: any) {
    let employee_id = [];
    employee_id.push(id);
    this.actionleaveallotapi(employee_id);
  }

  actionleaveallotapi(event: any) {
    this.apisevice.actionleaveallot(event).subscribe((res) => {
      if (res.code == 204 && res.status === true) {
        this.getrequestdata();
      }
    })
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();  // Clean up the subscription
    }
  }

  backnavigation() {
    this.router.navigate(['/requests']);
  }

  gotoemplyeeview(event: any) {
    console.log("click daya", event)
    this.router.navigate(['/leavesummary/leavehistory'], { queryParams: { dataId: event.employee_id } });
  }

  pagination(event: any) {
    this.paginationdata = event;
    this.getrequestdata();
  }
}
