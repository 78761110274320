import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../Authentication/services/authentication.service';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { verify } from 'crypto';

@Component({
  selector: 'app-forgotpassword',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './forgotpassword.component.html',
  styleUrl: './forgotpassword.component.scss'
})
export class ForgotpasswordComponent implements OnInit {

  loginerror: boolean | undefined;
  submitted = false;
  error = '';
  @Input() roleID = '';
  @Output() disableflags = new EventEmitter<boolean>();

  emailform: FormGroup = new FormGroup({
    useremailid: new FormControl(''),
  });

  verificationform: FormGroup = new FormGroup({
    verification_code: new FormControl(''),
  });
  passwordresetform: FormGroup = new FormGroup({
    password: new FormControl(''),
    reenter_password: new FormControl(''),
  });
  flagswitch: string = 'email';
  verificationdata: any;
  error_message: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiservice: ApiservicesService
  ) {
  }
  ngOnInit(): void {

    this.emailform = this.formBuilder.group(
      {
        useremailid: ['', [Validators.required, Validators.email]],
      }
    );
    this.verificationform = this.formBuilder.group(
      {
        verification_code: ['', [Validators.required]],
      }
    );
    this.passwordresetform = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6), PasswordStrengthValidator]],
        reenter_password: ['', [Validators.required]],
      }
    );
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value === frm.controls['reenter_password'].value ? null : { 'mismatch': true };
  }
  get femailform() {
    return this.emailform.controls;
  }
  get fverificationform() {
    return this.verificationform.controls;
  }
  get fpasswordresetform() {
    return this.passwordresetform.controls;
  }

  onSubmitemailID() {
    this.submitted = true;
    if (this.emailform.invalid) {
      return;
    }
    this.apiservice.Studentforgotpassword(this.roleID, this.emailform.value.useremailid).subscribe((res: any) => {
      if (res?.code == '200' && res?.status === true) {
        this.error_message = '';
      this.flagmaintance('verify');
      }else{
        this.error_message = res?.message; 
      }
    });
  }
  onSubmitverification() {
    this.submitted = true;
    // this.flagmaintance('password');
    if (this.verificationform.invalid) {
      return;
    }
    this.apiservice.verifyOTP(this.roleID, this.emailform.value.useremailid, this.verificationform.value.verification_code)
      .subscribe(
        data => {
          if (data?.code == '200' && data?.status === true) {
          this.verificationdata = data;
          this.error_message = '';
          this.flagmaintance('password');
          }
          else{
            this.error_message = data?.message; 
          }
        });
  }
  onSubmitpasswordreset() {
    this.submitted = true;
    if (this.passwordresetform.invalid) {
      return;
    }
    this.apiservice.passwordupdate(this.roleID, this.passwordresetform.value.password, this.passwordresetform.value.reenter_password, this.verificationdata.data.id)
      .subscribe(
        data => {
          if (data?.code == '200' && data?.status === true) {
          // this.verificationdata = data;
          this.error_message = '';
          this.flagmaintance('email');
          }
          else{
            this.error_message = data?.message; 
          }
        });
  }

  disableflag(value: boolean) {
    this.disableflags.emit(value);
  }

  flagmaintance(event: any) {
    switch (event) {
      case 'email':
        this.flagswitch = 'email';
        break;
      case 'verify':
        this.flagswitch = 'verify';
        break;
      case 'password':
        this.flagswitch = 'password';
        break;
    }
  }
}

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';

  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `text has to contine Upper case characters,current value ${value}` };
  }

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `text has to contine lower case characters,current value ${value}` };
  }


  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `text has to contine number characters,current value ${value}` };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `text has to contine special character,current value ${value}` };
  }
  return null;
}