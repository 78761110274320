<!-- <app-page-header icon='Home'> -->
<div class="container">
  <div class="page_header">
    <h3 class="m-0 page_header_title">Profile</h3>
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="Profileform">
    <div class="row">
      <div *ngFor="let question of questions" class="col-sm-6">
        <app-question [question]="question" [form]="Profileform"></app-question>
      </div>
    </div>
    <div class="mt-3 text-end">
      <button mat-raised-button color="primary" class="px-5 submit_button" type="submit" [disabled]="!Profileform.valid">Save</button>
    </div>
    <div class="mt-3 text-end">
      <button mat-raised-button color="primary" class="px-5 update_button" type="button" (click)="editform()">Edit</button>
    </div>
  </form>

  <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
</div>
<!-- </app-page-header> -->