import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { Observable, of } from 'rxjs';
import { DatepickerQuestion } from '../../question-datepicker';
import { TextboxQuestion } from '../../question-textbox';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { CheckboxQuestion } from '../../question-checkbox';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class LeavesassignedService {
  OrganisationDropdata: any = [];
  EmployeesDropData: any = [];
  LeavetypeDropData: any = [];
  Countrydata: any = [];
  leaveTypeDropData: any[] = [];
  maximum_allowed_leaves: number = 0;

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('ORGANISATION', this.OrganisationDropdata);
    this.loadDropdownData('EMPLOYEES', this.EmployeesDropData);
    this.loadDropdownData('LEAVE_TYPES', this.LeavetypeDropData);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.dropdownservice.getorganizationdrop();
    } else if (code === 'EMPLOYEES') {
      observable = this.dropdownservice.getemployeedrop();
    } else if (code === 'LEAVE_TYPES') {
      observable = this.dropdownservice.getassignleavedrop();
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code ===    'ORGANISATION') {
          lookupname = res[i].short_name;
        } else if (code === 'EMPLOYEES') {
          lookupname = res[i].name;
        }else if (code === 'LEAVE_TYPES') {
          lookupname = res[i].name;
        }
         else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  

  getassignleaveform() {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization',
        required: true,
        options: this.OrganisationDropdata,
        order: 1,
      }),
      new DropdownQuestion({
        key: "employee_id",
        label: 'Employees',
        required: true,
        options: this.EmployeesDropData,
        order: 2,
      }),
      new DropdownQuestion({
        key: "leave_type_id",
        label: 'Leave Type',
        required: true,
        options: this.LeavetypeDropData,
        order: 3,
      }),
      new TextboxQuestion({
        key: "number_of_allowed_leaves",
        label: 'Number Of Leaves Allowed',
        required: true,
        type: "number",
        order: 4,
      }),
      new DatepickerQuestion({
        key: 'start_date',
        label: 'From Date',
        required: true,
        order: 6
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: 'To Date',
        required: true,
        order: 7
      }),
      new CheckboxQuestion({
        key: 'allowed_carry_forward',
        label: 'Carry Forward',
        required: true,
        order: 8
      })
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
