<section class="mx-3">
    <div class="d-flex align-items-center">
        <div>
            <button mat-icon-button class="back_icon cancel_button me-2" (click)="backnavigation()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="page_header">
            <h4 class="m-0 page_header_title">Leave History</h4>
        </div>
    </div>
    <div class="mt-2">
        <div class="d-flex">
            <div class="col-sm-10">
                <div class="row">
                    <ng-container *ngFor="let items of leavesavailability">
                    <div class="col-sm-4">
                        <mat-card class="example-card" [appLeavecardcolor]="items.leave_name">
                            <mat-card-header>
                                <mat-card-title-group>
                                    <mat-card-title>{{items.available_leaves}} <span class="fs-12">/{{items.total_leaves}}</span></mat-card-title>
                                    <mat-card-subtitle>Available {{items.leave_name | removeUnderscore}}</mat-card-subtitle>
                                </mat-card-title-group>
                            </mat-card-header>
                            <mat-card-content></mat-card-content>
                        </mat-card>
                    </div>
                </ng-container>
                    <!-- <div class="col-sm-6">
                        <mat-card class="example-card">
                            <mat-card-header>
                                <mat-card-title-group>
                                    <mat-card-title>06 <span class="fs-12">/06</span></mat-card-title>
                                    <mat-card-subtitle>Available Sick Leaves</mat-card-subtitle>
                                </mat-card-title-group>
                            </mat-card-header>
                            <mat-card-content></mat-card-content>
                        </mat-card>
                    </div> -->
                </div>
            </div>
            <div class="col-sm-2 d-flex align-items-center justify-content-end">
                <div class="text-end">
                    <p class="fs-16">{{leaverequestdata.employee_name}}</p>
                    <p class="m-0">{{leaverequestdata.employee_unique_id}}</p>
                    <p class="m-0">{{leaverequestdata.designation | removeUnderscore}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <form>
            <div class="row m-0 justify-content-end">
                <div *ngFor="let question of questions" class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <app-question [question]="question" [form]="leavesummaryfilterform"></app-question>
                </div>
            </div>
        </form>
    </div>
    <div class="mt-2 table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef> From </th>
                <td mat-cell *matCellDef="let element"> {{element.start_date}} </td>
            </ng-container>

            <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef> To </th>
                <td mat-cell *matCellDef="let element"> {{element.end_date}} </td>
            </ng-container>

            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef> Duration </th>
                <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
            </ng-container>

            <ng-container matColumnDef="leave_name">
                <th mat-header-cell *matHeaderCellDef> Leave Type </th>
                <td mat-cell *matCellDef="let element"> {{element.leave_name}} </td>
            </ng-container>
 
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element" [appStatusColor]="element.status"> {{element.status}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="actions" sticky>
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element"> 
                <div>
                    <button  mat-icon-button [matMenuTriggerFor]="actionrequest" aria-label="menu" >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item (click)="actionrequestapi('APPROVED', element)">
                          <span>Approved</span>
                        </button>
                        <button mat-menu-item (click)="actionrequestapi('REJECTED', element)">
                          <span>Rejected</span>
                        </button>
                      </mat-menu>
                </div> 
            </td>
        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
    </div>
</section>