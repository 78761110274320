import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Inputfiledsbase } from '../../../../models/inputfiledsbase';
import { TextboxQuestion } from '../../../../question-textbox';
import { TextareaQuestion } from '../../../../question-textarea';
import { DropdownQuestion } from '../../../../question-dropdown';

@Injectable({
  providedIn: 'root'
})
export class SupportingDocumentsService {
  private apiResponse = {
    "NaacExtended_Data": [
      {
        "ID": "22",
        "URLs": {
          "level1": {
            "Title": "1.2.1: The Institution ensures effective curriculum delivery through a well planned and documented process.",
            "naac_extended_data_id": 226,
            "files": [
              {
                "ID": 1018,
                "ExternalID": 1211,
                "filename": "1.2.1.xlsx",
                "pathurl": "NAAC/Supporting Docs/criteria-1/1.2/1.2.1 Data Template.xlsx",
                "pathname": "1.1.1"
              }
            ]
          },
          "level2": {
            "Title": "1.3.1: Institution provides an elective course system.",
            "naac_extended_data_id": 227,
            "files": [
              {
                "ID": 1021,
                "ExternalID": 1214,
                "filename": "1.3.1.xlsx",
                "pathurl": "NAAC/Supporting Docs/criteria-1/1.3/1.3.1 Data Template.xlsx",
                "pathname": "1.3.1"
              }
            ]
          }
        }
      }
    ]
  };
  constructor() { }
   getCreateMenuPage(): Observable<Inputfiledsbase<string>[]> {
        const questions: Inputfiledsbase<string>[] = [
          new TextboxQuestion({
            key: 'menu_name',
            label: 'Menu Name',
            required: true,
            order: 1,
          })
        ];
        return of(questions);
      }
      getCreateSubmenuPage(): Observable<Inputfiledsbase<string>[]> {
        const questions: Inputfiledsbase<string>[] = [
          new TextboxQuestion({
            key: 'submenu_name',
            label: 'Sub Menu Name',
            required: true,
            order: 1,
          })
        ];
        return of(questions);
      }

      getUploadSupportingDocs(): Observable<Inputfiledsbase<string>[]> {
        const levels = this.extractLevels(this.apiResponse);
        
        const questions: Inputfiledsbase<string>[] = [
          new DropdownQuestion({
            key: 'level',
            label: 'Level',
            required: true,
            options: levels,
            order: 1,
          }),
          new TextboxQuestion({
            key: 'title',
            label: 'Group Title',
            required: true,
            order: 2,
          }),
          new TextareaQuestion({
            key: 'description',
            label: 'Description',
            required: true,
            order: 3,
          })
        ];
      
        return of(questions);
      }
    
      private extractLevels(apiData: any): { key: string; id: string | number; value: number }[] {
        const naacData = apiData?.NaacExtended_Data || [];
    
        if (!naacData.length) {
          console.warn("No NAAC Data found!"); // ✅ Debugging: Log if no data is present
          return [];
        }
    
        const levelsObject = naacData[0].URLs || {};
    
        return Object.keys(levelsObject).map((levelKey, index) => ({
          id: index + 1,
          key: levelKey,
          value: index + 1 // Use the index as the numeric value
        }));
      }
    
    }
