<div class="dialog-content">
  <div class="notice-container">
    <img
      [src]="this.imagePath + '/' + data.notice.image_url"
      alt="Notice Image"
      class="notice-image"
    />
  </div>

  <div class="notice-details">
    <h2 class="notice-title">{{ data.notice.heading }}</h2>
    <p class="notice-description">{{ data.notice.description }}</p>
    <div class="notice-status">
      <p>
        <strong>Active From:</strong>
        {{ data.notice.visible_from | date : "dd/MM/yyyy" }}
      </p>
      <p>
        <strong>Active Till:</strong>
        {{ data.notice.visible_until | date : "dd/MM/yyyy" }}
      </p>
      <p>
        <strong>Status:</strong>
        <span
          [ngClass]="{
            'status-active': data.notice.status === 'Active',
            'status-inactive': data.notice.status !== 'Active'
          }"
        >
          {{ data.notice.status }}
        </span>
      </p>
    </div>
  </div>

  <div class="dialog-actions">
    <button mat-button class="cancel_button" (click)="closeDialog()">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="editNotice()">
      Edit
    </button>
    <button mat-raised-button color="warn" (click)="deleteNotice()">
      Delete
    </button>
  </div>
</div>
