import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore',
  standalone: true
})
export class RemoveUnderscorePipe implements PipeTransform {

  transform(value: string): unknown {
    return value? value.split('_').map((word:any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ') : value;
  }
}
