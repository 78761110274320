import { CommonModule, Time } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AcademicyearsformService } from '../../../../../inputfileds/form_services/academics/academicyearsform/academicyearsform.service';
import { PaginationComponent } from '../../../../../components/pagination/pagination.component';

export interface Shiftview {
  index: number,
  id: number,
  shift_name: string,
  shift_start_time: string,
  shift_end_time: string,
  shit_end_day: string,
  break_type_id: number,
  break_type: string,
  break_start_time: string,
  break_end_time:string
}

@Component({
  selector: 'app-shift',
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule,PaginationComponent],
  templateUrl: './shift.component.html',
  styleUrl: './shift.component.scss',
  providers:[ApiservicesService]
})
export class ShiftComponent implements OnInit{
  shiftdata = []
  displayedColumns: string[] = ['shift_name', 'shift_start_time', 'shift_end_time', 'shit_end_day', 'break_type', 'break_start_time'];
  dataSource = new MatTableDataSource<Shiftview>();
  selection = new SelectionModel<Shiftview>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  total_count: any;
  paginationData: any;
  @Input() item :any;
  constructor(private apisevice: ApiservicesService, private streamService: AcademicyearsformService ) {
  }

  ngOnInit(): void {
   this.getShiftdetails();
   this.streamService.refreshStreamList$.subscribe(() => {
    this.getShiftdetails();
  });
  }

  getShiftdetails() {
    this.apisevice.getshiftdetails(this.paginationData).subscribe((res) => {
      this.shiftdata = res?.rows;
      this.total_count= res.total_count;
      this.dataSource = new MatTableDataSource<Shiftview>(res.rows);
    });
  }
  pagination(event: any) {
    this.paginationData = event;
    this.getShiftdetails();
  }


  ngAfterViewInit(){
    this.getShiftdetails(); 
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Shiftview): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }
}
