<div class="container">
  <!-- <div class="btn btn-primary" (click)="downloadImage()">DOWNLOAD</div> -->
  <ng-container>
    <div class="position-relative">
      @if (isLoadingResults || isRateLimitReached) {
        <div class="loading-shade">
          @if (isLoadingResults) {
            <mat-spinner class="mat_spinner"></mat-spinner>
          }
          @if (isRateLimitReached) {
            <div class="rate-limit-reached">
                gettimetabledata API rate limit has been reached. It will be reset in one minute.
            </div>
          }
        </div>
      }
      <div class="ms-2">
        <h4 class="m-0">{{timetabledisplaydata.academic_year}} - Time Table</h4>
      </div>
        <div #screen class="m-2 overflow-auto">
            <table class="timetable-table bg-white">
              <tr>
                <th class="day-column"></th>
                <th class="time-column" *ngFor="let time of timetabledisplaydata.headersForTimeSlots">{{ time.start_time }}-{{time.end_time}}</th>
              </tr>
              
              <tr *ngFor="let day of keys(); let i = index">
                <td class="day-column">{{ day }}</td>
                 <ng-container *ngFor="let period of getDaydata(day)">
                  <td class="time-column" [attr.rowspan]="period.is_break === true && i == 0 ? 6 : null" [ngClass]="{'break': period.is_break === true}" *ngIf="period.is_break && i == 0">
                    <div class="subject-details">
                      <ng-container *ngIf="period.is_break && i == 0">
                          <div class="subject">Break</div>
                      </ng-container>
                    </div>
                  </td>
                  <td class="time-column time-column-height" *ngIf="!period.is_break">
                    <div class="subject-details">
                      <ng-container *ngIf="!period.is_break">
                        <div class="subject">{{ period.subject }}</div>
                        <div class="teacher" *ngIf="period.employee_name">{{ period.employee_name }}</div>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>
              </tr>
            </table>
          </div>
        <!-- <table mat-table [dataSource]="timetableData.days" class="mat-elevation-z8">
          <ng-container matColumnDef="academic_year">
            <th mat-header-cell *matHeaderCellDef>Academic Year</th>
            <td mat-cell *matCellDef="let element"> {{element.academic_year}} </td>
          </ng-container>
          <ng-container matColumnDef="shift_id">
            <th mat-header-cell *matHeaderCellDef>Shift</th>
            <td mat-cell *matCellDef="let element"> {{element.shift_id}} </td>
          </ng-container>
          <ng-container matColumnDef="course_id">
            <th mat-header-cell *matHeaderCellDef>Course Name</th>
            <td mat-cell *matCellDef="let element"> {{element.course_id}} </td>
          </ng-container>
          <ng-container matColumnDef="semister_id">
            <th mat-header-cell *matHeaderCellDef>Semisters</th>
            <td mat-cell *matCellDef="let element"> {{element.semister_id}} </td>
          </ng-container>
          <ng-container matColumnDef="section">
            <th mat-header-cell *matHeaderCellDef>Section</th>
            <td mat-cell *matCellDef="let element"> {{element.section}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
          </tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
          </tr>
        </table> -->
      </div>
  </ng-container>
  <div id="download">
    <img #canvas>
    <a #downloadLink></a>
  </div>
  </div>