<section class="mx-3">
    <ng-container>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page_header">
          <h3 class="m-0 page_header_title">Designation List</h3>
        </div>
        <!-- <div *ngIf="actiondata?.create"> -->
          <button mat-raised-button color="primary" (click)="openAddDesignationDialog()" class="px-5 add_button">Add</button>
        <!-- </div> -->
      </div>
      <div class="position-relative">
        <!-- *ngIf="actiondata?.list_view" -->
      <div class="mt-2 table-container"> 
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Sl No. </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
    
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row">
              <button class="action_button" mat-raised-button (click)="showsemesterdetails(row)">
                View
              </button>
            </td>
          </ng-container> -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
          </tr>
        </table>
      </div>
      <div class="mt-2" *ngIf="total_count > 5">
        <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
      </div>
      </div>
    </ng-container>