import { Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { CoursesService } from '../../../inputfileds/form_services/courses/courses.service';
import { Observable } from 'rxjs';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { ToastComponent } from '../../../components/toast/toast.component';
import { ToastService } from '../../../Services/toast/toast.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { DesignationsService } from '../../../inputfileds/form_services/designations/designations.service';

export interface Designationmodel {
  name: string;
  department_id: number;
  designation_code: string;
}

@Component({
  selector: 'app-adddesignations',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    ToastComponent
  ],
  providers: [QuestionControlService, CoursesService, ApiservicesService, DropdownservicesService],
  templateUrl: './adddesignations.component.html',
  styleUrl: './adddesignations.component.scss'
})
export class AdddesignationsComponent {

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  desinationform!: FormGroup;
  datesend!: Designationmodel;
  semisterflag: boolean = false;
  coursedata: any = {};

  constructor(
    private dialogRef: MatDialogRef<AdddesignationsComponent>,
    private apiservice: ApiservicesService,
    private services: DesignationsService,
    private router: Router,
    private qcs: QuestionControlService,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.questions$ = this.services.getdesignationform();
    this.questions$.subscribe(q => this.questions = q);
  }

  ngOnInit(): void {
    this.desinationform = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  closeDialog(event: any) {
    // this.dialogRef.close();
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
    setTimeout(() => { this.dialogRef.close(event); }, 1000);
  }

  onSubmit() {
   
    this.datesend = {
      department_id: parseInt(this.desinationform.value.department_id),
      name: this.desinationform.value.name,
      designation_code: this.desinationform.value.designation_code,
    }
    this.createAPi(this.datesend)
  }

  createAPi(event: any) {
    this.apiservice.createdesgination(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        // if (this.data.total_count == 0) {
        //   this.apiservice.onboarding_status_updation({ "module_name": "COLLEGE_MANAGEMENT_SYSTEM", "sub_module_name": "COURSES" }).subscribe();
        // }
        this.toast.showToast('success', 'Saved Successfuly');
        this.coursedata = res.data;
        // this.desinationform.reset();
        this.closeDialog(true);
        // window.location.href = this.router.url;
        // this.closeDialog(true);
      }
      else {
        this.toast.showToast('danger', `Error: ${res.message}`);
      }
    })
  }
}
