import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { Observable, of, Subject } from 'rxjs';
import { DatepickerQuestion } from '../../question-datepicker';
import { TextboxQuestion } from '../../question-textbox';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { CheckboxQuestion } from '../../question-checkbox';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { LeaveSummaryComponent } from '../../../pages/attendance/leaveutilities/leave-summary/leave-summary.component';

@Injectable({
  providedIn: 'root'
})
export class LeavesummaryService {
  
  EmployeesDropData: any = [];
  LeavetypeDropData: any = [];
  DepartmentsDropData:any = [];
  eventchnage:any;
  private createReqData = new Subject<void>();
  createList = this.createReqData.asObservable();
  private notifyleavesummaryComponentSubject = new Subject<void>();
  notifyleavesummaryComponent$ = this.notifyleavesummaryComponentSubject.asObservable();

  private notifyleavehistoryComponentSubject = new Subject<void>();
  notifyleavehistoryComponent$ = this.notifyleavehistoryComponentSubject.asObservable();

  constructor(private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('EMPLOYEES', this.EmployeesDropData);
    this.loadDropdownData('LEAVE_TYPES', this.LeavetypeDropData);
    this.loadDropdownData('DEPARTMENTS', this.DepartmentsDropData);
    // this.eventchnage =  this.leavesummatycomponet.getrequestdata();
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'EMPLOYEES') {
      observable = this.dropdownservice.getemployeedrop();
    } else if (code === 'LEAVE_TYPES') {
      observable = this.dropdownservice.getassignleavedrop();
    } else if (code === 'DEPARTMENTS'){
      observable = this.dropdownservice.getdepartmentdrop();
    }else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      dataArray.push({
        id: 0,
        key: 'All',
        value: 0,
      });
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'EMPLOYEES') {
          lookupname = res[i].name;
        } else if (code === 'LEAVE_TYPES') {
          lookupname = res[i].leave_code;
        } else if (code === 'DEPARTMENTS') {
          lookupname = res[i].department_code;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  triggerleavesummaryComponentFunction() {
    this.notifyleavesummaryComponentSubject.next(); // Emit event to notify component
  }

  triggerleavehistoryComponentFunction() {
    this.notifyleavehistoryComponentSubject.next(); // Emit event to notify component
  }

  getleavesummaryform() {
    const questions: Inputfiledsbase<string>[] = [

      new TextboxQuestion({
        key: "employee_id",
        label: '',
        placeholder: 'Enter Name',
        required: false,
        options: this.EmployeesDropData,
        order: 1,
        onChange: this.triggerleavesummaryComponentFunction.bind(this)
      }),
    
      new DatepickerQuestion({
        key: 'start_date',
        label: '',
        placeholder: 'Enter start date',
        required: false,
        order: 2,
        onChange: this.triggerleavesummaryComponentFunction.bind(this)
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: '',
        placeholder: 'Enter end date',
        required: false,
        order: 3,
        onChange: this.triggerleavesummaryComponentFunction.bind(this)
      }),

      new DropdownQuestion({
        key: "leave_type_id",
        label: '',
        placeholder: 'Choose leave type',
        required: false,
        options: this.LeavetypeDropData,
        order: 4,
        onChange: this.triggerleavesummaryComponentFunction.bind(this)
      }),

      new DropdownQuestion({
        key: "department_id",
        label: '',
        placeholder: 'Choose department',
        required: false,
        options: this.DepartmentsDropData,
        order: 4,
        onChange: this.triggerleavesummaryComponentFunction.bind(this)
      }),

    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getleavehistoryform() {
    const questions: Inputfiledsbase<string>[] = [

      new DatepickerQuestion({
        key: 'start_date',
        label: '',
        placeholder: 'Enter start date',
        required: false,
        order: 2,
        onChange: this.triggerleavehistoryComponentFunction.bind(this)
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: '',
        placeholder: 'Enter end date',
        required: false,
        order: 3,
        onChange: this.triggerleavehistoryComponentFunction.bind(this)
      }),

      new DropdownQuestion({
        key: "leave_type_id",
        label: '',
        placeholder: 'Choose leave type',
        required: false,
        options: this.LeavetypeDropData,
        order: 4,
        onChange: this.triggerleavehistoryComponentFunction.bind(this)
      }),

    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
