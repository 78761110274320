import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { MultiSelectQuestion } from '../../question-multiselect';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  organisationdata: any = [];
  courselist: any = [];
  course_type: any = [];
  duration_type: any = [];
  stream: any = [];
  stream_type: any = [];
  medium_type:any = []; 

  constructor(private apiservice : ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('COURSE_TYPES', this.course_type);
    this.loadDropdownData('DURATION_TYPES', this.duration_type);
    this.loadDropdownData('STREAM', this.stream);
    this.loadDropdownData('STREAM_TYPES', this.stream_type);
    this.loadDropdownData('MEDIUM_OF_INSTRUCTION', this.medium_type);
}

private loadDropdownData(code: string, dataArray: any[]) {
  let observable: Observable<any>;

  if (code === 'ORGANISATION') {
    observable = this.dropdownservice.getorganizationdrop();
  } else if (code === 'STREAM') {
    observable = this.dropdownservice.getstreamdropdown();
  } else {
    observable = this.dropdownservice.getlookupcodesdropdown(code);
  }

  observable.subscribe((res) => {
    for (let i = 0; i < res.length; i++) {
      let lookupname;
      if (code === 'ORGANISATION') {
        lookupname = res[i]?.short_name;
      } else if (code === 'STREAM') {
        lookupname = res[i]?.name;
      } else {
        lookupname = res[i]?.lookup_name;
      }
      dataArray.push({
        id: res[i].id,
        key: lookupname,
        value: res[i].id,
      });
    }
  });
}


  getcoursesform(){
    const questions: Inputfiledsbase<string>[] = [
    
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization ID',
        required: true,
        options: this.organisationdata,
        order: 1
      }),

      new TextboxQuestion ({
        key: 'course_name',
        label: 'Courses Name',
        required: true,
        order: 2
      }),
      
      new DropdownQuestion({
        key: 'course_type_id',
        label: 'Course Type',
        required: true,
        options: this.course_type,
        order: 3
      }),

      new MultiSelectQuestion({
        key: 'medium',
        label: 'Medium',
        required: true,
        options: this.medium_type,
        order: 4,
      }),
      
      new DropdownQuestion({
        key: 'stream_id',
        label: 'Stream',
        required: true,
        options: this.stream,
        order: 4
      }),

      // new DropdownQuestion({
      //   key: 'stream_type_id',
      //   label: 'Stream Type',
      //   required: true,
      //   options: this.stream_type,
      //   order: 5
      // }),

      new DropdownQuestion({
        key: 'duration_type_id',
        label: 'Duration Type',
        required: true,
        options: this.duration_type,
        order: 6
      }),

      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        required: true,
        order: 7,
      }),

      new TextboxQuestion ({
        key: 'duration',
        label: 'Course Duration',
        required: true,
        order: 8,
        patterns: '^[1-7]$'
      }),

      new TextboxQuestion ({
        key: 'course_code',
        label: 'Courses Code',
        required: true,
        order: 9
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order: 10
      }),

      new TextboxQuestion({
        type: 'number',
        key: 'number_of_semisters',
        label: 'Number of Semesters',
        required: true,
        order: 11,
        maxLength: 8,
        patterns: '^[1-8]$'
      }),
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
