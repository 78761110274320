<section class="banner-section">
  <div class="header">
    <h2>Add Banners</h2>
  </div>
  <mat-divider></mat-divider>

  <form [formGroup]="bannersForm" class="form-container">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <!-- Banner Title Input -->
          <app-question
            [question]="questions[0]"
            [form]="bannersForm"
          ></app-question>

          <!-- Upload Box -->
          <div class="upload-container">
            <input
              #fileInput
              hidden
              type="file"
              (change)="imageInputChange($event)"
            />
            <div class="upload-box" (click)="fileInput.click()">
              <ng-container *ngIf="!selectedImageUrl">
                <img
                  src="../../../../../../assets/images/bulkupload_logo.svg"
                  alt="Upload Banner"
                  class="upload-icon"
                />
                <p class="image-dimensions-note">
                  *Recommended: 1920px (W) × 720px (H)
                </p>
              </ng-container>

              <!-- Display Selected Image -->
              <ng-container *ngIf="selectedImageUrl">
                <img
                  [src]="selectedImageUrl"
                  alt="Selected Image"
                  class="uploaded-image"
                />
              </ng-container>
            </div>
          </div>

          <!-- Additional Inputs -->
          <div class="additional-fields">
            <app-question
              [question]="questions[1]"
              [form]="bannersForm"
            ></app-question>
            <app-question
              [question]="questions[2]"
              [form]="bannersForm"
            ></app-question>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- Buttons Section -->
  <mat-dialog-actions align="center" class="button-actions">
    <button mat-stroked-button (click)="closeDialog(false)" class="cancel-btn">
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      class="submit-btn"
      type="submit"
      [disabled]="!bannersForm.valid"
      (click)="onSubmit()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</section>
