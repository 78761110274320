import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TimetablesService } from '../../../../inputfileds/form_services/timetable/timetables.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { filter, Observable } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ToastService } from '../../../../Services/toast/toast.service';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { TimetablesComponent } from '../timetables.component';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Component({
  selector: 'app-addtimetables',
  standalone: true,
  templateUrl: './addtimetables.component.html',
  styleUrl: './addtimetables.component.scss',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatTableModule,
    MatCardModule,
    ToastComponent
  ],
  providers: [QuestionControlService, TimetablesService, ApiservicesService, DropdownservicesService],
})

export class AddtimetablesComponent implements OnInit {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  AddSlotQuestions: Inputfiledsbase<string>[] | null = [];
  AddSlotQuestions$: Observable<Inputfiledsbase<any>[]> | null;
  addTimeslotform!: FormGroup;
  timeslotform!: FormGroup;
  timetableform!: FormGroup;
  timetableArrForm!: FormArray;
  showForm: boolean = false;
  organisationdata: any = [];
  timetable_subjects: any = [];
  employeedropdata: any = [];
  errormessage: any;
  editMode: boolean = false;
  currentEditingSlotIndex: number | null = null;
  timeSlots: any[] = [];
  TimeSlotsData: any;
  slotsflag: boolean = false;

  constructor(
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService, 
    services: TimetablesService,
    private router: Router,
    private fb: FormBuilder,
    private qcs: QuestionControlService,
    private toast: ToastService,
  ) {
    this.questions$ = services.getAddTimetableform();
    this.questions$.subscribe((q) => (this.questions = q));
    this.AddSlotQuestions$ = services.getAddTimeSlotform();
    this.AddSlotQuestions$.subscribe((q) => (this.AddSlotQuestions = q));

    this.addTimeslotform = this.fb.group({
      academic_calender_id: [''],
      organization_id: [''],
      start_day: [''],
      end_day: [''],
      start_time: [''],
      end_time: [''],
    });
  }
  
  ngOnInit(): void {
    this.timeslotform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
    // this.addTimeslotform = this.qcs.toFormGroup(
    //   this.AddSlotQuestions as Inputfiledsbase<string>[]
    // );
    // this.gettimetabledata();
    this.timetableform = this.fb.group({
      rows: this.fb.array([]) // Create empty form array initially
    });
  }

  onExit() {
    this.router.navigate(['timetable']);
    // this.timetablecomponent.gettimetablelist();
  }

  // fetchcreatetimetable(){
  // }

  prepopulateRows(timeslotdata: any) {
    this.rows.clear();
    for (let i = 0; i < timeslotdata.length; i++) {
    // for (let i = 0; i < 2; i++) {
      this.rows.push(this.createRow());
      this.rows.at(i).setValue({ start_day: timeslotdata[i]?.start_day, start_time: timeslotdata[i]?.start_time, id: timeslotdata[i]?.id, end_time: timeslotdata[i]?.end_time, subject: timeslotdata[i]?.subject_id, employee: 0, is_break: timeslotdata[i]?.is_break== 0 ? false : true, order:timeslotdata[i]?.order == null ? 0 : timeslotdata[i]?.order})
    }
  }

  createRow(): FormGroup {
    return this.fb.group({
      id: [''],
      start_time: [''],
      end_time: [''],
      start_day: [''],
      is_break:[''],
      order:[''],
      subject: ['', Validators.required], // Pre-selected subjects if required
      employee: ['', Validators.required] // Lecturer is empty and will be populated later
    });
  }

  get rows(): FormArray {
    return this.timetableform.get('rows') as FormArray;
  }

  onSubjectChange(subject_event: any, index: number): void {
    let selectedsubjectobject = JSON.parse(subject_event.target.value);
    this.dropdownservice.getEmployeedrop({ department_id: selectedsubjectobject.department_id }).subscribe((res: any) => {
      this.employeedropdata[index] = res;
    })
  }

  onchangesubjects(subject_event: any, index: number) {
    let selectedsubjectobject = JSON.parse(subject_event.target.value);
    this.dropdownservice.getEmployeedrop({ department_id: selectedsubjectobject.department_id }).subscribe((res: any) => {
      this.employeedropdata[index] = res;
    })
  }

  gettimetabledata() {
    const formdata = this.timeslotform.getRawValue();
    const demodata = {
      "organization_id": parseInt(formdata.organization_id),
      "course_id": parseInt(formdata.course_id),
      "semister_id": parseInt(formdata.semister_id),
      "shift_id": parseInt(formdata.shift_id),
      "academic_year_id": parseInt(formdata.academic_year_id),
      // "section_id": parseInt(formdata.),
      // "section": parseInt(formdata.)
    }
    this.apiservice.getTimeTabledata(demodata).subscribe({
      next: (res: any) => {
        if (res && res.timeSlots) {
          this.slotsflag = true;
          this.timeSlots = res.timeSlots;
          this.prepopulateRows(res.timeSlots);
          this.timetable_subjects = res.subjects;
        } else {
          // // console.warn('No timeslots found for the given shift_id:', shift_id);
        }
      },
      error: (err) => {
        console.error('Error fetching timeslots:', err)
      },
    });
  }

  onsubmit() {
    const formdata = this.timeslotform.getRawValue()
    let demoarrary = this.timetableform.getRawValue().rows;

    demoarrary.map((demo: any, index: number) => {
      if (demo.subject != 0) {
        demoarrary[index] = { id: demo.id, start_time: demo.start_time, end_time: demo.end_time, start_day: demo.start_day, subject_id: JSON.parse(demo.subject).id, subject_name: JSON.parse(demo.subject).subject_name, employee_id: JSON.parse(demo.employee).id, employee_name: JSON.parse(demo.employee).name, order:JSON.parse(demo.order),  is_break:JSON.parse(demo.is_break)}
      }
    })
    let submitdata: any = {
      "organization_id": parseInt(formdata.organization_id),
      "shift_id": parseInt(formdata.shift_id),
      // "academic_regulation_id": 26,
      "academic_year_id": parseInt(formdata.academic_year_id),
      "course_id":  parseInt(formdata.course_id),
      "semister_id": parseInt(formdata.semister_id),
      "section_id": parseInt(formdata.section_id),
      "timeSlots": demoarrary
    };
    this.apiservice.createTimeTabledata(submitdata).subscribe(
      (res: any) => {
        if (res.code == 201) {
          this.rows.clear();
          this.addTimeslotform.reset();
          this.toast.showToast('success', 'Time Table Created successfully');
          this.onExit();
        } else {
          this.toast.showToast('danger', `Error: ${res.message}`);
          // this.errorMessage = res.message;
        }
      })
  }
}
