import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appLeavecardcolor]',
  standalone: true
})
export class LeavecardcolorDirective {

  @Input() appLeavecardcolor: string = ''; // The status input to control the color

  constructor(private ele: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appLeavecardcolor']) {
      this.setColor(this.appLeavecardcolor);
    }
  }

  private setColor(status: string): void {
    switch (status) {
      case 'CL':
        this.renderer.setStyle(this.ele.nativeElement, 'background-color', '#FFE58F'); // Text color for readability
        break;
      case 'SickLeave':
        this.renderer.setStyle(this.ele.nativeElement, 'background-color', '#42526E');
        break;
      case 'COMP_OFF':
        this.renderer.setStyle(this.ele.nativeElement, 'background-color', '#B8F8BA');
        break;
      default:
        this.renderer.setStyle(this.ele.nativeElement, 'background-color', 'transparent');
        // this.renderer.setStyle(this.ele.nativeElement, 'color', 'black');
        break;
    }
  }
}
