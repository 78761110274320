import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { AuthenticationService } from '../../components/Authentication/services/authentication.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment.prod';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-studentprofileview',
  standalone: true,
  imports: [MatTabsModule, CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './studentprofileview.component.html',
  styleUrl: './studentprofileview.component.scss'
})
export class StudentprofileviewComponent implements OnInit {
  user: any;
  studentData: any;
  id: any;
  studentprofile: any;
  studentaddress: any;
  studenteducation: any;
  onerror = 'https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg'
  url: any;
  updateImgflag: boolean = false;
  imageUrl: any;
  studentfamily: any;
  dataId: number | undefined;

  constructor(
    private apiservice: ApiservicesService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute
  ) {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x => {
        this.user = JSON.parse(x)
      });
    }

  }
  getstudentdetails(event: any) {
    this.apiservice.getstudentdetails(event).subscribe(studentdata => {
      this.studentData = studentdata;
      this.imageUrl = environment.ASSET_URL + environment.ASSET_CONTAINER + '/' + this.studentData.image_path;
      this.studentprofile = studentdata.personal_details;
      this.studentaddress = studentdata.addresses;
      this.studenteducation = studentdata.education_qualifications;
      this.studentfamily = studentdata.family_details;
      console.log("student data", this.studentData);
    })
  }
  ngOnInit(): void {
    // this.route.params.subscribe(params => {

    if (this.route.snapshot.queryParams['dataId'] && !isNaN(this.route.snapshot.queryParams['dataId'])) {
      this.dataId = this.route.snapshot.queryParams['dataId'];
      this.getstudentdetails(this.dataId);
    } else {
      this.dataId = this.user?.data?.id;
      this.getstudentdetails(this.dataId)
    }
    // });
    // this.getstudentdetails();
  }

  csvInputChange(fileInputEvent: any) {
    console.log(fileInputEvent.target.files[0]);
    this.updateimage(fileInputEvent.target.files[0])
    if (fileInputEvent.target.files && fileInputEvent.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(fileInputEvent.target.files[0]);
      reader.onload = (fileInputEvent) => {
        this.url = fileInputEvent.target?.result;
        this.studentData.image_path = this.url;
        // this.updateImgflag = true;
      }
    }
  }

  updateimage(event: any) {
    this.apiservice.updatestudentimage(event, this.dataId).subscribe(res => {
      console.log("student image upload", res);
      this.getstudentdetails(this.dataId);
    })
  }

}
