import { Component, OnInit } from '@angular/core';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DatacontrolService } from '../../../../Services/controllers/datacontrol.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-leaverequestview',
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule, MatIconModule, MatMenuModule],
  templateUrl: './leaverequestview.component.html',
  styleUrl: './leaverequestview.component.scss',
  providers: [ApiservicesService, DatacontrolService]
})
export class LeaverequestviewComponent implements OnInit {

  leaverequestdata: any = [];
  displayedColumns: string[] = ['select', 'leave_type', 'leave_mode', 'start_date', 'end_date', 'reason', 'actions'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  requesttype: any;

  ngOnInit(): void {
  }

  constructor(private apisevice: ApiservicesService, private requestservice: DatacontrolService, private router: Router, public aRoute: ActivatedRoute) {
    // this.requestservice.getleaverequestcontrol.subscribe(req =>{
    //   this.requesttype = req
    // });
    this.requesttype = this.aRoute.snapshot.queryParamMap.get('ReqestType')
    if(typeof this.requesttype === undefined){
      this.backnavigation()
    }else{
      this.getrequestdata(this.requesttype)
    }
  }

  getrequestdata(event:any) {
    this.apisevice.getleaverequestdetails(event).subscribe((res) => {
      this.leaverequestdata = res?.rows;
      this.dataSource = new MatTableDataSource<any>(res.rows)
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  isSticky(column: string): boolean {
    return column === 'col6' ? true : false;
  }

  actionrequestapi(event: any, id: number) {
    const actionRequest = {
      "status": event
    }
    this.apisevice.actionleaverequest(actionRequest, id).subscribe((res) => {
      if (res.code == 204 && res.status === true) {
        this.getrequestdata(event);
      }
    })
  }

  backnavigation() {
    this.router.navigate(['/requests']);
  }
}
