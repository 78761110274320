<!-- <div>
  <button (click)="openleaveDialog('leave')">open</button>
</div> -->
<div class="row m-2">
  <div class="col-sm-8 col-md-8">
    <div class="row">
      <div class="col-md-6">
        <h2>My Attendance ({{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }})</h2>
      </div>
      <div class="col-md-6 text-end">
        <div class="btn-group">
          <button class="cal_perv_btn"  mat-raised-button color="primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
            Previous
          </button >
          <button  mat-raised-button mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="yearmonth(viewDate)">
            Today
          </button >
          <button class="cal_next_btn"  mat-raised-button color="primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
            Next
          </button >
        </div>
      </div>
    </div>
    <div>
      <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"
        (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)">
      </mwl-calendar-month-view>
    </div>
    <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
      let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody">
      <div class="calendardata_box">
      <div class="position-absolute end-0">
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div
        [ngStyle]="day?.events[0]?.meta.start_time ? {'height': '45px', 'padding-top': '25px'} : {'height': '35px', 'padding-top': '25px'}">
        <ng-container *ngIf="day?.events[0]?.meta.start_time">
          <div class="cal-day-notes px-2 py-2 lh-1">
            <p class="m-0"><small class="fs_11">{{day?.events[0]?.meta.start_time}} </small></p>
            <p class="m-0"><small class="fs_11">{{day?.events[0]?.meta.end_time}}</small></p>
          </div>
          <div class="cal-day-notes px-2 d-flex justify-content-start lh-1">
            <p class="m-0 px-2 rounded-pill duration_data"
              [ngStyle]="day?.events[0]?.meta.duration >= 4 ? {'background-color':'#CBFACD','color': '#20B026'} : {'background-color': '#FAF5CB', 'color': '#AB8355'}">
              <small class="fs_11">{{day?.events[0]?.meta.duration}}:00 Hrs</small></p>
          </div>
        </ng-container>
      </div>
      <div>
        <div class="text-start px-2">
          <div *ngIf="day.events.length >= 2">
            <div class="cal-event" [style.backgroundColor]="day.events[1].color.primary"
              [ngClass]="day.events[1]?.cssClass"
              [mwlCalendarTooltip]="day.events[1].title | calendarEventTitle:'monthTooltip':day.events[1]"
              [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="day.events[1]" [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody">
            </div>
          </div>
          <div *ngIf="day.events.length == 1">
            <div class="cal-event" [style.backgroundColor]="day.events[0].color.primary"
              [ngClass]="day.events[0]?.cssClass"
              [mwlCalendarTooltip]="day.events[0].title | calendarEventTitle:'monthTooltip':day.events[0]"
              [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="day.events[0]" [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody">
            </div>
          </div>
        </div>
        <!-- <ng-container *ngFor="let event of day.events"> -->
        <div class="leave_menu_show" *ngIf="day.inMonth">
          <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="more option"
            [ngStyle]="{'color':'white'}" fontIcon="more_vert"></mat-icon>
          <mat-menu #menu="matMenu">
            <small mat-menu-item (click)="handleAction('leave', day)">Apply Leave</small>
            <small mat-menu-item (click)="handleAction('compOff', day)">Apply Comp Off</small>
            <small mat-menu-item (click)="handleAction('permission', day)">Apply Permission</small>
          </mat-menu>
        </div>
        <!-- </ng-container> -->
      </div>
    </div>
    </ng-template>
    <div class="">
      <div *ngFor="let items of Representaion_data" class="representation">
        <div class="representation_color" [ngStyle]="{'background-color': items.color}"></div>
        <div class="representation_text">{{items.title}}</div>
      </div>
    </div>
  </div>
  <div class="col-sm-4 col-md-4 col-12">
    <div class="row">
      <div class="col-sm-6">
        <div class="bg-green border_radius_4">
          <div class="px-2 py-2">
            <h2 class="m-0">{{total_present_days}}</h2>
            <p class="m-0">Present Days</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="bg-pink border_radius_4">
          <div class="px-2 py-2">
            <h2 class="m-0">{{total_absent_days}}</h2>
            <p class="m-0">Absent Days</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white mt-2 border_radius_4">
      <div class="px-2 py-4">
        <div>
          <small>Attendance Percentage</small>
        </div>
        <div class="mt-2">
          <mat-progress-bar mode="determinate" [color]="color" [value]="attendance_percentage"></mat-progress-bar>
        </div>
      </div>
    </div>
    <ng-container *ngIf="dataSource.data.length > 0">
    <div class="mt-4">
      <mat-card class="mat-card-1 box-shadow-none status_table_height">
        <mat-card-header class="mat-card-header-1">
          <mat-card-title class="mat-card-title-1">My Leaves</mat-card-title>
        </mat-card-header>
        <hr class="m-0" />
        <mat-card-content class="mat-card-content-1">
          <div class="example-table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

              <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef> Date </th>
                <td mat-cell *matCellDef="let element" class="fs-12 no_table_border"> {{element.start_date}} </td>
              </ng-container>

              <ng-container matColumnDef="leave_mode">
                <th mat-header-cell *matHeaderCellDef> Day </th>
                <td mat-cell *matCellDef="let element" class="fs-12 no_table_border"> {{element.leave_mode | removeUnderscore}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element" class="fs-12 no_table_border" [appStatusColor]="element.status">{{element.status | removeUnderscore}} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let row" class="p-0 no_table_border">
                  <button mat-icon-button class="action_button p-0" aria-label="menu" style="height: 20px; width: 20px;"> 
                    <!-- [matMenuTriggerFor]="actionrequest" -->
                      <mat-icon>more_vert</mat-icon>
                  </button>
                  <!-- <mat-menu #actionrequest="matMenu">
                          <button *ngIf="requesttype != 'LEAVE_CANCELLATION_REQUEST'" mat-menu-item class="action_button" (click)="actionrequestapi('APPROVED', row?.id)">
                              <span>Approved</span>
                          </button>
                          <button *ngIf="requesttype == 'LEAVE_CANCELLATION_REQUEST'" mat-menu-item class="action_button" (click)="actionrequestapi('CANCELLED', row?.id)">
                              <span>Approved</span>
                          </button>
                  </mat-menu> -->
              </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  </div>
</div>