import { Component } from '@angular/core';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CourseView } from '../../../courses/courses.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { workService } from '../../../../inputfileds/form_services/workservice/workservice.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from "../../../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

export interface leaveentitiesView {
  index: number,
  id: number,
  leave_name: string,
  designation: string,
  maximum_allowed_leaves: number,
}
@Component({
  selector: 'app-leave-entities',
  standalone: true,
  templateUrl: './leave-entities.component.html',
  styleUrl: './leave-entities.component.scss',
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    CommonModule,
    ToastComponent,
    MatMenuModule,
    MatMenuTrigger
  ],
  providers: [QuestionControlService, workService, ApiservicesService, DropdownservicesService],
})
export class LeaveEntitiesComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  Arrayform!: FormGroup;
  payLoad: any;
  leave_entitlementsData = []
  displayedColumns: string[] = ['leave_name', 'designation', 'maximum_allowed_leaves'];
  dataSource = new MatTableDataSource<leaveentitiesView>();
  selection = new SelectionModel<leaveentitiesView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  arrayquestions$: Observable<Inputfiledsbase<string>[]>;
  arrayquestions: Inputfiledsbase<string>[] | null = [];
  editeducationarrayflag: boolean = true;
  addformarry: any = [];
  editarrayindex: any;
  editarrayid: any;

  constructor(
    private apiservice: ApiservicesService,
    private dropdownservice: DropdownservicesService,
    private router: Router,
    private qcs: QuestionControlService,
    private service: workService,
    private toast: ToastService) {
    this.questions$ = this.service.getleave_entitlementsform();
    this.questions$.subscribe(q => this.questions = q);
    this.arrayquestions$ = this.service.getleave_entitlementsArrayform();
    this.arrayquestions$.subscribe(q => this.arrayquestions = q);

    this.getleavetypedeatils();
  }
  getleavetypedeatils() {
    this.apiservice.getleave_entitlementsdetails().subscribe((res) => {
      this.leave_entitlementsData = res.rows;
      this.dataSource = new MatTableDataSource<leaveentitiesView>(res.rows)
    })
  }
  refreshDataSource(): void {
    this.getleavetypedeatils();
  }

  showadd() {
    this.form.reset();
    this.Arrayform.reset();
    this.addformarry = [];
    this.showaddflag = !this.showaddflag;
  }
  
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.Arrayform = this.qcs.toFormGroup(this.arrayquestions as Inputfiledsbase<string>[]);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: leaveentitiesView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  arraySubmit() {
    if (this.Arrayform.valid) {
      let submitaaryadata = this.Arrayform.getRawValue();
      forkJoin([
      this.dropdownservice.getdesignationsdrop(),
    ]).subscribe(([designationRes]) => {
      const filteredStatus = designationRes.find((dropfilter:any)=>{ return dropfilter.id == this.Arrayform.value.designation_id });
      submitaaryadata = { ...submitaaryadata, designation: filteredStatus?.name };
      this.addformarry.push(submitaaryadata);
      this.Arrayform.reset();
      this.editeducationarrayflag = true;
    });
    }
  }

  editarrayform(event: any, index: number) {
    this.editeducationarrayflag = false;
    this.editarrayindex = index;
    this.editarrayid = event.id;
    this.Arrayform.patchValue(event);
  }


  editarrySubmit() {
    this.addformarry[this.editarrayindex] = this.Arrayform.getRawValue();
    this.addformarry[this.editarrayindex].id = this.editarrayid;
    this.resetarrayform();
    this.editeducationarrayflag = true;
    this.editarrayindex = null;
  }

  deletearrayform(event: number) {
    this.addformarry.splice(event, 1);
  }

  resetarrayform() {
    this.Arrayform.reset();
    this.editeducationarrayflag = true;
  }

  onSubmit() {
    this.payLoad = this.form.getRawValue();
    this.datesend = this.form.value;
    this.payLoad.allocated_leaves = this.addformarry;
    this.createAPis(this.payLoad);
  }

  createAPis(event: any) {
    this.apiservice.createleave_entitlements(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast(
          'success',
          'Submited Successfuly',);
        this.form.reset();
        this.showadd();
        this.refreshDataSource()
      } else {
        this.toast.showToast('danger', `Error: ${res.message}`);
      }
    })
  }

}

