import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';

@Component({
  selector: 'app-banner-dialog',
  templateUrl: './banner-detail-dialog.component.html',
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
  ],
  standalone: true,
  styleUrls: ['./banner-detail-dialog.component.scss'],
})
export class BannerDetailDialog {
  imagePath = this.apiservice.environmentPath;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<BannerDetailDialog>,
    private apiservice: ApiservicesService // added service for API calls
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  editBanner(): void {
    if (this.data.editCallback) {
      this.data.editCallback(this.data.banner);
    }
    this.dialogRef.close();
  }

  deleteBanner(): void {
    if (this.data.deleteCallback) {
      this.data.deleteCallback(this.data.banner.id);
    }
    this.dialogRef.close();
  }
}
