<!-- <div> -->
    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <div>
            <ng-container *ngIf="error_message !== ''" >
                <p class="text-center fs-6" style="color: red;">{{error_message}}</p>
            </ng-container>
            <ng-container *ngIf="flagswitch == 'email'">
            <div class="d-flex align-items-center mb-2">
                <div class="bg-grey me-2 p-1 cursor_pointer" (click)="disableflag(false)"><mat-icon>arrow_back</mat-icon></div>
                <div><p class="m-0" style="font-size: 18px; font-weight: 400;">Reset Your Password</p></div>
            </div>
            <div class="card login_card">
                <div class="card-body">
                    <div class="text-center my-3">
                        <div style="font-size: 14px">Enter Registered Email to Receive OTP</div>
                    </div>
                    <form [formGroup]="emailform" (ngSubmit)="onSubmitemailID()" class="pt-2">
                        <div class="form-group mb-3">
                            <label>User mail ID</label>
                            <input type="text" formControlName="useremailid" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && femailform['useremailid'].errors }" />
                            @if (submitted && femailform['useremailid'].errors) {
                            <div class="invalid-feedback">
                                @if (femailform['useremailid'].errors['required']) {
                                <div>User Email Id is required</div>
                                }
                            </div>
                            }
                        </div>
                        <button class="px-5 form-control" type="submit" mat-flat-button color="primary">Send OTP  
                            <!-- <mat-icon>arrow_forward</mat-icon> -->
                        </button>
                    </form>
                    <div class="text-center mt-5">
                        <p style="font-size: 14px text_drak_light">Contact Admin Team if you Unable to Reset your Password</p>
                    </div>
                </div> 
                </div>            
            </ng-container>
            <ng-container *ngIf="flagswitch == 'verify'">
                <div class="d-flex align-items-center mb-2">
                    <div class="bg-grey me-2 p-1 cursor_pointer" (click)="this.flagswitch = 'email'"><mat-icon>arrow_back</mat-icon></div>
                    <div><p class="m-0" style="font-size: 18px; font-weight: 400;">Reset Your Password</p></div>
                </div>
                <div class="card login_card">
                <div class="card-body">
                    <div class="text-center my-3">
                        <div style="font-size: 14px">Verification Code Sent to <b> {{emailform.value.useremailid}} </b></div>
                    </div>
                    <form [formGroup]="verificationform" (ngSubmit)="onSubmitverification()" class="pt-2">
                        <div class="form-group mb-3">
                            <label>Enter Verification Code</label>
                            <input type="text" formControlName="verification_code" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && fverificationform['verification_code'].errors }" />
                            @if (submitted && fverificationform['verification_code'].errors) {
                            <div class="invalid-feedback">
                                @if (fverificationform['verification_code'].errors['required']) {
                                <div>Verification Code is required</div>
                                }
                            </div>
                            }
                        </div>
                        <button class="px-5 form-control" type="submit" mat-flat-button color="primary">Verify  
                            <!-- <mat-icon>arrow_forward</mat-icon> -->
                        </button>
                    </form>
                    <div class="text-center mt-5">
                        <p style="font-size: 14px text_drak_light">Contact Admin Team if you Unable to Reset your Password</p>
                    </div>
            </div>
            </div>
        </ng-container>
        <ng-container *ngIf="flagswitch == 'password'">
            <div class="d-flex align-items-center mb-2">
                <div class="bg-grey me-2 p-1" (click)="this.flagswitch = 'email'"><mat-icon>arrow_back</mat-icon></div>
                <div><p class="m-0" style="font-size: 18px; font-weight: 400;">Reset Your Password</p></div>
            </div>
            <div class="card login_card">
            <div class="card-body">
                <div class="text-center my-3">
                    <div style="font-size: 14px">Successfully Your Email Verified.</div>
                </div>
                <form [formGroup]="passwordresetform" (ngSubmit)="onSubmitpasswordreset()" class="pt-2">
                    <div class="form-group mb-3">
                        <label>Enter New Password</label>
                        <input type="text" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && fpasswordresetform['password'].errors }" />
                        @if (submitted && fpasswordresetform['password'].errors) {
                        <div class="invalid-feedback">
                            @if (fpasswordresetform['password'].errors['required']) {
                            <div>password Code is required</div>
                            }
                            @if (fpasswordresetform['password'].errors['minLength']) {
                                <div>password Code is required Minimum 6 Characters</div>
                                }
                            @if (fpasswordresetform['password'].errors['passwordStrength']) {
                                {{fpasswordresetform['password'].errors['passwordStrength']}}
                                }
                        </div>
                        }
                    </div>
                    <div>
                        <ul class="list-unstyled">
                            <li>Password Should Contain at least </li>
                            <li>1 Caps Letter</li>
                            <li>1 Small Letter</li>
                            <li>1 special Character</li>
                            <li>Minimum 6 Letters</li>
                        </ul>
                    </div>
                    <div class="form-group mb-3">
                        <label>Re-Enter New Password</label>
                        <input type="text" formControlName="reenter_password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && fpasswordresetform['reenter_password'].errors }" />
                        @if (submitted && fpasswordresetform['reenter_password'].errors) {
                        <div class="invalid-feedback">
                            @if (fpasswordresetform['reenter_password'].errors['required']) {
                            <div>Re-Enter password Code is required</div>
                            }
                            <!-- @if (fpasswordresetform.errors?.mismatch) {
                                <div>Password is not match</div>
                            } -->
                        </div>
                        }
                    </div>
                    <button class="px-5" type="submit" mat-flat-button color="primary">Update Password</button>
                </form>
                <div class="text-center mt-5">
                    <p style="font-size: 14px text_drak_light">Contact Admin Team if you Unable to Reset your Password</p>
                </div>
            </div>
            </div>
        </ng-container>
        </div>
    </div>
<!-- </div> -->