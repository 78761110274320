<section class="my-3">
  <div class="mx-1 d-flex justify-content-between align-items-center">
    <h3 class="m-0 fw-bold">{{heading}} Page - Vision &amp; Mission</h3>
    <button
      [disabled]="VisionMissionData.length >= 2"
      mat-raised-button
      color="primary"
      class="my-3"
      (click)="openUploadDialog()"
    >
      <mat-icon>cloud_upload</mat-icon>
      Add Mission/Vission
    </button>
  </div>
  <div *ngIf="VisionMissionData.length === 0">
    <p>No vision/mission data available.</p>
  </div>

  <ng-container *ngFor="let element of VisionMissionData; let last = last">
    <div class="content-wrapper">
      <div class="image-container">
        <img
          [src]="this.imagePath + '/' + element.image_url"
          alt="{{ element.content_type }}"
          matTooltip="{{ element.content_type }}"
          class="content-image"
        />
      </div>
      <div class="content-text">
        <p class="content-heading">{{ element.content_type }}</p>
        <p>{{ element.content | slice : 0 : 100 }}...</p>
      </div>
      <button
        mat-icon-button
        class="edit-icon update_button"
        (click)="EditopenUploadDialog(element.content_type, element)"
        aria-label="Edit"
        matTooltip="Edit {{ element.content_type }}"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <!-- Divider -->
    <hr *ngIf="!last" class="divider" />
  </ng-container>
</section>
