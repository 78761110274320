<!-- <div>
  <button (click)="openleaveDialog('leave')">open</button>
</div> -->
<div class="row m-2">
  <div class="col-sm-8 col-md-8">
    <div class="row text-center">
      <div class="col-md-6">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div class="col-md-6">
        <div class="btn-group">
          <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
            Previous
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="yearmonth(viewDate)">
            Today
          </div>
          <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
            Next
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"  (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)">
      </mwl-calendar-month-view>
    </div>
    <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
      let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody">
      <div class="position-absolute end-0">
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <div [ngStyle]="day?.events[0]?.meta.start_time ? {'height': '45px', 'padding-top': '25px'} : {'height': '35px', 'padding-top': '25px'}">
      <ng-container *ngIf="day?.events[0]?.meta.start_time">
        <div class="cal-day-notes px-2 py-2 lh-1" >
          <p class="m-0"><small class="fs_11">{{day?.events[0]?.meta.start_time}} </small></p>
          <p class="m-0"><small class="fs_11">{{day?.events[0]?.meta.end_time}}</small></p>
        </div>
        <div class="cal-day-notes px-2 d-flex justify-content-start lh-1">
          <p class="m-0 px-2 rounded-pill duration_data" [ngStyle]="day?.events[0]?.meta.duration >= 4 ? {'background-color':'#CBFACD','color': '#20B026'} : {'background-color': '#FAF5CB', 'color': '#AB8355'}"><small class="fs_11">{{day?.events[0]?.meta.duration}}:00 Hrs</small></p>
        </div>
      </ng-container>
    </div>
    <!-- <ng-container [ngSwitch]="day?.events[0]?.meta.status">
      <div *ngSwitchCase="'PRESENT'">
        <div class="cal-day-notes px-2 d-flex justify-content-start lh-1">
          <p class="m-0 px-2 rounded-pill duration_data" [ngStyle]="day?.events[0]?.meta.duration >= 4 ? {'background-color':'#CBFACD','color': '#20B026'} : {'background-color': '#FAF5CB', 'color': '#AB8355'}"><small class="fs_11">{{day?.events[0]?.meta.duration}}:00 Hrs</small></p>
        </div>
      </div>
      <div *ngSwitchCase="'ABSENT'">
        <div class="cal-day-notes px-2 d-flex justify-content-center lh-1">
          <p class="m-0 px-2 rounded-pill duration_data" [ngStyle]="{'background-color':'#FFD8D8','color': '#EA4C4C'}"><small class="fs_11">ABSENT</small></p>
        </div>
      </div>
    </ng-container> -->

    <!-- <div *ngFor="let daytype of day.events">
      <p class="m-0 holiday_show_data text-center"><small>{{daytype.meta.daytype}} </small></p>
      <p class="m-0 text-center"><small>{{daytype?.meta.notes}} </small></p>
    </div> -->
      <!-- <ng-container *ngIf="day?.events[0]?.meta.duration > 0">
        <div class="cal-day-notes px-2 d-flex justify-content-start lh-1">
          <p class="m-0 px-2 rounded-pill duration_data" [ngStyle]="day?.events[0]?.meta.duration >= 4 ? {'background-color':'#CBFACD','color': '#20B026'} : {'background-color': '#FAF5CB', 'color': '#AB8355'}"><small class="fs_11">{{day?.events[0]?.meta.duration}}:00 Hrs</small></p>
        </div>
      </ng-container> -->
      <!-- <ng-container [ngSwitch]="day?.events[0]?.meta.daytype">
        <div *ngSwitchCase="'ABSENT'" class="cal-day-notes p-2">
          <p class="m-0 absent_show_data text-center"><small>{{day?.events[0]?.meta.daytype}}</small></p>
          <p class="m-0 text-center"><small>{{day?.events[0]?.meta.notes}} </small></p>
        </div>
        <div *ngSwitchCase="'HOLIDAY'" class="cal-day-notes p-2">
          <p class="m-0 holiday_show_data text-center"><small>{{day?.events[0]?.meta.daytype}} </small></p>
          <p class="m-0 text-center"><small>{{day?.events[0]?.meta.notes}} </small></p>
        </div>
      </ng-container> -->
      <div>
        <div class="text-start px-2">
          <div *ngIf="day.events.length >= 2">
            <div class="cal-event" [style.backgroundColor]="day.events[1].color.primary"
              [ngClass]="day.events[1]?.cssClass" [mwlCalendarTooltip]="day.events[1].title | calendarEventTitle:'monthTooltip':day.events[1]"
              [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="day.events[1]" [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody">
            </div>
          </div>
          <div *ngIf="day.events.length == 1">
          <div class="cal-event" [style.backgroundColor]="day.events[0].color.primary"
            [ngClass]="day.events[0]?.cssClass" [mwlCalendarTooltip]="day.events[0].title | calendarEventTitle:'monthTooltip':day.events[0]"
            [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="day.events[0]" [tooltipTemplate]="tooltipTemplate"
            [tooltipAppendToBody]="tooltipAppendToBody">
          </div>
        </div>
        </div>
        <!-- <ng-container *ngFor="let event of day.events"> -->
        <div class="leave_menu_show" >
          <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="more option"
          [ngStyle]="{'color':'white'}" fontIcon="more_vert"></mat-icon>
          <mat-menu #menu="matMenu">
            <small mat-menu-item (click)="handleAction('leave', day)">Apply Leave</small>
            <small mat-menu-item (click)="handleAction('compOff', day)">Apply Comp Off</small>
            <small mat-menu-item (click)="handleAction('permission', day)">Apply Permission</small>
          </mat-menu>
        </div>
      <!-- </ng-container> -->
      </div>
    </ng-template>
    <div class="">
      <div *ngFor="let items of Representaion_data" class="representation">
        <div class="representation_color" [ngStyle]="{'background-color': items.color}"></div>
        <div class="representation_text">{{items.title}}</div>
      </div>
    </div>
  </div>
  <div class="col-sm-4 col-md-4">
    <div class="row">
      <div class="col-sm-6">
        <div class="bg-green">
          <div class="px-2 py-2">
            <h2 class="m-0">{{total_present_days}}</h2>
            <p class="m-0">Present Days</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="bg-pink">
          <div class="px-2 py-2">
            <h2 class="m-0">{{total_absent_days}}</h2>
            <p class="m-0">Absent Days</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grey mt-2">
      <div class="px-2 py-4">
        <div>
          <small>Attendance Percentage</small>
        </div>
        <div class="mt-2">
          <mat-progress-bar mode="determinate" [color]="color" [value]="attendance_percentage"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>