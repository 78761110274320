import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';

@Component({
  selector: 'app-notice-board-detail-dialog',
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
  ],
  standalone: true,
  templateUrl: './notice-board-detail-dialog.component.html',
  styleUrl: './notice-board-detail-dialog.component.scss',
})
export class NoticeBoardDetailDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NoticeBoardDetailDialogComponent>,
    private apiservice: ApiservicesService
  ) {
    console.log('data', data);
  }
  imagePath = this.apiservice.environmentPath;

  closeDialog(): void {
    this.dialogRef.close();
  }

  editNotice(): void {
    if (this.data.editCallback) {
      this.data.editCallback(this.data.notice);
    }
    this.dialogRef.close();
  }

  deleteNotice(): void {
    if (this.data.deleteCallback) {
      this.data.deleteCallback(this.data.notice.id);
    }
    this.dialogRef.close();
  }
}
