import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { catchError, Observable } from 'rxjs';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';

@Component({
  selector: 'app-dynamic-page-creation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: './dynamic-page-creation.component.html',
  styleUrl: './dynamic-page-creation.component.scss',
})
export class DynamicPageCreationComponent implements OnInit {
  @Input() pageTitle: string = 'Create New Page';
  @Input() questions$!: Observable<Inputfiledsbase<string>[]>; // Input for dynamic questions
  @Input() submitButtonText: string = 'Submit';
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  questions: Inputfiledsbase<string>[] = [];
  pageForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pageForm = this.fb.group({});

    // Set pageTitle and questions from data if provided
    if (this.data?.pageTitle) {
      this.pageTitle = this.data.pageTitle;
    }
    // Observe questions$ from data and subscribe to it
    if (this.data?.questions$) {
      this.data.questions$
        .pipe(
          catchError((err) => {
            console.error('Error loading questions:', err);
            return [];
          })
        )
        .subscribe((questions: any) => {
          console.log('Received questions:', questions); // Debugging log
          this.questions = questions || [];
          this.addDynamicControls(this.questions);
        });
    }
  }

  ngOnInit(): void {
    console.log('pageTitle', this.pageTitle);
    // No need to subscribe here since it's already handled in the constructor
  }

  private addDynamicControls(questions: Inputfiledsbase<string>[]): void {
    questions.forEach((question) => {
      const validators = question.required ? [Validators.required] : [];
      this.pageForm.addControl(
        question.key,
        this.fb.control(question.value || '', validators)
      );
    });
  }

  onCancel(): void {
    this.cancel.emit();
  }

  trackByQuestionKey(index: number, question: Inputfiledsbase<string>): string {
    return question.key;
  }

  onSubmit(): void {
    if (this.pageForm.valid) {
      console.log('Form Submitted:', this.pageForm.value);
      this.formSubmit.emit(this.pageForm.value);
    } else {
      console.log('Form Invalid');
      Object.keys(this.pageForm.controls).forEach((key) => {
        const control = this.pageForm.get(key);
        if (control?.invalid) {
          console.log(`${key} is invalid`);
        }
      });
    }
  }
}