<section class="container">
    <div class="mt-2 table-container">
        <div *ngIf="showForm">
            <app-addtimeslot></app-addtimeslot>
          </div>
          <div *ngIf="!showForm">
            <mat-tab-group>
              <!-- <mat-tab label="Period Time Slots">
                <div class="add-button-container">
                  <button mat-raised-button color="primary" (click)="toggleForm()">+ Add Time Slot</button>
                </div>
                <app-timeslot></app-timeslot>
              </mat-tab> -->
              <mat-tab label="Time Tables">
                <div class="add-button-container">
                  <button mat-raised-button color="primary" (click)="toggletableForm()">+ Add Time Table</button>
                </div>
                <div>
                  <app-timetables></app-timetables>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
    </div>
</section>
  