import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DatacontrolService } from '../../../../Services/controllers/datacontrol.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';

@Component({
  selector: 'app-leaverequest',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './leaverequest.component.html',
  styleUrl: './leaverequest.component.scss',
  providers: [DatacontrolService, ApiservicesService]
})
export class LeaverequestComponent {
  requestLeaveStats: any;

  constructor(private apisevice: ApiservicesService, private router: Router, private requestservice: DatacontrolService) {
    this.getrequestleavestats();
   }

  getrequestleavestats(){
    this.apisevice.getleaverequeststats().subscribe((res) => {
     this.requestLeaveStats = res;
    })
  }
  onclicktonavigate(requestype: string) {
    // this.requestservice.setleaverequestcontrol(requestype);
    this.router.navigate(['requests/requestsview'], { queryParams: { ReqestType: requestype } });
  }

  navigatetomyleaves(requestype: string) {
    // this.requestservice.setleaverequestcontrol(requestype);
    this.router.navigate(['requests/leaveview'], { queryParams: { ReqestType: requestype } });
  }
}
