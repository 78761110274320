import { Component, Input, OnInit, signal } from '@angular/core';
import { Menu } from '../menu.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-menu-items',
  standalone: true,
  imports: [
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule,
    RouterModule,
    CommonModule,
    // MenuItemsComponent
  ],
  templateUrl: './menu-items.component.html',
  styleUrl: './menu-items.component.scss'
})
export class MenuItemsComponent implements OnInit {

  @Input() menu: Menu = [];
  readonly panelOpenState = signal(false);
  currentRoute: string = '';

  constructor(
   private router:Router
  ){}
  
  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateActiveRoute();
    });

    // Initialize the active route
    this.updateActiveRoute();
  }

  // Update the current active route
  updateActiveRoute() {
    this.currentRoute = this.router.url;
  }

  // Navigate to the selected route
  navigateToRoute(link: any) {
    this.router.navigate([link.link]);
  }

  // Check if the route is active
  isActive(link: any): boolean {
    // return this.currentRoute === link.link;
    return this.isRouteActive(link.link);
  }

  private isRouteActive(path: string): boolean {
    // You can check if the current URL starts with the given path
    return this.currentRoute.startsWith(path);
  }

}
