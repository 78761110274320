import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AddTimeslotComponent } from './addtimeslot/addtimeslot.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

interface Timeslot {
  shift_id: number;
}

@Component({
  selector: 'app-timeslot',
  standalone: true,
  imports: [
      CommonModule,
      MatButtonModule,
      AddTimeslotComponent,
      MatCardModule,
      MatTableModule,
  ],
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss'],
  providers: [DropdownservicesService],
})
export class TimeslotComponent {
  showForm: boolean = false;
  slotData: any = {};
  timeslotslist: Timeslot[] = [];
  displayedColumns: string[] = [
    'academic_year',
    'shift_name',
    'action',
  ];
  constructor(
    private dropdownservice: DropdownservicesService, 
    private router: Router) {}

  ngOnInit(): void {
    this.getTimSlotsDetails();
  }

  getTimSlotsDetails() {
    this.dropdownservice.gettimeslotslist().subscribe({
      next: (res: any) => {
        if (res && res.rows) {
          this.timeslotslist = res.rows.map((timeslot: any) => ({
            ...timeslot,
            start_date: this.extractDate(timeslot.start_date),
            end_date: this.extractDate(timeslot.end_date),
          }));
          console.log('Processed timeslots list:', this.timeslotslist);
        } else {
          console.warn('No rows found in the response:', res);
          this.timeslotslist = [];
        }
      },
      error: (err) => {
        console.error('Error fetching timeslots:', err);
      }
    });
  }
  
  extractDate(datetime: string): string {
    const date = new Date(datetime);
    return date.toLocaleDateString();
  }
  

  viewSlot(slot: Timeslot) {
    this.router.navigate(['addtimeslots'], { state: { slot : slot } });
  }
}
