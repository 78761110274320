import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { workService } from '../../../../../inputfileds/form_services/workservice/workservice.service';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../Services/toast/toast.service';
import { ShiftComponent } from '../shift/shift.component';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';


@Component({
  selector: 'app-addshift',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    CommonModule,
  ],
  providers: [QuestionControlService, workService, ApiservicesService, ShiftComponent, DropdownservicesService],
  templateUrl: './addshift.component.html',
  styleUrl: './addshift.component.scss'
})
export class AddshiftComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  question: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  breakquestions: Observable<Inputfiledsbase<any>[] | null> | null;
  shifttimeform!: FormGroup;
  breaktimeform!: FormGroup;
  breakaddformarry: any = [];
  payLoad = '';
  permissionData = []
  showaddflag: boolean = false;
  editflag: boolean = false;
  editindex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<AddshiftComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService,
    private toast: ToastService, 
    private qcs: QuestionControlService, 
    service: workService, 
    private router: Router,
    private loadshiftdata : ShiftComponent
  ) {
    this.questions$ = service.getaddshiftservices();
    this.questions$.subscribe(q => this.questions = q);
    this.breakquestions = service.getbreaktimeservices();
    this.breakquestions.subscribe(q => this.question = q);
    // this.apiservice.getcoursesdetails().subscribe((res) => {
    //   this.permissionData = res.rows
    //   // this.dataSource = new MatTableDataSource<CourseView>(res.rows)
    // })
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.shifttimeform = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.breaktimeform = this.qcs.toFormGroup(this.question as Inputfiledsbase<string>[]);
  }

  onSubmit() {
    forkJoin([
      this.dropdownservice.getlookupcodesdropdown('BREAK_TYPES'),
      this.dropdownservice.getlookupcodesdropdown('SHIFT_END_DAY'),
    ]).subscribe(([BreakRes, endDayRes]) => {
      const updatedBreaks = this.breakaddformarry.map((breakItem: any) => {
        const filteredBreak = BreakRes.find((dropfilter: any) => 
          dropfilter.id === parseInt(breakItem.break_type_id)
        )
        return {
          ...breakItem,
          break_type: filteredBreak ? filteredBreak.lookup_name : "Unknown Break Type"
        };
      })
      const updateEndDay =  endDayRes.find((dropfilter: any) => { return dropfilter.id === parseInt(this.shifttimeform.value?.shift_end_day_id) }
    );
      let payload = {
        // "organization_id": parseInt(this.shifttimeform.value.organization_id),
        "academic_calendar_id": this.data.academic_calendar_id,
        "shift_end_day": updateEndDay.lookup_name,
        "shift_name": this.shifttimeform.value.shift_name,
        "shift_start_time": this.shifttimeform.value.shift_start_time,
        "shift_end_time": this.shifttimeform.value.shift_end_time,
        "breaks": updatedBreaks
      };
      this.createAPi(payload);
    });
  }
  
  createAPi(event: any) {
    this.apiservice.createshift(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.shifttimeform.reset();
        this.breaktimeform.reset();
        this.Canceldailog()
        this.toast.showToast(
          'success',
          `${res.message}`,);
      }
      else{
        this.toast.showToast(
          'danger',
          `${res.message}`,);
      }
    })
  }
  

  arraySubmit() {
    if (this.breaktimeform.valid) {
      this.breakaddformarry.push(this.breaktimeform.getRawValue());
      this.breaktimeform.reset();
      forkJoin([
        this.dropdownservice.getlookupcodesdropdown('BREAK_TYPES')
      ]).subscribe((res: any) => {
        const lookupData = res[0];
        this.breakaddformarry = this.breakaddformarry.map((breakItem: any) => {
          const filteredBreak = lookupData.find((dropfilter: any) => 
            dropfilter.id === parseInt(breakItem.break_type_id)
          );
          return {
            ...breakItem,
            break_type: filteredBreak ? filteredBreak.lookup_name : "Unknown Break Type" // Default value
          };
        });
      });
    }
  }

  editarraySubmit() {
    forkJoin([
      this.dropdownservice.getlookupcodesdropdown('BREAK_TYPES')
    ]).subscribe((res: any) => {
      const lookupData = res[0];
      this.breakaddformarry = this.breakaddformarry.map((breakItem: any) => {
        const filteredBreak = lookupData.find((dropfilter: any) => 
          dropfilter.id === parseInt(breakItem.break_type_id)
        );
        return {
          ...breakItem,
          break_type: filteredBreak ? filteredBreak.lookup_name : "Unknown Break Type"
        };
      });
    });
    const updatedFormValues = this.breaktimeform.value;
    if (this.breakaddformarry[this.editindex]) {
      Object.assign(this.breakaddformarry[this.editindex], updatedFormValues);
    } else {
      console.error('Invalid index or data not found.');
    }
    this.breaktimeform.reset();
    this.editflag = false;
  }
  

  reseteducationform() {
    this.breaktimeform.reset();
  }

  editarrayform(event: any, index: number) {
    this.breaktimeform.patchValue(event);
    this.breakaddformarry[index] = this.breaktimeform.value;
    this.editflag = !this.editflag;
    this.editindex = index;
  }

  deletearrayform(event: number) {
    this.breakaddformarry.splice(event, 1);
  }

  Canceldailog(): void {
    const dialogElement = document.getElementsByClassName("animate__animated")[0];
    if (dialogElement) {
      dialogElement.classList.remove("animate__fadeIn");
      dialogElement.classList.add("animate__fadeOut");
    }
    // this.loadshiftdata.getShiftdetails();
   setTimeout(() => [this.dialogRef.close(), 1000])
  }
}
