import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../../../Services/Models/user';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../../Services/toast/toast.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject?: BehaviorSubject<any>;
    public user?: Observable<any>;
    loginurl: any;

    constructor(
        private router: Router,
        private http: HttpClient,
        private toast: ToastService
    ) {
        this.getstoreduserdata();
    }

    getstoreduserdata() {
        if (typeof sessionStorage !== 'undefined') {
            this.userSubject = new BehaviorSubject<any>(sessionStorage.getItem('user'));
            this.user = this.userSubject.asObservable();
        }
    }

    public get userValue() {
        // return this.userSubject.value || {};
        return this.userSubject?.value || undefined
    }

    login(email: string, password: string, role: string) {
        if (role === 'student') {
            this.loginurl = `${environment.API_URL}students/login`
        } else if (role === undefined || role === null || role === 'employee') {
            this.loginurl = `${environment.API_URL}employees/login`
        } else {
            this.loginurl = `${environment.API_URL}employees/login`
        }
        return this.http.post<any>(this.loginurl, { email, password })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                if (user.status === true && user.code === 200) {
                    user.authdata = window.btoa(email + ':' + password);
                    // user.data.onboarding_status = true; 
                    sessionStorage.setItem('user', JSON.stringify(user));
                    if (user.data?.sub_orgs_data?.length > 0) {
                        sessionStorage.setItem('SubOrgData', JSON.stringify(user.data.sub_orgs_data[0]));
                        if (user.data?.sub_orgs_data[0].onboarding_status == false) {
                            this.router.navigate(['/onboarding']);
                        }
                    };
                    this.userSubject = new BehaviorSubject<any>(sessionStorage.getItem('user'));
                    this.getstoreduserdata();
                    this.toast.showToast(
                        'success',
                        'Login Successfull!');
                    this.dismissError();
                    return user;
                } else {
                    // this.handleError
                    this.toast.showToast(
                        'danger',
                        'Something went Worng!');
                    this.dismissError();
                    return user;
                }
            }),
                catchError(this.handleError))
    }

    handleError(errorResponse: HttpErrorResponse) {
        console.log('handleerror')
        this.toast.showToast(
            'danger',
            'Something went Worng !');
        this.dismissError();
        return throwError(errorResponse.message || 'server error');
    }

    logout() {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('SubOrgData');
        this.userSubject?.next(null!);
        this.getstoreduserdata();
        this.router.navigate(['/login']);
    }
    private dismissError(): void {
        setTimeout(() => {
            this.toast.dismissToast();
        }, 3000);
    }
}