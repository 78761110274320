import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { DropdownQuestion } from '../../question-dropdown';

@Injectable({
  providedIn: 'root'
})
export class CompoffService {

  organisationdata: any = [];
  leavemodedata: any[] = [];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    // this.apiservice.getorganizationdrop().subscribe((res) => 
    //   {
    //     for(let i=0; i <= res.length; i++){
    //       this.organisationdata?.push({id:res[i].id, key: res[i].short_name, value:res[i].id})
    //     }
    //   }); 
    this.loadDropdownData('LEAVE_MODES', this.leavemodedata);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'LEAVE_MODES') {
      observable = this.dropdownservice.getlookupcodesdropdown('LEAVE_MODES');
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'LEAVE_TYPES') {
          lookupname = res[i].leave_code;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  getCompoffLeaves() {
    const questions: Inputfiledsbase<string>[] = [

      new DatepickerQuestion({
        key: 'date',
        label: 'Date',
        required: true,
        order: 1
      }),

      new DropdownQuestion({
        key: 'comp_off_type',
        label: 'Day Type',
        required: true,
        options: this.leavemodedata,
        order: 3,
      }),

      new TextareaQuestion({
        key: 'reason',
        label: 'Reason for Leave',
        required: true,
        position: 'FULL',
        order: 3
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
