import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { AcademicsService } from '../../../../inputfileds/form_services/academics/academics.service';
import { Observable } from 'rxjs';
import { QuestionService } from '../../../../inputfileds/form_services/question/question.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SectionsComponent } from '../sections/sections.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';
export interface Coursemodel {
  organization_id: number;
  courses: [];
  start_year: any;
  end_year: any;
  code: string;
  // section: string;
  // section_id: number;
}

@Component({
  selector: 'app-addacademicregulations',
  standalone: true,
  imports: [
    SectionsComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    ToastComponent
  ],
  providers: [QuestionControlService, AcademicsService, ApiservicesService, DropdownservicesService],
  templateUrl: './addacademicregulations.component.html',
  styleUrls: ['./addacademicregulations.component.scss'],
})
export class AddacademicregulationsComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  academicsform!: FormGroup;
  datesend!: Coursemodel;
  pipe = new DatePipe('en-US');
  semisterflag: boolean = false;
  academicsdata: any = {};
  sectionsdropdowndata: any;
  selectedsectiondropdown: any;
  showToast: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SectionsComponent>,
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService,
    private services: AcademicsService,
    public router: Router,
    private qcs: QuestionControlService,
    private _snackBar: MatSnackBar,
    private toast: ToastService
  ) {
    this.questions$ = services.getacadamicsform();
    this.questions$.subscribe((q) => (this.questions = q));
  }

  ngOnInit(): void {
    this.getacadamicssectionsdetails();
    this.academicsform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );

    // Add start_year and end_year controls
    this.academicsform.addControl(
      'start_year',
      new FormControl('', [Validators.required, Validators.pattern(/^\d{4}$/)])
    );
    this.academicsform.addControl(
      'end_year',
      new FormControl('', [Validators.required, Validators.pattern(/^\d{4}$/)])
    );

    // Apply the custom validator to the form group
    this.academicsform.setValidators(this.dateRangeValidator);
  }

  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const startYear = control.get('start_year')?.value;
    const endYear = control.get('end_year')?.value;
    console.log("start", startYear, endYear);
    const startYearValue = startYear instanceof Date ? startYear.getFullYear() : parseInt(startYear, 10);
    const endYearValue = endYear instanceof Date ? endYear.getFullYear() : parseInt(endYear, 10);

    if (startYearValue && endYearValue && endYearValue <= startYearValue) {
      return { dateRangeInvalid: true };
    }
    return null;
  };
  // if (startYearValue > endYearValue){
  //   return { dateRangeInvalid: true };
  // }

  getacadamicssectionsdetails() {
    this.dropdownservice.getacademicregulationdrop().subscribe((res) => {
      this.sectionsdropdowndata = res;
      this.selectedsectiondropdown = res[0];
    });
  }

  gotosemisters() {
    this.semisterflag = !this.semisterflag;
  }

  closeDialog(event: any) {
    this.gotosemisters();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialogRef.close(event);
    }, 1000);
  }

  editcourse() {
    let editdata = {
      organization_id: 1,
      start_year: '01/01/2015',
      end_year: '02/01/2015',
      code: 'R10',
      courses: [
        {
          id: 1,
          name: 'test',
        },
        {
          id: 2,
          name: 'test1',
        },
      ],
    };

    this.academicsdata = editdata;
  }

  onSubmit() {
    this.datesend = {
      organization_id: parseInt(this.academicsform.value.organization_id),
      courses: this.academicsform.value.course_id,
      start_year: this.pipe.transform(this.academicsform.value.start_year, 'yyyy'),
      end_year: this.pipe.transform(this.academicsform.value.end_year, 'yyyy'),
      code: this.academicsform.value.code,
      // section_id: this.selectedsectiondropdown.id,
      // section: this.selectedsectiondropdown.name,
    };
    this.createAPi(this.datesend);
  }

  createAPi(event: any) {
    this.apiservice.createacadamics(event).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.academicsdata = res.data;
          this.toast.showToast('success', 'Saved Successfully');
          this.closeDialog(true);
        } else {
          this.toast.showToast('danger', `Error: ${res.message}`);
          this.showToast = true;
        }
      },
      (error) => {
        this.toast.showToast('warning', error.message);
      }
    );
  }
}
