import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { CompoffService } from '../../../../../inputfileds/form_services/Compoff/compoff.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { ApplyleavesService } from '../../../../../inputfileds/form_services/applyleaves/applyleaves.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { ToastService } from '../../../../../Services/toast/toast.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-compoffdialogbox',
  standalone: true,
  // imports: [],
  templateUrl: './compoffdialogbox.component.html',
  styleUrl: './compoffdialogbox.component.scss',
  imports: [MatIconModule, MatButtonModule, MatDividerModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, CompoffService, ApiservicesService, DropdownservicesService]
})
export class CompoffdialogboxComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  CompoffData = []
  showaddflag: boolean = false;
  datepipe = new DatePipe('en-US');

  constructor(
    @Inject(MAT_DIALOG_DATA) public datedata: any,
    public dialogRef: MatDialogRef<CompoffdialogboxComponent>,
    private apiservice: ApiservicesService,
    private qcs: QuestionControlService,
    private service: CompoffService,
    private Dropdownservice: DropdownservicesService,
    private toast: ToastService) {
    this.questions$ = this.service.getCompoffLeaves();
    this.questions$.subscribe(q => this.questions = q);

    this.Dropdownservice.getcoursesdetails().subscribe((res) => {
      this.CompoffData = res.rows
      // this.dataSource = new MatTableDataSource<CourseView>(res.rows)
    })
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.appending_date()
  }

  onSubmit() {
    let payload = {
      "date": this.datepipe.transform(this.form.value.date, 'yyyy-MM-dd'),
      "comp_off_type": this.form.value.comp_off_type,
      "reason": this.form.value.reason,
    }
    this.createAPi(payload);
  }

  createAPi(event: any) {
    this.apiservice.applyemployeecompoff(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.form.reset();
        this.Canceldailog()
      } else {
        this.toast.showToast(
          'danger',
          'Leave is Not Applied');
      }
    })
  }

  appending_date() {
    this.form.controls['date'].setValue(this.datedata.date);
    // this.form.patchValue('start_date', )
  }

  Canceldailog(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialogRef.close(); }, 1000);
  }
}
