<section class="mx-3">
  <!-- Breadcrumb section -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb" *ngFor="let org of organizationData">
      <li class="breadcrumb-item cursor_pointer" (click)="showOrganizations()">Organizations</li>
      <li class="breadcrumb-item  textunderline">{{ org.organization_name}}</li>
    </ol>
  </nav>
  <div class="d-flex px-2 text-center">
    <button class="m-3 py-1 college_details_header" (click)="showOrganizations()"><mat-icon>arrow_backward</mat-icon></button>
    <h1 class="py-3">Organization Details</h1>
  </div>

  <ng-container>
    <div class="bg-org-color college_details_card">
    <div *ngIf="organizationData && organizationData.length > 0">
      <div  class="p-4 d-flex organization_cards" *ngFor="let org of organizationData">
        <div class="organization-details">
          <h2>{{ org.organization_name}}</h2>
          <h4>{{ org.city }}</h4>
        </div>
        <div class="d-flex ml-2">
          <mat-icon>phone</mat-icon>
          <div>
            <h3>
            Person Name
          </h3>
          <span>*9876543567</span>
          </div>
        </div>
      </div>
    <div *ngIf="!organizationData || organizationData.length === 0">
      <p>Loading organization details...</p>
    </div>
  </div>
    </div>
  </ng-container>

<!-- 
    <div>
      <h2 class="p-4">
        Department Details:
      </h2>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
      
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>
      
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Symbol </th>
            <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                       showFirstLastButtons
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
      
    </div>
     -->
</section>
