// permission.service.ts
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {

    private userPermissions = ['view', 'edit', 'create']; // Example: this would be fetched from an API or state
    actionsdata: any;
    aspermission: any
    tabactionsdata: any;
    CreatePermission: any;
    ViewPermission: any;
    ListViewPermission: any;
    DeletePermission: any;
    EditPermission: any;
    constructor() { }

    getactiondata() {
        return this.actionsdata;
    }

    gettabactiondata() {
        return this.tabactionsdata;
    }

    // hasPermission(permission: any): boolean {
    //     this.aspermission = permission;
    //     return this.userPermissions.includes(permission);
    //     // return permission.permission;
    // }

    hasCreatePermission(permission: any): boolean {
        this.CreatePermission = permission;
        return false;
    }

    hasViewPermission(permission: any): boolean {
        this.ViewPermission = permission;
        return false;
    }

    hasListViewPermission(permission: any): boolean {
        this.ListViewPermission = permission;
        return false;
    }

    hasDeletePermission(permission: any): boolean {
        this.DeletePermission = permission;
        return false;
    }

    hasEditPermission(permission: any): boolean {
        this.EditPermission = permission;
        return false;
    }

    hasPermission(permission: any) {
        if (permission?.create == true) {
            this.hasCreatePermission(true);
        }
        if (permission?.create == false) {
            this.hasCreatePermission(false);
        }
        if (permission?.view == true) {
            this.hasViewPermission(true);
        } else {
            this.hasViewPermission(false);
        }
    }
}
