<div class="mx-3">
    <div class="row">
        <div class="col-sm-9 col-md-9 col-12 col-lg-9">
            <h1 class="mb-1">Hey...!! {{userdetails.data.first_name}} {{userdetails.data.last_name}}</h1>
            <p class="text_color_light m-0">Get started with setting up the Dr. B. R. Ambedkar Group of Colleges Admin
                Panel.</p>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 col-12">
            <div class="text-end mb-3">
                <p class="m-0 text_color_light">Onboarding Status</p>
            </div>
            <div class="d-flex align-items-center">
                <mat-progress-bar mode="determinate" value="40" class="onboarding_progress"
                    height="20"></mat-progress-bar>
                <small class="m-0 ps-2">40%</small>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <p class="text_color_light">Follow the steps below for a smooth onboarding experience. Begin with Stage 1.</p>
    </div>
    <div class="mb-5 mt-4 onboarding_tabs">
        <mat-card class="onboarding_cards">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
                    <mat-expansion-panel-header style="height: 70px;">
                        <mat-panel-title>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img src="../../../assets/icons/Onboarding_stage1.svg" />
                                </div>
                                <div class="ms-3">
                                    <p class="m-0">Stage - 1</p>
                                    <small class="text_color_light">Setup your organisation by adding required
                                        details.</small>
                                </div>
                            </div>
                        </mat-panel-title>
                        <div class="d-flex align-items-center mx-4">
                            <p class="m-0 bg-text-color px-3 py-1 border rounded-pill text_color_on_bg">2 Steps
                                Remaining</p>
                        </div>
                        <!-- <mat-panel-description>
                      Currently I am {{panelOpenState() ? 'open' : 'closed'}}
                    </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <mat-stepper orientation="vertical" [linear]="true"
                        class="vertical_stepper"><!-- [linear]="true" -->
                        <mat-step [completed]="control_stepper" editable="false"
                            class="vertical_stepper_step"> <!--[stepControl]="firstFormGroup"-->
                            <ng-template matStepLabel>
                                <div class="d-flex justify-content-between align-items-center" (click)="state_function_display(1)">
                                    <div>
                                        <p class="m-0">Create Program / Stream</p>
                                        <!-- <small>sjdksjdkjkd</small> -->
                                    </div>
                                    
                                </div>
                            </ng-template>
                            <div class="ms-5 obarding_add_button">
                                <button mat-raised-button color="primary" class="px-3 add_button height_30"
                                    (click)="state_control()"> <mat-icon>add</mat-icon>Add Stream</button>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>
                                <div class="d-flex justify-content-between align-items-center" (click)="state_function_display(2)">
                                    <div>
                                        <p class="m-0">Create Department</p>
                                        <!-- <small>sjdksjdkjkd</small> -->
                                    </div>
                                </div>
                            </ng-template>
                            <div class="ms-5 obarding_add_button">
                                <button mat-raised-button color="primary" class="px-3 add_button height_30"
                                    routerLink="/department"> <mat-icon>add</mat-icon>Add Department</button>
                            </div>
                        </mat-step>
                        <mat-step >
                            <ng-template matStepLabel>
                                <div class="d-flex justify-content-between align-items-center" (click)="state_function_display(3)">
                                    <div>
                                        <p class="m-0">Create Course {{state}}</p>
                                        <small class="text_color_light">Also Subjects in the course</small>
                                    </div>
                                    
                                </div>
                            </ng-template>
                            <div class="ms-5 obarding_add_button">
                                <button mat-raised-button color="primary" class="px-3 add_button height_30"
                                    routerLink="/courses"> <mat-icon>add</mat-icon>Add Course</button>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="m-0">Create Academic year & calendar</p>
                                        <small class="text_color_light">Also add student batches.</small>
                                    </div>
                                   
                                </div>
                            </ng-template>
                            <div class="ms-5 obarding_add_button">
                                <button mat-raised-button color="primary" class="px-3 add_button height_30"
                                    routerLink="/academicyears"> <mat-icon>add</mat-icon>Add Academic
                                    Year</button>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="m-0">Create Academic Calendar</p>
                                        <small class="text_color_light">Semester, Holidays, Shifts, Week-offs.</small>
                                    </div>
                                    
                                </div>
                            </ng-template>
                            <div class="ms-5 obarding_add_button">
                                <button mat-raised-button color="primary" class="px-3 add_button height_30"
                                    routerLink="/academicregulations"> <mat-icon>add</mat-icon>Add Academic
                                    Calendar</button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card>
    </div>
</div>