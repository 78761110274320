<div class="container py-4">
    <ng-container
      *ngIf="accordionData.length > 0 || uploadedDocuments.length > 0; else emptyState"
    >
      <div class="main-layout">
        <div class="sidebar">
          <ng-container *ngIf="accordionData.length > 0">
            <button
              mat-raised-button
              class="add-more"
              (click)="openDynamicPageCreationDialog()"
            >
              <mat-icon class="icon">add</mat-icon>
              Add More
            </button>
  
            <mat-accordion>
              <mat-expansion-panel *ngFor="let item of accordionData">
                <mat-expansion-panel-header (click)="toggleSelectedHeading(item)">
                  <mat-panel-title class="header-title">{{ item.title }}</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider></mat-divider>
  
                <ul class="subheading-list" *ngIf="item.subheadings?.length">
                  <li
                    *ngFor="let sub of item.subheadings"
                    class="sub-item"
                    [ngClass]="{ 'selected-subheading': sub.selected }"
                    (click)="toggleSelectedSubheading(sub)"
                  >
                    {{ sub.title }}
                  </li>
                </ul>
                <button
                  mat-raised-button
                  color="accent"
                  class="add-doc p-2 center"
                  (click)="openDynamicPageCreationDialog(true)"
                >
                  <mat-icon class="icon">add</mat-icon>
                  Add Subheading
                </button>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
  
        <div class="subheading-container">
          <div class="subheading-content" *ngIf="selectedSubheading">
            <div class="content-wrapper">
              <div class="center-content">
                <h3>{{ selectedSubheading.title }}</h3>
                <button mat-raised-button color="primary" class="add-more" (click)="triggerDynamicFileInput()">
                  <mat-icon class="icon">upload</mat-icon> Upload Document
                </button>
              </div>
              <div *ngIf="uploadedDocuments.length > 0" class="uploaded-documents">
                <h3>Uploaded Documents</h3>
                <div class="document-icons" *ngFor="let doc of uploadedDocuments">
                  <h1>{{ shortenFileName(doc.name, 5) }}</h1>
                  <div  class="document-icon">
                    <mat-icon class="doc-icon">insert_drive_file</mat-icon>
                    <a [href]="getFileURL(doc)" target="_blank" (click)="logFileDetails(doc)">Open</a>
                  </div>
                </div>              
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </ng-container>
  
    <!-- Empty State -->
    <ng-template #emptyState>
      <div class="empty-state-container">
        <p>No data available. Create a new page or upload a document.</p>
        <div class="button-group">
          <div class="upload-doc-button">
            <input
              type="file"
              id="fileUpload"
              (change)="onFileUpload($event)"
              accept=".pdf"
              style="display: none"
            />
            <button mat-stroked-button class="add-doc" color="primary" (click)="triggerFileInput()">
              <mat-icon class="icon">upload</mat-icon> Upload Document
            </button>
          </div>
  
          <div *ngIf="uploadedDocuments.length > 0" class="uploaded-documents">
            <h3>Uploaded Documents</h3>
            <div class="document-icons">
              <div *ngFor="let doc of uploadedDocuments" class="document-icon">
                <mat-icon class="doc-icon">insert_drive_file</mat-icon>
                <span>{{ doc.fileName }}</span>
                <a [href]="doc.fileUrl" target="_blank">Open</a>
              </div>
            </div>
          </div>
  
          <!-- Show Create Accordion button only if no documents exist -->
          <button
            *ngIf="uploadedDocuments.length === 0"
            mat-raised-button
            color="accent"
            class="add-more create_button"
            (click)="openDynamicPageCreationDialog()"
          >
            Create Accordion
          </button>
        </div>
      </div>
    </ng-template>
  </div>