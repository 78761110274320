import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { AddbannersComponent } from './addbanners/addbanners.component';
import { BannerDetailDialog } from './banner-detail-dialog/banner-detail-dialog.component';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { CdkDragPreview, DragDropModule } from '@angular/cdk/drag-drop';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
export interface PeriodicElement {
  index: number;
  id: string;
  name: number;
  status: number;
  tillDate: string;
}

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    MatIcon,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatMenuModule,
    DragDropModule,
    MatProgressSpinnerModule,
    CdkDragPreview,
  ],
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent {
  @Input() heading!: string;
  @Input() apiEndpoint!: string; // Endpoint for fetching banners
  @Input() createApiEndpoint!: string; // Dynamic create API endpoint
  @Input() updateApiEndpoint!: string;
  reorderMode = false;
  isLoading = false;
  displayedColumns: string[] = [
    'drag',
    'sl.no',
    'fromDate',
    'toDate',
    'info',
    'status',
    'images',
    'actions',
  ];
  bannersData: any = [];
  initialOrder: any[] = [];
  formattedDates: any;
  editId: string | null = null;
  imagePath = this.apiservice.environmentPath;

  constructor(
    private ApiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService,
    private apiservice: ApiservicesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getBannersData();
  }

  toggleReorderMode(): void {
    if (this.reorderMode) {
      if (this.hasOrderChanged()) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.submitReorderedBanners(); // Submit after 5s delay
        }, 5000);
      }
    } else {
      // Save the initial order when entering reorder mode
      this.initialOrder = this.bannersData.map((banner: any) => banner.id);
    }
    this.reorderMode = !this.reorderMode;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (!this.reorderMode) return;

    moveItemInArray(this.bannersData, event.previousIndex, event.currentIndex);

    // Update sequence
    this.bannersData = this.bannersData.map((banner: any, index: number) => ({
      ...banner,
      sequence: index + 1,
    }));
  }

  hasOrderChanged(): boolean {
    const currentOrder = this.bannersData.map((banner: any) => banner.id);
    return JSON.stringify(this.initialOrder) !== JSON.stringify(currentOrder);
  }

  submitReorderedBanners() {
    const reorderedData = this.bannersData.map(
      (banner: any, index: number) => ({
        id: banner.id,
        sequence: index + 1,
      })
    );
    this.snackBar.open('Reordered Successfully', 'Close', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['success-snackbar'],
    });
    const dataToSend = { banners: reorderedData };
    this.ApiService.updateBannerSequence(dataToSend).subscribe(
      () => {
        this.snackBar.open('Reordered Successfully', 'Close', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });
        this.getBannersData();
      },
      (error) => {
        console.error('Error updating sequence:', error);
      }
    );
  }

  openUploadDialog(): void {
    const dialogConfig = this.configureDialog({
      data: {
        createApiEndpoint: this.createApiEndpoint, // Pass create endpoint
        updateApiEndpoint: this.updateApiEndpoint, // Pass update endpoint
        bannerData: null, // For creating a new banner
      },
      minHeight: '95vh',
      maxHeight: '95vh',
      maxWidth: '35vw',
      minWidth: '35vw',
    });

    const dialogRef = this.dialog.open(AddbannersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getBannersData(); // Refresh banner list after success
      }
    });
  }

  viewItem(element: any): void {
    const dialogConfig = this.configureDialog({
      data: {
        banner: element, // Pass the selected banner data
        editCallback: (banner: any) => this.editItem(banner),
        deleteCallback: (id: number) => this.deleteApi(id),
      },
      minHeight: '70vh',
      maxHeight: '70vh',
    });

    const dialogRef = this.dialog.open(BannerDetailDialog, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getBannersData();
      }
    });
  }

  editItem(element: any): void {
    const dialogConfig = this.configureDialog({
      data: {
        createApiEndpoint: this.createApiEndpoint, // Pass create endpoint
        updateApiEndpoint: this.updateApiEndpoint, // Pass update endpoint
        bannerData: element, // Pass existing banner data for editing
      },
      minHeight: '95vh',
      maxHeight: '95vh',
      maxWidth: '35vw',
      minWidth: '35vw',
    });

    const dialogRef = this.dialog.open(AddbannersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getBannersData();
      }
    });
  }

  deleteApi(id: number) {
    const Deleteendpoint = this.createApiEndpoint;
    const confirmDelete = confirm(
      'Are you sure you want to delete this banner?'
    );
    if (confirmDelete) {
      this.apiservice.deleteBanners(Deleteendpoint,id).subscribe(
        (res: any) => {
          if (res.code === 200 && res.status === true) {
            this.getBannersData();
          } else {
            console.error(
              'Error deleting banner:',
              res.message || 'Unknown error'
            );
          }
        },
        (error) => {
          console.error('API Error during delete:', error);
        }
      );
    }
  }

  updateItem(row: any) {
    const bannerEndpoint = this.createApiEndpoint;
    this.ApiService.updateBanners(bannerEndpoint, row.id, row).subscribe(
      () => {
        this.editId = null;
        this.getBannersData();
      },
      (error) => {
        console.error('Error updating banner:', error);
      }
    );
  }

  getBannersData() {
    this.ApiService.getBannerslist(this.apiEndpoint).subscribe(
      (data: any) => {
        this.bannersData = data.rows;
      },
      (error) => {
        console.error('Error fetching banners data:', error);
      }
    );
  }

  private configureDialog(config: Partial<MatDialogConfig>): MatDialogConfig {
    return {
      disableClose: true,
      panelClass: [
        'dialog-bottom-enter',
        'dialog-bottom-leave',
        'animate__animated',
      ],
      maxWidth: '50vw', // Set the dialog width to 50% of the screen width
      ...config,
    };
  }
}
