import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { Observable } from 'rxjs';
import { SemestersService } from '../../../../inputfileds/form_services/semesters/semesters.service';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { environment } from '../../../../../environments/environment';

export interface Subjectmodel {
  index: number;
  organization_id: number;
  course_id: number;
  semister_id: number;
  department_id: number;
  department_name: string;
  subject_name: string;
  subject_code: string;
  description: string;
}

@Component({
  selector: 'app-addsubjects',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    DynamicFormQuestionComponent,
    ToastComponent,
  ],
  templateUrl: './addsubjects.component.html',
  styleUrls: ['./addsubjects.component.scss'],
  providers: [QuestionControlService, SemestersService, ApiservicesService],
})
export class AddsubjectsComponent {
  questions: any[] = [];
  questions$: Observable<any[]> | null = null;
  form!: FormGroup;
  step = 0;
  datesend: any;
  course_ID: any;
  semesterwisedata: any = [];
  errorMessage: any = null;
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file: File | null = null;
  dataId : number | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public SemesterData: any,
    private dialogRef: MatDialogRef<AddsubjectsComponent>,
    private apiservice: ApiservicesService,
    private services: SemestersService,
    private fb: FormBuilder,
    private toast: ToastService
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({});
    this.questions$ = this.services.getSubjectForm();
    this.questions$.subscribe((q) => (this.questions = q));
    for (const question of this.questions) {
      this.form.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }
  
    // Check if SemesterData.editdata exists to determine edit mode
    if (this.SemesterData?.editdata) {
      this.editsubjectdata();
      this.editflag = true;
    } else {
      this.form.reset();
      this.editflag = false;
    }
  }
  
  editsubjectdata() {
    if (this.SemesterData?.editdata) {
      this.form.patchValue({
        subjectImage: this.SemesterData.editdata.image_path || '',
        organization_id: this.SemesterData.editdata.organization_id || null,
        subject_name: this.SemesterData.editdata.subject_name || '',
        subject_code: this.SemesterData.editdata.subject_code || '',
        subject_credits: this.SemesterData.editdata.subject_credits || 0,
        department_id: this.SemesterData.editdata.department_id || null,
        description: this.SemesterData.editdata.description || '',
      });
      if (this.SemesterData.editdata.image_path) {
        this.selectedImageUrl = `${environment.ASSET_URL}${environment.ASSET_CONTAINER}/${this.SemesterData.editdata.image_path}`;
      }
    }
  }
  
  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any) {
    this.datesend = this.form.getRawValue();
    this.datesend = {
      ...this.datesend,
      course_id: this.SemesterData.semesterData.course_id,
      semister_id: this.SemesterData.semesterData.id,
      subject_credits: this.SemesterData.subject_credits,
    };
    this.datesend.subject_credits = parseInt(this.form.value.subject_credits);
    this.datesend.organization_id = parseInt(this.form.value.organization_id);
    this.datesend.department_id = parseInt(this.form.value.department_id);
    this.datesend.subjectImage = this.form.value.subjectImage;
    if (event == false) {
      this.createAPi(this.datesend);
    } else {
      this.editAPI(this.datesend);
    }
  }

  createAPi(event: any) {
    console.log('Event ======> ', event);

    this.apiservice.createsubjects(event).subscribe((res: any) => {
      if (res.code === "204" || res.status === true) {
        if (this.file) {
          this.imageupload(res.data.id, this.file);
          this.closeDialog();
          this.toast.showToast('success', 'Employee Saved successfully');
        } else {
          this.errorMessage = res.message;
          this.toast.showToast('danger',  `Error: ${res.message}`);
        }
      } else {
        this.errorMessage = res.message;
        this.toast.showToast('danger',  `Error: ${res.message}`);
      }
    });
  }

  editAPI(event: any) {
    this.apiservice.editsubjects(event, this.SemesterData.editdata.id).subscribe((res: any) => {
        if (res.code === "204" || res.status === true) {
          this.imageupload(event.id, event.subjectImage);
          this.closeDialog();
          this.toast.showToast('success', 'Employee updated successfully');
        } else {
          this.errorMessage = res.message;
          this.toast.showToast('danger',  `Error: ${res.message}`);
        }
      });
  }

  imageInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.fileName = fileInputEvent.target.files[0].name;
    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.selectedImageUrl = reader.result;
      };
    }
  }

  imageupload(subjectId: number, imagedata: File) {
    this.apiservice
      .createsubjectsimage(subjectId, imagedata)
      .subscribe((res: any) => {
        if (res.code === 201 && res.status === true) {
          this.form.reset();
          this.dialogRef.close();
        } else {
          console.error('Image upload failed:', res.message || 'Unknown error');
          this.errorMessage = res.message || 'Failed to upload image.';
        }
      });
  }
  csvInputChange(fileInputEvent: any) {
    console.log(fileInputEvent.target.files[0]);
    this.imageuploaddata = fileInputEvent.target.files[0];
    this.apiservice
      .createsubjectsimage(29, fileInputEvent.target.files[0])
      .subscribe((res: any) => {
        if (res.code === 201 && res.status === true) {
          this.form.reset();
          this.dialogRef.close();
          this.toast.showToast('success', 'saved successfully');
        } else {
          this.toast.showToast('danger', `Error: ${res.message}`);
          this.errorMessage = res.message;
        }
      });
    if (fileInputEvent.target.files && fileInputEvent.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(fileInputEvent.target.files[0]);
      reader.onload = (fileInputEvent) => {
        this.url = fileInputEvent.target?.result;
      };
    }
  }
}
