import { Component, Inject } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { permissionService } from '../../../../../inputfileds/form_services/permissionleaves/permissionleaves.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Component({
  selector: 'app-permissiondialogbox',
  standalone: true,
  imports: [MatIconModule, MatDividerModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, permissionService, ApiservicesService, DropdownservicesService],
  templateUrl: './permissiondialogbox.component.html',
  styleUrl: './permissiondialogbox.component.scss'
})
export class PermissiondialogboxComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  permissionData = []
  showaddflag: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PermissiondialogboxComponent>, 
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService,
    private qcs: QuestionControlService, 
    service: permissionService) {
    this.questions$ = service.getPermissionLeaves();
    this.questions$.subscribe(q => this.questions = q);

    this.dropdownservice.getcoursesdetails().subscribe((res) => {
      this.permissionData = res.rows
      // this.dataSource = new MatTableDataSource<CourseView>(res.rows)
    })
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  onSubmit() {
  }

  Canceldailog(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialogRef.close(); }, 1000);
  }
}