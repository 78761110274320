import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Inputfiledsbase } from '../../inputfileds/models/inputfiledsbase';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { Observable } from 'rxjs/internal/Observable';
import { QuestionService } from '../../inputfileds/form_services/question/question.service';
import { LookuptypeService } from '../../inputfileds/form_services/lookuptype/lookuptype.service';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DepartmentService } from '../../inputfileds/form_services/department/department.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SettingServiceService } from '../../Services/setting-service.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastComponent } from '../../components/toast/toast.component';
import { ToastService } from '../../Services/toast/toast.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { PermissionService } from '../../components/Authentication/services/permission.service';


// import {DynamicFormQuestionComponent} from './dynamic-form-question.component';

// import {QuestionBase} from './question-base';
// import {QuestionControlService} from './question-control.service';

export interface lookuptypeView {
  index: number,
  id: number,
  organization_id: number,
  lookup_type_name: string,
  lookup_name: string,
  // lookup_display_name: string,
  description: string,
}

@Component({
  selector: 'app-lookuptypes',
  standalone: true,
  providers: [QuestionControlService, QuestionService, ApiservicesService, LookuptypeService],
  imports: [
    DynamicFormQuestionComponent,
    MatTableModule,
    MatCheckboxModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    ToastComponent,
    PaginationComponent
  ],
  templateUrl: './lookuptypes.component.html',
  styleUrl: './lookuptypes.component.scss'
})

export class LookuptypesComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  lookuptypeData = []
  displayedColumns: string[] = ['lookup_type', 'lookup_type_displayname', 'description'];
  dataSource = new MatTableDataSource<lookuptypeView>();
  selection = new SelectionModel<lookuptypeView>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  showaddflag: boolean = false;
  isLoadingResults = true;
  isRateLimitReached = false;
  total_count: any;
  paginationData: any;
  actiondata: any;
  
  constructor(
    private qcs: QuestionControlService, 
    service: LookuptypeService, 
    private apiservice: ApiservicesService, 
    private toast: ToastService,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    // if (this.actiondata.view === false) {
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    // }
    this.questions$ = service.getlookuptypeform();
    this.questions$.subscribe(q => this.questions = q);
    this.getlookuptypedetails();
  }

  getlookuptypedetails() {
    this.apiservice.getlookuptypedetails(this.paginationData).subscribe((res) => {
      this.lookuptypeData = res.rows
      this.total_count = res.total_count;
      this.dataSource = new MatTableDataSource<lookuptypeView>(res.rows)
      this.isLoadingResults = false;
          this.isRateLimitReached = res === null;
      console.log("=>", res)
    })
  }
  showadd() {
    this.showaddflag = true;
  }

  pagination(event: any) {
    this.paginationData = event;
    this.getlookuptypedetails();
  }

  addbackflag(event: boolean) {
    this.showaddflag = event;
  }
  ngOnInit() {
    // this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: lookuptypeView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  // editform () {
  //   this.form 
  // }

  onSubmit() {
    let datatosend = this.form.value
    let filterdata: any
    // this.apiservice.getorganizationdrop().subscribe((res:any) => {
    //   console.log("id number", this.form.getRawValue().name );
    //   filterdata = res.filter((dropfilter:any)=>{ return dropfilter.id === this.form.value.organization_id })
    //   datatosend.organization_id = filterdata[0].id;
    //   datatosend = { ...datatosend, organization_id: filterdata[0].id}
    //   console.log(datatosend);
    //   this.createAPi(datatosend);
    // })
    this.payLoad = this.form.getRawValue();
    this.createAPi(this.payLoad)
    console.log(this.payLoad);

  }

  createAPi(event: any) {
    this.apiservice.createlookuptype(event).subscribe((res: any) => {
      if (res.code === 200 || res.code === 201 && res.status === true) {
        this.toast.showToast('success', "submitted successfully");
        this.form.reset();
        this.addbackflag(false);
        this.getlookuptypedetails();
      }
      else{
        this.toast.showToast('danger', `Error: ${res.message}`);
    }
    })
  }
}
