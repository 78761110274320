import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { Inputfiledsbase } from '../../../models/inputfiledsbase';
import { DropdownQuestion } from '../../../question-dropdown';

@Injectable({
  providedIn: 'root',
})
export class WebsiteDepartmentService {
  departmentlist$: Observable<
    {
      id: number;
      key: string;
      value: number;
      departments: { id: number; key: string; value: number }[];
    }[]
  >;

  constructor(private dropdownservice: DropdownservicesService) {
    this.departmentlist$ = of([
      {
        id: 1,
        key: 'Law',
        value: 1,
        departments: [
          { id: 101, key: 'Criminal Law', value: 101 },
          { id: 102, key: 'Corporate Law', value: 102 },
          { id: 103, key: 'Constitutional Law', value: 103 },
        ],
      },
      {
        id: 2,
        key: 'Degree',
        value: 2,
        departments: [
          { id: 201, key: 'Computer Science', value: 201 },
          { id: 202, key: 'Commerce', value: 202 },
          { id: 203, key: 'Psychology', value: 203 },
        ],
      },
    ]);
  }

  getWebDepartmentDropForm(): Observable<Inputfiledsbase<string>[]> {
    return this.departmentlist$.pipe(
      map((departmentlist) => {
        // Extracting all departments into a flat list
        const flatDepartments = departmentlist.flatMap(
          (dept) => dept.departments
        );

        const questions: Inputfiledsbase<string>[] = [
          new DropdownQuestion({
            key: 'department_id',
            label: 'Department',
            options: flatDepartments, // Now correctly mapped
            required: true,
            order: 1,
          }),
        ];
        return questions.sort((a, b) => a.order - b.order);
      })
    );
  }
}
