import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuItemsComponent } from './menu-items/menu-items.component';
import { HeaderComponent } from './header/header.component';
import { AuthenticationService } from '../components/Authentication/services/authentication.service';
import { LoginComponent } from '../components/login/login.component';
import { ToastComponent } from '../components/toast/toast.component';
import { MenuService } from '../components/Authentication/services/menu.service';
import { PermissionService } from '../components/Authentication/services/permission.service';

@Component({
    selector: 'app-layout',
    standalone: true,
    imports: [
        // LoginComponent,
        // ToastComponent,
        // NavbarComponent,
        // FooterComponent,
        RouterModule,
        HeaderComponent,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatExpansionModule,
        MenuItemsComponent],
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {

    menu: any
    opened = true;
    userdata: any;
    sub_org_data: any;
    onboarding_flag?: boolean;
    uiMenu: any = [
        // {
        //     title: 'Faqs',
        //     match_key: 'FAQS',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Academics',
            match_key: 'ACADEMICS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            order: 10,
            disable: this.onboarding_flag,
            subMenu: [
                {
                    title: 'Academic Calenders',
                    match_key: 'ACADEMIC_CALENDAR',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/academicyears',
                    color: '#3f51b5',
                    order: 5,
                },
                {
                    title: 'Academic Sections',
                    match_key: 'ACADEMIC_SECTIONS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    color: '#3f51b5',
                    link: '/sectiondetails',
                    order: 2,
                },
                {
                    title: 'Academic Regulations',
                    match_key: 'ACADEMIC_REGULATIONS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    color: '#3f51b5',
                    link: '/academicregulations',
                    order: 3,
                },
                {
                    title: 'Academic Streams',
                    match_key: 'ACADEMIC_STREAMS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    color: '#3f51b5',
                    link: '/stream',
                    order: 4,
                },
                {
                    title: 'Time Table',
                    match_key: 'TIME_TABLE',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/timetable',
                    color: '#3f51b5',
                    order: 1,
                    // subMenu: []
                },
            ],
        },
        {
            title: 'College Management',
            match_key: 'COLLEGE_MANAGEMENT_SYSTEM',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            order: 9,
            disable: this.onboarding_flag,
            subMenu: [
                {
                    title: 'Streams',
                    match_key: 'STREAMS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/stream',
                    color: '#3f51b5',
                    order: 5,
                },
                // {
                //     title: 'Academic Semisters',
                //     match_key: 'ACADEMIC_SEMISTERS',
                //     icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                //     color: '#3f51b5',
                //     link: '/academicyears',
                // },
                // {
                //     title: 'Academic Sections',
                //     match_key: 'ACADEMIC_SECTIONS',
                //     icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                //     color: '#3f51b5',
                //     link: '/sectiondetails',
                //     order:2,
                // },
                {
                    title: 'Courses',
                    match_key: 'COURSES',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/courses',
                    color: '#3f51b5',
                    order: 1,
                    subMenu: []
                },
                {
                    title: 'Homepage',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/homepage',
                    color: '#3f51b5',
                    order: 2,
                    subMenu: []
                },
                {
                    title: 'Activities',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/activities',
                    color: '#3f51b5',
                    order: 3,
                    subMenu: []
                },
                {
                    title: 'Departments',
                    match_key: 'DEPARTMENTS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/department',
                    color: '#3f51b5',
                    order: 4,
                    subMenu: []
                },
                // {
                //     title: 'Time Table',
                //     match_key: 'TIME_TABLE',
                //     icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                //     link: '/timetable',
                //     color: '#3f51b5',
                //     order:1,
                //     // subMenu: []
                // },
            ],
        },
        {
            title: 'Admissions',
            match_key: 'ADMISSIONS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            order: 3,
            disable: this.onboarding_flag,
            subMenu: [
                {
                    title: 'Students',
                    match_key: 'STUDENTS_LIST',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/student',
                    color: '#3f51b5',
                    order: 1,
                },
                {
                    title: 'Students',
                    match_key: 'STUDENTS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/student',
                    color: '#3f51b5',
                    order: 1,
                },
            ],
        },
        {
            title: 'Website Management',
            match_key: 'WEBSITE_MANAGEMENT_SYSTEM',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            order: 3,
            disable: this.onboarding_flag,
            subMenu: [
                {
                    title: 'Home Page',
                    match_key: 'HOME_PAGE',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/homepage',
                    color: '#3f51b5',
                    order: 1,
                },
                {
                    title: 'Activities',
                    match_key: 'ACTIVITIES',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/activities',
                    color: '#3f51b5',
                    order: 2,
                },
                {
                    title: 'NAAC',
                    match_key: 'NAAC',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/naacdetails',
                    color: '#3f51b5',
                    order: 3,
                },
            ],
        },
        {
            title: 'Organizations',
            match_key: 'ORGANIZATIONS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            order: 3,
            disable: this.onboarding_flag,
            subMenu: [
                {
                    title: 'Sub Organizations',
                    match_key: 'SUB_ORGANIZATIONS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/organizations',
                    color: '#3f51b5',
                    order: 1,
                }
            ],
        },
        {
            title: 'Students',
            match_key: 'STUDENTS',
            icon: '../../assets/logo/menu/students_menu_logo.svg',
            link: '/student',
            color: '#3f51b5',
            order: 2,
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Employees',
            match_key: 'EMPLOYEES',
            icon: '../../assets/logo/menu/staff_menu_logo.svg',
            link: '/employee',
            color: '#3f51b5',
            order: 3,
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Classes',
            match_key: 'CLASSES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/classes',
            color: '#3f51b5',
            order: 5,
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Lookup Codes',
            match_key: 'LOOKUP_CODES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/lookupcodeview',
            color: '#3f51b5',
            order: 6,
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Lookup Types',
            match_key: 'LOOKUP_TYPES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/lookuptype',
            color: '#3f51b5',
            order: 7,
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Demo Types',
            match_key: 'DEMO',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/timings',
            color: '#3f51b5',
            order: 7,
            disable: this.onboarding_flag,
            subMenu: [],
        },
        {
            title: 'Employee Attendance',
            match_key: 'EMPLOYEE_ATTENDANCE',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/employeeatendance',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Student Attendance',
            match_key: 'STUDENT_ATTENDANCE',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/studentatendance',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Leaves',
            match_key: 'EMPLOYEE_LEAVES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/requests',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Leave settings',
            match_key: 'ORGANIZATION_SETTINGS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/timings',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Leave Type',
            match_key: 'LEAVE_SETTINGS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/leavetype',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Sections',
            match_key: 'SECTIONS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/sectiondetails',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            subMenu: []
        },
        {
            title: 'Leave Management',
            match_key: 'LEAVE_MANAGEMENT_SYSTEM',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            disable: this.onboarding_flag,
            order: 5,
            subMenu: [
                {
                    title: 'My Leave',
                    match_key: 'MY_LEAVES',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/requests',
                    color: '#3f51b5',
                    order: 1,
                },
                {
                    title: 'Leave Types',
                    match_key: 'LEAVE_TYPES',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/leavetype',
                    color: '#3f51b5',
                    order: 2,
                },
                {
                    title: 'My Attendance',
                    match_key: 'MY_ATTENDANCE',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/employeeatendance',
                    color: '#3f51b5',
                    order: 3,
                },
            ],
        },
    ];


    constructor(private router: Router, private authenticationService: AuthenticationService, private permissionservice: PermissionService, private munuservice: MenuService) {
        if (this.authenticationService.user) {
            this.authenticationService.user.subscribe(x => {
                this.userdata = x
            });
        } else {
            this.router.navigate(['/login']);
        }
        let sub_org: any = sessionStorage.getItem("SubOrgData");
        this.sub_org_data = JSON.parse(sub_org);

        this.uiMenu.map((items: any, index: number) => {
            if (this.sub_org_data?.onboarding_status) {
                this.uiMenu[index].disable = false
            } else {
                this.uiMenu[index].disable = true;
            }
        })
    }
    toggle(): void {
        this.opened = !this.opened;
    }

    ngOnInit(): void {
        let updatedMenu: any = [];
        let roleJson: any = JSON.parse(this.userdata)?.data.role_json;
        // this.uiMenu.detectChanges();

        // let roleJson: any = this.RoleJson;

        // Object.keys(roleJson).map((a: any) => {
        //     if (typeof roleJson[a] != "undefined" && typeof roleJson[a].IS_ENABLED != "undefined") {
        //         if (roleJson[a].IS_ENABLED === true) {
        //             let x: any = this.uiMenu.find((ui: any) => { return ui.match_key === a; });
        //             if (typeof x != "undefined") {
        //                 x.subMenuflag = false;
        //                 x.SubMenu = []
        //                 x.actions = roleJson[a].ACTIONS;
        //                 if (x.subMenu.length > 0) {
        //                     x.subMenuflag = true;
        //                     roleJson[a].SUBMENU?.map((b: any) => {
        //                         if (b.IS_ENABLED === true) {
        //                             let y: any = x.subMenu.find((ui: any) => { return ui?.match_key === b?.SUBMENU_NAME; });
        //                             if (typeof y != "undefined") {
        //                                 x.SubMenu.push(y)
        //                             }
        //                         }
        //                     })
        //                 }
        //                 updatedMenu.push(x);
        //             }
        //         }
        //     }
        // });

        roleJson.modules.map((a: any) => {

            if (a.access_type === 'FULL_ACCESS' || a.access_type === 'LIMITED') {
                let x: any = this.uiMenu.find((ui: any) => { return ui.match_key === a.module_name; });
                if (typeof x != "undefined") {
                    x.subMenuflag = false;
                    x.SubMenu = []
                    x.actions = (((a.access_type === 'FULL_ACCESS' || a.access_type === 'LIMITED') && a.sub_modules?.length == 0) ? roleJson.permissions : a.permissions);
                    if (a.sub_modules?.length > 0) {
                        a.sub_modules?.map((b: any, index: number) => {
                            if (b.access_type === 'FULL_ACCESS' || b.access_type === 'LIMITED') {
                                let y: any = x.subMenu.find((ui: any) => { return ui?.match_key === b?.sub_module_name; });
                                if (typeof y != "undefined") {
                                    x.SubMenu.push(y);
                                    x.SubMenu[index] = { ...x.SubMenu[index], actions: (b.access_type == 'FULL_ACCESS' ? roleJson.permissions : b.permissions) }
                                } else {
                                    x.SubMenu[index] = { ...x.SubMenu[index], match_key: b.sub_module_name, actions: (b.access_type == 'FULL_ACCESS' ? roleJson.permissions : b.permissions) }
                                }
                            }
                        })

                        if (x.match_key == "DEMO") {
                            x.subMenuflag = false;
                            this.permissionservice.tabactionsdata = x.SubMenu;
                        } else {
                            x.subMenuflag = true;
                        }
                    }
                    delete x['subMenu'];
                    updatedMenu.push(x);
                }
            }
        })

        updatedMenu.forEach((item: any) => {
            if (item.SubMenu && item.SubMenu.length > 0) {
                item.SubMenu.sort((a: any, b: any) => a.order - b.order);
            }
        });

        updatedMenu.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order);

        this.menu = updatedMenu;
        this.munuservice.menudata = this.menu;
    }

}
