import { Component, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { AuthenticationService } from '../../components/Authentication/services/authentication.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment.prod';
import { ToastComponent } from '../../components/toast/toast.component';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';
@Component({
  selector: 'app-employeeprofileview',
  standalone: true,
  imports: [
    MatTabsModule, 
    CommonModule, 
    MatIconModule, 
    MatButtonModule, 
    // ToastComponent
  ],
  templateUrl: './employeeprofileview.component.html',
  styleUrl: './employeeprofileview.component.scss'
})
export class EmployeeprofileviewComponent {

  user: any;
  employeeData: any;
  id: any;
  userId: any;
  employeeprofile: any;
  employeeaddress: any = [];
  employeeeducation: any = [];
  imageUrl: any;
  imgurl = environment.ASSET_URL + environment.ASSET_CONTAINER + '/';
  onerror = 'https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg'
  url: string | ArrayBuffer | null | undefined;
  employeesalary: any;
  employeeexperience: any;
  dataId!: number;
  employeejoining: any;
  console = console;

  constructor(
    private apiservice: ApiservicesService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute) {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x => {
        this.user = JSON.parse(x)
      });
    }
  }

  getemployeesdetails(eventId: any) {
    this.apiservice.getemployeedetails(eventId).subscribe(employeedata => {
      this.employeeData = employeedata;
      this.imageUrl = environment.ASSET_URL + environment.ASSET_CONTAINER + '/' + this.employeeData.image_path;
      this.employeeprofile = employeedata.personal_details;
      this.employeeaddress = employeedata.addresses;
      this.employeeeducation = employeedata.educational_qualifications;
      this.employeesalary = employeedata.salary_deatails;
      this.employeeexperience = employeedata.experience_details;
      this.employeejoining = employeedata.joining_details;
   })
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {

    if (this.route.snapshot.queryParams['dataId'] && !isNaN(this.route.snapshot.queryParams['dataId'])) {
      this.dataId = this.route.snapshot.queryParams['dataId'];
      this.getemployeesdetails(this.dataId);
    } else {
      this.dataId = this.user?.data?.id;
      this.getemployeesdetails(this.user?.data?.id)
    }
    // });
  }

  csvInputChange(fileInputEvent: any) {
    this.updateimage(fileInputEvent.target.files[0])
    if (fileInputEvent.target.files && fileInputEvent.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(fileInputEvent.target.files[0]);
      reader.onload = (fileInputEvent) => {
        this.url = fileInputEvent.target?.result;
        this.employeeData.image_path = this.url;
        // this.updateImgflag = true;
      }
    }
  }

  updateimage(event: any) {
    this.apiservice.updateemployeeimage(event, this.dataId).subscribe(res => {
      this.getemployeesdetails(this.dataId)
    })
  }

}
