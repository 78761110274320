<section class="mx-3">
  <app-toast></app-toast>
  <ng-container *ngIf="!showaddflag">
    <div>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="page_header">
          <h3 class="m-0 page_header_title">Employee Details</h3>
        </div>
        <div *ngIf="actiondata.create" class="d-flex button-row">
          <div class="px-3">
            <div class="bulk_button">
              <!-- <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)" /> -->
              <button mat-raised-button class="bulk_button_add add_button" (click)="openUploadDialog('details', 'EMPLOYEES_BULKUPLOAD_TEMPLATE')">
                <img src="../../../assets/icons/fileupload.svg" class="me-2" alt="upload icon" />
                <span>Bulk Upload</span></button>
              <button mat-raised-button class="m-0 p-0 bulk_button_view" [matMenuTriggerFor]="menu" color="primary">
                <mat-icon class="m-0">expand_more</mat-icon></button>
            </div>
            <mat-menu #menu="matMenu" class="download_menu">
              <button mat-menu-item (click)="openUploadDialog('details', 'EMPLOYEES_BULKUPLOAD_TEMPLATE')">
                Upload Employee Details
              </button>
              <button mat-menu-item (click)="openUploadDialog('images','employeImages')">
                Upload Employee Images
              </button>
              <button mat-menu-item (click)="openUploadDialog('images','employeImages')">
                Download Template Employee
              </button>
            </mat-menu>
          </div>
          <div>
            <button mat-raised-button color="primary"
              (click)="showadd(true); resetemployeeform(); isEditMode = false; editeducationarrayflag = true"
              class="px-5">Add</button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="actiondata.list_view">
        <div class="mt-2 table-container">
          <div class="d-flex align-items-center m-2">
            <div class="filter_icon">
              <div style="color:rgb(19, 19, 19);" [matMenuTriggerFor]="filterMenu"><img
                  src="../../../assets/icons/filter_Icon.svg" alt="filter_icon" /> Filter</div>
              <mat-menu #filterMenu="matMenu" (closed)="menuClosed($event)">
                <div class="p-3" (click)="$event.stopPropagation()">
                  <form (ngSubmit)="filterEmployees($event)" [formGroup]="EmployeeFilterForm">
                    <div *ngFor="let question of EmployeeFilterFormQuestions">
                      <app-question [question]="question" [form]="EmployeeFilterForm"></app-question>
                    </div>
                    <div class="p-3" (click)="$event.stopPropagation()">
                      <button mat-flat-button style="background-color: #3F51B5;color:white;" type="submit">
                        <mat-icon>filter_list_alt</mat-icon>Filter
                      </button>
                      <button mat-flat-button class="m-2" style="background-color: #f52d2d;color:white;"
                        (click)="clearFilter()">Clear</button>
                    </div>
                  </form>
                </div>
              </mat-menu>
            </div>
            <!-- <div>
        <mat-icon class="material-symbols-outlined" style="color:rgb(160, 160, 160);">more_vert</mat-icon>
      </div>  -->
          </div>
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Position Column -->
            <!-- <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.professional_email}} </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number </th>
              <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="employee_unique_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Faculty Unique ID </th>
              <td mat-cell *matCellDef="let element"> {{element.employee_unique_id}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="designation_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Designation </th>
              <td mat-cell *matCellDef="let element"> {{element.designation_name}} </td>
            </ng-container>
            <ng-container matColumnDef="organization_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Department</th>
              <td mat-cell *matCellDef="let element"> {{element.department_name}} </td>
            </ng-container>
            <ng-container matColumnDef="Action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <!-- View option as clickable text -->
                  <ng-container *ngIf="actiondata.view">
                    <p (click)="gotoemplyeeview(element)" class="mb-0 cursor_pointer view_button">View</p>
                  </ng-container>

                  <!-- 3-dots menu for edit option -->
                  <ng-container *ngIf="actiondata.edit">
                    <button mat-icon-button class="action_button" [matMenuTriggerFor]="menu" aria-label="Actions">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button class="update_button" mat-menu-item (click)="editdata(element)">
                        <!-- <mat-icon>edit</mat-icon> -->
                        <span>Edit</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data</td>
            </tr>
          </table>
        </div>
        <div class="mt-2" *ngIf="total_count > 5">
          <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="showaddflag">
    <div class="">
      <div class="d-flex align-items-center">
        <div>
          <button mat-icon-button class="back_icon me-2 cancel_button" (click)="showadd(false)">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <!-- <div>
          <button (click)="editdata({id:35745})">edit</button>
        </div> -->
        <div>
          <h4 class="m-0">Add Employee</h4>
        </div>
      </div>

      <div class="wrapper">
        <div *ngIf="errorMessage" class="text-center text_color_red error-message">
          {{ errorMessage }}
        </div>
        <mat-stepper labelPosition="bottom" linear="true" #stepper class="form-stepper bg-transparent">
          <!-- <mat-step [stepControl]="form" label="Personal Details"> -->
          <mat-step label="Step 1"
            [stepControl]="EmployeeProfileForm && EmployeePresentAddressForm && EmployeePermanentAddressForm">
            <div class="mt-4">
              <h4>1.1 Personal Details</h4>
            </div>
            <form (ngSubmit)="getfirtformdata()" [formGroup]="EmployeeProfileForm">
              <!-- <div class="row">
                
              </div> -->
              <div class="row">
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-12">
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <app-question [question]="EmployeeProfileFormQuestions[0]"
                        [form]="EmployeeProfileForm"></app-question>
                    </div>
                  </div>
                    <!-- <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <app-question [question]="EmployeeProfileFormQuestions[0]"
                        [form]="EmployeeProfileForm"></app-question>
                    </div> -->
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <div class="d-flex">
                        <div class="col-4 me-2">
                          <app-question [question]="EmployeeProfileFormQuestions[1]"
                            [form]="EmployeeProfileForm"></app-question>
                        </div>
                        <div class="col-8">
                          <app-question [question]="EmployeeProfileFormQuestions[2]"
                            [form]="EmployeeProfileForm"></app-question>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <app-question [question]="EmployeeProfileFormQuestions[3]"
                        [form]="EmployeeProfileForm"></app-question>
                    </div>
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <app-question [question]="EmployeeProfileFormQuestions[4]"
                        [form]="EmployeeProfileForm"></app-question>
                    </div>
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <app-question [question]="EmployeeProfileFormQuestions[5]"
                        [form]="EmployeeProfileForm"></app-question>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 d-flex align-items-center justify-content-center">
                  <div class="m-2">
                    <input #csvInput hidden="true" type="file" onclick="this.value=null"
                      (change)="imageInputChange($event)" accept="image/png, image/jpeg"/>
                    <div>
                      <img *ngIf="!selectedImageUrl; else showImage" src="../../../assets/icons/Upload photo icon.svg"
                        aria-hidden="false" (click)="csvInput.click()"
                        style="cursor: pointer; width: 200px; height: 200px;" />
                      <ng-template #showImage>
                        <img [src]="selectedImageUrl" alt="Selected Image" (click)="csvInput.click()"
                          style="width: 200px; height: 200px; cursor: pointer;" />
                      </ng-template>
                      <p class="text-center mt-1">Upload Your Photo</p>
                    </div>
                    <p *ngIf="fileName" class="text-center mt-1">Selected file: {{ fileName }}</p>
                  </div>
                </div>
                <div *ngFor="let question of filterdata"
                  class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                  <app-question [question]="question" [form]="EmployeeProfileForm"></app-question>
                </div>
              </div>
              <!-- <div>
                <button type="submit">getdata</button>
              </div> -->
            </form>
            <hr />
            <div>
              <!-- <div>
                <h4>Present Address</h4>
              </div> -->
              <form [formGroup]="EmployeePresentAddressForm">
                <div class="row">
                  <div *ngFor="let question of EmployeeAddressFormQuestions"
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                    <app-question [question]="question" [form]="EmployeePresentAddressForm"></app-question>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <mat-checkbox class="mb-3" [(ngModel)]="checked" [(indeterminate)]="indeterminate"
                (click)="addresscheckbox()" [disabled]="!EmployeePresentAddressForm.valid">
                Permanent Address is same as Present Address
              </mat-checkbox>
            </div>
            <!-- <div>
              <form [formGroup]="EmployeeeAddresscheckboxForm">
                <div class="row">
                  <div *ngFor="let question of EmployeeeAddresscheckboxQuestions; let i=index"
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                    <app-question [question]="question"
                      [form]="EmployeeeAddresscheckboxForm"></app-question>
                  </div>
                </div>
              </form>
            </div> -->
            <div>
              <div>
                <h4>Permanent Address</h4>
              </div>
              <form [formGroup]="EmployeePermanentAddressForm">
                <div class="row">
                  <div *ngFor="let question of EmployeeAddressFormQuestions; let i=index"
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                    <app-question [question]="changingids(question, i)"
                      [form]="EmployeePermanentAddressForm"></app-question>
                  </div>
                </div>
              </form>
            </div>
            <hr />
            <div>
              <div class="mb-2">
                <ng-container *ngIf="educationaddformarry.length > 0">
                  <div class="row">
                    <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                      *ngFor="let arrayform of educationaddformarry; let i=index">
                      <!-- <ng-container *ngIf="arrayform.qualification_id !== null"> -->
                      <div class="card position-relative" *ngIf="arrayform.qualification_id !== null">
                        <div class="m-2 px-2">
                          <div><b>{{arrayform.qualification_name}}</b></div>
                          <div>{{arrayform.course_name}}</div>
                          <div class="mt-3">
                            <div>Year of passing : <span>{{arrayform.end_year | date: 'yyyy'}}</span> </div>
                            <div>Pass Percentage : <span>{{arrayform.percentage}}</span></div>
                          </div>
                        </div>
                        <!-- <div class="d-flex align-items-end">
                          <div><button mat-raised-button color="primary" class="mx-2"
                              (click)="editarrayform(arrayform)">edit</button></div>
                          <div><button mat-raised-button color="primary" (click)="deletearrayform(i)">delete</button>
                          </div>
                        </div> -->
                        <div class="position-absolute top-0 end-0">
                          <button mat-icon-button class="action_button" [matMenuTriggerFor]="menu" aria-label="edit">
                            <mat-icon>more_vert</mat-icon>
                          </button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item class="update_button" (click)="editarrayform(arrayform, i)">
                              <span>Edit</span>
                            </button>
                            <button mat-menu-item class="delete_button" (click)="deletearrayform(i)">
                              <span>Delete</span>
                            </button>
                          </mat-menu>
                        </div>
                      </div>
                      <!-- </ng-container> -->
                    </div>
                  </div>
                </ng-container>
              </div>
              <div>
                <form (ngSubmit)="arraySubmit()" [formGroup]="EmployeeEducationForm">
                  <div>
                    <h4>1.2 Education Details</h4>
                  </div>
                  <div class="row">
                    <div *ngFor="let question of EmployeeEducationFormQuestions"
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                      <app-question [question]="question" [form]="EmployeeEducationForm"></app-question>
                    </div>
                  </div>
                  <div class="d-flex">
                    <button type="button" class="me-3 cancel_button" (click)="reseteducationform()"
                      mat-raised-button>Cancel</button>
                    <ng-container *ngIf="editeducationarrayflag">
                      <button type="submit" class="add_button" mat-raised-button color="primary" (click)="arraySubmit()"
                        [disabled]="!EmployeeEducationForm.valid">Add Details</button>
                    </ng-container>
                    <ng-container *ngIf="!editeducationarrayflag">
                      <button type="submit" class="update_button" mat-raised-button color="primary"
                        (click)="editarrySubmit()" [disabled]="!EmployeeEducationForm.valid">Update Details</button>
                    </ng-container>
                  </div>

                  <!-- add the submit button if the form is valid -->
                </form>
              </div>
            </div>
          </mat-step>
          <!-- <mat-step label="Address" [stepControl]="form1"> -->

          <!-- <mat-step label="Educational Details" [stepControl]="form2">
            <button (click)="addGroup()">Add</button>
            <form [formGroup]="educationaddform">
              <div  *ngFor="let educational_qualifications of this.fa?.value; let i = index">
              <div class="row" >
                <div *ngFor="let question of Vuequestions" class="col-sm-6">
                  <app-question
                    [question]="functionaddid(question, i)"
                    [form]="objectToFormGroup(educational_qualifications)"
                  ></app-question>
                </div>
              </div>
              </div>
              <div>
                <button (click)="getform3()"></button>
              </div>
            </form>
          </mat-step> -->
          <mat-step [stepControl]="EmployeeExperienceForm" label="Step 2">
            <div>
              <div class="mt-4">
                <h4>2. Experience & Publications</h4>
              </div>
              <div>
                <form [formGroup]="EmployeeExperienceForm">
                  <div class="row">
                    <div *ngFor="let question of EmployeeExperienceFormQuestions"
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                      <app-question [question]="question" [form]="EmployeeExperienceForm"></app-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-step>
          <mat-step label="Step 3" [stepControl]="EmployeeJoiningForm && EmployeeSalaryForm">
            <div>
              <div class="mt-4">
                <h4>3.1 Joining</h4>
              </div>
              <div>
                <form [formGroup]="EmployeeJoiningForm">
                  <div class="row">
                    <div *ngFor="let question of EmployeeJoiningFormQuestions"
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                      <app-question [question]="question" [form]="EmployeeJoiningForm"></app-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <h4>3.2 Salary</h4>
              </div>
              <div>
                <form [formGroup]="EmployeeSalaryForm">
                  <div class="row">
                    <div *ngFor="let question of EmployeeSalaryFormQuestions"
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                      <app-question [question]="question" [form]="EmployeeSalaryForm"></app-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
      <div class="form-row d-flex justify-content-end my-3 py-2 bg-white">
        <button mat-raised-button color="primary" class="px-5 mx-2 cancel_button" (click)="stepper.previous()"
          [disabled]="stepper.selectedIndex === 0">
          Back
        </button>
        <button mat-raised-button color="primary" class="px-5 mx-2 submit_button"
          *ngIf="stepper.selectedIndex === stepper.steps.length - 1 && EmployeePresentAddressForm.valid && EmployeePermanentAddressForm.valid"
          [disabled]="!EmployeeJoiningForm.valid" (click)="onSubmit()">
          {{ isEditMode ? 'Update' : 'Submit' }}
        </button>
        <!-- <button mat-raised-button color="primary" class="px-5"
          *ngIf="stepper.selectedIndex !== stepper.steps.length - 1" (click)="onSubmit()" [disabled]="!EmployeeProfileForm.valid">
          Save
        </button> -->
        <button mat-raised-button class="px-5 mx-2 submit_button" 
          *ngIf="stepper.selectedIndex !== stepper.steps.length - 1" (click)="stepper.next()">
          Save And Continue
        </button>
      </div>

      <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
    </div>
  </ng-container>
  <div *ngIf="showuploadconfirm">
    <div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload Employee Data</h5>
            <button type="button" class="btn-close cancel_button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ...
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary cancel_button" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary submit_button">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>