import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appStatusColor]',
  standalone: true
})
export class StatusColorDirective {

  @Input() appStatusColor: string = ''; // The status input to control the color

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appStatusColor']) {
      this.setColor(this.appStatusColor);
    }
  }

  private setColor(status: string): void {
    switch (status) {
      case 'PENDING':
        this.renderer.setStyle(this.el.nativeElement, 'color', '#6D6F02'); // Text color for readability
        break;
      case 'CANCELLED':
        this.renderer.setStyle(this.el.nativeElement, 'color', '#EA4C4C');
        break;
      case 'APPROVED':
        this.renderer.setStyle(this.el.nativeElement, 'color', '#55AB58');
        break;
      // default:
      //   this.renderer.setStyle(this.el.nativeElement, 'background-color', 'transparent');
      //   this.renderer.setStyle(this.el.nativeElement, 'color', 'black');
      //   break;
    }
  }
}
