import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { environment } from '../../../../environments/environment.prod';
import { ToastService } from '../../toast/toast.service';
import { AuthenticationService } from '../../../components/Authentication/services/authentication.service';
import { ConnectiveservicesService } from '../Connectiveservices/connectiveservices.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownservicesService {

  private userdata: any;
  private userdatta: any;
  public demodata: Observable<Object> | undefined;
  private header: any;
  private fileheader: any;
  public selected_sub_org: any;

  constructor(
    private http: HttpClient, 
    private toast: ToastService, 
    private AuthenticationService: AuthenticationService,
    private connectiveservice: ConnectiveservicesService,
  ) { 
    if (typeof sessionStorage !== 'undefined') {
      this.userdata = JSON.parse(`${typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : undefined}`);
      this.userdatta = this.userdata;
      this.selected_sub_org = this.connectiveservice.get_selected_sub_organization();
      this.header = {
        'token': this.userdatta?.data.token,
        'content-type': 'application/json'
      };
      this.fileheader = {
        'token': this.userdatta?.data.token
      };
    } else {
      console.warn('sessionStorage is not available in this environment');
      this.userdata = null;
      this.userdatta = null;
      this.header = null;
    }
  }

  getprams(event: any) {
      let params = new HttpParams();
      if (typeof this.selected_sub_org != 'undefined') {
        params = params.append('organization_id', this.selected_sub_org?.organization_id);
        params = params.append('sub_organization_id', this.selected_sub_org?.id);
      } else {
        params = params.append('organization_id', this.userdata.data?.organization_id);
      }
      // params = {...this.get_selectedorganisation()}
  
      Object.keys(event).forEach(key => {
        // && Number(event[key])
        if ((typeof event[key] !== 'undefined' && typeof event[key] !== null)) {
          params = params.append(key, event[key]);
        }
      });
      return params;
    }

  getorganizationdrop() {
    return this.http.get(`${environment.API_URL}` + 'organizations?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getbatchesdropdown() {
    let setparms = {
      type: "drop",
    }
    return this.http.get(`${environment.API_URL}` + 'academicregulations', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getuserroles(data: any) {
    let setparms = {
      type: "drop",
    }
    return this.http.get(`${environment.API_URL}` + 'userroles', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getstudentuserroles(data: any) {
    let setparms = {
      type: "drop",
      roleType: "STUDENTS"
    }
    return this.http.get(`${environment.API_URL}` + 'userroles', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getuserroledropdown() {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'userroles', {params: this.getprams(setparms),  headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getlookupcodesdropdown(data: any) {
    let setparms = {
      type: "drop",
      lookupType: data
    }
    return this.http.get<any>(`${environment.API_URL}` + 'lookupcodes', {params: this.getprams(setparms),  headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getlooktypelist() {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'lookuptypes', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  // getdepartmentlist() {
  //   return this.http.get<any>(`${environment.API_URL}` + 'employees?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
  //     map(data => {
  //       return data
  //     }),
  //     catchError(this.handleError))
  // }

  getemployeedrop() {
    let setparms = {
      type: "drop",
    }
    return this.http.get(`${environment.API_URL}` + 'employees', {params: this.getprams(setparms), headers: this.header }).pipe(
      map(async (data) => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewisesemester(eventdata: any): Observable<any> {
    let setparms = {
      type: "drop",
      course_id : eventdata.course_ID
    }
    return this.http.get<any>(`${environment.API_URL}` + 'semisters', {params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getEmployeedrop(event:any) {
    let setparms = {
      type: "drop",
      departmentId : event.department_id
    }
    return this.http.get<any>(`${environment.API_URL}` + 'employees', {params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getdepartmentdrop(){
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'departments', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getassignleavedrop() {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'leavetypes', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  gettimeslotslist() {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'timeslots', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getstreamdropdown() {
    let setparms = {
      type: "drop",
    }
    return this.http.get(`${environment.API_URL}` + 'streams', { params: this.getprams(setparms), headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursesdetails(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'courses', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursesbybatch(batchId: any): Observable<any> {
    let setparms = {
      type: "drop",
      academic_regulation_id : batchId
    }
    return this.http.get<any>(`${environment.API_URL}` + 'courses', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacademicregulationdrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academicregulations', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacademicyeardrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academiccalendar', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewiseacademicyeardrop(event:any): Observable<any> {
    let setparms = {
      type: "drop",
      course_id: event.course_id,
      academic_regulation_id: event.academic_regulation_id,
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academiccalendar', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getshiftdrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'breaktimes?type=drop', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getshiftdropbyaccademic(BatchId:any): Observable<any> {
    let setparms = {
      type: "drop",
      academic_regulation_id : BatchId
    }
    return this.http.get<any>(`${environment.API_URL}` + 'breaktimes', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsemisterdrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'semisters', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacademic_yeardrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academicyear', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectionsdrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'organizationacademicsections', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  gettablesectionsdrop(event:any): Observable<any> {
    let setparms = {
      type: "drop",
      course_id: event.course_ID,
      academic_year_id: event.academic_year_id,
      academic_regulation_id: event.academic_regulation_id
    }
    return this.http.get<any>(`${environment.API_URL}` + 'organizationwisesections', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectionsdropdown(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'sections', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectiondrop(eventdata: any): Observable<any> {
    let setparms = {
      type: "drop",
      course_id: eventdata.course_ID
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academicsections', { params: this.getprams(setparms), headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewisesemesterdrop(eventdata: any): Observable<any> {
    let setparms = {
      type: "drop",
      course_id: eventdata
    }
    return this.http.get<any>(`${environment.API_URL}` + 'academicyearsemisters', { params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getleavetypedrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'leavetypes', { params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  } 

  getdesignationsdrop(): Observable<any> {
    let setparms = {
      type: "drop",
    }
    return this.http.get<any>(`${environment.API_URL}` + 'designations', { params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  } 
  getstudentcoursewisesemesterdrop(eventdata: any): Observable<any> {
    let setparms = {
      type: "create",
      course_id: eventdata
    }
    return this.http.get<any>(`${environment.API_URL}` + 'students', { params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getwebpagedrop() {
    let setparms = {
      type: "drop",
    }
    return this.http.get(`${environment.API_URL}` + 'webpages', { params: this.getprams(setparms), headers: this.header }).pipe(
      map(data => {
        return data

      }),
      catchError(this.handleError))
  }
  
  // Handle Error for Apis
  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 401) {
      sessionStorage.removeItem("user");
      window.location.reload();
      this.AuthenticationService.logout();
    }
    return throwError(errorResponse.message || 'Server error');
  }

  private dismissError(): void {
    setTimeout(() => {
      this.toast.dismissToast();
    }, 3000);
  }
}
