import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { FrontpageService } from '../../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';

export interface noticeModel {
  notice_heading: string;
  image_name: string;
  description: string;
  image_url: File | null;
}
@Component({
  selector: 'app-addnoticeboard',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatSlideToggle,
  ],
  templateUrl: './addnoticeboard.component.html',
  styleUrl: './addnoticeboard.component.scss',
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class AddnoticeboardComponent {
  questions: any[] = [];
  questions$: Observable<any[]> | null = null;
  noticeForm!: FormGroup;
  datesend!: noticeModel;
  noticeboarddata: any = {};
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file!: File;
  isButtonLinkEnabled = false;
  isSubmitting = false;
  showReplaceButton : boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddnoticeboardComponent>,
    private dropdownservice: DropdownservicesService,
    Noticesservices: FrontpageService,
    private qcs: QuestionControlService,
    private apiservice: ApiservicesService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    {
      this.questions$ = Noticesservices.getNoticeForm();
      this.questions$.subscribe((q) => {
        return (this.questions = q)});
      this.noticeForm = this.fb.group({
        button_text: [''],
        button_link: [''],
      });

      if (data) {
        this.editflag = true;
        this.fileName = data.image_name || null;
        this.selectedImageUrl = data.image_url || null;
        this.noticeForm.patchValue({
          button_text: data.button_text || '',
          button_link: data.button_link || '',
          image_url: data.image_url || null,
          isButton: data.isButton || 0,
          heading: data.heading || '',
          description: data.description || '',
          start_date: data.start_date || '',
          end_date: data.end_date || '',
        });
        if (data.isButton) {
          this.isButtonLinkEnabled = true;
          this.toggleButtonFields();
        }
      }

      if (data?.image_url) {
        this.fileName = data.image_name;
        this.selectedImageUrl =
          `${this.apiservice.environmentPath}/${data.image_url}` ||
          null;
      }
    }
  }

  ngOnInit(): void {
    this.noticeForm = this.fb.group({
      notice_heading: [this.data?.heading || ''],
      image_url: [this.data?.image_url || ''],
      start_date: [this.data?.visible_from || ''],
      end_date: [this.data?.visible_until || ''],
      button_text: [
        {
          value: this.data?.button_text || '',
          disabled: !this.isButtonLinkEnabled,
        },
      ],
      button_link: [
        {
          value: this.data?.button_link || '',
          disabled: !this.isButtonLinkEnabled,
        },
      ],
      isButton: [this.data?.isButton || 0],
      description: [this.data?.description || ''],
    });
  
    if (this.data?.isButton) {
      this.isButtonLinkEnabled = true;
      this.toggleButtonFields();
    }
  }
  
  onToggleChange(event: MatSlideToggleChange): void {
    this.isButtonLinkEnabled = event.checked;
    this.toggleButtonFields();
  }

  toggleButtonFields(): void {
    const buttonTextControl = this.noticeForm.get('button_text');
    const buttonLinkControl = this.noticeForm.get('button_link');
    if (this.isButtonLinkEnabled) {
      buttonTextControl?.enable();
      buttonLinkControl?.enable();
    } else {
      buttonTextControl?.disable();
      buttonLinkControl?.disable();
    }
  }
  onSubmit() {
    this.isSubmitting = true;
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;
  
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        organizationId = parsedData.data?.organization_id || null;
      } catch (error) {
        console.error('Error parsing organization data:', error);
      }
    }
  
    if (!organizationId) {
      console.error('Organization ID not found. Cannot proceed.');
      return;
    }
  
    const formData = new FormData();
    formData.append('heading', this.noticeForm.value.notice_heading || '');
    formData.append('visible_from', this.noticeForm.value.start_date || '');
    formData.append('visible_until', this.noticeForm.value.end_date || '');
    formData.append('description', this.noticeForm.value.description || '');
    formData.append('organization', organizationId.toString() || '');
    if (this.file) {
      formData.append('image_url', this.file);
    } else if (this.selectedImageUrl) {
      formData.append('image_url', this.selectedImageUrl as string);
    } else {
      console.error('No image selected and no existing image URL found.');
      return;
    }
  
    formData.append('button_text', this.noticeForm.value.button_text || null);
    formData.append('button_link', this.noticeForm.value.button_link || null);
    formData.append('isButton', this.noticeForm.value.isButton || 0);
    formData.append('type', 'home');
    if (this.data?.id) {
      this.updateApi(this.data?.id, formData);
    } else {
      this.createAPi(formData);
    }
  }
  
  createAPi(formData: FormData) {
    this.apiservice.createNoticeBoard(formData).subscribe(
      (res: any) => {
        this.isSubmitting = false;
        if (res.code === 200 && res.status === true) {
          this.noticeboarddata = res.data;
          this.noticeForm.reset();
          this.dialogRef.close(true);
        } else {
          console.error('Error:', res.message || 'Unknown error');
          this.dialogRef.close(false);
        }
      },
      (error) => {
        this.isSubmitting = false;
        console.error('API Error:', error);
        this.dialogRef.close(false);
      }
    );
  }
  
  updateApi(id: number, formData: FormData) {
    this.apiservice.updateNoticeBoard(id, formData).subscribe(
      (res: any) => {
        this.isSubmitting = false;
        if (res.code === 200 && res.status === true) {
          this.noticeboarddata = res.data;
          this.noticeForm.reset();
          this.dialogRef.close(true);
        } else {
          console.error('Error updating mission/vision:', res.message || 'Unknown error');
          this.dialogRef.close(false);
        }
      },
      (error) => {
        this.isSubmitting = false;
        console.error('API Error during edit:', error);
        this.dialogRef.close(false);
      }
    );
  }
  imageInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
  
    if (input.files?.length) {
      const file = input.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        this.file = file;
        this.selectedImageUrl = e.target?.result as string;
        this.noticeForm.patchValue({ image_url: file });
      };
  
      reader.readAsDataURL(file);
    } else {
      console.error('No file selected.');
    }
  }
  
  

  closeDialog(event: any) {
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    this.dialogRef.close(event);
  }
}
