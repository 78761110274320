<div class="dialog-content">
  <div class="close-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </div>
    <div class="banner-container">
      <img
        [src]="'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' + data.banner.image_url"
        alt="Banner Image"
        class="banner-image"
      />
    </div>
  
    <div class="banner-details">
      <h2 class="banner-title">{{ data.image_name }}</h2>
      <p class="banner-description">{{ data.description }}</p>
  
      <div class="banner-status">
        <p><strong>Active From:</strong> {{ data.banner.visible_from | date: 'dd/MM/yyyy' }}</p>
        <p><strong>Active Till:</strong> {{ data.banner.visible_until | date: 'dd/MM/yyyy' }} </p>
         <p><strong>Status:</strong> <span
            [ngClass]="{
              'status-active': data.status === 'Active',
              'status-inactive': data.status !== 'Active'
            }"
          >
            {{ data.status === 'Active' ? 'Still Active' : 'Inactive' }}
          </span>
       </p>
      </div>
    </div>
  
    <div class="dialog-actions">
    
      <button mat-raised-button color="primary" (click)="editBanner()">Edit</button>
      <button mat-raised-button color="warn" 
      (click)="deleteBanner()"
      >Delete</button>
    </div>
  </div>
  