import {
  Component,
  TemplateRef,
  Inject,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { catchError, Observable } from 'rxjs';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dynamic-file-upload',
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    FormsModule
  ],
  templateUrl: './dynamic-file-upload.component.html',
  styleUrls: ['./dynamic-file-upload.component.scss'],
})
export class DynamicFileUploadComponent implements OnInit {
  @Input() allowedFileTypes: string = '.pdf,.docx,.xlsx'; // Allow customization of file types
  @Input() service: any;
  @Input() questions$!: Observable<Inputfiledsbase<string>[]>;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();

  questions: Inputfiledsbase<string>[] = [];
  uploadForm: FormGroup;
  fileUploadData: any = {};
  selectedDocuments: File[] = [];
  selectedFiles: File[] = [];
  isDragging: boolean = false;
  levels: { key: string; id: string | number; value: number }[] = [];
  selectedLevel: string = '';
  customLevel: string = '';
  showCustomInput: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Initialize form group
    this.uploadForm = this.fb.group({
      documents: this.fb.array([]), // Form array for dynamic document uploads
    });

    // Subscribe to questions if provided via MAT_DIALOG_DATA
    if (this.data?.questions$) {
      this.data.questions$
        .pipe(
          catchError((err: Error) => {
            console.error('Error loading questions:', err);
            return [];
          })
        )
        .subscribe((questions: any) => {
          console.log('Received questions:', questions); // Debugging log
          this.questions = questions || [];
          this.addDynamicControls(this.questions);
        });
    }
    console.log("Upload======================================>", this.data)
  }

  ngOnInit(): void {
    this.loadLevels();
  }

  // Getter for document controls in the form array
  documentControls() {
    return (this.uploadForm.get('documents') as FormArray).controls;
  }

  onLevelSelected(level: string) {
    this.selectedLevel = level;
    this.showCustomInput = (level === 'Other');
    this.customLevel = '';
    this.uploadForm.get('level')?.setValue(level);
  }

  onCustomLevelInput() {
    this.uploadForm.get('level')?.setValue(this.customLevel);
  }

  // Add dynamic form controls based on questions
  private addDynamicControls(questions: Inputfiledsbase<string>[]): void {
    questions.forEach((question) => {
      const validators = question.required ? [Validators.required] : [];
      this.uploadForm.addControl(
        question.key,
        this.fb.control(question.value || '', validators)
      );
    });
  }

  // Handle cancel button action
  onCancel(): void {
    this.cancel.emit();
  }

  // Open file upload dialog (optional)
  openUploadDialog(uploadDialog: TemplateRef<any>) {
    this.dialog.open(uploadDialog);
    console.log("lkqwnlcksndlkvm;sdmbsdb/sdb/", this.data)
  }

  onFileSelected(event: any) {
    if (!event.target.files || event.target.files.length === 0) {
      console.error("❌ No files chosen.");
      return;
    }
  
    this.selectedFiles = Array.from(event.target.files); // Convert FileList to array
  
    console.log("✅ Files Selected:", this.selectedFiles);
  }
  

  // Add a new document field to the form
  addDocument() {
    const documentGroup = this.fb.group({
      file: [null, Validators.required],
    });
    (this.uploadForm.get('documents') as FormArray).push(documentGroup);
  }

  // Remove a document field from the form
  removeDocument(index: number) {
    (this.uploadForm.get('documents') as FormArray).removeAt(index);
  }

  // Handle file change for each document field
  onFileChange(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      (this.uploadForm.get('documents') as FormArray).at(index).patchValue({
        file: file,
      });
    }
  }

  // Track questions by key
  trackByQuestionKey(index: number, question: Inputfiledsbase<string>): string {
    return question.key;
  }

  // Handle form submission (including file upload)
  onSubmit() {
    const formData = new FormData();
  
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      console.error("❌ No files selected.");
      return;
    }
  
    this.selectedFiles.forEach((file: File, index) => {
      formData.append("uploadedFiles[]", file, file.name); // Ensure file name is added
    });
  
    console.log("✅ Submitting FormData:", formData);
  
    // Emit the form data instead of selected files array
    this.formSubmit.emit(formData );
  }
  

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files.length) {
      const newFiles: File[] = Array.from(event.dataTransfer.files) as File[];
      this.selectedFiles = [...this.selectedFiles, ...newFiles]; // Append new files
    }
  }
  
  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }
  
  onDragLeave(event: DragEvent) {
    this.isDragging = false;
  }
  
  documentInputChange(event: any) {
    const files = event.target.files;
    if (files.length > 0) {
      const newFiles: File[] = Array.from(files) as File[];
      this.selectedFiles = [...this.selectedFiles, ...newFiles]; // Append new files
    }
  }
  
  removeSelectedFile(index: number, event: Event) {
    event.stopPropagation();
    this.selectedFiles.splice(index, 1);
  }

  private loadLevels(): void {
    // Assuming your service method returns the levels directly
    this.levels = this.data.service.extractLevels(this.data.service.apiResponse);
    console.log("🚀 ~ DynamicFileUploadComponent ~ loadLevels ~ this.levels:", this.levels)
  }
}