import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BannersComponent } from '../../../components/websitemenagement/Homepage/banners/banners.component';
import { VisionMissionComponent } from '../../../components/websitemenagement/Homepage/vision-mission/vision-mission.component';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { WebsiteDepartmentService } from '../../../inputfileds/form_services/WebsiteManagement/website_departments/website-department.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';

@Component({
  selector: 'app-departmentpage-view',
  standalone: true,
  imports: [
    DynamicFormQuestionComponent,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatSidenavModule,
    MatTabsModule,
    MatListModule,
    BannersComponent,
    VisionMissionComponent,
    ReactiveFormsModule,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './departmentpage-view.component.html',
  styleUrls: ['./departmentpage-view.component.scss'],
  providers: [QuestionControlService, WebsiteDepartmentService],
})
export class DepartmentpageViewComponent {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  isDepartmentSelected: boolean = false;

  componentsList = [
    { label: 'Banners', value: 'Banners' },
    { label: 'Vision & Mission', value: 'Vision & Mission' },
  ];

  selectedComponent: string = '';
  selectedDepartmentId: number | string | null = null;

  constructor(
    private qcs: QuestionControlService,
    departServices: WebsiteDepartmentService
  ) {
    this.questions$ = departServices.getWebDepartmentDropForm();
    this.questions$.subscribe((q) => (this.questions = q));
    if (this.questions.length > 0 && this.questions[0].options.length > 0) {
      this.selectedDepartmentId = this.questions[0].options[0].value;
      this.isDepartmentSelected = true;
    }
  }

  ngOnInit(): void {
    this.selectedComponent = this.componentsList[0].value;
    this.form = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );

    // Set auto-selected department in form
    if (this.selectedDepartmentId) {
      this.form.patchValue({ department_id: this.selectedDepartmentId });
    }

    // Listen for department selection changes
    this.form.get('department_id')?.valueChanges.subscribe((selectedId) => {
      this.selectedDepartmentId = selectedId;
      this.isDepartmentSelected = !!selectedId;
    });
  }

  onSelectComponent(item: { label: string; value: string }) {
    this.selectedComponent = item.value;
  }

  getBannerApiEndpoint(): string {
    return this.selectedDepartmentId
      ? `departments/${this.selectedDepartmentId}/banners`
      : 'departments/banners';
  }

  getVisionMissionApiEndpoint(): string {
    return this.selectedDepartmentId
      ? `departments/${this.selectedDepartmentId}/visions`
      : 'departments/visions';
  }

  onSubmit() {
    console.log(this.form.value);
  }
}
