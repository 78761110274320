import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { QuestionService } from './inputfileds/form_services/question/question.service';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationService } from './components/Authentication/services/authentication.service';
import { ToastComponent } from './components/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ToastComponent, CommonModule, LoginComponent, LayoutComponent],
  providers: [QuestionService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'visaka_erp';
  user: any | undefined;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x =>  this.user = x);
    }
  }
  ngAfterContentChecked() {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x => this.user = x);
    }
  }
}
