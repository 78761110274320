import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectiveservicesService {
  sub_organizationdata:any;

  constructor() { }

  get_selected_sub_organization(){
    if(sessionStorage.getItem("SubOrgData")){
      this.sub_organizationdata = sessionStorage.getItem("SubOrgData");
      return JSON.parse(this.sub_organizationdata);
    }else{
      return undefined;
    } 
  }
}
