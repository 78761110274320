import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { workService } from '../../../../../inputfileds/form_services/workservice/workservice.service';
import { Observable, forkJoin } from 'rxjs';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../Services/toast/toast.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Component({
  selector: 'app-addweekoff',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule,MatIconModule, MatDividerModule,FormsModule, MatFormFieldModule,MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, workService, ApiservicesService, DropdownservicesService],
  templateUrl: './addweekoff.component.html',
  styleUrl: './addweekoff.component.scss'
})
export class AddweekoffComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  weekOffForm!: FormGroup;
  payLoad : any;
  permissionData = []
  showaddflag: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddweekoffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService,
    private router: Router, 
    private qcs: QuestionControlService,
    private toast: ToastService, 
    service: workService) {
      this.questions$ = service.getWeekOffservices();
      this.questions$.subscribe(q => this.questions = q);
    }

  ngOnInit(): void {
    this.weekOffForm = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }
  // getdaydata(){
  //   let daydata = this.weekOffForm.value.weekoff_type_id.forEach((data:any) => {
  //     console.log("foreach =====> data", data)
  //     return data.name;
  //   })
  //   return daydata;
  // }
  onSubmit() {
    this.payLoad = this.weekOffForm.getRawValue();
    // this.payLoad = {...this.payLoad, 'day': this.getdaydata()};
    // this.payLoad.append('weekoff_type_id', parseInt(this.weekOffForm.value.weekoff_type_id));
    forkJoin([
      this.dropdownservice.getlookupcodesdropdown('WEEK_OFF_TYPES'),
    ]).subscribe(([weekoffRes]) => {
     
      // Filter status data based on form's status_id
      const filteredStatus = weekoffRes.filter((dropfilter:any)=>{ return dropfilter.id === this.weekOffForm.value.weekoff_type_id });
      // Add status name to the payload
      this.payLoad = { ...this.payLoad, weekoff_type: filteredStatus[0]?.lookup_name };
      this.payLoad = { ...this.payLoad, academic_calendar_id: this.data.academic_calendar_id };
      this.createAPi(this.payLoad);
    });
  }

  createAPi(event:any){
    this.apiservice.createweekoff(event).subscribe((res: any) => {
      if(res.code === 201 && res.status === true){
        this.weekOffForm.reset();
        this.dialogRef.close()
        this.toast.showToast(
          'success',
          `${res.message}`,);
      }
      else{
        this.toast.showToast(
          'danger',
          `${res.message}`,);
      }
      })
  }

  Canceldailog(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
    setTimeout(()=>{this.dialogRef.close();}, 1000);
    // this.dialogRef.close();
  }
}
