import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PermissionService } from '../../components/Authentication/services/permission.service';
@Component({
  selector: 'app-classes',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatMenuModule,
  ],
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent implements OnInit {
  selectedDate = new Date();
  pipe = new DatePipe('en-US');
  classeslist: any;
  userdata: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  actiondata: any;
  constructor(
    private router: Router,
    private apiservice: ApiservicesService,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    this.userdata = JSON.parse(`${typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : undefined}`);
    console.log("user data", this.userdata)
  }

  ngOnInit(): void {
    this.getClassdetails()
  }

  get filteredClasses() {
    return this.classeslist;
  }

  onDateChange(event: any) {
    if (event.value) {
      this.selectedDate = event.value;
      this.getClassdetails();
    }
  }


  previousDate() {
    const previousDay = new Date(this.selectedDate);
    previousDay.setDate(this.selectedDate.getDate() - 1);
    this.selectedDate = previousDay;
    this.getClassdetails();
  }

  // Function to handle next date navigation
  nextDate() {
    const nextDay = new Date(this.selectedDate);
    nextDay.setDate(this.selectedDate.getDate() + 1);
    this.selectedDate = nextDay;
    this.getClassdetails();
  }

  filterClassesByDate() {
    // Add your logic here to filter classes based on `this.selectedDate`
    console.log('Filtering classes for:', this.selectedDate);
    // Example: Update `filteredClasses` based on the selected date.
  }

  viewDetails(classItem: any) {
    console.log('Viewing details for:', classItem);
    // Add logic to view class details
  }

  enroll(classItem: any) {
    console.log('Enrolling in:', classItem);
    // Add logic to enroll in class
  }

  openClasses(item: any) {
    item = { ...item, date: this.selectedDate }
    this.router.navigate(['classes/classview'], { state: { data: item } });
  }

  getClassdetails() {
    let payload = {
      date: this.pipe.transform(this.selectedDate, 'yyyy-MM-dd'),
      employee: this.userdata?.data.id
      // employee : 35807
    }
    this.apiservice.getClassesdetails(payload).subscribe((res) => {
      this.classeslist = res.data;
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

}
