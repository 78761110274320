import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastService } from '../../../Services/toast/toast.service';
import { ToastComponent } from '../../../components/toast/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

Chart.register(...registerables);

export interface PeriodicElement {
  index: number,
  id: string;
  name: number;
  status: number;
  tillDate: string;
}

@Component({
  selector: 'app-attendance',
  templateUrl: './classesview.component.html',
  styleUrls: ['./classesview.component.scss'],
})
export class ClassesviewComponent implements OnInit {
  displayedColumns: string[] = ['select', 'id', 'name', 'status'];
  attendanceData: any[] = [];
  presentData: any[] = [];
  absenteesData: any[] = [];
  showPresent: boolean = true;
  classesData: any;
  chart: any;
  StudentList: any;
  pipe = new DatePipe('en-US');
  showToast: boolean = false;
  selection = new SelectionModel<PeriodicElement>(true, []);
  studentsattendancedetailschart: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  isChecked = false;
  selectedDate: Date = new Date();
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private apiservice: ApiservicesService,
    private toast: ToastService) {
    this.classesData = history.state.data;
     }

  ngOnInit() {
    this.selectedDate = this.classesData.date;
    this.getstudentattendancelist();
    this.getstudentattendanceDetails();
  }

  // fetchAttendanceData() {
  //   const apiData = [
  //     { id: 301, name: 'Akula Lavanya', tillDate: 70 },
  //     { id: 302, name: 'Devane Vaishnavi', tillDate: 90 },
  //     { id: 303, name: 'Student 3', tillDate: 40 }
  //   ];
  //   this.attendanceData = apiData.map((student) => ({
  //     ...student,
  //     status: 'Present',
  //   }));
  //   this.updateAttendanceLists();
  // }

  toggleStatus(element: any, status:any) {
    if(status === 'ABSENT' || status === false){
      element.status = 'ABSENT';
    }else if(status === 'PRESENT' || status === true){
      element.status = 'PRESENT';
    }
    // element.status = element.status === 'ABSENT' ? 'Present' : 'ABSENT';
    // this.updateAttendanceLists();  
  }

  updateAttendanceLists() {
    this.presentData = this.attendanceData.filter(
      (student) => student.status === 'PRESENT'
    );
    this.absenteesData = this.attendanceData.filter(
      (student) => student.status === 'ABSENT'
    );
  }

  // onDateChange(event: any) {
  //   if (event.value) {
  //     this.selectedDate = event.value;
  //   }
  // }

  // previousDate() {
  //   const previousDay = new Date(this.selectedDate);
  //   previousDay.setDate(this.selectedDate.getDate() - 1);
  //   this.selectedDate = previousDay;
  // }

  // nextDate() {
  //   const nextDay = new Date(this.selectedDate);
  //   nextDay.setDate(this.selectedDate.getDate() + 1);
  //   this.selectedDate = nextDay;
  // }

  previousDate() {
    const date = new Date(this.selectedDate);
    date.setDate(date.getDate() - 1);
    this.selectedDate = date;
  }
  
  nextDate() {
    const date = new Date(this.selectedDate);
    date.setDate(date.getDate() + 1);
    this.selectedDate = date;
  }
  
  onDateChange(event: any) {
    this.selectedDate = event.value;
  }

  closeClasses() {
    this.router.navigate(['classes']);
  }

  getstudentattendancelist() {
    const dateFormate = this.pipe.transform(this.classesData.date, 'yyyy-MM-dd');
    this.apiservice.getMarkAttendancelist(this.classesData, dateFormate).subscribe((res) => {
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
      this.StudentList = new MatTableDataSource<PeriodicElement>(res.rows);
    });
  }

  getstudentattendanceDetails() {
    const dateFormate = this.pipe.transform(this.selectedDate, 'yyyy-MM-dd');
    // const dateFormate = this.pipe.transform(this.classesData?.date, 'yyyy-MM-dd');
    this.apiservice.getstudentattendanceDetails(this.classesData, dateFormate).subscribe((res) => {
      this.studentsattendancedetailschart = res; 
      this.absenteesData = res.absenteesData;
      const config: any = {
        type: 'doughnut',
        data: {
          labels: ['Present', 'Absent'],
          datasets: [
            {
              data: [res.presenteesCount, res.absenteesCount],
              backgroundColor: ['#4CAF50', '#F44336'],
              responsive: true,
            },
          ],
        },
        options: {
          aspectRatio: 1,
        },
      };
      // Chart instial
      this.chart = new Chart('MyChart', config);

    });
  }

  selectioncheckbox(event:any){
      this.selection.toggle(event);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.StudentList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(event:any) {
    if (event === 'ABSENT') {
      this.selection.clear();
      this.StudentList.data.map((res:any) => {
        this.toggleStatus(res, 'ABSENT')
        })
      return;
    }else{
      this.selection.select(...this.StudentList.data);
      this.StudentList.data.map((res:any) => {
      this.toggleStatus(res, 'PRESENT')
      })
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }else{
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
    }
  }

  submitstudentattendance() {
    // let submit_data: any = [];
    // if(this.selection.selected.length !== 0){
    //   submit_data = this.selection.selected.map((student) => ({
    //     ...student,
    //     status: 'Present',
    //   }));
    // }else{
    //   submit_data = this.StudentList.data;
    // }

    // let formattedDate: string = '';

    // if (this.classesData?.date && this.classesData.date !== null && this.classesData.date !== undefined && this.classesData.date !== '') {
    //   const date = new Date(this.classesData.date);
    //   if (!isNaN(date.getTime())) {
    //     formattedDate = this.pipe.transform(this.classesData.date, 'yyyy-MM-dd') || '';
    //   } else {
    //     console.error('Invalid date format in classesData');
    //     formattedDate = new Date().toISOString().split('T')[0];
    //   }
    // } else {
    //   formattedDate = new Date().toISOString().split('T')[0];
    //   console.warn('Date is undefined or null, using current date as fallback');
    // }

    let payload_data = {
      "course_id": this.classesData.course_id,
      "semester_id": this.classesData.semester_id,
      "organization_id": 1,
      "sub_organization_id": 1,
      "subject_id": 109,
      "section_id": this.classesData.section_id,
      "academic_regulation_id": this.classesData.academic_regulation_id,
      "academic_year_id": this.classesData.academic_year_id,
      "date": this.pipe.transform(this.classesData.date, 'yyyy-MM-dd') || '',
      "order": this.classesData.order,
      "subject_name": this.classesData.subject_name,
      "start_time": this.classesData.start_time,
      "end_time": this.classesData.end_time,
      "students": this.StudentList.data
    }
    this.createAPi(payload_data);
  }
  createAPi(event: any) {
    this.apiservice.postMarkAttendancelist(event).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.getstudentattendancelist();
          this.getstudentattendanceDetails();
          this.toast.showToast('success', 'Saved Successfully');
        } else {
          // this.toast.showToast('danger', `Error: ${res.message}`);
          this.showToast = true;
        }
      },
      (error) => {
        // this.toast.showToast('warning', error.message);
      }
    );
  }
}

@NgModule({
  declarations: [ClassesviewComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatDatepickerModule,
    MatTableModule,
    MatFormFieldModule,
    BaseChartDirective,
    ToastComponent,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
  ],
  exports: [ClassesviewComponent],
})
export class ClassesViewModule { }
