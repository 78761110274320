<div class="homepage-container mx-3 p-2">
    <div>
        <h2>Home Page</h2>
    </div>
  
  <div class="row">
      <!-- Sidebar -->
    <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="sidebar">
          <mat-list class="matlist_custom">
              <mat-list-item
                  *ngFor="let item of componentsList"
                  [class.active]="item.value === selectedComponent"
                  [style]="selectedComponent ? '' : 'color: #FFFFF !important'"
                  (click)="onSelectComponent(item)"
              >
                  {{ item.label }}
              </mat-list-item>
          </mat-list>
        </div>
      </div>

      <!-- Dynamic Content -->
      <div class="content col-12 col-sm-12 col-md-9 col-lg-9" [ngSwitch]="selectedComponent">
          <app-banners *ngSwitchCase="'Banners'"></app-banners>
          <app-notice-board *ngSwitchCase="'Notice Board'"></app-notice-board>
          <app-vision-mission *ngSwitchCase="'Vision & Mission'"></app-vision-mission>
          <app-testimonials *ngSwitchCase="'Testimonials'"></app-testimonials>
      </div>
  </div>
</div>
