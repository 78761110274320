import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { MultiSelectQuestion } from '../../question-multiselect';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root',
})
export class AcademicsService {
  organisationdata: any = [];
  courselist: any = [];
  course_type: any = [];
  duration_type: any = [];
  stream: any = [];
  stream_type: any = [];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('COURSES', this.course_type);
    this.loadDropdownData('DURATION_TYPES', this.duration_type);
    this.loadDropdownData('STREAM', this.stream);
    this.loadDropdownData('STREAM_TYPES', this.stream_type);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.dropdownservice.getorganizationdrop();
    } else if (code === 'COURSES') {
      observable = this.dropdownservice.getcoursesdetails();
    }else if (code === 'STREAM') {
      observable = this.dropdownservice.getstreamdropdown();
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ORGANISATION') {
          lookupname = res[i]?.short_name;
        } else if (code === 'COURSES') {
          lookupname = res[i]?.name;
        }else if (code === 'STREAM') {
          lookupname = res[i]?.name;
        } else {
          lookupname = res[i]?.lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  getacadamicsform() {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization',
        required: true,
        options: this.organisationdata,
        order: 1,
      }),

      new DatepickerQuestion({
        key: 'start_year',
        label: 'Start Year',
        required: true,
        order: 2,
      }),

      new DatepickerQuestion({
        key: 'end_year',
        label: 'End Year',
        required: true,
        order: 3,
      }),
      new MultiSelectQuestion({
        key: 'course_id',
        label: 'Course',
        required: true,
        options: this.course_type,
        order: 4,
      }),
      new TextboxQuestion({
        key: 'code',
        label: 'Regulation Code',
        required: true,
        order: 5,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
