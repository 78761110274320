import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { CoursesService } from '../../inputfileds/form_services/courses/courses.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { PermissionService } from '../../components/Authentication/services/permission.service';
import { AdddesignationsComponent } from './adddesignations/adddesignations.component';

export interface DesignationView {
  index: number;
  id: number;
  // organization_id: number;
  name: string;
  // course_code: string;
  // medium_name: string;
  // department_name: string;
  // number_of_semisters: number;
  // description: string;
}

@Component({
  selector: 'app-designations',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    PaginationComponent
  ],
  providers: [QuestionControlService, CoursesService, ApiservicesService],
  templateUrl: './designations.component.html',
  styleUrl: './designations.component.scss'
})
export class DesignationsComponent implements OnInit{

  displayedColumns: string[] = [
      // 'select',
      'id',
      'name',
      'status'
      // 'course_code',
      // 'medium_name',
      // 'description',
      // 'status',
      // 'actions',
    ];
    dataSource = new MatTableDataSource<DesignationView>();
    selection = new SelectionModel<DesignationView>(true, []);
    lookupdatas: any;
    showaddflag: boolean = false;
    datesend: any;
    courseList : any
    isLoadingResults = true;
    isRateLimitReached = false;
    total_count: any;
    paginationData : any;
    canCreate: boolean = false;
    canView: boolean = false;
    actiondata: any;
  
    constructor(
      private apiservice: ApiservicesService,
      private dialog: MatDialog,
      private router: Router,
      private qcs: QuestionControlService,
      service: CoursesService,
      private permissionService: PermissionService, 
    ) {}
  
    ngOnInit(): void {
      this.actiondata = this.permissionService.getactiondata();
      if(this.actiondata?.view === false){
      //  delete [this.displayedColumns.indexOf('actions')];
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
      }
      this.getDesignationdetails();
    }
  
    pagination(event: any){
      this.paginationData= event;
      this.getDesignationdetails();
    }
  
    getDesignationdetails(){
      this.apiservice.getdesignationlist(this.paginationData).subscribe((res) => {
        this.courseList = res.rows
        this.total_count = res.total_count;
        this.dataSource = new MatTableDataSource<DesignationView>(res.rows);
        this.isLoadingResults = false;
            this.isRateLimitReached = res === null;
      });
    }
  
    openAddDesignationDialog() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
      dialogConfig.minWidth = '50vw';
      dialogConfig.maxWidth = '60vw';
      dialogConfig.minHeight = '50vh';
      dialogConfig.maxHeight = '50vh';
      dialogConfig.data = {total_count: this.total_count};
      const dialogRef = this.dialog.open(AdddesignationsComponent, dialogConfig);
      
      dialogRef.afterClosed().subscribe(result => {
        // if (result) {
          this.getDesignationdetails();
        // }
      });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }
      this.selection.select(...this.dataSource.data);
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: DesignationView): string {
      if (!row) {
        return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
        row.index + 1 
      }`;
    }
  
    // To show the semester page
    // showsemesterdetails(id: any) {
    //   this.router.navigate(['courses/semesterdetails'], { queryParams: { data:id.id} });
    // }
}
