import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-onboardingscreens',
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    RouterModule,
    CommonModule
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ],
  templateUrl: './onboardingscreens.component.html',
  styleUrl: './onboardingscreens.component.scss'
})
export class OnboardingscreensComponent implements OnInit{

  userdetails:any;
  readonly panelOpenState = signal(false);
  state:any = 1;
  control_stepper: boolean = false;
  
  constructor(){
    let userdata : any = sessionStorage.getItem("user");
    this.userdetails = JSON.parse(userdata);
  }

  ngOnInit(): void {

  }

  state_function_display(event:any){
    this.state = event;
    console.log("working", event)
  }

  state_control(){
    this.control_stepper = true;
    // this.state='done' 
    this.state = 2
  }

}
