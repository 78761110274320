<div class="mx-3">
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <mat-icon class="arrow_back me-1" (click)="goBack()">arrow_back</mat-icon>
      <h2 class="m-0">Activities</h2>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="create-page-button"
      (click)="openAddActivityDialog()"
    >
      <mat-icon>cloud_upload</mat-icon>Add Activity
    </button>
  </div>
  <div class="mt-2">
    <div
      class="activities-card"
      *ngIf="eventList.length > 0; else noDataTemplate"
    >
      <mat-table [dataSource]="eventList" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Sl. No. </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="heading">
          <mat-header-cell *matHeaderCellDef> Heading </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.heading }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            matTooltip="{{ element.description }}"
          >
            {{ element.description | slice : 0 : 50
            }}<span *ngIf="element.description.length > 50">...</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Event Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.date | date : "dd/MM/yyyy" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="images">
          <mat-header-cell *matHeaderCellDef> Images </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="image-container" *ngIf="element.image_urls.length > 0">
              <div class="image-boxes">
                <img
                  *ngFor="let imgObj of element.image_urls; let i = index"
                  [src]="this.imagePath + '/' + imgObj.image_url"
                  alt="Activity Image"
                  class="activity-image"
                  [ngClass]="{
                    first: i === 0,
                    second: i === 1,
                    third: i === 2
                  }"
                />
              </div>
            </div>
            <span
              *ngIf="!element.image_urls || element.image_urls.length === 0"
            >
              No images available
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-raised-button class="view_button">View</button>
            <button
              class="action_button"
              mat-icon-button
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                class="update_button"
                (click)="editItem(element)"
              >
                <mat-icon>edit</mat-icon> Edit
              </button>
              <button
                mat-menu-item
                class="delete_button"
                (click)="deleteEvents(element)"
              >
                <mat-icon>delete</mat-icon> Delete
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <ng-template #noDataTemplate>
      <div class="no-data">
        <p>No activities found. Click "Create Activity Page" to add one.</p>
      </div>
    </ng-template>
  </div>
</div>
