<section class="mx-3">
    <div class="d-flex justify-content-between">
        <div>
            <h1>Logger data</h1>
        </div>
        <!-- <div>
            <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div> -->
</div>
<div>
    <p> data loading {{loggerdata}}</p>
</div>
    <!-- <textarea rows="24" cols="85">{{loggerdata}}</textarea> -->
</section>

