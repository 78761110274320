import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar'; // Import MatSnackBar for toast notifications

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatProgressBarModule],
  providers: [ApiservicesService]
})
export class UploadDialogComponent {
  selectedFile: File | null = null;
  isLoading = false;
  allowedFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']; // MIME types for Excel files

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private apiservices: ApiservicesService,
    private snackBar: MatSnackBar, // Inject MatSnackBar
    @Inject(MAT_DIALOG_DATA) public data: { uploadType: string, formDataKey: string }
  ) { }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0] || null;

    // Validate file type
    if (file && this.allowedFileTypes.includes(file.type)) {
      this.selectedFile = file;
      console.log("Selected file:", this.selectedFile);
    } else {
      this.selectedFile = null;
      this.showToast('Please upload a valid Excel file (.xls or .xlsx).');
    }
  }

  handleUpload(): void {
    if (this.selectedFile) {
      if (!this.allowedFileTypes.includes(this.selectedFile.type)) {
        this.showToast('Invalid file type. Only Excel files are allowed.');
        return;
      }

      this.isLoading = true;
      const formData = new FormData();
      formData.append(this.data.formDataKey, this.selectedFile);

      if (this.selectedFile) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append(this.data.formDataKey, this.selectedFile);
    
        if (this.data.formDataKey === 'EMPLOYEES_BULKUPLOAD_TEMPLATE') {
          this.apiservices.bulkuploademployee(formData).subscribe({
            next: (response) => {
              console.log('Upload successful', response);
              this.showToast('File uploaded successfully.');
              this.closeDialog();
            },
            error: (error: HttpErrorResponse) => {
              console.error('Upload failed', error);
              this.showToast('Failed to upload the file. Please check the file format and try again.');
              this.isLoading = false; // Reset loading state on error
            },
            complete: () => {
              this.isLoading = false; // Reset loading state on completion
            }
          })}
         else if (this.data.formDataKey === 'STUDENTS_BULKUPLOAD_TEMPLATE') {
        const studentData = new FormData();
        studentData.append(this.data.formDataKey, this.selectedFile);

        this.apiservices.bulkuploadstudent(studentData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            this.showToast('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
            this.showToast('Failed to upload the file. Please check the file format and try again.');
          }
        });
      } else {
        this.showToast('Payload key mismatch.');
      }
    } else {
      this.showToast('Please select a file to upload.');
    }
  }
}

  handleImagesUpload(): void {
    if (this.selectedFile) {
      if (!this.allowedFileTypes.includes(this.selectedFile.type)) {
        this.showToast('Invalid file type. Only Excel files are allowed.');
        return;
      }

      this.isLoading = true;
      const formData = new FormData();
      formData.append(this.data.formDataKey, this.selectedFile);

      if (this.data.formDataKey === 'employeImages') {
        this.apiservices.bulkuploadimages(formData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            this.showToast('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
            this.showToast('Failed to upload the file. Please check the file format and try again.');
          },
          complete: () => {
            this.isLoading = false; // Set loading to false
          }
        });
      } else if (this.data.formDataKey === 'studentImages') {
        const studentData = new FormData();
        studentData.append(this.data.formDataKey, this.selectedFile);

        this.apiservices.bulkuploadimages(studentData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            this.showToast('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
            this.showToast('Failed to upload the file. Please check the file format and try again.');
          }
        });
      } else {
        this.showToast('Payload key mismatch.');
      }
    } else {
      this.showToast('Please select a file to upload.');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // Helper function to show toast notifications
  showToast(message: string, action: string = 'Close', duration: number = 3000): void {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}