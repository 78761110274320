// view-permission.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class ViewPermissionGuard implements CanActivate {

  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    if (this.permissionService.ViewPermission) {
      return true; // Allow access to the route
    } else {
        alert('You do not have permission to view');
    //   this.router.navigate(['/not-authorized']); // Redirect to a "not authorized" page
      return false; // Deny access to the route
    }
  }
}
