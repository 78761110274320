<div class="homepage-container mx-3 p-2">
  <div>
    <h2>Departments Page</h2>
  </div>

  <div class="row">
    <!-- Sidebar -->
    <div class="col-sm-12 col-md-3 col-lg-3">
      <div class="sidebar">
        <form
          (ngSubmit)="onSubmit()"
          [formGroup]="form"
        >
          <div class="row">
            <div *ngFor="let question of questions" class="mb-3">
              <app-question [question]="question" [form]="form"></app-question>
            </div>
          </div>
        </form>

        <ng-template #noDepartmentMessage>
          <div class="text-center mt-4">
            <p class="text-muted">Please select a department above 😊</p>
          </div>
        </ng-template>

        <mat-list class="matlist_custom" *ngIf="isDepartmentSelected">
          <mat-list-item
            *ngFor="let item of componentsList"
            [class.active]="item.value === selectedComponent"
            [style]="selectedComponent ? '' : 'color: #FFFFF !important'"
            (click)="onSelectComponent(item)"
          >
            {{ item.label }}
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <!-- Dynamic Content -->
    <div
      class="content col-12 col-sm-12 col-md-9 col-lg-9"
      [ngSwitch]="selectedComponent"
    >
      <ng-container *ngIf="isDepartmentSelected; else noContentMessage">
        <app-banners
          *ngSwitchCase="'Banners'"
          heading="Departments"
          [apiEndpoint]="getBannerApiEndpoint()"
          [createApiEndpoint]="getBannerApiEndpoint()"
          [updateApiEndpoint]="getBannerApiEndpoint()"
        ></app-banners>

        <app-vision-mission
          *ngSwitchCase="'Vision & Mission'"
          heading="Departments"
          [apiEndpoint]="getVisionMissionApiEndpoint()"
          [createApiEndpoint]="getVisionMissionApiEndpoint()"
          [updateApiEndpoint]="getVisionMissionApiEndpoint()"
        ></app-vision-mission>
      </ng-container>

      <ng-template #noContentMessage>
        <div class="text-center mt-5">
          <h4 class="text-muted">
            Please select a department above to view content 📌
          </h4>
        </div>
      </ng-template>
    </div>
  </div>
</div>
