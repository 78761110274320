import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TimetablesService } from '../../../../inputfileds/form_services/timetable/timetables.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { filter, Observable } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';

export interface TimeslotModel {
  organization_id: number;
  shift_id: number;
  academic_calender_id: number;
  timeslots: any[];
}

@Component({
  selector: 'app-addtimeslot',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatTableModule,
    MatCardModule,
    ToastComponent,
  ],
  templateUrl: './addtimeslot.component.html',
  styleUrls: ['./addtimeslot.component.scss'],
  providers: [QuestionControlService, TimetablesService, ApiservicesService],
})
export class AddTimeslotComponent implements OnInit {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  AddSlotQuestions: Inputfiledsbase<string>[] | null = [];
  AddSlotQuestions$: Observable<Inputfiledsbase<any>[]> | null;
  addTimeslotform!: FormGroup;
  timeslotform!: FormGroup;
  showForm: boolean = false;
  organisationdata: any = [];
  datesend!: TimeslotModel;
  errormessage: any;
  editMode: boolean = false;
  currentEditingSlotIndex: number | null = null;
  timeSlots: any[] = [];
  TimeSlotsData: any;

  constructor(
    private apiservice: ApiservicesService,
    private services: TimetablesService,
    private router: Router,
    private fb: FormBuilder,
    private qcs: QuestionControlService,
    private toast: ToastService
  ) {
    this.questions$ = services.getTimeSlotsform();
    this.questions$.subscribe((q) => (this.questions = q));
    this.AddSlotQuestions$ = services.getAddTimeSlotform();
    this.AddSlotQuestions$.subscribe((q) => (this.AddSlotQuestions = q));

    this.addTimeslotform = this.fb.group({
      academic_calender_id: [''],
      organization_id: [''],
      start_day: [''],
      end_day: [''],
      start_time: [''],
      end_time: [''],
    });
  }

  ngOnInit(): void {
    this.timeslotform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
    this.addTimeslotform = this.qcs.toFormGroup(
      this.AddSlotQuestions as Inputfiledsbase<string>[]
    );

    this.TimeSlotsData = history.state.slot;
    this.fetchTimeslots(this.TimeSlotsData.shift_id);
  }
  
  fetchTimeslots(shift_id: number) {
    this.apiservice.getTimeslotsByShiftId(shift_id).subscribe({
      next: (res: any) => {
        if (res && res.rows) {
          this.timeSlots = res.rows;
        } else {
          console.warn('No timeslots found for the given shift_id:', shift_id);
        }
      },
      error: (err) => {
        console.error('Error fetching timeslots:', err)
      },
    });
  }

  populateForm(slot: any) {
    this.addTimeslotform.setValue({
      start_day: slot.start_day,
      end_day: slot.end_day,
      start_time: slot.start_time,
      end_time: slot.end_time
    });
  }
  addTimeSlot() {
    if (this.addTimeslotform.valid) {
      const newTimeslot = this.addTimeslotform.value;
      if (this.editMode && this.currentEditingSlotIndex !== null) {
        this.timeSlots[this.currentEditingSlotIndex] = newTimeslot;
        this.editMode = false;
        this.currentEditingSlotIndex = null;
      } else {
        this.timeSlots.push(newTimeslot);
      }
      this.addTimeslotform.reset();
    }
  }

  removeTimeSlot(slot: any) {
    this.timeSlots = this.timeSlots.filter((s) => s !== slot);
  }

  closeForm(event: any): void {
    this.showForm = event;
  }

  editTimeSlot(slot: any, index: number) {
    this.addTimeslotform.setValue({
      start_day: slot.start_day,
      end_day: slot.end_day,
      start_time: slot.start_time,
      end_time: slot.end_time
    });
    this.editMode = true;
    this.currentEditingSlotIndex = index;
  }

  onSave() {
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;
  
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        if (parsedData.data && parsedData.data.organization_id) {
          organizationId = parsedData.data.organization_id;
        } else { 
          console.error('Parsed data does not contain expected structure');
        }
      } catch (error) {
        console.error('Error parsing data from sessionStorage:', error);
      }
    }
  
    if (organizationId === null) {
      console.error('Organization ID not found');
      return;
    }
  
    const timeslotData = {
      organization_id: organizationId,
      shift_id: parseInt(this.timeslotform.value.shift_id),
      academic_calender_id: parseInt(this.timeslotform.value.academic_calender_id),
      timeslots: this.timeSlots,
    };
    if (this.editMode && this.currentEditingSlotIndex !== null) {
      const slotId = this.timeSlots[this.currentEditingSlotIndex].id;
      this.updateExistingTimeSlots(timeslotData, slotId);
    } else {
      this.createTimeSlots(timeslotData);
    }
  }
  
  updateExistingTimeSlots(timeslotData: any, slotId: number) {
    this.apiservice.updateTimeSlots(timeslotData, slotId).subscribe({
      next: (response) => {
        // Reset the form and state
        this.timeslotform.reset();
        this.addTimeslotform.reset();
        this.editMode = false;
        this.currentEditingSlotIndex = null;
      },
      error: (error) => {
        console.error('Error updating time slots:', error);
      },
    });
  }
  
  createTimeSlots(timeslotData: any) {
    this.apiservice.createtimeslots(timeslotData).subscribe({
      next: (response) => {
        // Reset the form and state
        this.toast.showToast(
          'success',
          'Submited Successfuly',);
        this.timeslotform.reset();
        this.addTimeslotform.reset();
      },
      error: (error) => {
        this.toast.showToast('danger', `Error: ${error.message}`);
        console.error('Error creating time slots:', error);
      },
    });
  }

  onExit() {
    this.router.navigate(['timetable']);
  }

  isFormValid(): boolean {
  const academicYear = this.timeslotform.value.academic_calender_id;
  const shift = this.timeslotform.value.shift_id;
  return academicYear && shift;
}
  isTimeSlotsEmpty(): boolean {
    return this.timeSlots.length === 0;
  }
}
