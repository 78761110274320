<div class="p-3">
    <div class="row m-0">
        <div class="col-sm-3 col-md-3">
            <div>
                <div class="card">
                    <div class="position-relative">
                        <div class="position-absolute top-0 end-0">
                            <div class="m-2">
                                <input #csvInput hidden="true" type="file" onclick="this.value=null"
                                    (change)="csvInputChange($event)" accept="image/png, image/jpeg"/>
                                <!-- <button mat-flat-button color="primary" (click)="csvInput.click()">Choose Spreadsheet
                                File (CSV)</button> -->
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    [ngStyle]="{'color':'#3f51b5'}" fontIcon="create"
                                    (click)="csvInput.click()"></mat-icon>
                            </div>
                        </div>
                        <img #img [src]="imageUrl" (error)="img.src = onerror" class="card-img-top" alt="...">
                        <div class="card-body bg-profile-navyblue rounded-bottom">
                            <div class="card-text text-center text-white">
                                <div>
                                    <h4 class="m-0"><strong>{{employeeprofile?.first_name}}
                                            {{employeeprofile?.last_name}}</strong></h4>
                                </div>
                                <div>
                                    <p class="m-0">{{employeejoining?.designation}}</p>
                                </div>
                                <div>
                                    <p class="m-0">{{employeejoining?.department_name}}</p>
                                </div>
                                <div>
                                    <p class="m-0">Experience: {{employeeexperience?.total_expiriance}} Years</p>
                                </div>
                            </div>
                            <div class="card-text text-white mt-3">
                                <div class="d-flex">
                                    <!-- <p class="m-0" *ngIf="employeejoining?.appointment_type">{{employeejoining?.appointment_type}}</p> -->
                                    <p class="m-0 px-2" *ngIf="employeejoining?.appointment_type">
                                    <mat-icon class="m-0" aria-hidden="false" fontIcon="fiber_manual_record" style="font-size: 10px !important; height: 10px !important; width: 10px !important;"></mat-icon></p>
                                    <p class="m-0">{{employeejoining?.appointment_type}}</p>
                                </div>
                                <div>
                                    <p class="m-0">ID: {{employeeprofile?.employee_unique_id}}</p>
                                </div>
                                <div>
                                    <p class="m-0">Date Of Joining: {{employeejoining?.date_of_joining | date}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-9 col-md-9">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Personal">
                    <div class="mt-4">
                        <div class="row m-0">
                            <div class="col-md-6">
                                <!-- <div>
                                    <p><strong> Dost id : </strong><span>{{employeeprofile?.dost_id}}</span></p>
                                </div> -->
                                <div>
                                    <p><strong> Gender : </strong><span>{{employeeprofile?.gender}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Date of Birth : </strong><span>{{employeeprofile?.date_of_birth}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Religion : </strong><span>{{employeeprofile?.religion}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Caste : </strong><span>{{employeeprofile?.caste}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Sub Caste : </strong><span>{{employeeprofile?.sub_caste}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Aadhar : </strong><span>{{employeeprofile?.adhar_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> PAN : </strong><span>{{employeeprofile?.pan_number}}</span></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <p><strong> Contact : </strong><span>{{employeeData?.phone_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Alternate Contact :
                                        </strong><span>{{employeeprofile?.alternate_mobile_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Email Id : </strong><span>{{employeeData?.professional_email}}</span></p>
                                </div>
                                <div *ngIf="employeeaddress.length > 0">
                                    <p><strong> Present Address : </strong><span>{{employeeaddress[0]?.address_line1}},
                                            {{employeeaddress[0].address_line2}}, {{employeeaddress[0].city}},
                                            {{employeeaddress[0].state_name}}, {{employeeaddress[0].country_name}},
                                            {{employeeaddress[0].pincode}}</span></p>
                                    <p *ngIf="employeeaddress.length >= 2 && employeeaddress[1]" ><strong> Permanent Address : </strong><span>{{employeeaddress[1]?.address_line1}},
                                            {{employeeaddress[1]?.address_line2}}, {{employeeaddress[1]?.city}},
                                            {{employeeaddress[1]?.state_name}}, {{employeeaddress[1]?.country_name}},
                                            {{employeeaddress[1]?.pincode}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Experience & Education">
                    <div class="mt-4">
                        <div>
                            <div>
                                <p><strong> Over all Teaching Experience (Yrs) :
                                    </strong><span>{{employeeexperience?.total_expiriance}}</span></p>
                            </div>
                            <div>
                                <p><strong> Experience in this College (Yrs) :
                                    </strong><span>{{employeeexperience?.any_other_expirince_in_years}}</span></p>
                            </div>
                            <div>
                                <p><strong> Any Other Experience (Yrs):
                                    </strong><span>{{employeeexperience?.expiriance_in_our_institute}}</span></p>
                            </div>
                        </div>
                        <div class="row m-0" *ngIf="employeeeducation.length > 0">
                            <div class="col-md-6 mb-3" *ngFor="let education of employeeeducation">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{education?.qualification}}</h5>
                                        <div class="card-text">
                                            <div>
                                                <p>Specialization :
                                                    <span>{{education?.course_name}}</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p>Marks :<span>{{education?.percentage}}</span></p>
                                            </div>
                                            <div>
                                                <p>Year of Pass : <span>{{education?.end_year}}</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p>College Name & Address :
                                                    <span>{{education?.university_name}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Research">
                    <div class="mt-4">
                        <div class="row m-0">
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div>
                                    <p><strong> Publications in National Journal :
                                        </strong><span>{{employeeexperience?.number_of_publications_in_national_journal}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Publications in International Journal :
                                        </strong><span>{{employeeexperience?.number_of_publications_in_international_journal}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Books Published :
                                        </strong><span>{{employeeexperience?.number_of_books_published}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Publications in International Conference :
                                        </strong><span>{{employeeexperience?.number_of_publications_in_national_conference}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Publications in National Conference :
                                        </strong><span>{{employeeexperience?.number_of_publications_in_international_conference}}</span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div>
                                    <p><strong> No Of Patents : </strong><span></span></p>
                                </div>
                                <div>
                                    <p><strong> No. of PG Projects Guided :
                                        </strong><span>{{employeeexperience?.number_of_pg_projects_guided}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Doctorate Students Guided :
                                        </strong><span>{{employeeexperience?.number_of_doctorate_students_guided}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Workshops Attended :
                                        </strong><span>{{employeeexperience?.number_of_workshops_attended}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Seminars Attended :
                                        </strong><span>{{employeeexperience?.number_of_seminars_attended}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Seminars Conducted :
                                        </strong><span>{{employeeexperience?.number_of_seminars_conducted}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Conferences Attended :
                                        </strong><span>{{employeeexperience?.number_of_conferences_conducted}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Conferences Conducted :
                                        </strong><span>{{employeeexperience?.number_of_conferences_attended}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Salary">
                    <div class="mt-4">
                        <div class="row m-0">
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div>
                                    <p><strong> Pay Scale : </strong><span>{{employeesalary?.pay_scale}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Gross Pay per month :
                                        </strong><span>{{employeesalary?.gross_pay_per_month}}</span></p>
                                </div>
                                <div>
                                    <p><strong> HRA in Rs. : </strong><span>{{employeesalary?.hra_in_rupees}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Basic Pay in Rs. : </strong><span>{{employeesalary?.basic_pay}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Other Allowances in Rs. :
                                        </strong><span>{{employeesalary?.other_allowances}}</span></p>
                                </div>
                                <div>
                                    <p><strong> PF Number : </strong><span>{{employeesalary?.pf_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> ESI Number : </strong><span>{{employeesalary?.esi_number}}</span></p>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div>
                                    <p><strong> Salary Mode : </strong><span>{{employeesalary?.salary_mode}}</span></p>
                                </div>
                                <!-- <div>
                                    <p><strong> No. of PG Projects Guided :
                                        </strong><span>{{employeeprofile?.number_of_pg_projects_guided}}</span></p>
                                </div> -->
                                <div>
                                    <p><strong> Bank Name : </strong><span>{{employeesalary?.bank_name}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Bank Account Number :
                                        </strong><span>{{employeesalary?.bank_account_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Bank Branch Name :
                                        </strong><span>{{employeesalary?.bank_branch_name}}</span></p>
                                </div>
                                <div>
                                    <p><strong> IFSC Code : </strong><span>{{employeesalary?.bank_ifsc_code}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>