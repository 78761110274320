import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { User } from '../../../Services/Models/user';

// import { environment } from '../../environments/environment';
// import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class MenuService {

    menudata:any;
    constructor() { }

    menulist() {
        console.log(this.menudata);
        return this.menudata;
      }
      
}