<!-- <ng-container *ngIf="lookupdata.length > 0"> -->
<section class="mx-3">
  <app-toast></app-toast>
  <ng-container *ngIf="!showaddflag">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4 class="m-0">Lookup Type</h4>
      </div>
      <div *ngIf="actiondata.create">
        <button mat-raised-button color="primary" (click)="addbackflag(true)" class="px-5">Add</button>
      </div>
    </div>
    <div class="position-relative">
    @if (isLoadingResults || isRateLimitReached) {
      <div class="loading-shade">
        @if (isLoadingResults) {
          <mat-spinner class="mat_spinner"></mat-spinner>
        }
        @if (isRateLimitReached) {
          <div class="rate-limit-reached">
            GitHub's API rate limit has been reached. It will be reset in one minute.
          </div>
        }
      </div>
    }
    <div *ngIf="actiondata.list_view" class="mt-2 table-container" >
      <table mat-table [dataSource]="dataSource">

        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container> -->

        <!-- Weight Column -->
        <ng-container matColumnDef='lookup_type'>
          <th mat-header-cell *matHeaderCellDef>Lookup type</th>
          <td mat-cell *matCellDef="let element"> {{element.lookup_type}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="lookup_type_displayname">
          <th mat-header-cell *matHeaderCellDef>Display Name</th>
          <td mat-cell *matCellDef="let element"> {{element.lookup_type_displayname}} </td>
        </ng-container>
        <!-- Symbol Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
    <div *ngIf="total_count > 5">
      <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
    </div>
  </div>
  </ng-container>


  <!-- </ng-container> -->
  <!-- <ng-container *ngIf="lookupdata.length <= 0">
<p>No data Found</p>
</ng-container> -->

  <ng-container *ngIf="showaddflag">
    <div class="d-flex align-items-center">
      <div>
        <button mat-icon-button class="back_icon me-2"
          (click)="addbackflag(false)"><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div>
        <h4 class="m-0">Add Lookup Type</h4>
      </div>
    </div>

    <div class="bg-white p-3 mt-2">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="row">
          <div *ngFor="let question of questions" class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <app-question [question]="question" [form]="form"></app-question>
          </div>
        </div>
        <div class="form-row">
          <button mat-raised-button color="primary" class="px-5" type="submit" [disabled]="!form.valid">Save</button>
        </div>
      </form>

      <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
    </div>
  </ng-container>
</section>