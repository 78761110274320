import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class StreamfromService {

  organisationdata: any = [];
  categorydata: any = [];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('STREAMS_CATEGORIES', this.categorydata);

    // this.apiservice.getorganizationdrop().subscribe((res) => 
    //   {
    //     for(let i=0; i <= res.length; i++){
    //       this.organisationdata?.push({id:res[i].id, key: res[i].short_name, value:res[i].id})
    //     }
    //   }); 
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    // if (code === 'ORGANISATION') {
    //   observable = this.dropdownservice.getorganizationdrop();
    // } else {
    observable = this.dropdownservice.getlookupcodesdropdown(code);
    // }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        // if (code === 'ORGANISATION') {
        //   lookupname = res[i].short_name;
        // } else {
        lookupname = res[i].lookup_name;
        // }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  getStreamFormServices() {
    const questions: Inputfiledsbase<string>[] = [

      // new DropdownQuestion({
      //   key: 'organization_id',
      //   label: 'Organization Id',
      //   required: true,
      //   options: this.organisationdata,
      //   order: 1,
      // }),

      new TextboxQuestion({
        key: 'stream_name',
        label: 'Stream Name',
        required: true,
        order: 2
      }),

      new DropdownQuestion({
        key: 'category_id',
        label: 'Category data',
        required: false,
        options: this.categorydata,
        order: 1,
      }),

      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        required: true,
        order: 2
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: 'End Date',
        required: false,
        order: 2
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
