import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { CoursesService } from '../../../inputfileds/form_services/courses/courses.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddacademicyearsComponent } from './addacademicyear/addacademicyears.component';
import { AcademicsService } from '../../../inputfileds/form_services/academics/academics.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';

export interface AcademicsView {
  index: number;
  id: number;
  organization_id: number;
  courses: { id: number; name: string };
  start_year: number;
  end_year: number;
}

@Component({
  selector: 'app-academicyears',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatProgressSpinnerModule,
    CommonModule,
    PaginationComponent
  ],
  templateUrl: './academicyears.component.html',
  styleUrl: './academicyears.component.scss'
})
export class AcademicyearsComponent implements OnInit {
  displayedColumns: string[] = [
    // 'select',
    // 'id',
    'regulation_code',
    'course',
    'Academic_Year',
    'actions',
  ];

  dataSource = new MatTableDataSource<AcademicsView>();
  selection = new SelectionModel<AcademicsView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  acadamicsList: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  courseData: any;
  total_count: any;
  paginationdata: any;

  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private router: Router,
    private service: AcademicsService
  ) {}

  ngOnInit(): void {
    this.getacadamicsdetails();
  }

  pagination(event: any){
    this.paginationdata = event;
    this.getacadamicsdetails();
  }
  getacadamicsdetails() {
    this.apiservice.getacadamicyearslist(this.paginationdata).subscribe((res) => {
      this.acadamicsList = res.rows;
      this.total_count = res.total_count;
      this.dataSource = new MatTableDataSource<AcademicsView>(
        this.acadamicsList
      );
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

  openAddAcademicsDialog() {
  // Additional logic to pass selected years or context to dialog
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
  dialogConfig.minWidth = '80%';
  dialogConfig.minHeight = '65vh';
  dialogConfig.maxHeight = '65vh';
  
  const dialogRef = this.dialog.open(AddacademicyearsComponent, dialogConfig);

  dialogRef.afterClosed().subscribe((result) => {
    if (result) {
      this.getacadamicsdetails();
    }
  });
}


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: AcademicsView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.index + 1
    }`;
  }

  showsemesterdetails(id: any) {
    this.router.navigate(['/academicyearsdetails'],{ queryParams: { data:id.id} });
  }
}