import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { AddnoticeboardComponent } from './addnoticeboard/addnoticeboard.component';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NoticeBoardDetailDialogComponent } from './notice-board-detail-dialog/notice-board-detail-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CdkDragPreview,
  moveItemInArray,
  type CdkDragDrop,
} from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-notice-board',
  standalone: true,
  imports: [
    MatIcon,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatCheckboxModule
  ],
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss'], // Corrected styleUrl to styleUrls
})
export class NoticeBoardComponent {
  reorderMode = false;
  isLoading = false;
  displayedColumns: string[] = [
    'drag',
    'sl.no',
    'visible_from',
    'visible_until',
    'info',
    'status',
    'images',
    'actions',
  ];
  noticeboardData: any[] = [];
  formattedDates: any;
  editId: string | null = null;
  initialOrder: any[] = [];

  constructor(
    private apiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService,
    private apiservice: ApiservicesService,
    private snackBar: MatSnackBar
  ) {}
  imagePath = this.apiservice.environmentPath;

  ngOnInit(): void {
    this.getNoticeBoardData();
  }

  openUploadDialog(): void {
    const dialogConfig = this.configureDialog({
      data: null,
      minHeight: '95vh',
      maxHeight: '95vh',
    });

    const dialogRef = this.dialog.open(AddnoticeboardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getNoticeBoardData();
      }
    });
  }

  editItem(element: any): void {
    const dialogConfig = this.configureDialog({
      data: element,
      minHeight: '90vh',
      maxHeight: '90vh',
    });

    const dialogRef = this.dialog.open(AddnoticeboardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getNoticeBoardData();
      }
    });
  }

  viewItem(element: any): void {
    const dialogConfig = this.configureDialog({
      data: {
        notice: element, // Pass the selected banner data
        editCallback: (notice: any) => this.editItem(notice),
        deleteCallback: (id: number) => this.deleteApi(id),
      },
      minHeight: '40vh',
      maxHeight: '80vh',
    });

    const dialogRef = this.dialog.open(
      NoticeBoardDetailDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(() => {});
  }

  deleteApi(id: number) {
    const confirmDelete = confirm(
      'Are you sure you want to delete this banner?'
    );
    if (confirmDelete) {
      this.apiservice.deleteNoticeBoard(id).subscribe(
        (res: any) => {
          if (res.code === 200 && res.status === true) {
            this.getNoticeBoardData();
          } else {
            console.error(
              'Error deleting banner:',
              res.message || 'Unknown error'
            );
          }
        },
        (error) => {
          console.error('API Error during delete:', error);
        }
      );
    }
  }

  getNoticeBoardData(): void {
    this.apiService.getNoticeBoardlist().subscribe(
      (data: any) => {
        this.noticeboardData = data.rows || [];
      },
      (error) => {
        console.error('Error fetching notice board data:', error);
      }
    );
  }

  private configureDialog(config: Partial<MatDialogConfig>): MatDialogConfig {
    return {
      disableClose: true,
      panelClass: [
        'dialog-bottom-enter',
        'dialog-bottom-leave',
        'animate__animated',
      ],
      maxWidth: '50vw',
      ...config,
    };
  }

  toggleReorderMode(): void {
    if (this.reorderMode) {
      if (this.hasOrderChanged()) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.submitReorderedNotices(); // Submit after 5s delay
        }, 5000);
      }
    } else {
      // Save the initial order when entering reorder mode
      this.initialOrder = this.noticeboardData.map(
        (noticeInitialData: any) => noticeInitialData.id
      );
    }
    this.reorderMode = !this.reorderMode;
  }

  hasOrderChanged(): boolean {
    const currentOrder = this.noticeboardData.map(
      (noticeData: any) => noticeData.id
    );
    return JSON.stringify(this.initialOrder) !== JSON.stringify(currentOrder);
  }

  submitReorderedNotices() {
    const reorderedData = this.noticeboardData.map(
      (notice: any, index: number) => ({
        id: notice.id,
        sequence: index + 1,
      })
    );
    const noticeboardDatatoSend = { notices: reorderedData };
    this.snackBar.open('Submitting reorder...', 'Close', {
      duration: 3000,
      verticalPosition: 'top',
      panelClass: ['info-snackbar'],
    });
    console.log('Submitted', noticeboardDatatoSend);
    this.apiService.updateNoticeBoardSequence(noticeboardDatatoSend).subscribe(
      () => {
        this.snackBar.open('Reordered Successfully', 'Close', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['success-snackbar'],
        });
        this.getNoticeBoardData();
      },
      (error) => {
        console.error('Error updating sequence:', error);
        let errorMessage =
          'An unexpected error occurred. Please try again later.';
        if (error.status === 0) {
          errorMessage =
            'Network error. Please check your internet connection.';
        } else if (error.status >= 400 && error.status < 500) {
          errorMessage =
            'Request failed. Please verify the data and try again.';
        } else if (error.status >= 500) {
          errorMessage =
            'Server error. Please contact support if the issue persists.';
        }

        // Display an error snackbar with a relevant message
        this.snackBar.open(errorMessage, 'Close', {
          duration: 5000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
      }
    );
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.noticeboardData,
      event.previousIndex,
      event.currentIndex
    );

    this.noticeboardData = this.noticeboardData.map(
      (noticeData: any, index: number) => ({
        ...noticeData,
        sequence: index + 1, // Adjusting sequence after sorting
      })
    );
  }
}
