import { Component, Inject } from '@angular/core';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { FrontpageService } from '../../../../../inputfileds/form_services/collegemanagement/frontpage.service';

export interface testimonialModel {
  heading: string;
  description: string;
  image_url: File | null;
}

@Component({
  selector: 'app-addtestimonials',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: './addtestimonials.component.html',
  styleUrl: './addtestimonials.component.scss',
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class AddtestimonialsComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  testimonialsdata!: FormGroup;
  datesend!: testimonialModel;
  Testimonialdata: any = {};
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedVideoUrl: string | ArrayBuffer | null = null;
  file!: File | null;
  imagePath = this.apiservice.environmentPath
  showReplaceButton : boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddtestimonialsComponent>,
    private dropdownservice: DropdownservicesService,
    Noticesservices: FrontpageService,
    private qcs: QuestionControlService,
    private apiservice: ApiservicesService,
    private fb: FormBuilder,
  @Inject(MAT_DIALOG_DATA) public data: any = {},
  ) {
    {
      this.questions$ = Noticesservices.getTestimonialForm();
      this.questions$.subscribe((q) => (this.questions = q));
      this.testimonialsdata = this.fb.group({
        heading: [data?.heading || '', Validators.required],
        description: [data?.description || '', Validators.required],
        media_url: [this.imagePath + data.media_url || null],
      });

      if (data) {
        this.fileName = data.media_url?.split('/').pop() || null;
        this.selectedVideoUrl = data.media_url
          ? `${this.apiservice.environmentPath}/${data.media_url}`
          : null;
      }
    }
  }
  // ngOnInit(): void {
  //   this.testimonialsdata = this.fb.group({
  //     heading: [this.data.heading || ''],
  //     media_url: [this.data.media_url || ''],
  //     description: [this.data.description || ''],
  //   });
  // }
  videoInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
  
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
  
      const reader = new FileReader();
      reader.onload = () => {
        this.selectedVideoUrl = reader.result as string;
  
        this.testimonialsdata.patchValue({
          video_url: this.file,
        });
      };
  
      reader.readAsDataURL(this.file);
    } else {
      console.error('No file selected.');
    }
  }
  
  
  onSubmit() {
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;

    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        organizationId = parsedData.data?.organization_id || null;
      } catch (error) {
        console.error('Error parsing organization data:', error);
      }
    }

    if (!organizationId) {
      console.error('Organization ID not found. Cannot proceed.');
      return;
    }

    const formData = new FormData();
    formData.append('name', this.testimonialsdata.value.heading || '');
    formData.append(
      'description',
      this.testimonialsdata.value.description || ''
    );
    formData.append('organization', organizationId.toString());
    if (this.file) {
      formData.append('media_url', this.file);
    } else if (this.selectedVideoUrl) {
      formData.append('media_url', this.selectedVideoUrl as string);
    } else {
      console.error('No video selected and no existing video URL found.');
      return;
    }
    if (this.data?.id) {
      this.updateApi(this.data?.id, formData);
    } else {
      this.createAPi(formData);
    }
  }

  updateApi(id: number, formData: FormData) {
    this.apiservice.updateTestimonials(id, formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.Testimonialdata = res.data;
          this.testimonialsdata.reset();
          this.dialogRef.close(true);
        } else {
          console.error(
            'Error updating mission/vision:',
            res.message || 'Unknown error'
          );
        }
      },
      (error) => {
        console.error('API Error during edit:', error);
      }
    );
  }
  createAPi(formData: FormData) {
    this.apiservice.createTestimonials(formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.testimonialsdata.reset();
          this.selectedVideoUrl = null;
          this.fileName = null;
          this.dialogRef.close(true);
        } else {
          console.error('Submission Error:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }
  closeDialog(event: any) {
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    this.dialogRef.close(event);
  }
}
