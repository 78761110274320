<section class="mx-3">
  <mat-dialog-content class="mat-typography">
    <div class="d-flex align-items-center mb-3">
      <button mat-icon-button class="back_icon cancel_button" (click)="closeDialog(false)">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h4 class="m-0">Add Sub Organisation</h4>
    </div>
    <mat-divider></mat-divider>
    <div class="mb-4">
      <form (ngSubmit)="onSubmit()" [formGroup]="suborganisationForm">
        <div class="row">
          <div *ngFor="let question of suborganisationquestions"
            class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <app-question [question]="question" [form]="suborganisationForm"></app-question>
          </div>
          <div *ngFor="let question of suborganisationpersonal_detailsquestions"
            class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <app-question [question]="question" [form]="suborganisationpersonal_detailsForm"></app-question>
          </div>
          <div class="col-sm-6 d-flex align-items-center justify-content-start">
            <div class="m-2">
              <label class="d-block">Attach Logo</label>
              <input type="file" (change)="imageInputChange($event)" onclick="this.value=null" />
              <!-- <p *ngIf="fileName" class="text-center mt-1">Selected file: {{ fileName }}</p> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="w-100 bg-white position-absolute bottom-0 end-0">
    <div class="form-actions">
      <button mat-raised-button color="primary" type="submit" class="submit_button" (click)="onSubmit()"
        [disabled]="!suborganisationForm.valid">
        Submit
      </button>
    </div>
  </mat-dialog-actions>

</section>