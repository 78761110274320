import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { TimetablesComponent } from './timetables/timetables.component';
import { AddTimeslotComponent } from './timeslot/addtimeslot/addtimeslot.component';
import { Router, RouterModule } from '@angular/router';
import { PermissionService } from '../../components/Authentication/services/permission.service';
import { MenuService } from '../../components/Authentication/services/menu.service';

@Component({
  selector: 'app-timetable',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    // TimeslotComponent,
    TimetablesComponent,
    AddTimeslotComponent,
    RouterModule
  ],
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent {
  showForm: boolean = false;
  canCreate: boolean = false;
  canView: boolean = false;
  actiondata: any;
  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private menuDataService: MenuService) { 
    this.actiondata = this.permissionService.getactiondata();
    }

  toggleForm() {
    this.router.navigate(['timetable/addtimeslots']);
  }
  toggletableForm() {
    this.router.navigate(['timetable/addtimetable']);
  }
  closeForm() {
    this.showForm = false;
  }

  ngOnInit() {
    //  this.permissionService.hasPermission();
    //  if(actiondata.create == true){
    //   this.canCreate = true;
    //   this.permissionService.hasCreatePermission(true);
    //  }
    //  if(actiondata.create == false){
    //   this.canCreate = false;
    //   this.permissionService.hasPermission(false);
    //  }
    //  if(actiondata.view == true){
    //   this.canView = true
    //   this.permissionService.hasViewPermission(true);
    //  }else{
    //   this.canView = false
    //   this.permissionService.hasViewPermission(false);
    //  }
    // if (!this.canCreate) {
    //   // Handle the case when user does not have permission
    //   alert('You do not have permission to edit');
    // }
  }
}
