import { Component } from '@angular/core';
import { Inputfiledsbase } from '../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { OrganizationsService } from '../../inputfileds/form_services/organization/organizations.service';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AddsuborganisationComponent } from './addsuborganisation/addsuborganisation.component';
import { PermissionService } from '../../components/Authentication/services/permission.service';


export interface OrganizationView {
  index: number,
  id: number,
  organization_id: number,
  leave_name: string,
  designation: string,
  maximum_allowed_leaves: number,
}

@Component({
  selector: 'app-addorganization',
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatDialogModule, MatCheckboxModule, MatIcon, MatTableModule, RouterModule, MatButtonModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, OrganizationsService, ApiservicesService],
  templateUrl: './organization.component.html',
  styleUrl: './organization.component.scss'
})
export class AddorganizationComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad: any;
  SuborganizationData = []
  displayedColumns: string[] = ['Sl_No', 'name', 'city', 'contact_number', 'actions'];
  dataSource = new MatTableDataSource<OrganizationView>();
  selection = new SelectionModel<OrganizationView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  formConfig: any;
  actiondata: any;

  constructor(
    public dialog: MatDialog,
    private apiservice: ApiservicesService,
    private router: Router,
    private qcs: QuestionControlService,
    service: OrganizationsService,
    private fb: FormBuilder,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    if (this.actiondata.view === false) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }
    this.questions$ = service.getOrganizationservices();
    this.questions$.subscribe(q => this.questions = q);
    this.getorganizationdetails();
  }

  getorganizationdetails() {
    this.apiservice.getsuborganization().subscribe((res) => {
      this.SuborganizationData = res;
      this.dataSource = new MatTableDataSource<OrganizationView>(res.rows);
    });
  }

  refreshDataSource(): void {
    this.getorganizationdetails();
  }

  showcollegedetails() {
    this.router.navigate(['/collegedetails']);
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }

  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: OrganizationView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AddsuborganisationComponent, {
      width: '80vw',
      height: '65vh',
      data: this.form
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getorganizationdetails();
    });
  }

}
