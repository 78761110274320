import { Component } from '@angular/core';
import { OrganizationsService } from '../../../inputfileds/form_services/organization/organizations.service';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { forkJoin, Observable } from 'rxjs';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-addsuborganisation',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatDividerModule,
    MatIcon,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    CommonModule
  ],
  providers: [
    QuestionControlService,
    OrganizationsService,
    ApiservicesService,
    DropdownservicesService
  ],
  templateUrl: './addsuborganisation.component.html',
  styleUrl: './addsuborganisation.component.scss'
})

export class AddsuborganisationComponent {
  suborganisationquestions: [] = [];
  suborganisationquestions$: Observable<any[]> | null = null;
  suborganisationpersonal_detailsquestions: [] = [];
  suborganisationpersonal_detailsquestions$: Observable<any[]> | null = null;
  suborganisationForm!: FormGroup;
  suborganisationpersonal_detailsForm!: FormGroup;
  payLoad: any;
  datesend: any;
  file: any;
  fileName: any;
  fomdata: FormData = new FormData()
  selectedImageUrl!: string | ArrayBuffer | null;
  errorMessage!: string;

  constructor(
    private dialogRef: MatDialogRef<AddsuborganisationComponent>,
    public suborganisationService: OrganizationsService,
    private qcs: QuestionControlService,
    private fb: FormBuilder,
    public apiService: ApiservicesService,
    private dropdownservice: DropdownservicesService
  ) {
    this.suborganisationquestions$ = suborganisationService.getOrganizationservices();
    this.suborganisationquestions$.subscribe((q: any) => this.suborganisationquestions = q);
    this.suborganisationpersonal_detailsquestions$ = suborganisationService.getOrganizationpersonal_detailsservices();
    this.suborganisationpersonal_detailsquestions$.subscribe((q: any) => this.suborganisationpersonal_detailsquestions = q);
  }

  ngOnInit(): void {
    // this.suborganisationFrom = this.fb.group({});
    // this.suborganisationpersonal_detailsFrom = this.fb.group({});
    this.suborganisationForm = this.qcs.toFormGroup(this.suborganisationquestions as Inputfiledsbase<string>[]);
    this.suborganisationpersonal_detailsForm = this.qcs.toFormGroup(this.suborganisationpersonal_detailsquestions as Inputfiledsbase<string>[]);
  }

  closeDialog(event: any) {
    this.dialogRef.close(event);
    // document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
    // document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
    // setTimeout(() => { this.dialogRef.close(event); }, 1000);
  }

  imageInputChange(fileInputEvent: any): void {
    this.file = fileInputEvent.target.files[0];
    this.fileName = fileInputEvent.target.files[0].name;
    this.fomdata.append('employeImage', this.file);
    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.selectedImageUrl = reader.result;
      };
    }
  }

  updateImage(file: File, id: number): void {
    this.apiService.updatesuborganizationimage(file, id).subscribe(
      (res) => {
        this.closeDialog(false);
      },
      (err) => {
        console.error('Image upload error', err);
        this.errorMessage = 'Image upload failed. Please try again.';
      }
    );
  }

  onSubmit() {
    this.payLoad = this.suborganisationForm.getRawValue();
    this.payLoad = { ...this.payLoad, "personal_details": this.suborganisationpersonal_detailsForm.getRawValue(), "logo_url":null, "educational_qualifications": [], "addresses": [], "experience_details": {}, "salary_deatails": {}, "joining_details": {} }
    this.datesend = this.suborganisationForm.value;

    forkJoin([
      this.dropdownservice.getlookupcodesdropdown('DESIGNATIONS'),
    ]).subscribe(([designationRes]) => {
      const filteredStatus = designationRes.filter((dropfilter: any) => { return dropfilter.id === this.suborganisationForm.value.designation_id });
      this.payLoad = { ...this.payLoad, designation: filteredStatus[0]?.lookup_name };
      // this.payLoad.maximum_allowed_leaves = parseInt(this.form.value.maximum_allowed_leaves);
      this.createAPis(this.payLoad);
    });
  }

  createAPis(event: any) {
    this.apiService.createsuborganization(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.suborganisationForm.reset();
        this.suborganisationpersonal_detailsForm.reset();
        this.updateImage(res.data.id, this.file);
      }
    })
  }

}
