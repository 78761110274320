import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreatePageDialogComponent } from '../activitiespageview/createpagedialogcomponent/createpagedialogcomponent.component';
import { DynamicPageCreationComponent } from '../../../components/webpagecreation/dynamic-page-creation/dynamic-page-creation.component';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { NaacPageService } from '../../../inputfileds/form_services/WebsiteManagement/Naac/naac.service';

@Component({
  selector: 'app-naacpageview',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule
  ],
  templateUrl: './naacpageview.component.html',
  styleUrl: './naacpageview.component.scss',
  providers: [NaacPageService]
})
export class NaacpageviewComponent {
  displayedColumns: string[] = ['id', 'page_name', 'actions'];
  naacpageData: any[] = [];
  constructor(private dialog: MatDialog, private router: Router,private naacPageService : NaacPageService,  private apiServices: ApiservicesService) {}

  ngOnInit(): void {
    this.getnaacpageData()
  }

  openDynamicPageCreationDialog(): void {
    const dialogRef = this.dialog.open(DynamicPageCreationComponent, {
      width: '600px',
      data: {
        pageTitle: 'Create New NAAC Details Page', // Custom title
        questions$: this.naacPageService.getCreatePage(), // Dynamic questions
        submitButtonText: 'Create Page', // Custom submit button text
      },
    });

    dialogRef.componentInstance.formSubmit.subscribe((formData) => {
      const organizationData = sessionStorage.getItem('user');
      let organizationId: number | null = null;
      let suborganizationId: number | null = null;

      if (organizationData) {
        try {
          const parsedData = JSON.parse(organizationData);
          if (parsedData.data && parsedData.data.organization_id) {
            organizationId = parsedData.data.organization_id;
            suborganizationId = parsedData.data.sub_organization_id
          } else {
            console.error('Parsed data does not contain expected structure');
          }
        } catch (error) {
          console.error('Error parsing data from sessionStorage:', error);
        }
      }

      if (organizationId === null) {
        console.error('Organization ID not found');
        return;
      }
      const pageData = {
        organization_id: organizationId,
        page_name: formData.page_name,
        module_id: '2',
        sub_module_name: 'NAAC',
        sub_organization_id: suborganizationId,
      };

      this.apiServices.createNaacdetailsPage(pageData).subscribe({
        next: (response) => {
          dialogRef.close();
        },
        error: (err) => {
          console.error('Error creating page:', err);
        },
      });
    });

    dialogRef.componentInstance.cancel.subscribe(() => {
      dialogRef.close();
    });
  }

  navigateToActivityPage(id: number, data: any): void {
    this.router.navigate([`/naacdetails/${id}`], { state: { activityData: data } });
  }

  getnaacpageData() {
    this.apiServices.getNAACPagelist().subscribe(
      (data: any) => {
        this.naacpageData = data.rows;
      },
      (error) => {
        console.error('Error fetching activities data:', error);
        alert('Failed to load activities. Please try again later.');
      }
    );
  }
  

}