<div class="text-center">
  <div class="left-side">
    <!-- <div class="mb-3">
      <h2 class="m-0">Hello, User !</h2>
      <p>Dashboard is under development</p>
    </div> -->
    <div class="m-4">
      <img src="../../../assets/images/under_construction.jpg" class="img-fluid"/>
    </div>
  </div>
</div>