<section>
  <app-toast></app-toast>
  <div class="container">
    <mat-dialog-content class="mat-typography">
      <div *ngIf="!semisterflag" class="pb-5">
        <div class="d-flex align-items-center mb-3">
          <button mat-icon-button class="back_icon" (click)="closeDialog(false)">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <h4 class="m-0">Add Courses</h4>
        </div>
        <mat-divider></mat-divider>
        <form [formGroup]="courseform" class="pt-3">
          <div class="row g-3">
            <div
              *ngFor="let question of questions"
              class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3"
            >
              <div class="form-group">
                <app-question [question]="question" [form]="courseform"></app-question>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="semisterflag">
        <app-addsemesters [courseData]="coursedata" (backtocourseform)="gotosemisters()"></app-addsemesters>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="w-100 bg-white position-absolute bottom-0 end-0">
      <div class="d-flex gap-2">
        <button mat-raised-button (click)="closeDialog(false)" class="px-5">
          Cancel
        </button>
        <button
          *ngIf="!semisterflag"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          class="px-5"
          type="submit"
          [disabled]="!courseform.valid"
        >Save</button>
      </div>
    </mat-dialog-actions>
  </div>
  
</section>
