<div class="dialog-content">
    <div color="primary">
       <h1>
         Testimonials
       </h1>
    </div>
    <div class="testimonials-container">
        <video
            [src]="'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' + data.testimonials.media_url"
            controls
            class="testimonials-video"
        >
            Your browser does not support the video tag.
        </video>
    </div>

    <div class="testimonials-details">
        <h2 class="testimonials-title">{{ data.testimonials.name }}</h2>
        <p class="testimonials-description">{{ data.testimonials.description }}</p>
        <div class="testimonials-status">
            <p><strong>Uploaded on:</strong> {{ data.testimonials.updated_at | date: 'dd/MM/yyyy' }}</p>
        </div>
    </div>

    <div class="dialog-actions">
        <button mat-stroked-button class="cancel_button" (click)="closeDialog()">Cancel</button>
        <button mat-raised-button class="update_button" color="primary" (click)="editTestimonials()">Edit</button>
        <button mat-stroked-button class="delete_button" (click)="deleteTestimonials()">Delete</button>
    </div>
</div>
