import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { ConnectiveservicesService } from '../../../Services/Apis/Connectiveservices/connectiveservices.service';

@Injectable({
  providedIn: 'root'
})
export class ApplyleavesService {

  organisationdata: any = [];
  leavetypedata: any = [];
  leavemodedata: any = [];
  leave_mode_start_data: any = [];
  leave_mode_end_data: any = [];
  startdate: any;
  diffDays: any;
  enddate: any;

  constructor(
    private apiservice: ApiservicesService,
    private dropdownservice: DropdownservicesService,
    private _ngZone: NgZone,
    private cdRef: ChangeDetectorRef,
    private connectiveservice: ConnectiveservicesService) {
    this.loadDropdownData('LEAVE_TYPES', this.leavetypedata);
    this.loadDropdownData('LEAVE_MODES', this.leavemodedata);
    this.loadDropdownData('LEAVE_MODES_START', this.leave_mode_start_data);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'LEAVE_TYPES') {
      observable = this.apiservice.getApplyLeavetypes();
    } else if (code === 'LEAVE_MODES_START') {
      observable = this.dropdownservice.getlookupcodesdropdown('LEAVE_MODES');
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'LEAVE_TYPES') {
          lookupname = res[i].leave_code;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  updatedataforarry() {
    // this.leave_mode_start_data =[];
    let startdataupdate = this.leavemodedata.filter((items: any) => {
      return items.key != 'FORENOON'
    })
    return startdataupdate;
  }
  datedifference(startdate: any, enddate: any): void {
    let diff = Math.abs(new Date(startdate).getTime() - new Date(enddate).getTime());
    this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    this.connectiveservice.number_of_selected_days = this.diffDays;
    if (this.diffDays >= 1) {
      let enddataupdate = this.leavemodedata.filter((items: any) => {
        return items.key != 'AFTERNOON'
      })
      let startdataupdate = this.leavemodedata.filter((items: any) => {
        return items.key != 'FORENOON'
      })
      this.leave_mode_end_data.splice(0, this.leave_mode_end_data.length);
      enddataupdate.map((item: any) => {
        this.leave_mode_end_data.push(item);
      })
      this.leave_mode_start_data.splice(0, this.leave_mode_start_data.length);
      startdataupdate.map((item: any) => {
        this.leave_mode_start_data.push(item);
      })
    } else {
      this.leave_mode_end_data.splice(0, this.leave_mode_start_data.length);
      this.leave_mode_start_data.splice(0, this.leave_mode_start_data.length);
      this.leavemodedata.map((item: any) => {
        this.leave_mode_start_data.push(item);
      })
    }
  }

  startdatechange(date: any): void {
    this.connectiveservice.start_leave_date = date;
    this.startdate = date;
    this.datedifference(date, this.enddate);
  }

  enddatechange(date: any): void {
    this.enddate = date;
    this.datedifference(this.startdate, date);
  }

  getstart_data() {
    this.startdate = this.connectiveservice.get_start_leave_date();
    this.enddate = this.connectiveservice.get_start_leave_date();
    this.datedifference(this.connectiveservice.get_start_leave_date(), this.connectiveservice.get_start_leave_date());
  }

  getApplyedLeaves() {
      this.getstart_data()

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'leave_type_id',
        label: 'Leave Type',
        position: 'FULL',
        required: true,
        options: this.leavetypedata,
        order: 1
      }),

      new DatepickerQuestion({
        key: 'start_date',
        label: 'From Date',
        required: true,
        order: 2,
        onChange: this.startdatechange.bind(this)
      }),

      new DropdownQuestion({
        key: 'leave_mode_start_id',
        label: 'Day Type',
        required: true,
        options: this.leave_mode_start_data,
        order: 3,
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: 'To Date',
        required: true,
        order: 4,
        onChange: this.enddatechange.bind(this)
      }),

      new DropdownQuestion({
        key: 'leave_mode_end_id',
        label: 'Day Type',
        required: true,
        disable: true,
        options: this.leave_mode_end_data,
        order: 5
      }),

      // new DropdownQuestion({
      //   key: 'leave_mode_id',
      //   label: 'Leave Mode',
      //   required: true,
      //   options: this.leavemodedata,
      //   order: 6
      // }),

      new TextareaQuestion({
        key: 'reason',
        label: 'Reason for Leave',
        position: 'FULL',
        required: true,
        disable: true,
        order: 7
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getCompoffLeaves() {
    const questions: Inputfiledsbase<string>[] = [

      new TimepickerQuestion({
        key: 'time',
        label: 'Time',
        type: 'datetime',
        required: true,
        order: 1
      }),

      new DatepickerQuestion({
        key: 'from_date',
        label: 'From Date',
        required: true,
        order: 2
      }),

      new TextareaQuestion({
        key: 'reason',
        label: 'Reason for Leave',
        required: true,
        order: 3
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
