import { Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SettingServiceService } from '../../Services/setting-service.service';
import { Inputfiledsbase } from '../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs/internal/Observable';
import { LookupcodeService } from '../../inputfileds/form_services/lookupcode/lookupcode.service';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from "../../components/toast/toast.component";
import { ToastService } from '../../Services/toast/toast.service';
import { DropdownservicesService } from '../../Services/Apis/Dropdownservices/dropdownservices.service';

@Component({
  selector: 'app-lookupcodes',
  standalone: true,
  imports: [FormsModule, RouterModule, MatIconModule, MatFormFieldModule, MatInputModule, MatButtonModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule, ToastComponent],
  providers: [QuestionControlService, LookupcodeService,ApiservicesService, DropdownservicesService],
  templateUrl: './lookupcodes.component.html',
  styleUrl: './lookupcodes.component.scss'
})
export class LookupcodesComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  errormessage: any;

  constructor(
    private qcs: QuestionControlService, 
    private router: Router, 
    private service: LookupcodeService,
    private toast : ToastService, 
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService
  ) {
    this.questions$ = this.service.getlookupcodeform();
    this.questions$.subscribe(q => this.questions = q);
  }

  ngOnInit() {
    // this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  onSubmit() {
    let datatosend = {
      organization_id: parseInt(this.form.value.organization_id),
      description: this.form.value.description,
      lookup_display_name: this.form.value.lookup_display_name,
      lookup_name: this.form.value.lookup_name,
      lookup_type_id: parseInt(this.form.value.lookup_type_id),
      lookup_type_name: ''
    }
    let filterdata: any
    this.dropdownservice.getlooktypelist().subscribe((res: any) => {
      console.log("id number", this.form.getRawValue().lookup_type_id);
      filterdata = res.find((dropfilter: any) => { return dropfilter.id === parseInt(this.form.value.lookup_type_id) })
      // this.form.get("lookup_type_name")?.setValue(filterdata.lookup_type_name);
      console.log('filter data', filterdata)
      // datatosend.lookup_type_name = filterdata.name;
      // datatosend.push('lookup_type_id', filterdata[0].id);
      datatosend.lookup_type_name = filterdata.name 
      console.log(datatosend);
      this.createAPi(datatosend)
    })
  }

  createAPi(event: any) {
    this.apiservice.createlookupcode(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast(
          'success',
          'Submited Successfuly',);
        this.form.reset();
        this.router.navigate(["/lookupcodeview"])
      }else{
        this.toast.showToast('danger', `Error: ${res.message}`);
        this.errormessage = res.message;
      }
    })
  }
}
