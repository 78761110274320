import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ShiftComponent } from './shifttimings/shift/shift.component';
import { WeekoffComponent } from './weekoff/weekoff/weekoff.component';
import { HolidaysComponent } from './holidays/holidays/holidays.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddshiftComponent } from './shifttimings/addshift/addshift.component';
import { AddholidaysComponent } from './holidays/addholidays/addholidays.component';
import { AddweekoffComponent } from './weekoff/addweekoff/addweekoff.component';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';

@Component({
  selector: 'app-workingdays',
  standalone: true,
  imports: [MatTabsModule, ShiftComponent, WeekoffComponent, HolidaysComponent, MatButtonModule, MatIconModule],
  templateUrl: './workingdays.component.html',
  styleUrls: ['./workingdays.component.scss'],
  providers: [ApiservicesService, ShiftComponent]
})
export class WorkingdaysComponent {

  constructor(public dialog: MatDialog, apiservice: ApiservicesService, private loadshiftdata : ShiftComponent) {   
  }

  openShiftDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '25%';
    dialogConfig.minHeight = '30vh';
    const dialogRef = this.dialog.open(AddshiftComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.loadshiftdata.getShiftdetails();
    });
  }
  openholidayDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '25%' ;
    dialogConfig.minHeight = '30vh' ;
    const dialogRef = this.dialog.open(AddholidaysComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openweekoffDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '25%';
    dialogConfig.minHeight = '30vh';
  
    const dialogRef = this.dialog.open(AddweekoffComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // Refresh the weekoff data if needed
    });
  }
}
