<section class="mx-3">
    <div class="d-flex align-items-center">
        <!-- <div>
            <button mat-icon-button class="back_icon cancel_button me-2" (click)="backnavigation()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div> -->
        <div class="page_header">
            <h4 class="m-0 page_header_title">Leave Summary</h4>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-sm-2 col-md-2">
            <div class="d-flex align-items-center">
                <div>
                    <p class="m-0">Group Actions</p>
                </div>
                <div>   
                    <button mat-icon-button class="action_button" [matMenuTriggerFor]="actionrequest" aria-label="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item class="action_button"
                            (click)="allactionrequestapi()">
                            <span>Allot Leaves</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-10 text-end">
            <div>
                <form>
                    <div class="row m-0">
                        <div *ngFor="let question of questions"
                            class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <app-question [question]="question" [form]="leavesummaryfilterform"></app-question>
                        </div>
                        <!-- <button (click)="submit()">submit</button> -->
                        <div class="mb-3 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <button mat-button class="action_button me-0 pe-0">
                                Filter <img src="../../../../../assets/icons/filter_Icon.svg"/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
           
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; columns: displayedColumns;">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let row">
                    <div class="d-flex align-items-center">
                    <ng-container>
                        <p (click)="gotoemplyeeview(row)" class="mb-0 cursor_pointer view_button">View</p>
                      </ng-container>
                      <ng-container>
                    <button mat-icon-button class="action_button" [matMenuTriggerFor]="actionrequest" aria-label="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item class="action_button"
                            (click)="actionrequestapi(row?.employee_id)">
                            <span>Allot Leaves</span>
                        </button>
                    </mat-menu>
                    </ng-container>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="emp_name">
                <th mat-header-cell *matHeaderCellDef> Employee Name </th>
                <td mat-cell *matCellDef="let element"> {{element.employee_name}} {{element.last_name}} </td>
            </ng-container>

            <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef> Designation </th>
                <td mat-cell *matCellDef="let element"> {{element.designation_name}} </td>
            </ng-container>

            <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef> Department </th>
                <td mat-cell *matCellDef="let element"> {{element.department_name}} </td>
            </ng-container>

            <ng-container matColumnDef="allotted_leaves">
                <th mat-header-cell *matHeaderCellDef> Allotted Leaves </th>
                <td mat-cell *matCellDef="let element"> {{element.total_allocated_leaves}} </td>
            </ng-container>

            <ng-container matColumnDef="used_leaves">
                <th mat-header-cell *matHeaderCellDef> Used </th>
                <td mat-cell *matCellDef="let element"> {{element.total_used_leaves}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="actions" sticky>
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element"> 
                <div>
                    <button  mat-icon-button [matMenuTriggerFor]="actionrequest" aria-label="menu" >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item (click)="actionrequestapi('APPROVED', element)">
                          <span>Approved</span>
                        </button>
                        <button mat-menu-item (click)="actionrequestapi('REJECTED', element)">
                          <span>Rejected</span>
                        </button>
                      </mat-menu>
                </div> 
            </td>
        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
    </div>
    <div class="mt-2" *ngIf="total_count > 5">
        <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
      </div>
</section>