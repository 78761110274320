import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddtimetablesComponent } from "./addtimetables/addtimetables.component";
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { PermissionService } from '../../../components/Authentication/services/permission.service';

export interface TimeTableView {
  index: number;
  course_id: number;
  academic_year: number;
  shift_id: string;
  semister_id: string;
  section: string;
}

@Component({
  selector: 'app-timetables',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    AddtimetablesComponent,
    MatProgressSpinnerModule
  ],
  providers: [ApiservicesService],
  templateUrl: './timetables.component.html',
  styleUrl: './timetables.component.scss'
})
export class TimetablesComponent implements OnInit {

  timetableSlots: any;
  dataSource = new MatTableDataSource<TimeTableView>();
  selection = new SelectionModel<TimeTableView>(true, []);
  showaddflag: boolean = false;
  isLoadingResults = true;
  isRateLimitReached = false;
  // @Input() year?: string;
  // @Input() shifts?: { name: string, time: string }[];
  // showForm: boolean = false;
  displayedColumns: string[] = [
    'course_id',
    'academic_year',
    'shift_id',
    'year',
    'semister_id',
    'section',
    'actions',
  ];
  actiondata: any;

  constructor(
    private apiservice: ApiservicesService,
    private router: Router,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    if (this.actiondata.view === false) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }
  }

  ngOnInit(): void {
    this.getTimeTableSlotslist();
  }

  getTimeTableSlotslist() {
    this.apiservice.gettimetablelist().subscribe((res) => {
      console.log("time table slots", res)
      // this.courseList = res.rows
      this.dataSource = new MatTableDataSource<TimeTableView>(res.rows);
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

  // toggleForm() {
  //   this.showForm = !this.showForm;
  // }

  gettimetablelist() {
    this.apiservice.gettimetablelist().subscribe({
      next: (res: any) => {
        console.log("API Response time table:", res); // Log full response
        if (res && res.rows) {
          this.timetableSlots = res.rows;
          console.log("Updated timeSlots:", this.timetableSlots);
        } else {
          // console.warn('No timeslots found for the given shift_id:', shift_id);
        }
      },
      error: (err) => {
        console.error('Error fetching timeslots:', err)
      },
    });
  }

  gettimetabledata(Event: any) {
    // console.log("event table data", Event);
    this.router.navigate(['timetable/timetableview'], { state: { tabledata: Event } });
  }
  // showtimetablecreation(e:any){
  //   this.showaddflag = true;
  // }
}

