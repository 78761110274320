import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Inputfiledsbase } from '../../../models/inputfiledsbase';
import { TextboxQuestion } from '../../../question-textbox';

@Injectable({
  providedIn: 'root'
})
export class NaacPageService {

  constructor() { }

    getCreatePage(): Observable<Inputfiledsbase<string>[]> {
      const questions: Inputfiledsbase<string>[] = [
        new TextboxQuestion({
          key: 'page_name',
          label: 'Page Name',
          required: true,
          order: 1,
        })
      ];
      return of(questions);
    }
    
}
