import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { StreamfromService } from '../../inputfileds/form_services/streamform/streamfrom.service';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { MatDialog } from '@angular/material/dialog';
import { AddstreamComponent } from './addstream/addstream.component';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { PermissionService } from '../../components/Authentication/services/permission.service';

export interface streamView {
  index: number,
  id: number,
  organization_id: number,
  stream_name: string,
  // category_id: number,
  // category_name: string,
  start_date: Date,
  end_date: Date,
}

@Component({
  selector: 'app-stream',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    PaginationComponent,
    CommonModule
  ],
  providers: [QuestionControlService, StreamfromService, ApiservicesService],
  templateUrl: './stream.component.html',
  styleUrl: './stream.component.scss'
})
export class StreamComponent implements OnInit {

  displayedColumns: string[] = ['stream_name', 'category_name', 'start_date', 'end_date'];
  dataSource = new MatTableDataSource<streamView>();
  selection = new SelectionModel<streamView>(true, []);
  streamdata: any = [];
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  streamList: any;
  total_count: any;
  paginationData: any;
  actiondata: any;

  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private StreamfromService: StreamfromService,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    // if (this.actiondata.view === false) {
    //   this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    // }
   }

  ngOnInit(): void {
    this.getstreamdetails();
  }

  pagination(event: any){
    this.paginationData = event;
    this.getstreamdetails();
  }
  openAddStreamDialog() {
    const dialogRef = this.dialog.open(AddstreamComponent, {
      data: {total_count: this.total_count}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getstreamdetails();
    });
  }

  getstreamdetails() {
    this.apiservice.getstreamlist(this.paginationData).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<streamView>(res.rows);
      this.total_count = res.total_count;
    });
  }

  

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: streamView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

}
