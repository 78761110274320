import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { FrontpageService } from '../../../../../inputfileds/form_services/collegemanagement/frontpage.service';

interface VisionMission {
  uploadDate: string;
  content_type: 'Mission' | 'Vision';
  info: string;
  images: string;
  id: number;
  image_url: string; // Add this line to the interface
  content: string; // Add this line to the interface
}

interface DialogData {
  content_type?: 'missions' | 'visions';
  rowData?: VisionMission;
}

export interface missionVissionModel {
  description: string;
  image_url: File | null;
}

@Component({
  selector: 'app-addmissionvision',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: './addmissionvision.component.html',
  styleUrl: './addmissionvision.component.scss',
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class AddmissionvisionComponent implements OnInit {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  missionVissionForm!: FormGroup;
  datesend!: missionVissionModel;
  missionVisiondata: any = {};
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;  // Changed type
  file: File | null = null;                               // Changed type
  isButtonLinkEnabled: boolean = false;
  isEditMode: boolean = false;  // Add this line


  constructor(
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddmissionvisionComponent>,
    private dropdownservice: DropdownservicesService,
    Noticesservices: FrontpageService,
    private qcs: QuestionControlService,
    private apiservice: ApiservicesService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData  // Corrected type
  ) {
    this.questions$ = Noticesservices.getMissionVissionForm();
  }

  ngOnInit(): void {
    this.questions$?.subscribe((q) => {
      this.questions = q!; // Assertion operator
      this.cdr.detectChanges();
    });

    this.isEditMode = !!this.data.rowData;
    console.log("data vision mission", this.data.rowData?.content_type)

    this.missionVissionForm = this.fb.group({
      content_type: [this.data.content_type || '', Validators.required],
      description: [this.data.rowData?.content || '', Validators.required],
      image_url: [null] // Remove initial value
    });

    if (this.data.rowData?.image_url) {
      this.selectedImageUrl = `https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/${this.data.rowData.image_url}`;
    }
  }

  onSave(): void {
    const formData = this.missionVissionForm.value;
    this.dialogRef.close(formData);
  }


  onSubmit() {
    if (this.missionVissionForm.invalid) {
      console.error('Form is invalid. Please fix the errors and try again.');
      return;
    }

    const formData = new FormData();
    formData.append('content', this.missionVissionForm.value.description);
    formData.append('content_type', this.missionVissionForm.value.content_type);

    if (this.file) {
      formData.append('image_url', this.file);
    }

    if (this.isEditMode) {
      this.updateMissionVission(formData);
    } else {
      this.createMissionVissionData(formData);
    }
  }



  createMissionVissionData(formData: FormData) {
    this.apiservice.createMissionVission(formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.missionVisiondata = res.data;
          this.missionVissionForm.reset();
          this.closeDialog(true);
        } else {
          console.error(
            'Error creating mission/vision:',
            res.message || 'Unknown error'
          );
        }
      },
      (error: any) => {
        console.error('Error Creating mission/vision:', error);
      }
    );
  }


  updateMissionVission(formData: FormData) {
    if (!this.data.rowData) {
      console.error('Invalid data: rowData or rowData._id is missing.');
      return;
    }
  
    this.apiservice.updateMissionVission(this.data.rowData.id, formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.missionVisiondata = res.data;
          this.missionVissionForm.reset();
          this.closeDialog(true);
        } else {
          console.error('Error updating mission/vision:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error during edit:', error);
      }
    );
  }


  imageInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files?.length) {
      this.file = input.files[0];  // Store the file
      const reader = new FileReader();

      reader.onload = (e) => {
        this.selectedImageUrl = reader.result as string;
        this.cdr.detectChanges();   // Trigger change detection
      };

      reader.readAsDataURL(this.file);
    }
  }

  closeDialog(success: boolean) {
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    this.dialogRef.close(success); // Pass success status
  }
}
