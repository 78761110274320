import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectiveservicesService {
  sub_organizationdata:any;
  academic_calendar_data :any;
  start_leave_date: any;
  number_of_selected_days:any;

  constructor() { }

  get_selected_sub_organization(){
    if(sessionStorage.getItem("SubOrgData")){
      this.sub_organizationdata = sessionStorage.getItem("SubOrgData");
      return JSON.parse(this.sub_organizationdata);
    }else{
      return undefined;
    } 
  }

  get_academic_calendar_data(){
    return this.academic_calendar_data;
  }

  get_number_of_selected_days(){
    return this.number_of_selected_days;
  }

  get_start_leave_date(){
    return this.start_leave_date;
  }
}
