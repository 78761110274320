<section class="m-4 p-3">
  <h2 class="m-0 pb-2 ">Add Notice</h2>
  <mat-divider></mat-divider>

  <form [formGroup]="noticeForm" class="pt-3">
    <div class="row">
      <!-- Left Column -->
      <div class="col-md-6 my-2">
        <app-question
          *ngFor="let question of questions.slice(0, 2)"
          [question]="question"
          [form]="noticeForm"
        ></app-question>
      </div>

      <!-- Right Column (Image Upload) -->
      <div class="col-md-6 d-flex justify-content-center align-items-center">
        <div class="upload-container text-center p-3 rounded shadow-sm border">
          <input
            #fileInput
            hidden
            type="file"
            (change)="imageInputChange($event)"
          />
          <div class="upload-box" (click)="fileInput.click()">
            <ng-container *ngIf="!selectedImageUrl">
              <img
                src="../../../../../../assets/images/bulkupload_logo.svg"
                alt="Upload Banner"
                class="upload-icon"
              />
              <p class="text-muted mt-2">Click to upload an image</p>
            </ng-container>
            <ng-container *ngIf="selectedImageUrl">
              <img
                [src]="selectedImageUrl"
                alt="Selected Image"
                class="uploaded-image rounded"
              />
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12">
        <app-question
          *ngFor="let question of questions.slice(2, 4)"
          [question]="question"
          [form]="noticeForm"
        ></app-question>
      </div>

      <!-- Toggle for Button -->
      <div class="col-12 d-flex align-items-center mb-3">
        <mat-slide-toggle
          color="primary"
          [checked]="isButtonLinkEnabled"
          (change)="onToggleChange($event)"
        >
          Need Button?
        </mat-slide-toggle>
      </div>

      <!-- Button Text & Link -->
      <div class="col-md-6 mb-3" *ngIf="isButtonLinkEnabled">
        <label for="button_text">Button Text</label>
        <input
          id="button_text"
          type="text"
          matInput
          formControlName="button_text"
          placeholder="Enter button text"
          class="form-control"
        />
      </div>
      <div class="col-md-6 mb-3" *ngIf="isButtonLinkEnabled">
        <label for="button_link">Button Link</label>
        <input
          id="button_link"
          type="text"
          matInput
          formControlName="button_link"
          placeholder="Enter button link"
          class="form-control"
        />
      </div>
    </div>
    <mat-dialog-actions align="center" class="bg-white p-3 rounded">
      <div class="d-flex gap-3">
        <button
          mat-stroked-button
          (click)="closeDialog(false)"
          class="cancel_button px-4"
        >
          Cancel
        </button>
        <button
          mat-raised-button
          color="primary"
          class="submit_button px-4"
          type="submit"
          [disabled]="!noticeForm.valid"
          (click)="onSubmit()"
        >
          Submit
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</section>