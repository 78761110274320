import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { CreatePageDialogComponent } from './createpagedialogcomponent/createpagedialogcomponent.component';
import { Router } from '@angular/router';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';


@Component({
  selector: 'app-activitiespageview',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule
  ],
  templateUrl: './activitiespageview.component.html',
  styleUrls: ['./activitiespageview.component.scss'],
})
export class ActivitiesPageViewComponent implements OnInit {
  displayedColumns: string[] = ['id', 'page_name', 'createdAt', 'actions'];
  activitiesData:any = [];
  constructor(private dialog: MatDialog, private router: Router,private apiServices: ApiservicesService) {}

  ngOnInit(): void {
    this.getActivitiesData()
  }

  openCreatePageDialog(): void {
    const dialogRef = this.dialog.open(CreatePageDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getActivitiesData()
      if (result) {
        const newActivity = {
          id: this.activitiesData.length + 1,
          sub_menu: result,
          status: 'active',
          image_url: '',
          data_json: []
        };
        this.activitiesData;
      }
    });
  }
  
  editItem(element: any): void {
    
    const dialogConfig = this.dialog.open(CreatePageDialogComponent, {
      minHeight: '65vh',
      maxHeight: '65vh',
      maxWidth: '70vw',
      minWidth: '70vw',
      data: {
        activityData: element, // Selected activity data
        isEditMode: true,
      },
    });
  
    dialogConfig.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getActivitiesData(); // Refresh the data
      }
    });
  }
  
  onDelete(itemId: number): void {
    if (confirm('Are you sure you want to delete this item?')) {
      this.apiServices.deleteActivitiesPage(itemId).subscribe({
        next: (response) => {
          console.log('Item deleted successfully:', response);
          this.getActivitiesData();
        },
        error: (err) => { 
          console.error('Error deleting item:', err);
        },
      });
    }
  }
  
  

  navigateToActivityPage(id: number, data: any): void {
    this.router.navigate([`/activities/${id}`], { state: { activityData: data } });
  }

  
  getActivitiesData() {
    this.apiServices.getActivitiesPagelist().subscribe(
      (data: any) => {
        this.activitiesData = data.rows;
      },
      (error) => {
        console.error('Error fetching activities data:', error);
        alert('Failed to load activities. Please try again later.');
      }
    );
  }
  

}
