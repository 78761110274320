import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormsModule
} from '@angular/forms';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Observable, Subscription, map } from 'rxjs';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { LeavesummaryService } from '../../../../inputfileds/form_services/leavesummary/leavesummary.service';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { MatCardModule } from '@angular/material/card';
import { RemoveUnderscorePipe } from '../../../../components/Utilites/Pipe/remove-underscore.pipe';
import { StatusColorDirective } from '../../../../components/Utilites/Directives/status-color.directive';
import { ParseIntPipePipe } from '../../../../components/Utilites/Pipe/parse-int-pipe.pipe';
import { LeavecardcolorDirective } from '../../../../components/Utilites/Directives/leavecardcolor.directive';

@Component({
  selector: 'app-leave-history',
  standalone: true,
  imports: [
    DynamicFormQuestionComponent,
    MatTableModule,
    MatCheckboxModule,
    CommonModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    RemoveUnderscorePipe,
    StatusColorDirective,
    ParseIntPipePipe,
    LeavecardcolorDirective
  ],
  templateUrl: './leave-history.component.html',
  styleUrl: './leave-history.component.scss',
  providers: [ApiservicesService, QuestionControlService]
})
export class LeaveHistoryComponent {

  leaverequestdata: any = [];
  displayedColumns: string[] = ['start_date', 'end_date', 'duration', 'leave_name', 'status']; //'select' for adding checkbox, 
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  requesttype: any;
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  leavesummaryfilterform!: FormGroup;
  private subscription: Subscription | undefined;
  datepipe = new DatePipe('en-US');
  apirequest: any;
  leavesavailability :any;

  ngOnInit(): void {
    this.leavesummaryfilterform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );

    this.leavesummaryfilterform.controls['start_date'].setValue(this.datepipe.transform(new Date, 'yyyy-MM-dd'));
    this.leavesummaryfilterform.controls['end_date'].setValue(this.datepipe.transform(new Date, 'yyyy-MM-dd'));
    this.leavesummaryfilterform.controls['leave_type_id'].setValue("0");

    this.subscription = this.services.notifyleavehistoryComponent$.subscribe(() => {
      this.getrequestdata()  // Call the component function
    });

    this.getrequestdata()
  }

  constructor(
    private apisevice: ApiservicesService,
    private qcs: QuestionControlService,
    private router: Router,
    public aRoute: ActivatedRoute,
    private services: LeavesummaryService,
  ) {
    this.questions$ = this.services.getleavehistoryform();
    this.questions$.subscribe((q) => (this.questions = q));
    // this.requestservice.getleaverequestcontrol.subscribe(req =>{
    //   this.requesttype = req
    // });
    this.requesttype = this.aRoute.snapshot.queryParamMap.get('dataId')
    console.log('requst from url', this.requesttype);
    // if (typeof this.requesttype === undefined) {
    //   this.backnavigation()
    // } else {
    //   this.getrequestdata()
    // }
    
    // this.getrequestdata()
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();  // Clean up the subscription
    }
  }

  getrequestdata() {
    this.apirequest = {
      'start_date': this.datepipe.transform(this.leavesummaryfilterform.value?.start_date, 'yyyy-MM-dd'),
      'end_date': this.datepipe.transform(this.leavesummaryfilterform.value?.end_date, 'yyyy-MM-dd'),
      'employee_id': this.requesttype,
      'leave_type_id': this.leavesummaryfilterform.value?.leave_type_id
    }
    this.apisevice.getleavehistory(this.apirequest).subscribe((res) => {
      this.leaverequestdata = res;  
      this.leavesavailability = res.leaves_availability; 
      this.leavesavailability.map((items:any, index:number) => {
        this.leavesavailability[index].total_leaves = parseFloat(items.used_leaves) + parseFloat(items.available_leaves)
      }) 
      this.dataSource = new MatTableDataSource<any>(res?.employee_leaves)
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  isSticky(column: string): boolean {
    return column === 'col6' ? true : false;
  }

  actionrequestapi(event: any, id: number) {
    const actionRequest = {
      "status": event
    }
    this.apisevice.actionleaverequest(actionRequest, id).subscribe((res) => {
      if (res.code == 204 && res.status === true) {
        this.getrequestdata();
      }
    })
  }

  backnavigation() {
    this.router.navigate(['/leavesummary']);
  }

}
