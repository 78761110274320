<div class="mx-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="left-side">
        <h2 class="m-0">NAAC Details Page</h2>
      </div>
      <button
        mat-raised-button
        color="primary"
        class="submit_button"
        (click)="openDynamicPageCreationDialog()"
      >
        Add NAAC Details Page
      </button>
    </div>
  
    <div class="mt-2">
      <div *ngIf="naacpageData.length === 0" class="no-data">
        <p>No activities found. Click "Add NAAC Details Page" to add one.</p>
      </div>
      <div class="activities-table">
        <div class="activities-card" *ngIf="naacpageData.length > 0">
          <mat-table [dataSource]="naacpageData" class="mat-elevation-z8">
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef>Sl. No.</mat-header-cell>
              <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="page_name">
              <mat-header-cell *matHeaderCellDef>Page Name</mat-header-cell>
              <mat-cell *matCellDef="let element">{{
                element.title
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <mat-header-cell *matHeaderCellDef>Created At</mat-header-cell>
              <mat-cell *matCellDef="let element">{{
                element.createdAt | date: 'dd/MM/yyyy'
              }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="
                      navigateToActivityPage(element.id, element.data_json)
                    "
                  >
                    <mat-icon>visibility</mat-icon> View
                  </button>
                  <button mat-menu-item><mat-icon>edit</mat-icon> Edit</button>
                  <button mat-menu-item>
                    <mat-icon>delete</mat-icon> Delete
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>
        </div>
        <ng-template #noDataTemplate>
          <div class="no-data">
            <p>No activities found. Click "Add NAAC Details Page" to add one.</p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>