<section class="m-4 p-4">
    <h2 class="m-0 pb-2">Add Testimonials</h2>
    <mat-divider></mat-divider>
  
    <div class="row mt-4">
      <!-- Form Section on Left -->
      <div class="col-md-7">
        <form [formGroup]="testimonialsdata" class="pe-3">
          <div *ngFor="let question of questions" class="mb-3">
            <app-question
              [question]="question"
              [form]="testimonialsdata"
            ></app-question>
          </div>
        </form>
      </div>
  
      <!-- Video Upload Section on Right -->
      <div class="col-md-5 d-flex justify-content-center align-items-center">
        <input
          type="file"
          accept="video/*"
          (change)="videoInputChange($event)"
          #fileInput
          hidden
        />
        <div class="upload-container text-center p-3 rounded shadow-sm border" (click)="fileInput.click()">
          <ng-container *ngIf="!selectedVideoUrl">
            <img
              src="../../../../../../assets/images/bulkupload_logo.svg"
              alt="Upload Banner"
              class="upload-icon"
            />
            <p class="text-muted mt-2">Click to upload a testimonial video</p>
          </ng-container>
      
          <ng-container *ngIf="selectedVideoUrl"  >
            <div class="video-preview" (mouseover)="showReplaceButton = true" 
            (mouseleave)="showReplaceButton = false">
              <video 
                [src]="selectedVideoUrl" 
                controls 
                class="selected-video rounded shadow-sm"
              ></video>
              
              <!-- Replace Button shown on hover -->
              <button *ngIf="showReplaceButton" 
                      class="replace-video-btn">
                Replace Video
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      
    </div>
  
    <!-- Dialog Actions -->
    <mat-dialog-actions align="center" class="bg-white p-3 rounded mt-3">
      <div class="d-flex gap-3">
        <button
          mat-stroked-button
          (click)="closeDialog(false)"
          class="cancel_button px-4"
        >
          Cancel
        </button>
        <button
          mat-raised-button
          color="primary"
          class="submit_button px-4"
          type="submit"
          (click)="onSubmit()"
          [disabled]="!testimonialsdata.valid"
        >
          Submit
        </button>
      </div>
    </mat-dialog-actions>
  </section>
  