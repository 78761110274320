import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { Observable } from 'rxjs';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StreamfromService } from '../../../inputfileds/form_services/streamform/streamfrom.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { StreamComponent } from '../stream.component';

@Component({
  selector: 'app-addstream',
  standalone: true,
  imports: [
    ReactiveFormsModule, 
    CommonModule, 
    MatButtonModule, 
    DynamicFormQuestionComponent,
    StreamComponent
  ],
  templateUrl: './addstream.component.html',
  styleUrl: './addstream.component.scss',
  providers: [QuestionControlService, StreamfromService, ApiservicesService, DropdownservicesService]
})
export class AddstreamComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  datasend: any;

  constructor(
    private dialogRef: MatDialogRef<AddstreamComponent>, 
    private apiservice: ApiservicesService, 
    private dropdownservice : DropdownservicesService,
    public services: StreamfromService, 
    private router: Router,
    private qcs: QuestionControlService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.questions$ = services.getStreamFormServices();
    this.questions$.subscribe(q => this.questions = q);
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.datasend = this.form.value;
    let filterdata: any
    this.datasend.category_id = parseInt(this.datasend.category_id);
    // this.datasend.organization_id = parseInt(this.datasend.organization_id);
    this.dropdownservice.getlookupcodesdropdown('STREAMS_CATEGORIES').subscribe((res: any) => {
      filterdata = res.find((dropfilter: any) => { return dropfilter.id === parseInt(this.form.value.category_id) })
      // this.form.get("lookup_type_name")?.setValue(filterdata.lookup_type_name);
      this.datasend = { ...this.datasend, category_name: filterdata.lookup_name }
      this.createAPi(this.datasend)
    })
  }
  createAPi(event: any) {
    this.apiservice.createstreams(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        if(this.data.total_count == 0 ){
          this.apiservice.onboarding_status_updation({"module_name":"COLLEGE_MANAGEMENT_SYSTEM","sub_module_name":"STREAMS"}).subscribe();
        }
        this.form.reset();
        this.dialogRef.close();
      }
    })
  }
}

