import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { AddmissionvisionComponent } from './addmissionvision/addmissionvision.component';
import { MatSnackBar } from '@angular/material/snack-bar';

interface VisionMission {
  uploadDate: string;
  content_type: 'missions' | 'visions';
  info: string;
  images: string;
  id: number;
  image_url: string;
  content: string;
}

@Component({
  selector: 'app-vision-mission',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  templateUrl: './vision-mission.component.html',
  styleUrls: ['./vision-mission.component.scss'],
})
export class VisionMissionComponent implements OnInit {
  @Input() apiEndpoint!: string; // API endpoint for fetching data
  @Input() heading!: string; // Heading for the component
  @Input() createApiEndpoint!: string; // API endpoint for creating data
  @Input() updateApiEndpoint!: string; // API endpoint for updating data

  displayedColumns: string[] = [
    'uploadDate',
    'content_type',
    'info',
    'images',
    'actions',
  ];

  VisionMissionData: VisionMission[] = [];

  imagePath = this.ApiService.environmentPath;

  constructor(
    private ApiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getMissionVisionData();
  }

  openUploadDialog(
    contentType?: 'missions' | 'visions',
    rowData: VisionMission | null = null
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = [
      'dialog-bottom-enter',
      'dialog-bottom-leave',
      'animate__animated',
    ];
    dialogConfig.minWidth = '60vw';
    dialogConfig.minHeight = '80vh';
    dialogConfig.maxHeight = '80vh';

    dialogConfig.data = {
      content_type: contentType,
      rowData,
      createApiEndpoint: this.createApiEndpoint, // Pass create endpoint
      updateApiEndpoint: this.updateApiEndpoint, // Pass update endpoint
    };

    const dialogRef = this.dialog.open(AddmissionvisionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getMissionVisionData();
      }
    });
  }

  EditopenUploadDialog(
    contentType: 'missions' | 'visions',
    rowData: VisionMission | null = null
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = [
      'dialog-bottom-enter',
      'dialog-bottom-leave',
      'animate__animated',
    ];
    dialogConfig.minWidth = '60vw';
    dialogConfig.minHeight = '80vh';
    dialogConfig.maxHeight = '80vh';

    dialogConfig.data = {
      content_type: contentType,
      rowData,
      createApiEndpoint: this.createApiEndpoint, // Pass create endpoint
      updateApiEndpoint: this.updateApiEndpoint, // Pass update endpoint
    };

    const dialogRef = this.dialog.open(AddmissionvisionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getMissionVisionData();
      }
    });
  }

  getMissionVisionData(): void {
    this.ApiService.getMissionVissionlist(this.apiEndpoint).subscribe({
      next: (data: any) => {
        this.VisionMissionData = data.rows;
      },
      error: (error) => {
        console.error('Error fetching vision/mission data:', error);
        this.snackBar.open('Error loading data. Please try again.', 'Close', {
          duration: 5000,
        });
      },
    });
  }
}
