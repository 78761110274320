import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Inputfiledsbase } from '../../../models/inputfiledsbase';
import { DropdownQuestion } from '../../../question-dropdown';
import { TextboxQuestion } from '../../../question-textbox';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { ObjectDropdownQuestion } from '../../../question-objectdropdown';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { MultiSelectQuestion } from '../../../question-multiselect';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  organisationdata: any = [];
  semisterdata:any = [];
  sectiondata:any = [];
  regsectiondata:any = [];
  academicyeardropdata:any = []; 
  coursewisesemisterdata:any = []; 
  selectedcourse_id:number | undefined; 
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private courselistSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  courselist$: Observable<any[]> = this.courselistSubject.asObservable();
  assignsectiondata: any = [];
  selectedacademic_regulation_id: any;
  // sectiondatafromcomponent: Observable<any[]> | undefined;
  private sectiondatafromcomponent: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  sectiondatafromcomponent$: Observable<any[]> = this.sectiondatafromcomponent.asObservable();


  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {

    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('SEMISTER', this.semisterdata);
    this.loadDropdownData('SECTIONS', this.sectiondata);

    this.apiservice.getcourseslist().subscribe((res) => {
      const courselist = res.rows.map((course: any) => ({
        'course_ID': course.id,
        'name': course.course_name
      }));
      this.courselistSubject.next(courselist);
      console.log('Course List:', this.courselistSubject);
    });
    // this.apiservice.getsemisterdetails().subscribe((res) => {
    //   const semisterlist = res.semister_number;
    // });
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.dropdownservice.getorganizationdrop();
    } else if(code === 'SEMISTER'){
      observable = this.dropdownservice.getsemisterdrop();
    } else if(code === 'COURSEWISESEMISTER'){
      observable = this.dropdownservice.getcoursewisesemesterdrop(this.selectedcourse_id);
    }
    else if(code === 'ACADEMICYEAR'){
      observable = this.dropdownservice.getcoursewiseacademicyeardrop({course_id:this.selectedcourse_id, academic_regulation_id: this.selectedacademic_regulation_id});
    } 
    else if(code === 'REGSECTIONS'){
      observable = this.sectiondatafromcomponent;
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ORGANISATION') {
          lookupname = res[i]?.short_name;
        } else if (code === 'SEMISTER' || 'SECTIONS' || 'REGSECTIONS') {
          console.log("semester drop", this.coursewisesemisterdata)
          lookupname = res[i]?.name;
        } else {
          lookupname = res[i]?.lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    console.log('hit method from semester details', this.dataSubject);
    return this.dataSubject.asObservable();
  }

  onsemisterChange(selectedValue: any): void {
    console.log("sections", selectedValue)
        for (let i = 0; i < selectedValue?.length; i++) {
          if(selectedValue[i].id !== '' && selectedValue[i].name !== ''){
          this.sectiondata?.push({ id: selectedValue[i]?.id, key: selectedValue[i]?.name, value: selectedValue[i]?.id })
        }}
  }

  filtersections(selectedValue: any): void {
    console.log("sections", selectedValue)
        for (let i = 0; i < selectedValue?.length; i++) {
          if(selectedValue[i].id !== '' && selectedValue[i].name !== ''){
          this.assignsectiondata?.push({ id: selectedValue[i]?.id, key: selectedValue[i]?.name, value: selectedValue[i]?.name })
        }}
  }

  filtersectionsdata(selectedValue: any): void {
    console.log("sections", selectedValue)
        for (let i = 0; i < selectedValue?.length; i++) {
          if(selectedValue[i].id !== '' && selectedValue[i].name !== ''){
          this.regsectiondata?.push({ id: selectedValue[i]?.id, key: selectedValue[i]?.name, disable:selectedValue[i].is_selected, value: selectedValue[i]?.name })
        }}
  }


  getRegulationstudentfilterForm(event:any): Observable<Inputfiledsbase<string>[]> { 
    // academic_year_id
    console.log("regulation section view", event); 
    this.selectedcourse_id = event?.course_id;
    this.selectedacademic_regulation_id = event?.academic_regulation_id;
    this.loadDropdownData('COURSEWISESEMISTER', this.coursewisesemisterdata);
    this.loadDropdownData('ACADEMICYEAR', this.academicyeardropdata);
    this.onsemisterChange(event?.sections);
    return new Observable((observer) => {

      const createquestions: Inputfiledsbase<string>[] = [
          new DropdownQuestion({
            key: 'academic_year_id',
            label: 'Academic Year',
            required: true,
            options: this.academicyeardropdata,
            order: 1,
          }),
          new DropdownQuestion({
            key: 'semister_id',
            label: 'Semister',
            required: true,
            options: this.coursewisesemisterdata,
            order: 2,
          }),
          new DropdownQuestion({
            key: 'section_id',
            label: 'Section',
            required: true,
            options: this.sectiondata,
            order: 3,
          }),
        ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
      })
  }

  getCreateSectionForm(): Observable<Inputfiledsbase<string>[]> {
    return new Observable((observer) => {
      const createquestions: Inputfiledsbase<string>[] = [
        
        new DropdownQuestion({
          key: 'organization_id',
          label: 'Organization',
          required: true,
          options: this.organisationdata,
          order: 1,
        }),

        new TextboxQuestion({
          key: 'sequence',
          label: 'Sequence',
          type: 'number',
          required: true,
          // patterns: '^\\d{1,2}(\\.\\d{1,2})?$',
          order: 2,
        }),

        new TextboxQuestion({
          key: 'name',
          label: 'Section Name',
          required: true,
          order: 3,
        }),
      ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }

  getCreatebatchSectionForm(Sectiondata:any): Observable<Inputfiledsbase<string>[]> {
    // this.loadDropdownData('REGSECTIONS', this.regsectiondata);
    // this.sectiondatafromcomponent = Sectiondata;
    // this.sectiondatafromcomponent.next(Sectiondata)
    this.regsectiondata = []
    this.filtersectionsdata(Sectiondata);
    return new Observable((observer) => {
      const createquestions: Inputfiledsbase<string>[] = [

        new MultiSelectQuestion({
          key: 'section_id',
          label: 'Section',
          required: true,
          options: this.regsectiondata,
          order: 1,
        })

      ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }

  getassigntoSectionForm(event:any): Observable<Inputfiledsbase<string>[]> {
    console.log("section fiter fuction working", event)
    this.assignsectiondata = [];
    this.filtersections(event?.sections);
    return new Observable((observer) => {
      const createquestions: Inputfiledsbase<string>[] = [

        new DropdownQuestion({
          key: 'section_name',
          label: 'Section',
          required: true,
          options: this.assignsectiondata,
          order: 1,
        })

      ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }
}
