<div class="pb-5">
  <app-toast></app-toast>
  <div class="mat-typography">
    <div class="d-flex align-items-center">
      <div>
        <button mat-icon-button class="back_icon me-2" (click)="backtocourse()"><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div>
        <h4 class="m-0">Add Semesters</h4>
      </div>
    </div>
    <mat-divider class="mt-2"></mat-divider>
    <div *ngIf="errormessage" class="mt-2">
      {{errormessage}}
    </div>
    <mat-accordion class="example-headers-align">
      <div *ngFor="let semester of courseData.semesters; let i=index">
        <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i, semester)">
          <mat-expansion-panel-header>
            <mat-panel-title>
             {{semester?.year}} - year
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <div class="card p-2 bg-card-color border-0 m-2" *ngFor="let yearsemesters of yearwisesemesterdata">
              <div class="row align-items-center">
                <div class="col-sm-4">
                  <p class="m-0">Semester : {{yearsemesters?.semister}}</p>
                </div>
                <div class="col-sm-4">
                  <p class="m-0">Duration (in_months) : {{yearsemesters?.duration_in_months}}</p>
                </div>
                <div class="col-sm-4 d-flex justify-content-end align-items-center">
                  <!-- <button mat-raised-button color="primary" (click)="editsemester(yearsemesters)" class="px-3" type="submit">
                    Edit
                  </button> -->
                  <mat-icon aria-hidden="false" aria-label="Edit icon" color="blue" (click)="editsemester(yearsemesters)" fontIcon="mode_edit"></mat-icon>
                  <mat-icon aria-hidden="false" aria-label="Edit icon" color="red" (click)="deletesemester(yearsemesters)" fontIcon="delete"></mat-icon>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="yearwisesemesterdata.length < 2 || remainingSemestersCount > 0">
          <div class="mt-3">
            <form [formGroup]="semisterForm">
              <div class="row m-0">
                <div *ngFor="let question of questions"
                  class="mb-3 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                  <app-question [question]="question" [form]="semisterForm"></app-question>
                </div>
                <div *ngIf="!editflag" class="col-sm-3 d-flex justify-content-end align-items-center">
                  <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5" type="submit"
                    [disabled]="!semisterForm.valid">
                    Save
                  </button>
                </div>
                <div *ngIf="editflag" class="col-sm-3 d-flex justify-content-end align-items-center">
                  <button mat-raised-button color="primary" (click)="EditAPi()" class="px-5" type="submit"
                    [disabled]="!semisterForm.valid">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ng-container>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  <!-- <mat-dialog-actions align="end" class="w-100 bg-white position-absolute bottom-0 end-0">
    <div class="form-row">
      <button mat-raised-button (click)="closeDialog(false)" class="px-5">
        Cancel
      </button>
      <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5" type="submit"
        [disabled]="!semisterForm.valid">
        Save
      </button>
    </div>
  </mat-dialog-actions> -->
</div>