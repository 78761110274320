import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { SemestersService } from '../../../../../inputfileds/form_services/semesters/semesters.service';
import { AddacademicregulationsComponent } from '../../addacademicregulations/addacademicregulations.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SectionsService } from '../../../../../inputfileds/form_services/academics/sections/sections.service';
import { ToastService } from '../../../../../Services/toast/toast.service';
import { ToastComponent } from '../../../../../components/toast/toast.component';

export interface semestermodel {
  organization_id:  number;
  course_id: number;
  semister: any;
  duration_in_months: any;
  year:Number;
}

@Component({
  selector: 'app-addsections',
  standalone: true,
  imports: [
    AddacademicregulationsComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatExpansionModule,
    ToastComponent,
  ],
  providers: [QuestionControlService, SemestersService, ApiservicesService],
  templateUrl: './addsections.component.html',
  styleUrl: './addsections.component.scss'

})
export class AddsectionsComponent {

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  sectionForm!: FormGroup;
  datesend!: semestermodel;
  errormessage: any;
  yearwisesemesterdata:any = [];
  yearsemester:any;
  step = 0;
  console = console;
  @Input() courseData: any;
  @Output() backtocourseform: EventEmitter<any> = new EventEmitter();
  editflag: boolean = false;
  selectededit_id: any;

  constructor(private dialogRef: MatDialogRef<AddacademicregulationsComponent>, private apiservice: ApiservicesService, services: SectionsService, private router: Router,private toast : ToastService,
    private qcs: QuestionControlService
  ) {
    this.questions$ = services.getCreateSectionForm();
    this.questions$.subscribe(q => this.questions = q);
  }

  ngOnInit(): void {
    this.sectionForm = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  backtocourse() {
    this.backtocourseform.emit();
  }

  closeDialog(event: any) {
    // this.dialogRef.close();
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
    setTimeout(() => { this.dialogRef.close(event); }, 1000);
  }

  setStep(index: number, yearsemester: any) {
    this.step = index;
  }

  editsemester(editdata:any){
    this.editflag = true;
    this.selectededit_id = editdata.id;
    this.sectionForm.setValue({organization_id:editdata.organization_id, semister:editdata.semister, duration_in_months:editdata.duration_in_months})
   
    // this.EditAPi(this.datesend, editdata.id)
  }
  onSubmit() {
    this.datesend = this.sectionForm.getRawValue();
    // this.datesend.course_id = parseInt(this.courseData.id);
    // this.datesend.semister = parseInt(this.sectionForm.value.semister);
    // this.datesend.duration_in_months = parseInt(this.sectionForm.value.duration_in_months);
    // this.datesend.year = parseInt(this.yearsemester);
    this.createAPi(this.datesend)

  }

  createAPi(event: any) {
    this.apiservice.createsections(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast(
          'success',
          'Saved Successfuly',);
         
        this.sectionForm.reset();
        this.closeDialog(true);
      } else{
        this.toast.showToast('danger', `Error: ${res.message}`);
        this.errormessage = res.message;
      }
    })
  }

  EditAPi() {
    this.datesend = this.sectionForm.getRawValue();
    this.datesend.semister = parseInt(this.sectionForm.value.semister);
    this.datesend.duration_in_months = parseInt(this.sectionForm.value.duration_in_months);
    this.apiservice.Editsemester(this.datesend, this.selectededit_id).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.sectionForm.reset();
        // window.location.href = this.router.url;
        // this.closeDialog(true);
      } else {
        this.errormessage = res.message;
      }
    })
  }
}
