<section>
    <div style="position: relative;">
    <div class="d-flex justify-content-center mx-3">
        <img src="../../../../assets/error/401_unauthorized_access.jpg" alt="404 image" class="img-fluid" />
    </div>
    <div class="text-center" style="position: absolute; top: 40%; left: 45%;">
        <div>
            <h4>Unauthorized</h4>
            <p>No access for the page</p>
        </div>
        <div>
            <button mat-raised-button [routerLink]="['/dashboard']">Back to Home</button>
        </div>
    </div>
</div>
</section>