<section class="p-3">
    <div class="position-relative pt-2">
        <div class="d-flex justify-content-between">
            <div>
                <h2>Apply Comp-Off</h2>
            </div>
            <div class="cursor_pointer" (click)="Canceldailog()">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form">
            <ng-container>
                <div class="container pt-3">
                    <form (ngSubmit)="onSubmit()" [formGroup]="form">
                        <div class="row">
                            <div *ngFor="let question of questions" [ngClass]="question?.position === 'FULL' ? 'col-sm-12 col-md-12 col-lg-12 col-12 mb-3': 'col-sm-6 col-md-6 col-lg-6 col-12 mb-3'">
                                <app-question [question]="question" [form]="form"></app-question>
                            </div>
                        </div>
                        <div>
                            <p class="text_color_light ">Max Comp-offs Available: 2</p>
                        </div>
                        <div class="form-row mt-5">
                            <button mat-flat-button color="primary" class="px-5 submit_button w-100" type="submit" [disabled]="!form.valid">
                                Apply Comp-off
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</section>