import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, skip } from 'rxjs/operators';
import { SettingServiceService } from '../setting-service.service';
// import { json } from 'stream/consumers';
import { environment } from '../../../environments/environment.prod';
// import { format } from 'path';
import { ToastService } from '../toast/toast.service';
import { AuthenticationService } from '../../components/Authentication/services/authentication.service';
import { Router } from '@angular/router';
import { ConnectiveservicesService } from './Connectiveservices/connectiveservices.service';
import { param } from 'jquery';
@Injectable({
  providedIn: 'root',
})
export class ApiservicesService {
  // public readonly imageMaxSize = 1024 * 1024;
  // public readonly imageDymSizes = {
  //   banner: { width: 1920, height: 720 },
  //   notice: { width: 580, height: 380 },
  //   missionVision: { width: 430, height: 320 },
  // };

  // public readonly videoMaxSize = 10 * 1024 * 1024;
  // public readonly videoDymSizes = {
  //   testimonial: { width: 1920, height: 1080 },
  //   small: { width: 1280, height: 720 },
  // };
  public readonly environmentPath = `${environment.ASSET_URL}${environment.ASSET_CONTAINER}`;
  private userdata: any;
  private userdatta: any;
  public demodata: Observable<Object> | undefined;
  private header: any;
  private fileheader: any;
  public selected_sub_org: any;
  constructor(
    private http: HttpClient,
    private connectiveservice: ConnectiveservicesService,
    private router: Router,
    private toast: ToastService,
    private AuthenticationService: AuthenticationService
  ) {
    if (typeof sessionStorage !== 'undefined') {
      this.userdata = JSON.parse(
        `${
          typeof sessionStorage !== 'undefined'
            ? sessionStorage.getItem('user')
            : null
        }`
      );
      this.userdatta = this.userdata;
      this.selected_sub_org =
        this.connectiveservice.get_selected_sub_organization();
      this.header = {
        token: this.userdatta?.data.token,
        'content-type': 'application/json',
      };
      this.fileheader = {
        token: this.userdatta?.data.token,
      };
    } else {
      console.warn('sessionStorage is not available in this environment');
      this.userdata = null;
      this.userdatta = null;
      this.header = null;
    }
  }

  getprams(event: any) {
    let params = new HttpParams();
    if (this.userdata.data?.user_role == 'STUDENTS') {
      params = params.append(
        'organization_id',
        this.userdata.data?.organization_id
      );
      params = params.append(
        'organization_id',
        this.userdata.data?.sub_organization_id
      );
    } else {
      if (typeof this.selected_sub_org != 'undefined') {
        params = params.append(
          'organization_id',
          this.selected_sub_org?.organization_id
        );
        params = params.append(
          'sub_organization_id',
          this.selected_sub_org?.id
        );
      } else {
        params = params.append(
          'organization_id',
          this.userdata.data?.organization_id
        );
      }
    }
    Object.keys(event).forEach((key) => {
      // && Number(event[key])
      // console.log("event===========>", event);
      if (typeof event[key] !== 'undefined' && typeof event[key] !== null) {
        // if(Number(!event[key])){
        params = params.append(key, event[key]);
        // }
      }
    });
    return params;
  }

  get_selectedorganisation() {
    let orgdata: any;
    if (typeof this.selected_sub_org != undefined) {
      orgdata = {
        ...orgdata,
        organization_id: this.selected_sub_org.organization_id,
        sub_organization_id: this.selected_sub_org.id,
      };
    } else {
      orgdata = {
        ...orgdata,
        organization_id: this.userdata.data.organization_id,
      };
    }
    return orgdata;
  }
  getpasswordemployee() {
    return this.http
      .post(`${environment.API_URL}` + 'commonservice/employeePassword', {
        headers: this.header,
      })
      .pipe(catchError(this.handleError))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getpasswordstudent() {
    return this.http
      .post(`${environment.API_URL}` + 'commonservice/studentPassword', {
        headers: this.header,
      })
      .pipe(catchError(this.handleError))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getlookupcodeslist(paginationdata: any) {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'lookupcodes', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getcastesdetails(data: any) {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + '&lookupType=USER_ROLES', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getassignleavelist() {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'assignedEmployeeLeaves?type=type=list&limit=1000',
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getcourseslist(paginationdata?: any) {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'courses', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getdesignationlist(paginationdata?: any) {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'designations', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getrespectivecoursesdata(event?: any) {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'courses/' + event, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getMarkAttendancelist(event?: any, dateFormate?: any) {
    let setparms = {
      type: 'toMarkAttendance',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'students?academic_regulation_id=' +
          event.academic_regulation_id +
          '&academic_year_id=' +
          event.academic_year_id +
          '&course_id=' +
          event.course_id +
          '&section_id=' +
          event.section_id +
          '&start_time=' +
          event.start_time +
          '&end_time=' +
          event.end_time +
          '&semester_id=' +
          event.semester_id +
          '&subject_id=' +
          event.subject_id +
          '&date=' +
          dateFormate,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getstudentattendanceDetails(event: any, dateFormate: any) {
    let setparms = {
      type: 'attendanceStats',
    };
    event.date = event.date;
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'studentattendance?course_id=' +
          event.course_id +
          '&semester_id=' +
          event.semester_id +
          '&subject_id=' +
          event.subject_id +
          '&section_id=' +
          event.section_id +
          '&academic_regulation_id=' +
          event.academic_regulation_id +
          '&academic_year_id=' +
          event.academic_year_id +
          '&date=' +
          dateFormate +
          '&start_time=' +
          event.start_time +
          '&end_time=' +
          event.end_time,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getClassesdetails(event: any) {
    let setparms = {
      type: 'classes',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employees?date=' +
          event.date +
          '&employee_id=' +
          event.employee,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  postMarkAttendancelist(event: any) {
    let body = event;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'studentattendance', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '201' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  getTimeslotsByShiftId(shift_id: number) {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` + 'timeslots?type=list&shiftId=' + shift_id,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getTimeTabledata(event: any) {
    let setparms = {
      type: 'create',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'timetables?shift_id=' +
          event.shift_id +
          '&course_id=' +
          event.course_id +
          '&semister_id=' +
          event.semister_id,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  getviewTimeTabledata(event: any) {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'timetables/' + event.timetable_id, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  createTimeTabledata(event: any) {
    let body = event;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'timetables', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  gettimetablelist() {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'timetables', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getBannerslist(apiEndpoint: string) {
    let setparms = {
      type: 'list',
      erp: true,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + apiEndpoint, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createBanners(apiEndpoint: string, event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    } else {
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}${apiEndpoint}`, body, {
        headers: event instanceof FormData ? {} : this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            return throwError(() => this.handleError(data));
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  updateBanners(
    apiEndpoint: string,
    id: number,
    formData: FormData
  ): Observable<any> {
    return this.http
      .put(`${environment.API_URL}` + apiEndpoint + '/' + id, formData, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteBanners(endpoint: string, id: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + endpoint + '/' + id, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  updateBannerSequence(body: any): Observable<any> {
    body = { ...body, ...this.get_selectedorganisation() };
    console.log('thiusjoiashcha=====>', body);
    return this.http
      .put(`${environment.API_URL}` + 'banners', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getNoticeBoardlist() {
    let setparms = {
      type: 'list',
      erp: true,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'notices', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateNoticeBoardSequence(body: any): Observable<any> {
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .put(`${environment.API_URL}` + 'notices', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createNoticeBoard(event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]);
      });
    } else {
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}` + 'notices', body, {
        headers: event instanceof FormData ? {} : this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  updateNoticeBoard(id: number, formData: FormData): Observable<any> {
    return this.http
      .put(`${environment.API_URL}` + 'notices/' + id, formData, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteNoticeBoard(id: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + 'notices/' + id, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getTestimonialslist() {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'testimonials', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateTestimonials(id: number, formData: FormData): Observable<any> {
    return this.http
      .put(`${environment.API_URL}` + 'testimonials/' + id, formData, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  deleteTestimonials(id: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + 'testimonials/' + id, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createTestimonials(event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    } else {
      // If event is a normal object, merge with organization data
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}` + 'testimonials', body, {
        headers: event instanceof FormData ? {} : this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  getMissionVissionlist(apiEndpoint: string) {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + apiEndpoint, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createMissionVission(endpoint: string, event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    } else {
      // If event is a normal object, merge with organization data
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}` + endpoint, body, {
        headers: event instanceof FormData ? {} : this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  updateMissionVission(
    endpoint: string,
    id: number,
    formData: FormData
  ): Observable<any> {
    let body: FormData = formData || new FormData();

    const selectedOrg = this.get_selectedorganisation();
    if (selectedOrg) {
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    }

    return this.http
      .put(`${environment.API_URL}${endpoint}/${id}`, body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  //Website Management

  createActivitiesPage(event: any): Observable<any> {
    let body = event;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'webpages', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '201' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }

  getActivitiesPagelist() {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'webpages', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  getActivitiesEventData(id: any) {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'webpages/' + `${id}`, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  createActivitiesEventData(event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    } else {
      // If event is a normal object, merge with organization data
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}activities`, body, {
        headers: event instanceof FormData ? {} : this.header, // Remove headers for FormData
      })
      .pipe(
        map((data) => {
          if (data?.code == '201' && data?.status === true) {
            return data;
          } else {
            return throwError(() => new Error('Unexpected response from API'));
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  updateActivityEvents(pageID: number, formData: FormData): Observable<any> {
    return this.http
      .put(`${environment.API_URL}activities/${pageID}`, formData, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteActivityEvents(activitiesId: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + 'activities/' + `${activitiesId}`, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updateActivitiesPage(activitiesId: number, pageData: any): Observable<any> {
    return this.http
      .put(
        `${environment.API_URL}` + 'webpages/' + `${activitiesId}`,
        pageData,
        { headers: this.fileheader }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  deleteActivitiesPage(activitiesId: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + 'webpages/' + `${activitiesId}`, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createNaacdetailsPage(event: any): Observable<any> {
    let body: any;

    if (event instanceof FormData) {
      // If event is FormData, append selected organization data
      body = event;
      const selectedOrg = this.get_selectedorganisation();
      Object.keys(selectedOrg).forEach((key) => {
        body.append(key, selectedOrg[key]); // Append organization details
      });
    } else {
      // If event is a normal object, merge with organization data
      body = { ...event, ...this.get_selectedorganisation() };
    }

    return this.http
      .post<any>(`${environment.API_URL}activities`, body, {
        headers: event instanceof FormData ? {} : this.header, // Remove headers for FormData
      })
      .pipe(
        map((data) => {
          if (data?.code == '201' && data?.status === true) {
            return data;
          } else {
            return throwError(() => new Error('Unexpected response from API'));
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  getNAACPagelist() {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'naacdetails/', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getstreamlist(paginationdata: any) {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'streams', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createlookupcode(lookupcode: any): Observable<any> {
    // const header = { 'content-type': 'application/json'};
    let body = lookupcode;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post<any>(`${environment.API_URL}` + 'lookupcodes', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
            return data;
          }
        })
      );
  }
  bulkuploadstudent(uploaddata: any): Observable<any> {
    // let fomdata: FormData = new FormData();
    // fomdata.append('STUDENTS_BULKUPLOAD_TEMPLATE', uploaddata);
    let body: any = uploaddata;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'students/bulkupload', body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
    //   const header = {
    //     'token': this.userdatta?.data.token,
    //     'content-type':  'multipart/form-data',
    //     'Accept' : 'application/json'
    // }
    //   let filesdata  = new FormData();
    //   filesdata.append('EMPLOYEES_BULKUPLOAD_TEMPLATE' ,uploaddata);
    //   // const body = {"EMPLOYEES_BULKUPLOAD_TEMPLATE" : JSON.stringify(uploaddata)};
    //   return this.http.post<any>(`${environment.API_URL}` + 'students/bulkupload', filesdata, { headers: header }).pipe(
    //     map(data => {
    //       if (data?.code == '200' && data?.status === true) {
    //         return data
    //       } else {
    //         catchError(this.handleError)
    //       }
    //     }))
  }
  bulkuploademployee(uploaddata: any): Observable<any> {
    // let formData = new FormData();
    // formData.append('EMPLOYEES_BULKUPLOAD_TEMPLATE', uploaddata);
    let body = uploaddata;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'employees/bulkupload', body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  bulkuploadimages(uploaddata: any, type:any): Observable<any> {
    let fomdata: FormData = new FormData();
    fomdata.append(type, uploaddata);
    // fomdata.append( 'organization_id', this.selected_sub_org.organization_id)
    // fomdata.append( 'sub_organization_id', this.selected_sub_org.id)
    let body = fomdata;  
    return this.http
      .post<any>(
        `${environment.API_URL}` + 'commonservice/bulkimagesUpload?fieldName=' + type + '&organization_id=' + this.selected_sub_org.organization_id + '&sub_organization_id=' + this.selected_sub_org.id,
        body,
        { headers: this.fileheader }
      )
      .pipe(
        map((data) => {
          if (data?.code == '200') {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  bulkuploadsubjects(uploaddata: any): Observable<any> {
    let fomdata: FormData = new FormData();
    fomdata.append('subjectsBulkuploadFile', uploaddata);
    let body = fomdata;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'subjects/bulkupload', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  createsuborganization(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'subOrganizations', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updatesuborganizationimage(image: any, ID: number) {
    let fomdata: FormData = new FormData();
    fomdata.append('logo_url', image);
    const body = fomdata;
    return this.http
      .put<any>(`${environment.API_URL}` + 'subOrganizations/' + ID, body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  getsuborganization(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'subOrganizations', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createlookuptype(lookuptype: any): Observable<any> {
    let body = lookuptype;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'lookuptypes', body, {
        headers: this.header,
      })
      .pipe(
        map((Data) => {
          this.dismissError();
          return Data;
        })
      );
  }

  createdepartment(departments: any): Observable<any> {
    let body = departments;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'departments', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createcourses(courses: any): Observable<any> {
    let body: any = courses;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'courses', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createdesgination(courses: any): Observable<any> {
    let body: any = courses;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'designations', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  createtimeslots(timeslots: any): Observable<any> {
    let body: any = timeslots;
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post(`${environment.API_URL}` + 'timeslots', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createsemester(semester: any): Observable<any> {
    let body: any = semester;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'semisters', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacadamics(courses: any): Observable<any> {
    let body: any = courses;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'academicregulations', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  AssignStudenttoSection(courses: any): Observable<any> {
    let body: any = courses;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .patch(`${environment.API_URL}` + 'students/updateStudentSection', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacadamicssections(courses: any): Observable<any> {
    let body: any = courses;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'organizationwisesections', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacadamicyears(batches: any): Observable<any> {
    let body = batches;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'academicyear', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  update_acadamicyears_status(status: any, id:number): Observable<any> {
    let body = status;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .put(`${environment.API_URL}` + 'academicyear/'+ id, body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacadamiccalendar(batches: any): Observable<any> {
    let body = batches;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'academiccalendar', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacademicyearsemisters(eventdata: any): Observable<any> {
    let body = eventdata;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'academicyearsemisters', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createacademicsections(eventdata: any): Observable<any> {
    let body: any = eventdata;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'academicsections', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createsections(sections: any): Observable<any> {
    let body: any = sections;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'organizationacademicsections', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  Editsemester(semester: any, semester_id: number): Observable<any> {
    const body = semester;
    return this.http
      .put(`${environment.API_URL}` + 'semisters/' + semester_id, body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  Deletesemester(semester_id: number): Observable<any> {
    return this.http
      .delete(`${environment.API_URL}` + 'semisters/' + semester_id, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createsubjects(subjects: any): Observable<any> {
    delete subjects.subjectImage;
    let body: any = subjects;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'subjects', body, {
        headers: this.header,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  editsubjects(subjects: any, subject_id: number): Observable<any> {
    delete subjects.subjectImage;
    const body = subjects;
    return this.http
      .put(`${environment.API_URL}` + 'subjects/' + subject_id, body, {
        headers: this.header,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  editStudentDetails(studentId: number, studentData: any): Observable<any> {
    let body = studentData;
    // body = {...body, ...this.get_selectedorganisation()}
    return this.http
      .put(`${environment.API_URL}` + 'students/' + studentId, body, {
        headers: this.header,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  editEmployeeDetails(employees: any, employee_id: number): Observable<any> {
    delete employees.employeesImage;
    let body = employees;
    // body = { ...body, ...this.get_selectedorganisation() }
    return this.http
      .put(`${environment.API_URL}` + 'employees/' + employee_id, body, {
        headers: this.header,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createsubjectsimage(ID: number, imagedata: any): Observable<any> {
    let formdata: FormData = new FormData();
    formdata.append('subjectImage', imagedata);
    const body = formdata;
    return this.http
      .put(`${environment.API_URL}` + 'subjects/' + ID, body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createstudents(data: any): Observable<any> {
    let body: any = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'students', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createleavetypes(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'leavetypes', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createleave_entitlements(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'leaveentitlements', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createemployees(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'employees', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createholidays(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'holidaycalendar', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createweekoff(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'weekoffcalendar', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createassignedleaves(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'assignedemployeeleaves', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  CreatestudentProfile(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http.post(`${environment.API_URL}` + 'profile', body, {
      headers: this.header,
    });
  }

  createstreams(courses: any): Observable<any> {
    let body = courses;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'streams', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getloggerdata(date: any) {
    let body = date;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'employees/getlogger', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  updatestudentimage(student: File, ID: any) {
    let fomdata: FormData = new FormData();
    fomdata.append('studentImage', student);
    const body = fomdata;
    return this.http
      .put<any>(`${environment.API_URL}` + 'students/' + ID, body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  updateemployeeimage(employee: any, ID: number) {
    let fomdata: FormData = new FormData();
    fomdata.append('employeImage', employee);
    const body = fomdata;
    return this.http
      .put<any>(`${environment.API_URL}` + 'employees/' + ID, body, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code == '200' && data?.status === true) {
            return data;
          } else {
            catchError(this.handleError);
          }
        })
      );
  }

  updateTimeSlots(slotData: any, ID: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('slotData', JSON.stringify(slotData));
    return this.http
      .put<any>(`${environment.API_URL}` + 'timeslots/' + ID, formData, {
        headers: this.fileheader,
      })
      .pipe(
        map((data) => {
          if (data?.code === '200' && data?.status === true) {
            return data;
          } else {
            throw new Error('Unexpected response from the server');
          }
        }),
        catchError(this.handleError)
      );
  }

  getstudentlist(paginationdata: any, filters: any) {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };

    // const url = `${environment.API_URL}students?${params.toString()}`;
    return this.http
      .get<any>(`${environment.API_URL}students`, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getacademicstudent(paginationdata: any, filters: any) {
    let setparms = {
      course_id: filters.course_id,
      semester: filters.semester_id,
      academic_regulation_id: filters.academic_regulation_id,
      academic_year_id: filters.academic_year_id,
      // params.set('year', 'list');
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };

    // Object.keys(filters).forEach(key => {
    //   if (filters[key] !== undefined && filters[key] !== null) {
    //     params.set(key, filters[key]);
    //   }
    // });
    // const url = `${environment.API_URL}students?${params.toString()}`;
    return this.http
      .get<any>(`${environment.API_URL}students`, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getemployeelist(paginationdata: any, filters: any) {
    // let params = new URLSearchParams();
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    setparms = { ...setparms, ...filters };
    // params.set('type', 'list');
    // params.set('limit', paginationdata?.pageSize);
    // params.set('skip', (paginationdata?.pageIndex + 1).toString());
    // Object.keys(filters).forEach((key: any) => {
    //   if (filters[key] !== undefined && filters[key] !== null) {
    //     params.set(key, filters[key]);
    //   }
    // });
    // const url = `${environment.API_URL}employees?${params.toString()}`;
    return this.http
      .get<any>(`${environment.API_URL}` + 'employees', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getselectedstudentProfile(data: any): Observable<any> {
    const header = { 'content-type': 'application/json' };
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http.post(`${environment.API_URL}` + 'profile', body);
  }

  getstudentdetails(data: number): Observable<any> {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'students' + '/' + data, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getemployeedetails(data: number): Observable<any> {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'employees' + '/' + data, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          this.dismissError();
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getdepartmentdetails(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'departments', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getacadamicslist(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academicregulations', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getrespectiveacadamicsdata(event: any): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academicregulations/' + event, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getsectionlist(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academicsections?type=list', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getBatchDetails(eventdata: any): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` + 'academicregulations/' + eventdata.id,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getSectionDetails(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'organizationacademicsections', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAcademicYearsSection(eventdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'academiccalendar?course_id=' +
          eventdata.course_ID,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getAcademicSemister(eventdata: any, paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'academicyearsemisters?course_id=' +
          eventdata.course_ID +
          '&academic_year_id=' +
          eventdata.id,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getacadamicyearslist(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academicyear', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getacadamiccalendarlist(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academiccalendar', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getrespectiveacadamicyearslist(event: any): Observable<any> {
    let setparms = {
      type: undefined,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'academiccalendar/' + event, {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getlookuptypedetails(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'lookuptypes', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  Studentforgotpassword(role: any, email: any): Observable<any> {
    const body = { email: email };
    let urlnavigate;
    if (role === 'stiudent') {
      urlnavigate = 'students';
    } else {
      urlnavigate = 'employees';
    }
    return this.http
      .patch<any>(
        `${environment.API_URL}` + urlnavigate + '/forgotPassword',
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  verifyOTP(role: any, email: any, OTP: any): Observable<any> {
    const body = {
      email: email,
      otp: OTP,
    };
    let urlnavigate;
    if (role === 'stiudent') {
      urlnavigate = 'students';
    } else {
      urlnavigate = 'employees';
    }
    return this.http
      .patch<any>(
        `${environment.API_URL}` + urlnavigate + '/verifyEmailOtp',
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  passwordupdate(
    role: any,
    password: any,
    repassword: any,
    ID: number
  ): Observable<any> {
    const body = {
      password: password,
      confirm_password: repassword,
      id: ID,
    };
    let urlnavigate;
    if (role === 'stiudent') {
      urlnavigate = 'students';
    } else {
      urlnavigate = 'employees';
    }
    return this.http
      .patch<any>(
        `${environment.API_URL}` + urlnavigate + '/updatePassword',
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getattendancedetails(
    year: number,
    month: number,
    id: number
  ): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employeeattendance?employee_id=' +
          id +
          '&year=' +
          year +
          '&month=' +
          ('0' + month).slice(-2),
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        // return this.http.get<any>(`${environment.API_URL}` + 'employeeattendance?type=list&limit=100&employee_id=1&month=10&year=2024', { headers: this.header }).pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getstudentattendancedetails(year: number, month: number): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    // this is for student ID : this.userdatta?.data?.id  and added student ID as 18264 for testing purpose ONLY
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'studentattendance?student_id=' +
          this.userdata.data.id +
          '&year=' +
          year +
          '&month=' +
          ('0' + month).slice(-2),
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getshiftdetails(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'breaktimes', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getweekoffdetails(): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'weekoffcalendar', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getholidaydetails(paginationdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'holidaycalendar', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleavetypedetails(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'leavetypes', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleave_entitlementsdetails(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'leaveentitlements', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getApplyLeavetypes(): Observable<any> {
    let setparms = {
      type: 'drop',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'leavetypes?type=drop', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getorganizationdetails(): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'organizations', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleaverequestdetails(actionRequest: any): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employeeleaves?managerId=' +
          this.userdatta?.data.id +
          '&status=' +
          actionRequest,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleavesummary(paginationdata: any,actionRequest: any): Observable<any> {
    let setparms: any = {
      type: 'list',
      limit: paginationdata?.pageSize,
      skip: paginationdata?.pageIndex ? paginationdata?.pageIndex + 1 : 1,
      start_date: actionRequest.start_date,
      end_date: actionRequest.end_date,
      // employee_name: actionRequest.employee_name,
    };
    if(actionRequest.employee_name != ''){
      setparms = {...setparms, employee_name: actionRequest.employee_name}
    }else if(actionRequest.department_id != 0){
      setparms = {...setparms, department_id: actionRequest.department_id}
    }else if(actionRequest.leave_type_id != 0){
      setparms = {...setparms, leave_type_id: actionRequest.leave_type_id}
    }
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'assignedemployeeleaves',
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleavehistory(actionRequest: any): Observable<any> {
    let setparms: any = {
      type: 'list',
      leaves_history: true,
      start_date: actionRequest.start_date,
      end_date: actionRequest.end_date,
      employeeId: actionRequest.employee_id,
    };
    if(actionRequest.leave_type_id != 0){
      setparms = {...setparms, leave_type_id: actionRequest.leave_type_id}
    }
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employeeleaves',
        {params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  actionleaveallot(action: any): Observable<any> {
    let body:any;
    body = { ...body, employee_id: action};
    body = { ...body, ...this.get_selectedorganisation() };
    return this.http
      .post<any>(`${environment.API_URL}` + 'assignedEmployeeLeaves', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }
  
  getonboardingstatus(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(`${environment.API_URL}` + 'modules', {
        params: this.getprams(setparms),
        headers: this.header,
      })
      .pipe(
        map((data) => {
          console.log('status working fine');
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleavestdetails(actionRequest: any): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employeeleaves?employeeId=' +
          this.userdatta?.data.id +
          '&status=' +
          actionRequest,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getleaverequeststats(): Observable<any> {
    let setparms = {
      type: 'list',
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'employeeleaves?stats=true&employeeId=' +
          this.userdatta?.data.id,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getsemesteryearwise(eventdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'semisters?course_id=' +
          eventdata.course_ID +
          '&year=' +
          eventdata.year,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getcoursewisesemester(eventdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` + 'semisters?course_id=' + eventdata.course_ID,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getsemestersubjectsDetails(eventdata: any): Observable<any> {
    let setparms = {
      type: 'list',
      limit: 1000,
    };
    return this.http
      .get<any>(
        `${environment.API_URL}` +
          'subjects?course_id=' +
          eventdata.course_ID +
          '&semisterId=' +
          eventdata.semester_ID,
        { params: this.getprams(setparms), headers: this.header }
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  actionleaverequest(action: any, id: number): Observable<any> {
    const body = action;
    return this.http
      .patch<any>(`${environment.API_URL}` + 'employeeleaves/' + id, body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  applyemployeeleaves(data: any): Observable<any> {
    let body = data;
    body = { ...body, employee_id: this.userdatta.data.id };
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'employeeleaves', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  applyemployeecompoff(data: any): Observable<any> {
    let body = data;
    body = { ...body, employee_id: this.userdatta.data.id };
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'compOff', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  applyemployeepermissions(data: any): Observable<any> {
    let body = data;
    body = { ...body, employee_id: this.userdatta.data.id };
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'permissions', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  createshift(data: any): Observable<any> {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'breaktimes', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  subjectStatus(id: number, data: any): Observable<any> {
    const url = `${environment.API_URL}subjects/${id}`;
    return this.http.patch(url, data, { headers: this.header }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  courseStatus(id: number, data: any): Observable<any> {
    const url = `${environment.API_URL}courses/${id}`;

    return this.http.patch(url, data, { headers: this.header }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  onboarding_status_updation(data: any) {
    let body = data;
    body = { ...body, ...this.get_selectedorganisation() };

    return this.http
      .post(`${environment.API_URL}` + 'modules', body, {
        headers: this.header,
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 401) {
      sessionStorage.removeItem('user');
      this.AuthenticationService.logout();
      window.location.reload();
    }
    return throwError(errorResponse.message || 'Server error');
  }

  private dismissError(): void {
    setTimeout(() => {
      this.toast.dismissToast();
    }, 3000);
  }
}
