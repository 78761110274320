<mat-list class="menulist">
  <ng-container *ngFor="let item of menu">
    <!-- If the item doesn't have children show it as list item-->
    <ng-container *ngIf="!item?.subMenuflag">
      <mat-list-item *ngIf="item?.title" (click)="navigateToRoute(item)" [ngClass]="{'active': isActive(item)}" [disabled]="item?.disable" class="center-flex">
        <div class="d-inline-flex list_item align-items-center cursor_pointer" >
          <img [src]="item?.icon" alt="menu logo" />
          <span matLine>{{ item?.title }}</span>
        </div>
      </mat-list-item>
    </ng-container>

    <!-- If the item has subMenu show it as accordion -->
    <ng-container *ngIf="item?.subMenuflag">
      <mat-expansion-panel [expanded]="item.expanded" [disabled]="item?.disable" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title class="center-flex">
            <div class="d-inline-flex list_item align-items-center">
              <img [src]="item?.icon" alt="menu logo" />
              <span matLine>{{ item?.title }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- <app-menu-items [menu]="item.subMenu"></app-menu-items> -->
        <ng-container *ngFor="let submenu of item.SubMenu">
          <mat-list-item *ngIf="submenu?.title" [routerLink]="submenu?.link" routerLinkActive="active"
             class="px-4 center-flex" [disabled]="item?.disable">
            <div class="d-inline-flex list_item align-items-center">
              <img [src]="submenu?.icon" alt="menu logo" />
              <span matLine>{{ submenu?.title }}</span>
            </div>
          </mat-list-item>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</mat-list>