import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { FrontpageService } from '../../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';

export interface bannersModel {
  image_name: string;
  visible_from: any;
  visible_until: any;
  organization: number;
  image_url: File | null;
}

@Component({
  selector: 'app-addbanners',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: './addbanners.component.html',
  styleUrl: './addbanners.component.scss',
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class AddbannersComponent {
  questions: any[] = [];
  questions$: Observable<any[]> | null = null;
  bannersForm!: FormGroup;
  datesend!: bannersModel;
  bannersdata: any = {};
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file!: File | null;

  constructor(
    private dialogRef: MatDialogRef<AddbannersComponent>,
    private dropdownservice: DropdownservicesService,
    private Bannersservices: FrontpageService,
    private qcs: QuestionControlService,
    private apiservice: ApiservicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    {
      this.questions$ = this.Bannersservices.getBannersForm();
      this.questions$?.subscribe((q) => {
        return(this.questions = q)});
      if (data) {
        this.fileName = data.image_name || null;
        this.selectedImageUrl = `${this.apiservice.environmentPath}/${data.image_url}` || null; // Initialize existing image URL
      }
    }
  }

  ngOnInit(): void {
    this.bannersForm = this.qcs.toFormGroup([]);
    this.bannersForm.addControl(
      'image_name',
      new FormControl(this.data?.image_name || '')
    );
    this.bannersForm.addControl(
      'image_url',
      new FormControl(this.data?.image_url || null)
    ); // Ensure existing image_url is used
    this.bannersForm.addControl(
      'start_date',
      new FormControl(this.data?.visible_from || '')
    );
    this.bannersForm.addControl(
      'end_date',
      new FormControl(this.data?.visible_until || '')
    );
  }

  onSubmit() {
    const visibleFrom = new Date(this.bannersForm.value.start_date).toISOString();
    const visibleUntil = new Date(this.bannersForm.value.end_date).toISOString();
  
    const formData = new FormData();
    formData.append('image_name', this.bannersForm.value.image_name);
    formData.append('visible_from', visibleFrom);
    formData.append('visible_until', visibleUntil);
  
    // Include selected image or existing image
    if (this.file) {
      formData.append('image_url', this.file);
    } else if (this.selectedImageUrl) {
      formData.append('image_url', this.selectedImageUrl as string);
    } else {
      console.error('No image selected and no existing image URL found.');
      return;
    }
  
    formData.append('type', 'homepage90');
    if (this.data?.id) {
      this.updateApi(this.data?.id, formData);
    } else {
      this.createAPi(formData);
    }
  }

  createAPi(formData: FormData) {
    this.apiservice.createBanners(formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.bannersForm.reset();
          this.dialogRef.close(true);
        } else {
          console.error('Error:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }

  updateApi(id: number, formData: FormData) {
    this.apiservice.updateBanners(id, formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.bannersForm.reset();
          this.dialogRef.close(true);
        } else {
          console.error('Error:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error during update:', error);
      }
    );
  }

  imageInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
  
    if (input.files?.length) {
      this.file = input.files[0]; // Store the file
      const reader = new FileReader();
  
      reader.onload = (e) => {
        this.selectedImageUrl = e.target?.result as string; // Store for preview
  
      };
  
      reader.readAsDataURL(this.file);
    } else {
      console.error('No file selected.');
      this.file = null;
      this.selectedImageUrl = null;
    }
  }
  


  closeDialog(event: any) {
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    this.dialogRef.close(event);
  }
}
