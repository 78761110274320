import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CreatepageserviceService } from '../../../../inputfileds/form_services/web_activities/createpageservice.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from "../../../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-add-activity-dialog',
  templateUrl: './addactivitydialog.component.html',
  styleUrls: ['./addactivitydialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatButtonModule
],
providers: [DatePipe]
})
export class AddactivitydialogComponent implements OnInit, OnDestroy {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<string>[]> | null = null;
  pagecreationForm: FormGroup;
  images: File[] = [];
  previewUrl: string[] = [];
  backendImages: string[] = [];
  currentSlide: number = 0;
  private subscription = new Subscription();
  editflag: boolean = false;
  baseImageUrl = `${this.apiServices.environmentPath}/`;
  deletedImages: { image_id: number; image_url: string }[] = [];
  updatedImageUrls: { image_id: number; image_url: string}[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddactivitydialogComponent>,
    private createpageServices: CreatepageserviceService,
    private apiServices: ApiservicesService,
    private route: ActivatedRoute,
    private datePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: { pageId: string; activityData: any, editId:number; }
  ) {
    console.log("Create activity dialog", data)
    this.pagecreationForm = this.fb.group({
      heading: ['', Validators.required],
      description: ['', Validators.required],
      date: ['', Validators.required],
      activityImages: ['', Validators.required],
      parent_web_page_id: ['', Validators.required],
    });
    if (data.activityData) {
      this.pagecreationForm.patchValue({
        heading: data.activityData.heading,
        description: data.activityData.description,
        date: data.activityData.date,
        parent_web_page_id: data.activityData.parent_web_page_id,
      });
      this.previewUrl = (data.activityData.image_urls || []).map((url: { image_url: string }) => `${this.baseImageUrl}${url.image_url}`);
      
      this.editflag = true;
    }
    
    this.loadQuestions();
  }

  ngOnInit(): void {
    
  }

  private loadQuestions(): void {
    this.questions$ = this.createpageServices.getCreateEvents();
    this.subscription.add(
      this.questions$.subscribe({
        next: (q) => (this.questions = q || []),
        error: (err) => console.error('Error loading questions:', err),
      })
    );
  }

  onImageUpload(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const uploadedFiles = Array.from(target.files);
      console.log('Uploaded images:', this.images);
      uploadedFiles.forEach((file) => {
        if (!this.images.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)) {
          this.images.push(file);
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.previewUrl.push(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      });
    } else {
      console.error('No files selected or invalid input.');
    }
  }
  
  
  deleteImage(index: number): void {
    if (index < 0 || index >= this.previewUrl.length) {
      console.error('Invalid index for deleting image.');
      return;
    }
  
    // Handle existing images
    if (this.editflag && this.data.activityData.image_urls[index]) {
      const deletedImage = this.data.activityData.image_urls[index];
      this.deletedImages.push({ image_id: deletedImage.image_id, image_url: '' });
    }
  
    // Remove the image from the preview list
    this.previewUrl.splice(index, 1);
  
    // Remove the corresponding file from images array (if it exists)
    if (index < this.images.length) {
      this.images.splice(index, 1);
    }
  
    // Adjust the current slide index to stay within bounds
    this.currentSlide = Math.max(0, Math.min(this.currentSlide, this.previewUrl.length - 1));
  }
  
  
  
  prevSlide(): void {
    if (this.previewUrl.length > 0) {
      this.currentSlide = (this.currentSlide - 1 + this.previewUrl.length) % this.previewUrl.length;
    }
  }
  
  nextSlide(): void {
    if (this.previewUrl.length > 0) {
      this.currentSlide = (this.currentSlide + 1) % this.previewUrl.length;
    }
  }
  

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const formattedDate = this.datePipe.transform(this.pagecreationForm.value.date, 'yyyy-MM-dd');
  
    const formDataToSend = new FormData();
    this.images.forEach((file) => formDataToSend.append('activityImages', file));
    formDataToSend.append('heading', this.pagecreationForm.value.heading);
    formDataToSend.append('description', this.pagecreationForm.value.description);
    formDataToSend.append('date', formattedDate!);
    formDataToSend.append('parent_web_page_id', this.data.pageId);
  
    if (this.editflag) {
      const editId = this.data.editId;
      this.updatePage(editId, formDataToSend);
    } else {
      this.createPage(formDataToSend);
    }
  }
  
  
  

  private createPage(pageData: FormData): void {
    this.apiServices.createActivitiesEventData(pageData).subscribe({
      next: (response) => {
        console.log('Page created successfully:', response);
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error creating page:', err),
    });
  }

  private updatePage(pageId: number , pageData: FormData): void {
    console.log("Update page Triggered", pageData)
    this.apiServices.updateActivityEvents(pageId,pageData).subscribe({
      next: (response) => {
        console.log('Activity updated successfully:', response);
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error updating activity:', err),
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}