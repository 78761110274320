import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AcademicyearsformService } from '../../../../inputfileds/form_services/academics/academicyearsform/academicyearsform.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { QuestionService } from '../../../../inputfileds/form_services/question/question.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AddshiftComponent } from '../../../settings/workingdays/shifttimings/addshift/addshift.component';
import { ShiftComponent } from '../../../settings/workingdays/shifttimings/shift/shift.component';
import { AddholidaysComponent } from '../../../settings/workingdays/holidays/addholidays/addholidays.component';
import { AddweekoffComponent } from '../../../settings/workingdays/weekoff/addweekoff/addweekoff.component';
import { WeekoffComponent } from '../../../settings/workingdays/weekoff/weekoff/weekoff.component';
import { HolidaysComponent } from '../../../settings/workingdays/holidays/holidays/holidays.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { PaginationComponent } from '../../../../components/pagination/pagination.component';

export interface yearDetailsView {
  id: string;
  batch: number;
  semister: string;
  course_name: string;
}

@Component({
  selector: 'app-academicyearsdetails',
  standalone: true,
  imports: [
    WeekoffComponent,
    HolidaysComponent,
    FormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    DynamicFormQuestionComponent,
    CommonModule,
    MatTabsModule,
    MatDividerModule,
    ShiftComponent,
    PaginationComponent
],
  providers: [QuestionControlService, ApiservicesService],
  templateUrl: './academicyearsdetails.component.html',
  styleUrls: ['./academicyearsdetails.component.scss']
})
export class AcademicyearsdetailsComponent implements OnInit {
  displayedColumns: string[] = ['semister','batch', 'course_name', 'action'];
  createsemister: Inputfiledsbase<any>[] = [];
  createsemister$: Observable<Inputfiledsbase<any>[]> | undefined
  createsemisterForm!: FormGroup;
  BatchData: any;
  yearsDetailsData: any;
  dataSource = new MatTableDataSource<yearDetailsView>();
  subjects: any[] = [];
  onerror = 'https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg';
  editsubjectdata: any = null;
  isSubjectEnabled = false;
  assetUrl: string = environment.ASSET_URL;
  assetContainer: string = environment.ASSET_CONTAINER;
  yearsSemisterData: any;
  datasend:any;
  total_count: any;
  paginationData: any;

  @ViewChild('createSemesterDialog') createSemesterDialog!: TemplateRef<any>;
  routedata: any;


  constructor(
    private router: Router,
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private dataService: AcademicyearsformService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private qcs: QuestionControlService,
    private toast: ToastService,
    public route: ActivatedRoute
  ) {
    // this.BatchData = history.state.data;
    this.routedata = this.route.snapshot.queryParams['data'];
    this.getacadamicsdetails();
  }

  ngOnInit(): void {
    this.createsemister$ = this.dataService.getacadamicsemisterForm({course_id : this.BatchData.course_id});
    this.createsemister$.subscribe(q => this.createsemister = q);
    this.createsemisterForm = this.qcs.toFormGroup(this.createsemister as Inputfiledsbase<string>[]);
  }

  pagination(event: any) {
    this.paginationData = event;
  }

  getacadamicsdetails() {
    this.apiservice.getrespectiveacadamicyearslist(this.routedata).subscribe((res) => {
      this.BatchData = res;
      this.getacademicyearsemisterDetails();
    });
  }

  getacademicyearsemisterDetails(): void {
    const data = {
      id: this.BatchData?.id,
      course_ID: this.BatchData?.course_id
    };
    
    this.apiservice.getAcademicSemister(data, this.paginationData).subscribe((res: any) => {
      this.yearsDetailsData = res?.rows;
      this.total_count = res?.total_count;
      this.dataSource.data = this.yearsDetailsData;
    });
  }

  openCreateSemisterDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '80%';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    dialogConfig.data = {batchdetails : this.yearsDetailsData};
    const dialogRef = this.dialog.open(this.createSemesterDialog, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getacademicyearsemisterDetails();
      console.log('The dialog was closed');
    });
  }

  closeDialog(event: any) {
    // this.dialogRef.close();
    // const dialogRef = this.dialog.open(this.createSemesterDialog);
    this.dialog.closeAll();
    this.getacademicyearsemisterDetails();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    // setTimeout(() => {
    //   dialogRef.close(event);
    // }, 1000);
  }

  onSaveClick(): void {
    this.dialog.closeAll();
  }

  onSubmit(){
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null
    const payload = this.createsemisterForm.value
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        if (parsedData.data && parsedData.data.organization_id) {
          organizationId = parsedData.data.organization_id;
        } else {
          console.error('Parsed data does not contain expected structure');
        }
      } catch (error) {
        console.error('Error parsing data from sessionStorage:', error);
      }
    }
  
    if (organizationId === null) {
      console.error('Organization ID not found');
      return;
    }
    if (payload.start_date) {
      const localStartDate = new Date(payload.start_date);
      const utcStartDate = new Date(Date.UTC(
        localStartDate.getFullYear(),
        localStartDate.getMonth(),
        localStartDate.getDate()
      ));
      payload.start_date = utcStartDate.toISOString().split('T')[0];
    }
    
    if (payload.end_date) {
      const localEndDate = new Date(payload.end_date);
      const utcEndDate = new Date(Date.UTC(
        localEndDate.getFullYear(),
        localEndDate.getMonth(),
        localEndDate.getDate()
      ));
      payload.end_date = utcEndDate.toISOString().split('T')[0];
    }
    this.datasend = {
      organization_id: organizationId,
      academic_year_id: this.BatchData.id,
      academic_regulation_id: this.BatchData.academic_regulation_id,
      course_id:  this.BatchData.course_id,
      semister_id: payload.semister_id,
      start_date: payload.start_date,
      end_date: payload.end_date,
    }
    this.createAPi(this.datasend);
  }

  createAPi(event: any) {
    this.apiservice.createacademicyearsemisters(event).subscribe({
      next: (res: any) => {

        if (res.code === 201 && res.status === true) {
          this.closeDialog(true);
          this.toast.showToast('success', 'Saved Successfully');
          this.createsemisterForm.reset();
        } else {
          console.error('API Response Error:', res.message);
          this.toast.showToast('danger', `Error: ${res.message}`);
        }
      },
      error: (err) => {
        console.error('API Error:', err);
        this._snackBar.open('An error occurred while creating academic year semester', 'Close', {
          duration: 3000,
        });
      },
    });
  }
  backPage(): void {
    this.router.navigate(['/academicyears']).then(() => {
      this.BatchData;
    });
  }
  
  openShiftDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.position = {
    //   top:  '50%',
    //   right: '50%'
    // };
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '25%' ;
    dialogConfig.minHeight = '30vh' ;
    console.log(dialogConfig);
    const dialogRef = this.dialog.open(AddshiftComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(result => {
      this.dataService.refreshDataOnSubmit();
      console.log('The dialog was closed', result);
    });
  }

  openholidayDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '25%' ;
    dialogConfig.minHeight = '30vh' ;
    console.log(dialogConfig);
    const dialogRef = this.dialog.open(AddholidaysComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(result => {
      this.dataService.refreshDataOnSubmit();
      console.log('The dialog was closed');
    });
  }

  openweekoffDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '25%' ;
    dialogConfig.minHeight = '30vh';
    console.log(dialogConfig);
    const dialogRef = this.dialog.open(AddweekoffComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(result => {
      this.dataService.refreshDataOnSubmit();
      console.log('The dialog was closed', result);
    });
  }
}
