import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../../question-textbox';
import { Inputfiledsbase } from '../../../models/inputfiledsbase';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { DropdownQuestion } from '../../../question-dropdown';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../../question-datepicker';
import { MultiSelectQuestion } from '../../../question-multiselect';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class AcademicyearsformService {

  Batches: any = [];
  course_type: any = [];
  filteredCourses: any = [];
  semister_drop: any = [];
  selectedBatchId: number | null = null;
  selectedbatchValue: any;
  private refreshStreamListSubject = new Subject<void>();
  refreshStreamList$ = this.refreshStreamListSubject.asObservable();
  selectedCourseValue: any;
  Course_wise_semister_drop: any = [];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('BATCHES', this.Batches);
    // this.loadDropdownData('SEMISTER', this.semister_drop);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'BATCHES') {
      observable = this.dropdownservice.getacademicregulationdrop();
    } else if (code === 'COURSE_TYPE') {
      observable = this.dropdownservice.getcoursesbybatch(this.selectedbatchValue);
    } else if (code === 'SEMISTER') {
      observable = this.dropdownservice.getsemisterdrop();
    } else if (code === 'COURSEWISESEMISTER') {
      observable = this.dropdownservice.getcoursewisesemester({course_ID: this.selectedCourseValue});
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }
    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ORGANISATION') {
          lookupname = res[i]?.short_name;
        } else if (code === 'BATCHES' || code === 'COURSE_TYPE' || code === 'SEMISTER' || code === 'COURSEWISESEMISTER') {
          lookupname = res[i]?.name;
        } else {
          lookupname = res[i]?.lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  onBatchChange(selectedValue: any): void {
    this.selectedbatchValue= selectedValue;
    this.loadDropdownData('COURSE_TYPE', this.course_type);
  }

  onchangecourse(selectedValue:any):void {
    this.selectedCourseValue= selectedValue.course_id;
    this.loadDropdownData('COURSEWISESEMISTER', this.Course_wise_semister_drop);
  }
  getacadamicyearsform(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [

      new DatepickerQuestion({
        key: 'start_year',
        label: 'Start Year',
        required: true,
        order: 1,
      }),

      new DatepickerQuestion({
        key: 'end_year',
        label: 'End Year',
        required: true,
        order: 2,
      }),
      new MultiSelectQuestion({
        key: 'batch',
        label: 'Batches',
        required: true,
        options: this.Batches,
        order: 3,
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getacadamicsemisterForm(event:any): Observable<Inputfiledsbase<string>[]> {
    this.onchangecourse(event);
    return new Observable((observer) => {
      const createsemister: Inputfiledsbase<string>[] = [
        // new DropdownQuestion({
        //   key: 'batch',
        //   label: 'Batch Years',
        //   required: true,
        //   options: this.Batches,
        //   order: 1,
        //   onChange: this.onBatchChange.bind(this)
        // }),
        // new DropdownQuestion({
        //   key: 'course_id',
        //   label: 'Course',
        //   required: true,
        //   options: this.course_type,
        //   order: 2,
        // }),
        new DropdownQuestion({
          key: 'semister_id',
          label: 'Select Semister',
          required: true,
          options: this.Course_wise_semister_drop,
          order: 3,
        }),
        new DatepickerQuestion({
          key: 'start_date',
          label: 'Start Date',
          required: true,
          order: 4,
        }),
        new DatepickerQuestion({
          key: 'end_date',
          label: 'End Date',
          required: true,
          order: 5,
        })
      ];

      observer.next(createsemister.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }

  refreshDataOnSubmit(){
    this.refreshStreamListSubject.next();
  }
}
