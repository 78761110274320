import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DynamicPageCreationComponent } from '../../../webpagecreation/dynamic-page-creation/dynamic-page-creation.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFileUploadComponent } from '../../../webpagecreation/dynamic-file-upload/dynamic-file-upload.component';
import { SupportingDocumentsService } from '../../../../inputfileds/form_services/WebsiteManagement/Naac/supporting_documents/supporting-documents.service';

interface Subheading extends AccordionItem {
  title: string;
  selected?: boolean;
}

interface AccordionItem {
  title: string;
  content: string;
  type: string;
  fileUrl?: string;
  expanded: boolean;
  selected?: boolean;
  subheadings?: Subheading[];
}

@Component({
  selector: 'app-supporting-documents',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatDividerModule,
    MatDialogModule,
    MatIconModule,
    CdkAccordionModule,
    MatExpansionModule,
  ],
  templateUrl: './naacdetails-dynamic-page.component.html',
  styleUrls: ['./naacdetails-dynamic-page.component.scss'],
})
export class NaacDetailsDynamicPageComponent {
  accordionData: AccordionItem[] = [];
  selectedItem: AccordionItem | null = null;
  selectedSubheading: any = null;
  uploadedDocuments: any[] = [];
  documentsBySubheading: { [key: string]: any[] } = {};
  isUploadPopupVisible: boolean = false;

  constructor(
    private dialog: MatDialog,
    public supportingDocService: SupportingDocumentsService
  ) {}

  toggleSelectedHeading(item: AccordionItem): void {
    this.accordionData.forEach((h) =>
      h !== item ? (h.expanded = false) : (h.expanded = !h.expanded)
    );
    this.selectedItem = item;
  }

  toggleSelectedSubheading(sub: any) {
    // Remove selection from all subheadings
    this.accordionData.forEach((item) => {
      item.subheadings?.forEach((s) => (s.selected = false));
    });
    sub.selected = true;
    this.selectedSubheading = sub;
    this.uploadedDocuments = this.documentsBySubheading[sub.title] || [];
  }

  openDynamicPageCreationDialog(isSubheading = false): void {
    const dialogRef = this.dialog.open(DynamicPageCreationComponent, {
      width: '600px',
      data: {
        pageTitle: isSubheading ? 'Add Sub Menu' : 'Add Menu',
        questions$: isSubheading
          ? this.supportingDocService.getCreateSubmenuPage()
          : this.supportingDocService.getCreateMenuPage(),
        submitButtonText: isSubheading ? 'Add Sub Menu' : 'Add Menu',
      },
    });
    dialogRef.componentInstance.formSubmit.subscribe((formData) => {
      const newEntry: AccordionItem = {
        title: isSubheading ? formData.submenu_name : formData.menu_name,
        content: JSON.stringify(formData, null, 2),
        type: 'accordion',
        expanded: true,
        subheadings: [],
      };

      if (isSubheading && this.selectedItem) {
        // Ensure selectedItem is not null before adding subheading
        this.selectedItem.subheadings = this.selectedItem.subheadings || [];
        this.selectedItem.subheadings.push(newEntry as Subheading); // Cast to Subheading
      } else {
        this.accordionData.push(newEntry);
      }
      dialogRef.close();
    });

    dialogRef.componentInstance.cancel.subscribe(() => dialogRef.close());
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
    fileInput?.click();
  }

  triggerDynamicFileInput() {
    if (!this.selectedSubheading) {
      console.error('Please select a subheading before uploading.');
      return;
    }

    this.isUploadPopupVisible = true;
    const dialogRef = this.dialog.open(DynamicFileUploadComponent, {
      width: '600px',
      disableClose: true,
      data: {
        allowedFileTypes: '.pdf,.docx,.xlsx',
        service: this.supportingDocService,
        questions$: this.supportingDocService.getUploadSupportingDocs(),
        title: 'NAAC',
      },
    });

    dialogRef.componentInstance.formSubmit.subscribe((formData) => {
      const uploadedFiles: any[] = [];
      let title = formData.get('title') || ''; // Get Title
      let description = formData.get('description') || '';
      formData.forEach((value: FormDataEntryValue, key: string) => {
        if (value instanceof File) {
          uploadedFiles.push(value);
        }
      });
      if (!this.documentsBySubheading[this.selectedSubheading.title]) {
        this.documentsBySubheading[this.selectedSubheading.title] = [];
      }
      this.documentsBySubheading[this.selectedSubheading.title] = [
        ...this.documentsBySubheading[this.selectedSubheading.title],
        ...uploadedFiles,
      ];
      this.documentsBySubheading[this.selectedSubheading.title].push({
        title,
        description,
        files: uploadedFiles
      });
      this.uploadedDocuments = [
        ...this.documentsBySubheading[this.selectedSubheading.title],
      ];
      dialogRef.close();
    });

    dialogRef.componentInstance.cancel.subscribe(() => dialogRef.close());
  }

  closeUploadPopup() {
    this.isUploadPopupVisible = false;
  }

  onFileUpload(event: any) {
    const file = event.target.files[0];
    if (!file || !this.selectedSubheading) return;

    const fileUrl = URL.createObjectURL(file);
    const newDocument = { fileName: file.name, fileUrl };
    if (!this.documentsBySubheading[this.selectedSubheading.title]) {
      this.documentsBySubheading[this.selectedSubheading.title] = [];
    }
    this.documentsBySubheading[this.selectedSubheading.title].push(newDocument);
    this.uploadedDocuments = [
      ...this.documentsBySubheading[this.selectedSubheading.title],
    ];
  }
  getFileURL(file: File): string {
    return URL.createObjectURL(file); // Creates a temporary URL for the file
  }

  logFileDetails(file: File): void {
    console.log('Opening File:', file);
  }
  shortenFileName(fileName: string, maxLength: number): string {
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + '...'
      : fileName;
  }

  openFile(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }
}
