import { Component } from '@angular/core';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CourseView } from '../../../courses/courses.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { workService } from '../../../../inputfileds/form_services/workservice/workservice.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from "../../../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { DropdownservicesService } from '../../../../Services/Apis/Dropdownservices/dropdownservices.service';

export interface leavetypesView {
  index: number,
  id: number,
  organization_id: number,
  leave_name: string,
  designation: string,
  maximum_allowed_leaves: number,
}
@Component({
    selector: 'app-leavetypes',
    standalone: true,
    templateUrl: './leavetypes.component.html',
    styleUrl: './leavetypes.component.scss',
    imports: [
      FormsModule,
      MatFormFieldModule,
      MatCheckboxModule,
      MatTableModule,
      RouterModule, 
      MatButtonModule, 
      MatIconModule,
      MatInputModule, 
      ReactiveFormsModule, 
      DynamicFormQuestionComponent, 
      CommonModule,
      ToastComponent
    ],
  providers: [QuestionControlService, workService, ApiservicesService, DropdownservicesService],
})
export class LeavetypesComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad :any;
  leaveData = []
  displayedColumns: string[] = ['leave_name','leave_code', 'designation', 'maximum_allowed_leaves'];
  dataSource = new MatTableDataSource<leavetypesView>();
  selection = new SelectionModel<leavetypesView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;

  constructor(
    private apiservice: ApiservicesService,
    private dropdownservice : DropdownservicesService,
    private router: Router,  
    private qcs: QuestionControlService, 
    service: workService, 
    private toast : ToastService) {
    this.questions$ = service.getleavetypeform();
    this.questions$.subscribe(q => this.questions = q);

   this.getleavetypedeatils();
  }
  getleavetypedeatils(){
    this.apiservice.getleavetypedetails().subscribe((res) => {
      this.leaveData = res.rows;
      this.dataSource = new MatTableDataSource<leavetypesView>(res.rows)
    })
  }
  refreshDataSource(): void {
  this.getleavetypedeatils();
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: leavetypesView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  onSubmit() {
    this.payLoad = this.form.getRawValue();
    this.datesend = this.form.value;
    console.log(this.payLoad);
   
    forkJoin([
      this.dropdownservice.getlookupcodesdropdown('DESIGNATIONS'),
    ]).subscribe(([designationRes]) => {
      // Filter status data based on form's status_id
      const filteredStatus = designationRes.filter((dropfilter:any)=>{ return dropfilter.id === this.form.value.designation_id });
      // Add status name to the payload
      this.payLoad = { ...this.payLoad, designation: filteredStatus[0]?.lookup_name };
      this.payLoad = { ...this.payLoad, leave_code: this.form.value.leave_name.toUpperCase() };
      this.payLoad.maximum_allowed_leaves = parseInt(this.form.value.maximum_allowed_leaves);
      this.payLoad.designation_id = parseInt(this.form.value.designation_id);
      this.createAPis(this.payLoad);
    });
  }

  createAPis(event:any){
    this.apiservice.createleavetypes(event).subscribe((res: any) => {
      if(res.code === 201 && res.status === true){
        this.toast.showToast(
          'success',
          'Submited Successfuly',);
        this.form.reset();
        this.showadd();
        this.refreshDataSource()
  }else{
    this.toast.showToast('danger', `Error: ${res.message}`);
    }
})
  }
}

