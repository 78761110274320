<div class="row m-2">
    <div class="col-sm-8 col-md-8">
        <div class="row text-center">
            <div class="col-md-6">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-6">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
                        Previous
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate"
                        (viewDateChange)="yearmonth(viewDate)">
                        Today
                    </div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(); yearmonth(viewDate)">
                        Next
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate">
            </mwl-calendar-month-view>
        </div>
        <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
            let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody">

            <div class="">
                <div>{{console.log(day)}}</div>
                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>
            <!-- <ng-container *ngIf="day?.events[0]?.meta.start_time">
          <div class="cal-day-notes p-2">
            <p class="m-0"><small>In - {{day?.events[0]?.meta.start_time}} </small></p>
            <p class="m-0"><small>Out - {{day?.events[0]?.meta.end_time}}</small></p>
          </div>
        </ng-container> -->
        <ng-container [ngSwitch]="day?.events[0]?.meta.daytype">
            <div *ngSwitchCase="'Absent'" class="cal-day-notes p-2">
              <p class="m-0 absent_show_data text-center"><small>{{day?.events[0]?.meta.daytype}}</small></p>
              <p class="m-0 text-center"><small>{{day?.events[0]?.meta.notes}} </small></p>
            </div>
            <div *ngSwitchCase="'Holiday'" class="cal-day-notes p-2">
              <p class="m-0 holiday_show_data text-center"><small>{{day?.events[0]?.meta.daytype}} </small></p>
              <p class="m-0 text-center"><small>{{day?.events[0]?.meta.notes}} </small></p>
            </div>
          </ng-container>
            <div class="d-flex justify-content-between">
                <div class="text-start attendance_view_dot px-2">
                    <ng-container *ngFor="let event of day.events">
                        <ng-container *ngIf="event.meta.status === 'PRESENT';else absent_content">
                            <div class="cal-event" [style.backgroundColor]="event.color.primary"
                                [ngClass]="event?.cssClass"
                                [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
                                [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event"
                                [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody">
                            </div>
                        </ng-container>
                        <ng-template #absent_content>
                            <div class="attendance_view_absent"><span class="m-0">ABSENT</span>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
                <!-- <div>
                    <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="more option"
                        [ngStyle]="{'color':'white'}" fontIcon="more_vert"></mat-icon>
                    <mat-menu #menu="matMenu">
                        <small mat-menu-item>Apply Leave</small>
                        <small mat-menu-item>Apply Comp Off</small>
                        <small mat-menu-item>Apply Permission</small>
                    </mat-menu>
                </div> -->
            </div>
        </ng-template>
    </div>
    <div class="col-sm-4 col-md-4">
        <div class="row">
            <div class="col-sm-6">
                <div class="bg-green">
                    <div class="px-2 py-2">
                        <h2 class="m-0">0</h2>
                        <p class="m-0">Present Days</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="bg-pink">
                    <div class="px-2 py-2">
                        <h2 class="m-0">0</h2>
                        <p class="m-0">Absent Days</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-grey mt-2">
            <div class="px-2 py-4">
                <div>
                    <small>Attendance Percentage</small>
                </div>
                <div class="mt-2">
                    <mat-progress-bar mode="determinate" [color]="color" value="70"></mat-progress-bar>
                </div>
            </div>
        </div>
    </div>
</div>