<section class="my-3">
  <div class="mx-1 d-flex justify-content-between align-items-center">
    <h3 class="m-0 fw-bold">Home Page - Notice Board</h3>
    <div class="d-flex gap-2">
      <button
        mat-stroked-button
        (click)="toggleReorderMode()"
        [disabled]="isLoading"
        class="stroked_button"
      >
        <mat-icon *ngIf="!isLoading">{{
          reorderMode ? "check" : "format_line_spacing"
        }}</mat-icon>
        <span *ngIf="!isLoading">{{
          reorderMode ? "Submit" : "Re-Order"
        }}</span>
        <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
      </button>
      <button mat-raised-button color="primary" (click)="openUploadDialog()">
        <mat-icon>cloud_upload</mat-icon>
        Upload Notice
      </button>
    </div>
  </div>
  <table
    mat-table
    [dataSource]="noticeboardData"
    class="mat-elevation-z8"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <ng-container matColumnDef="drag">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="reorderMode">
          <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
        </ng-container>
      </td>
    </ng-container>
    <!-- Serial Number -->
    <ng-container matColumnDef="sl.no">
      <th mat-header-cell *matHeaderCellDef>Sl. No.</th>
      <td mat-cell *matCellDef="let element">{{ element.sequence }}</td>
    </ng-container>
    <!-- Upload Date Column -->
    <ng-container matColumnDef="visible_from">
      <th mat-header-cell *matHeaderCellDef>From Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.visible_from | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="visible_until">
      <th mat-header-cell *matHeaderCellDef>To Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.visible_until | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <!-- Notice Info Column -->
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef>Notice Board Info</th>
      <td
        mat-cell
        *matCellDef="let element"
        class="cursor_pointer"
        matTooltip="{{ element.description }}"
        matTooltipPosition="right"
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
      >
        {{ element.description | slice : 0 : 50
        }}{{ element.description.length > 100 ? "..." : "" }}
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.status }}
      </td>
    </ng-container>
    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef>Images</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="this.imagePath + '/' + element.image_url"
          alt="Notice Image"
          class="notice-image"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-items-center">
          <button mat-button class="view_button" (click)="viewItem(element)">
            View
          </button>
          <button
            class="action_button"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [disabled]="reorderMode"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            class="update_button"
            *ngIf="editId !== element.id"
            (click)="editItem(element)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button
            mat-menu-item
            class="delete_button"
            (click)="deleteApi(element.id)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      cdkDrag
      [cdkDragDisabled]="!reorderMode"
      [class.drag-disabled]="!reorderMode"
      [cdkDragStartDelay]="50"
    ></tr>
  </table>
</section>
