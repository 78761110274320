<section class="m-4 p-4">
  <h2 class="m-0">
    Add
    {{
      isEditMode === false
        ? "Mission/Vision"
        : data.rowData?.content_type === "Mission"
        ? "Mission"
        : "Vision"
    }}
  </h2>
  <mat-divider class="my-3"></mat-divider>

  <div class="d-flex flex-column flex-md-row align-items-start">
    <!-- Left Form Section -->
    <form [formGroup]="missionVissionForm" class="flex-grow-1 pe-md-4">
      <div class="mb-3">
        <div class="form-group">
          <app-question
          [question]="questions[0]"
            [form]="missionVissionForm"
            class="mission-vision-textarea"
          ></app-question>
          <div [class.disabled]="isEditMode">
            <app-question
              [question]="questions[1]"
              [form]="missionVissionForm"
              class="mission-vision-textarea"
            ></app-question>
          </div>
        </div>
      </div>
    </form>

    <!-- Right Image Upload Section -->
    <div class="selected-image-container">
      <div class="upload-box" (click)="fileInput.click()">
        <input
          #fileInput
          hidden
          type="file"
          (change)="imageInputChange($event)"
          accept="image/*"
        />

        <!-- Placeholder Image / Upload Prompt -->
        <div *ngIf="!selectedImageUrl" class="upload-placeholder">
          Click to upload an image
        </div>

        <!-- Display Newly Uploaded Image -->
        <img
          *ngIf="selectedImageUrl"
          [src]="selectedImageUrl"
          alt="Selected Image"
          class="selected-image"
        />
      </div>
    </div>
  </div>

  <mat-dialog-actions align="center" class="bg-white pt-4">
    <div class="d-flex gap-3">
      <button mat-button class="cancel-btn" (click)="closeDialog(false)">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="missionVissionForm.invalid"
        type="submit"
        (click)="onSubmit()"
      >
        {{ isEditMode === false ? "Submit" : "Update" }}
      </button>
    </div>
  </mat-dialog-actions>
</section>
