<section class="my-3">
  <div class="mx-1 d-flex justify-content-between align-items-center">
    <h3 class="m-0 fw-bold">Home Page - Notice Board</h3>
    <button mat-raised-button color="primary" class="add_button" (click)="openUploadDialog()">
      + Upload Notice
    </button>
  </div>
  <table mat-table [dataSource]="noticeboardData" class="mat-elevation-z8">
    <!-- Upload Date Column -->
    <ng-container matColumnDef="visible_from">
      <th mat-header-cell *matHeaderCellDef> From Date </th>
      <td mat-cell *matCellDef="let element"> {{ element.visible_from | date:'dd/MM/yyyy' }} </td>
    </ng-container>
    <ng-container matColumnDef="visible_until">
      <th mat-header-cell *matHeaderCellDef> To Date </th>
      <td mat-cell *matCellDef="let element"> {{ element.visible_until | date:'dd/MM/yyyy' }} </td>
    </ng-container>

    <!-- Notice Info Column -->
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef> Notice Board Info </th>
      <td mat-cell *matCellDef="let element" matTooltip="{{ element.description }}" class="cursor_pointer"
        matTooltipPosition="below">
        {{ element.description | slice:0:50 }}{{ element.description.length > 100 ? '...' : '' }}
      </td>

    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        {{ element.is_homepage_popup ? 'Active' : 'Inactive' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef> Images </th>
      <td mat-cell *matCellDef="let element">
        <img [src]="this.imagePath + '/' + element.image_url" alt="Notice Image" class="notice-image" />
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-items-center">
          <button mat-button class="view_button" (click)="viewItem(element)">View</button>
          <button class="action_button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="update_button" *ngIf="editId !== element.id" (click)="editItem(element)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item class="delete_button" (click)="deleteApi(element.id)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Header and Row Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>