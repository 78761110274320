import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { Observable, of } from 'rxjs';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
@Injectable({
  providedIn: 'root'
})
export class TimetablesService {
  academicyeardata: any = [];
  shiftdropdata: any = [];
  organisationdata: any = [];
  Coursesdata: any = [];
  semister_drop: any = [];
  selectedAccademichValue: any;

  weekdays: { key: string; id: string; value: number; }[] = [
    { key: 'monday', id: 'MONDAY', value: 1 },
    { key: 'tuesday', id: 'TUESDAY', value: 2 },
    { key: 'wednesday', id: 'WEDNESDAY', value: 3 },
    { key: 'thursday', id: 'THURSDAY', value: 4 },
    { key: 'friday', id: 'FRIDAY', value: 5 },
    { key: 'saturday', id: 'SATURDAY', value: 6 },
    { key: 'sunday', id: 'SUNDAY', value: 7 }
  ];
  selectedShfithValue: any;
  selectedCourseValue: number = 0;
  Sectiondropdata: any = [];

  constructor(private apiservices: ApiservicesService, private dropdownservice: DropdownservicesService) {
    // this.apiservices.getacademicyeardrop().subscribe((res: any) => {
    //   for (let i = 0; i < res.length; i++) {
    //     this.academicyeardata?.push({ id: res[i].id, key: res[i].name, value: res[i].id })
    //   }
    // });
    // this.apiservices.getshiftdrop().subscribe((res: any) => {
    //   for (let i = 0; i < res.length; i++) {
    //     this.shiftdropdata?.push({ id: res[i].id, key: res[i].name, value: res[i].id })
    //   }
    // });
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('COURSES', this.Coursesdata);
    // this.loadDropdownData('SEMISTER', this.semister_drop);
    this.loadDropdownData('BATCHES', this.academicyeardata);
  }

  private loadDropdownData(code: string, dataArray: any[] = []) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.dropdownservice.getorganizationdrop();
    } else if (code === 'DEPARTMENTS') {
      observable = this.dropdownservice.getdepartmentdrop();
    } else if (code === 'BATCHES') {
      observable = this.dropdownservice.getacademicyeardrop();
    } else if (code === 'COURSES') {
      observable = this.dropdownservice.getcoursesbybatch(this.selectedAccademichValue);
    } else if (code === 'SEMISTER') {
      observable = this.dropdownservice.getcoursewisesemester({ course_ID: this.selectedCourseValue });
    } else if (code === 'USER_ROLES') {
      observable = this.dropdownservice.getuserroledropdown();
    } else if (code === 'SHIFT') {
      observable = this.dropdownservice.getshiftdropbyaccademic(this.selectedAccademichValue);
    } else if (code === 'SECTIONS') {
      observable = this.dropdownservice.gettablesectionsdrop({ course_ID: this.selectedCourseValue, academic_year_id: this.selectedAccademichValue });
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      // if(res instanceof Array){
      for (let i = 0; i < res?.length; i++) {
        let lookupname;
        if (code === 'BATCHES' || code === 'SEMISTER' || code === 'SECTIONS') {
          lookupname = res[i]?.name;
        } else if (code === 'COURSES') {
          if (res[i]?.medium_name !== 'ENGLISH') {
            lookupname = res[i]?.name + '(' + res[i]?.medium_name + ')';
          } else {
            lookupname = res[i]?.name;
          }
        } else if (code === 'SHIFT') {
          lookupname = res[i]?.name;
        } else if (code === 'DEPARTMENTS') {
          lookupname = res[i].department_name;
        } else if (code === 'USER_ROLES') {
          lookupname = res[i].name;
        } else {
          lookupname = res[i].lookup_name;
        } if (lookupname != null) {
          dataArray.push({
            id: res[i].id,
            key: lookupname,
            value: res[i].id,
          });
        }
      }
      // }else{
      //   this.password = res.password
      // }
    });
  }

  onAccademicyearChange(selectedValue: any): void {
    this.selectedAccademichValue = selectedValue;

    this.shiftdropdata.splice(0, this.shiftdropdata.length);
    this.Coursesdata.splice(0, this.Coursesdata.length);

    this.loadDropdownData('SHIFT', this.shiftdropdata);
    this.loadDropdownData('COURSES', this.Coursesdata);
    if (this.selectedCourseValue) {
      this.Sectiondropdata.splice(0, this.Sectiondropdata.length);
      this.loadDropdownData('SECTIONS', this.Sectiondropdata);
    }
  }
  // onShiftChange(selectedValue: any): void {
  //   this.selectedShfithValue= selectedValue;
  //   this.loadDropdownData('COURSE', this.shiftdropdata);
  // }
  onCourseChange(selectedValue: any): void {
    this.selectedCourseValue = selectedValue;
    this.semister_drop.splice(0, this.semister_drop.length);
    this.loadDropdownData('SEMISTER', this.semister_drop);
    if (this.selectedAccademichValue) {
      this.Sectiondropdata.splice(0, this.Sectiondropdata.length);
      this.loadDropdownData('SECTIONS', this.Sectiondropdata);
    }
  }

  getTimeSlotsform() {

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'academic_calender_id',
        label: 'Academic Year',
        type: 'Number',
        required: true,
        options: this.academicyeardata,
        order: 1,
        onChange: this.onAccademicyearChange.bind(this)
      }),
      new DropdownQuestion({
        key: 'shift_id',
        label: 'Shifts',
        type: "Number",
        required: true,
        options: this.shiftdropdata,
        order: 2
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getAddTimeSlotform() {

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'start_day',
        label: 'Start Day',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 1
      }),
      new TimepickerQuestion({
        key: 'start_time',
        label: 'Start Time',
        type: "time",
        required: true,
        order: 2
      }),
      new TimepickerQuestion({
        key: 'end_time',
        label: 'End Time',
        type: "time",
        required: true,
        order: 3
      }),
      new DropdownQuestion({
        key: 'end_day',
        label: 'End Day',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 4
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getAddTimetableform() {

    const questions: Inputfiledsbase<string>[] = [
      // new DropdownQuestion({
      //   key: 'organization_id',
      //   label: 'Organization',
      //   type: 'Number',
      //   required: true,
      //   options: this.organisationdata,
      //   order: 1
      // }),
      new DropdownQuestion({
        key: 'academic_year_id',
        label: 'Academic Year',
        type: 'Number',
        required: true,
        options: this.academicyeardata,
        order: 2,
        onChange: this.onAccademicyearChange.bind(this)
      }),
      new DropdownQuestion({
        key: 'shift_id',
        label: 'Shift',
        type: "number",
        required: true,
        options: this.shiftdropdata,
        order: 3,
        // onChange: this.onShiftChange.bind(this)
      }),
      new DropdownQuestion({
        key: 'course_id',
        label: 'Course',
        type: 'Number',
        required: true,
        options: this.Coursesdata,
        order: 4,
        onChange: this.onCourseChange.bind(this)
      }),
      new DropdownQuestion({
        key: 'semister_id',
        label: 'Semister',
        type: 'Number',
        required: true,
        options: this.semister_drop,
        order: 5
      }),
      new DropdownQuestion({
        key: 'section_id',
        label: 'Section',
        type: "number",
        required: false,
        options: this.Sectiondropdata,
        order: 6
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
