import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuService } from './services/menu.service';
import { PermissionService } from './services/permission.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    userSubject: any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private menuDataService: MenuService,
        private permissionService: PermissionService
    ) {
        this.userSubject = JSON.parse(`${typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : null}`);
     }

    // canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     const user = new BehaviorSubject<any>(typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : undefined);
    //     if (user === undefined) {
    //         this.router.navigate(['/login']);
    //         return false;
    //         // logged in so return true
    //     } 
    //     else {
    //         // not logged in so redirect to login page with the return url
    //         // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    //         return true;
    //     }
    // }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const currentRoute = next.url[0].path;
        let menuItem = this.menuDataService?.menulist()?.find((item: any) => {
            if (item?.subMenuflag == true) {
                const submenulink = item?.SubMenu?.find((submenu: any) => {
                    this.permissionService.actionsdata = submenu?.actions;
                    this.permissionService.hasPermission(submenu?.actions);
                    return submenu?.link === `/${currentRoute}`
                })
                return submenulink
            } else {
                this.permissionService.actionsdata = item.actions;
                this.permissionService.hasPermission(item.actions);
                return item.link === `/${currentRoute}`
            }
        });

        // if (menuItem && this.userSubject.data.onboarding_status) {
        if (menuItem) {
            return true; // Allow access to the route
        }
        else {
            this.router.navigate(['/not-authorized']); // Redirect to "not authorized" page
            return false;
        }
    }
}