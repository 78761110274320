<div class="header_fixed">
  <mat-toolbar class="background-header-color">
    <div class="">
      <img src="../../../assets/logo/logo.png" width="218px" />
    </div>
    <span class="title" routerLink="/"></span>
    <!-- <button class="action_button" (click)="menuToggled.emit(true)" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button> -->
    <ng-container *ngIf="userdata?.data?.sub_orgs_data.length > 0">
      <div class="ms-4 cursor_pointer" [matMenuTriggerFor]="Submenu">
        <img src="../../../assets/icons/suborg_Icon.svg" />
      </div>
      <mat-menu #Submenu="matMenu">
        <div *ngFor="let suborgsdata of userdata?.data?.sub_orgs_data; let i = index; last as isLast">
          <div class="hr_line my-2 cursor_pointer"
            [ngStyle]="{'border-left': selected_sub_org?.id == suborgsdata?.id ? '4px solid #0056B5' : 'none'} ">
            <div class="px-3 py-2 action_button line-height-15" (click)="Storesuborgdata(suborgsdata)">
              <p class="m-0 fs-14"> {{suborgsdata?.sub_organization_name}}</p>
              <small class="m-0 fs-12 text_color_light"> {{suborgsdata?.city}}</small>
            </div>
            <!-- <hr *ngIf="!isLast" class="hr_position text_color_light my-2"/> -->
          </div>
          <mat-divider *ngIf="!isLast" class="hr_position text_color_light my-2 mx-3"></mat-divider>
        </div>
      </mat-menu>
    </ng-container>
    <span class="spacer"></span>
    <span class="welcome-text">{{ user }}</span>
    <button mat-icon-button class="action_button" [matMenuTriggerFor]="menu">
      <mat-icon>person_pin</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item class="action_button" (click)="gotoprofile()">
        <span>Profile</span>
      </button>
      <button mat-menu-item class="action_button" (click)="logout()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>