import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, NgModule, OnInit } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth, endOfMonth, format } from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarA11y,
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent,
  CalendarModule,
  CalendarUtils,
  CalendarView,
  DateAdapter,
  CalendarEventTitleFormatter,
  CalendarCommonModule,
  CalendarMonthModule
} from 'angular-calendar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { I18nPluralPipe } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ThemePalette } from '@angular/material/core';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeavedialogboxComponent } from './Leaves/leavedialogbox/leavedialogbox.component';
import { CompoffdialogboxComponent } from './Leaves/compoffdialogbox/compoffdialogbox.component';
import { PermissiondialogboxComponent } from './Leaves/permissiondialogbox/permissiondialogbox.component';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { PermissionService } from '../../../components/Authentication/services/permission.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { RemoveUnderscorePipe } from "../../../components/Utilites/Pipe/remove-underscore.pipe";
import { StatusColorDirective } from '../../../components/Utilites/Directives/status-color.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

const colors: any = {
  red: {
    primary: '#EA4C4C',
    secondary: '#EA4C4C',
  },
  blue: {
    primary: '#7BB3F0',
    secondary: '#7BB3F0',
  },
  yellow: {
    primary: '#D4D813',
    secondary: '#D4D813',
  },
  green: {
    primary: '#55AB58',
    secondary: '#55AB58',
  },
  orange: {
    primary: 'orange',
    secondary: 'orange',
  },
  pink: {
    primary: '#FE6BDE',
    secondary: '#FE6BDE',
  },
  dark_blue: {
    primary: '#1d26f0',
    secondary: '#1d26f0',
  },
  dark_yellow: {
    primary: '#FFC107',
    secondary: '#FFC107',
  },
  black: {
    primary: 'black',
    secondary: 'black',
  }
};

const Representaion_data: any = [
  {
    title: 'Present',
    color: '#55AB58'
  },
  {
    title: 'Absent',
    color: '#EA4C4C'
  },
  {
    title: 'Holidays',
    color: '#D4D813'
  },
  {
    title: 'Weekly offs',
    color: '#7BB3F0'
  },
  {
    title: 'Leave Applied',
    color: '#FE6BDE'
  },
  {
    title: 'Leave Approved',
    color: '#1d26f0'
  },
  {
    title: 'Multiple Events',
    color: '#FFC107'
  },
]

export interface PeriodicElement {
  start_date: string;
  leave_mode: number;
  status: number;
  action: string;
}
@Component({
  selector: 'app-employeeatendance',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './employeeatendance.component.html',
  styleUrls: ['./employeeatendance.component.scss'],
  providers: [
    I18nPluralPipe,
    CalendarEventTitleFormatter,
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    CalendarDateFormatter,
    CalendarUtils,
    CalendarA11y,
  ]
})
export class EmployeeatendanceComponent implements OnInit {
  console = console;
  private yesterday!: Date;
  private today!: Date;
  private year: number = new Date().getFullYear();
  private month: number = new Date().getMonth();
  @ViewChild('modalContent')
  modalContent!: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  // excludeDays = [0, 6];
  color = 'green'
  total_present_days: number = 0;
  total_absent_days: number = 0;
  attendance_percentage: number = 0;
  modalData!: {
    action: string;
    event: CalendarEvent;
  };

  // actions: CalendarEventAction[] = [
  //   {
  //     label: 'sjdkf',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.handleEvent('Edited', event);
  //     },
  //   },
  //   {
  //     label: 'snkfd',
  //     onClick: ({ event }: { event: CalendarEvent }): void => {
  //       this.events = this.events.filter((iEvent) => iEvent !== event);
  //       this.handleEvent('Deleted', event);
  //     },
  //   },
  // ];
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  hover: boolean = false;
  showit: boolean = false;
  Representaion_data: any = Representaion_data;
  userdata: any;
  actiondata: any;
  displayedColumns: string[] = ['start_date', 'leave_mode', 'status', 'action'];
  dataSource = new MatTableDataSource<any>();;

  constructor(
    private apiservice: ApiservicesService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    this.today = new Date();
    this.userdata = JSON.parse(`${typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : undefined}`);
    // this.getattendancedetails()
  }

  ngOnInit(): void {
    this.getattendancedetails();
  }

  show(e: any) {
    let card = e.srcElement.children[0].children[2];
    card.classList.add('show');
  }

  leavep(e: any) {
    let card = e.srcElement.children[0].children[2];
    card.classList.remove('show');
  }

  leave(e: any) {
    let target = e.target;
    target.classList.remove('show');
  }

  yearmonth(event: Date) {
    this.events = [];
    let formated = new Intl.DateTimeFormat("en-US", {
      timeZone: 'Asia/Kolkata',
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(event);
    this.year = event.getFullYear();
    this.month = event.getMonth();
    this.getattendancedetails();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  hoverStateIn() {
    this.hover = !this.hover;
  }

  hoverStateOut() {
    this.hover = false;
  }

  formattimezone(date: any) {
    let formatted = date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatted
  }

  getattendancedetails() {
    // const monthEnd = endOfMonth(this.viewDate);
    this.events = [];
    // const month = format(monthEnd, 'yyyy-mm');
    this.apiservice.getattendancedetails(this.year, this.month + 1, this.userdata?.data.id).subscribe((res: any) => {
      // if(res.code === 200 && res.status === true){
      this.total_present_days = res?.total_present_days;
      this.total_absent_days = res?.total_absent_days;
      this.attendance_percentage = res?.attendance_percentage;
      this.dataSource = new MatTableDataSource<any>(res.leaves_data);
      res?.attendance_data.forEach((item: any, index: number) => {
        this.events.push({
          // id : index,
          // start: startOfDay(JSON.stringify(item.year + '-' + item.month + '-' + item.date)),
          start: startOfDay(item.date),
          // end: endOfDay(item.date),
          title: item.status === 'PRESENT' ? 'PRESENT' : 'ABSENT',
          color: item.status === 'PRESENT' ? colors.green : colors.red,
          draggable: false,
          // actions: this.actions,
          meta: {
            id: item?.id,
            entity: 'Show contains',
            start_time: item?.start_time,
            end_time: item?.end_time,
            status: item.status,
            duration: item?.duration,
            daytype: item?.status,
            notes: item.notes ? item.notes : ''
          }
        });
      });

      res?.weekoffs.forEach((item: any, index: number) => {
        this.events.push({
          // id : index,
          start: startOfDay(item.date),
          // end: endOfDay(item.end_date),
          title: 'Week Off',
          color: colors.blue,
          draggable: false,
          // actions: this.actions,
          meta: {
            // id: item.id,
            entity: 'Week Off',
            // start_time: item.start_time,
            // end_time: item.end_time,
            status: 'WEEKOFF',
            daytype: 'WEEKOFF',
            notes: 'Week Off'
          }
        });
      });

      res?.holidays.forEach((item: any, index: number) => {
        this.events.push({
          // id : index,
          start: startOfDay(item.date),
          // end: endOfDay(item.date),
          title: item.holiday_name,
          color: colors.yellow,
          draggable: false,
          // actions: this.actions,
          meta: {
            id: item?.id,
            entity: 'Show contains',
            start_time: item?.start_time,
            end_time: item?.end_time,
            status: item?.status,
            duration: item?.duration,
            daytype: 'HOLIDAY',
            notes: item?.holiday_name
          }
        });
      });

      res?.leaves_data.forEach((item: any, index: number) => {
        if (item.status && item.status !== 'CANCELLED' && item.status !== 'REJECTED') {
          this.events.push({
            // id : index,
            start: startOfDay(item.start_date),
            end: endOfDay(item.end_date),
            title: item.leave_name + ' ' + '(' + item.leave_mode + ')',
            color: this.leavecolors(item.status, item.leave_mode),
            draggable: false,
            // actions: this.actions,
            meta: {
              id: item.id,
              entity: 'Show contains',
              start_time: item.start_time,
              end_time: item.end_time,
              status: item.status,
              daytype: 'LEAVE',
              notes: item?.leave_type
            }
          });
        }
      });

      this.refresh.next(true);
      // }
    })
  }

  leavecolors(status:string, leavemode:string){
    if(status == 'APPROVED' && leavemode == 'FULL_DAY'){
      return colors.dark_blue;
    }else if (status == 'APPROVED' && leavemode == 'FORENOON'){
      return colors.dark_yellow;
    } else if (status == 'APPROVED' && leavemode == 'AFTERNOON'){
      return colors.dark_yellow;
    } else {
      return colors.pink;
    }
  }
  // leavecolors(event: any) {
  //   if (event === 'CANCELLED') {
  //     return colors.black
  //   } else if (event === 'PENDING') {
  //     return colors.red
  //   } else {
  //     return colors.pink
  //   }
  // }

  openleaveDialog(data: any, date?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      top: '0',
      right: '0'
    };
    dialogConfig.panelClass = ['animate__animated', 'animate__slideInRight']
    dialogConfig.width = '30%';
    dialogConfig.height = '100vh';
    dialogConfig.hasBackdrop = true;
    dialogConfig.backdropClass = 'custom-backdrop';
    dialogConfig.data = date;
    const dialogRef = this.dialog.open(data, dialogConfig);

    dialogRef.afterOpened().subscribe(() => {
      // // Ensure that the time picker appears above the dialog
      // const timePickerOverlay = document.querySelector('.ngx-material-timepicker-overlay') as HTMLElement;
      // if (timePickerOverlay) {
      //   timePickerOverlay.style.zIndex = '2000'; // Set a higher z-index for the time picker overlay
      // }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getattendancedetails()
      // Handle any actions after the dialog is closed (e.g., update data based on dialog result)
    });
  }

  handleAction(actionType: string, date: any): void {
    switch (actionType) {
      case 'leave':
        this.openleaveDialog(LeavedialogboxComponent, date);
        break;
      case 'compOff':
        this.openleaveDialog(CompoffdialogboxComponent, date);
        break;
      case 'permission':
        this.openleaveDialog(PermissiondialogboxComponent, date);
        break;
      default:
        break;
    }
  }
}



@NgModule({
  declarations: [
    EmployeeatendanceComponent
  ],
  exports: [EmployeeatendanceComponent, StatusColorDirective],
  imports: [
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,
    StatusColorDirective,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RemoveUnderscorePipe
  ],
})
export class EmployeeAtendanceModule { }