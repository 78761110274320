import { Component, Inject } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApplyleavesService } from '../../../../../inputfileds/form_services/applyleaves/applyleaves.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
@Component({
  selector: 'app-leavedialogbox',
  standalone: true,
  imports: [MatIconModule, MatDividerModule, MatDialogModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, ApplyleavesService, ApiservicesService, DropdownservicesService],
  templateUrl: './leavedialogbox.component.html',
  styleUrl: './leavedialogbox.component.scss'

})
export class LeavedialogboxComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  showaddflag: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datedata: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LeavedialogboxComponent>,
    private apiservice: ApiservicesService,
    private qcs: QuestionControlService,
    service: ApplyleavesService,
    private dropdownservice: DropdownservicesService
  ) {
    this.questions$ = service.getApplyedLeaves();
    this.questions$.subscribe(q => this.questions = q);
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(DialogContentExampleDialog);

  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.appending_date();
  }

  appending_date() {
    this.form.controls['start_date'].setValue(this.datedata.date);
    // this.form.patchValue('start_date', )
  }

  formattimezone(date: any) {
    let formatted = date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' });
    return formatted
  }

  onSubmit() {


    let payload = {
      ...this.form.getRawValue()
    };

    forkJoin([
      this.apiservice.getleavetypedetails(),
      this.dropdownservice.getlookupcodesdropdown('LEAVE_MODES')
    ]).subscribe(([LeaveTypeRes, LeaveModeRes]) => {
      const leaveTypes = LeaveTypeRes.rows; 

      if (Array.isArray(leaveTypes)) {
        const filteredleavetype = leaveTypes.filter((dropfilter: any) => {
          return dropfilter.id === this.form.value.leave_type_id;
        });
        payload = { ...payload, leave_type: filteredleavetype[0]?.name };
      } else {
        console.error('LeaveTypeRes.rows is not an array:', leaveTypes);
      }
    
      if (Array.isArray(LeaveModeRes)) {
        const filteredleavemode = LeaveModeRes.filter((dropfilter: any) => {
          return dropfilter.id === this.form.value.leave_mode_id;
        });
        payload = { ...payload, leave_mode: filteredleavemode[0]?.lookup_name };
      }
      payload.start_date = this.formattimezone(this.form.value.start_date);
      payload.end_date = this.formattimezone(this.form.value.end_date);
      this.createAPi(payload);
    });
  }

  createAPi(event: any) {
    this.apiservice.applyemployeeleaves(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.form.reset();
        this.Canceldailog()
      }
    })
  }

  Canceldailog(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialogRef.close(); }, 1000);
  }
}
