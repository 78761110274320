<section class="container">
  <app-toast></app-toast>
  <div class="row">
    <div class="col-sm-12 col-lg-8 col-12 col-xl-8">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="d-flex align-items-center">
          <button mat-icon-button class="back_icon" (click)="closeClasses()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <h4 class="m-0 ps-2"><span>{{ classesData?.course_name }} </span> - <span>{{ classesData?.year }} Year </span>
            <span *ngIf="classesData?.section">,{{ classesData?.section }} Sec </span></h4>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="submitstudentattendance()">Save</button>
        </div>
      </div>
      <!-- <div class="position-relative"> -->
        @if (isLoadingResults || isRateLimitReached) {
          <div class="loading-shade">
            @if (isLoadingResults) {
              <mat-spinner class="mat_spinner"></mat-spinner>
            }
            @if (isRateLimitReached) {
              <div class="rate-limit-reached">
                GitHub's API rate limit has been reached. It will be reset in one minute.
              </div>
            }
          </div>
        }
      <div class="table-container">
        <mat-table [dataSource]="StudentList">
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <!-- <mat-checkbox (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox> -->
                <div class="d-flex align-items-center" [matMenuTriggerFor]="menu">
                  <span>Select</span>
                  <mat-icon>more_vert</mat-icon>
                </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="toggleAllRows('PRESENT')">Present</button>
                <button mat-menu-item (click)="toggleAllRows('ABSENT')">Absent</button>
              </mat-menu>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) && toggleStatus(row, $event.checked) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.student_id }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.student_name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- <mat-icon [ngClass]="{
                  absent: element.status == 'ABSENT',
                  present: element.status == 'Present'
                }" (click)="toggleStatus(element)" class="status-toggle">
                {{ element.status === 'ABSENT' ? 'toggle_off' : 'toggle_on' }}
              </mat-icon>
              <span class="status-text">{{element.status === 'ABSENT' ? 'Absent' : 'Present'}}</span> -->

              <mat-slide-toggle
              class="example-margin"
              (click)="toggleStatus(element, element.status === 'ABSENT' ? 'PRESENT' : 'ABSENT');selectioncheckbox(element)"
              [checked]="element.status == 'PRESENT'"
              >
              <p class="status-text">{{element.status}}</p>
          </mat-slide-toggle>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tillDate">
            <mat-header-cell *matHeaderCellDef> Till Date % </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="progress-bar-container">
                <mat-progress-bar mode="determinate" [value]="element.tillDate" [ngClass]="{
                  'progress-red': element.tillDate < 50,
                  'progress-green': element.tillDate > 50
                }"></mat-progress-bar>
              </div>
              <span>{{ element.tillDate }}%</span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" ></mat-row>
          <mat-row *matNoDataRow>
            <mat-cell colspan="4">No data</mat-cell>
          </mat-row>
        </mat-table>
      </div>
    </div>
    <ng-container *ngIf="studentsattendancedetailschart?.absenteesCount == 0 && studentsattendancedetailschart?.presenteesCount == 0">
    <div class="col-sm-12 col-lg-4 col-xl-4 col-12">
      <div>
        <mat-card class="p-3">
          <h2>Attendance Data</h2>
          <div class="chart-container">
            <div style="display: block">
              <canvas baseChart id="MyChart">{{ chart }}</canvas>
            </div>
          </div>
        </mat-card>
        <mat-card class="p-3 mt-3">
          <h3>Absentees Data</h3>
          <mat-list>
            <mat-list-item *ngFor="let item of absenteesData" class="list-item p-0">
              <span class="item-id">{{ item.student_id }}</span>
              <span class="item-name">{{ item.student_name }}</span>
              <span class="icon-container">
                <mat-icon class="icon">comment</mat-icon>
              </span>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </div>
    </div>
  </ng-container>
  </div>
<!-- </div> -->

</section>