import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs';

export interface TimeTableView {
  index: number;
  course_id: number;
  academic_year: number;
  shift_id: string;
  semister_id: string;
  section: string;
}

@Component({
  selector: 'app-viewtimetable',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatProgressSpinnerModule
  ],
  providers: [ApiservicesService],
  templateUrl: './viewtimetable.component.html',
  styleUrl: './viewtimetable.component.scss'
})
export class ViewtimetableComponent {

  isLoadingResults = true;
  isRateLimitReached = false;
  dataSource = new MatTableDataSource<TimeTableView>();
  selection = new SelectionModel<TimeTableView>(true, []);
  displayedColumns: string[] = [
    'Monday',
    'Tuesday',
    'Wed',
    'Thur',
    'Fri',
    'Sat',
  ];

  periods = [
    '9:00 - 9:50', '9:50 - 10:40', '10:40 - 11:30', '11:30 - 12:20',
    '12:20 - 1:10', '1:10 - 2:00', '2:00 - 2:50', '2:50 - 3:40', '3:40 - 4:30'
  ];

  timetableData: any;
  timetabledisplaydata: any;
  routetimetableData: any;

  // timetableData = [
  //   {
  //     MONDAY: [
  //       { subject: 'Maths', teacher: 'G. Anchana', period: '1st', time: '9:00 - 9:50' },
  //       { subject: 'MPC', teacher: '', period: '2nd', time: '9:50 - 10:40' },
  //       { subject: 'Break', teacher: '', period: '', time: '10:40 - 11:00', type: 'break' }, // Break Period
  //       { subject: 'MPCS', teacher: '', period: '3rd', time: '11:00 - 11:50' },
  //       { subject: 'Lunch Break', teacher: '', period: '', time: '12:50 - 1:50', type: 'lunch' } // Lunch Period
  //       // Other periods...
  //     ],
  //   },
  //   {
  //     TUESDAY: [
  //       { subject: 'MDCS', teacher: '', period: '1st', time: '9:00 - 9:50' },
  //       // Add break periods here too...
  //       { subject: 'Break', teacher: '', period: '', time: '10:40 - 11:00', type: 'break' }, // Break Period
  //       { subject: 'MPCS', teacher: '', period: '3rd', time: '11:00 - 11:50' },
  //       { subject: 'Lunch Break', teacher: '', period: '', time: '12:50 - 1:50', type: 'lunch' } // Lunch Period
  //       // Other periods...
  //     ],
  //   },
  //   // ... Other days
  // ];

  constructor(
    private apiservice: ApiservicesService,
    private activatedRoute: ActivatedRoute
  ) {
    // console.log("hduehdia", window.history.state)
    this.routetimetableData = window.history.state.tabledata;
   }

  ngOnInit(): void {
    this.gettimetabledata(this.routetimetableData.id);
  }

  getDayName(dayObject: any): string {
    return Object.keys(dayObject)[0];
  }

  getDaydata(dayObject: string) {
    // let objectkey = Object.keys(dayObject)
    let objectdata = this.timetableData[dayObject as keyof typeof this.timetableData] ? this.timetableData[dayObject as keyof typeof this.timetableData].sort((a: { order: number; }, b: { order: number; }) => a.order - b.order) : '';
    // console.log("get data from day data=============", dayObject, this.timetableData[dayObject as keyof typeof this.timetableData])
    return objectdata;
  }

  keys() : Array<string> {
    let datafilter
    Object.keys(this.timetableData).forEach((key:string)=>{
      // console.log(this.timetableData.days[key]);
      });
    // console.log("get data from days array", Object.keys(this.timetableData))
    return Object.keys(this.timetableData);
  }

  gettimetabledata(event:number) {
    this.apiservice.getviewTimeTabledata({ timetable_id: event }).subscribe((res) => {
      // console.log("time table slots", res)
      // this.courseList = res.rows
      this.timetabledisplaydata = res;
      // this.timeslotheader = res.headersForTimeSlots
      this.timetableData = res.days[0];
      this.dataSource = new MatTableDataSource<TimeTableView>(res.time_table_details);
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

  @ViewChild("screen") screen!: ElementRef;
  @ViewChild("canvas") canvas!: ElementRef;
  @ViewChild("downloadLink") downloadLink!: ElementRef;

  downloadImage() {
    html2canvas(this.screen.nativeElement).then((canvas: any) => {
      // this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL("image/png");
      this.downloadLink.nativeElement.download = "Time Table";
      this.downloadLink.nativeElement.click();
    });
  }
}
