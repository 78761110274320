import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { Router } from '@angular/router';
import { RemoveUnderscorePipe } from '../../components/Utilites/Pipe/remove-underscore.pipe';

@Component({
  selector: 'app-onboardingscreens',
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    RemoveUnderscorePipe
],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  templateUrl: './onboardingscreens.component.html',
  styleUrl: './onboardingscreens.component.scss'
})
export class OnboardingscreensComponent implements OnInit {

  userdetails: any;
  readonly panelOpenState = signal(false);
  state: any = 1;
  control_stepper: boolean = false;
  step = signal(0);
  onboarding_data: any;

  constructor(
    private apiservice: ApiservicesService,
    private router: Router
  ) {
    let userdata: any = sessionStorage.getItem("user");
    this.userdetails = JSON.parse(userdata);
  }

  ngOnInit(): void {
    this.getonboarding_data();
  }

  state_function_display(event: any) {
    this.state = event;
  }

  getrouting(event: any) {
    switch (event.name ? event.name : event.module_name) {
      case 'COURSES':
        this.router.navigate(['/courses']);
        break;
      // default :
      case "STREAMS":
        this.router.navigate(['/stream']);
        break;
      case "DEPARTMENTS":
        this.router.navigate(['/department']);
        break;
      case "ACADEMIC_REGULATIONS":
        this.router.navigate(['/academicregulations']);
        break;
      case "ACADEMIC_CALENDAR":
        this.router.navigate(['/academicyears']);
        break;
    }
  }
  state_control() {
    this.control_stepper = true;
    // this.state='done' 
    this.state = 2
  }

  getonboarding_data() {
    this.apiservice.getonboardingstatus().subscribe((res: any) => {
      this.onboarding_data = res.rows;
      this.onboarding_data = this.onboarding_data.sort((a:any, b:any) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
    })
  }
}
