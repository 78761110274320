import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { CreatepageserviceService } from '../../../../inputfileds/form_services/web_activities/createpageservice.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-add-activity-dialog',
  templateUrl: './addactivitydialog.component.html',
  styleUrls: ['./addactivitydialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatButtonModule,
  ],
  providers: [DatePipe],
})
export class AddactivitydialogComponent implements OnInit, OnDestroy {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<string>[]> | null = null;
  pagecreationForm: FormGroup;
  images: File[] = [];
  previewUrls: { image_url: string; image_id?: number }[] = [];
  private subscription = new Subscription();
  editflag: boolean = false;
  baseImageUrl = `${this.apiServices.environmentPath}/`;
  currentSlide: number = 0;

  // Track deleted images and remaining image IDs
  deletedImages: { image_id: number; image_url: string }[] = [];
  updatedImageUrls: { image_id: number; image_url: string }[] = [];
  remainingImageIds: number[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddactivitydialogComponent>,
    private createpageServices: CreatepageserviceService,
    private apiServices: ApiservicesService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA)
    public data: { pageId: string; activityData: any; editId: number }
  ) {
    console.log('Loading', data);

    this.pagecreationForm = this.fb.group({
      heading: ['', Validators.required],
      description: ['', Validators.required],
      date: ['', Validators.required],
      activityImages: ['', Validators.required],
      parent_web_page_id: ['', Validators.required],
    });

    if (data.activityData) {
      this.pagecreationForm.patchValue({
        heading: data.activityData.heading,
        description: data.activityData.description,
        date: data.activityData.date,
        parent_web_page_id: data.activityData.parent_web_page_id,
      });

      this.previewUrls = (data.activityData.image_urls || []).map(
        (urlObj: { image_url: string; image_id: number }) => ({
          image_url: `${this.baseImageUrl}${urlObj.image_url}`,
          image_id: urlObj.image_id,
        })
      );
      this.updatedImageUrls = (data.activityData.image_urls || []).map(
        (img: any) => ({
          image_id: img.image_id,
          image_url: img.image_url || '', // Ensure empty string for deleted images
        })
      );

      this.editflag = true;
    }

    this.loadQuestions();
  }

  ngOnInit(): void {}

  private loadQuestions(): void {
    this.questions$ = this.createpageServices.getCreateEvents();
    this.subscription.add(
      this.questions$.subscribe({
        next: (q) => (this.questions = q || []),
        error: (err) => console.error('Error loading questions:', err),
      })
    );
  }

  onImageUpload(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const uploadedFiles = Array.from(target.files);
      uploadedFiles.forEach((file) => {
        if (
          !this.images.some(
            (existingFile) =>
              existingFile.name === file.name && existingFile.size === file.size
          )
        ) {
          this.images.push(file);
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.previewUrls.push({ image_url: e.target.result }); // No image_id for new uploads
          };
          reader.readAsDataURL(file);
        }
      });
    } else {
      console.error('No files selected or invalid input.');
    }
  }
  deleteImage(index: number): void {
    if (index < 0 || index >= this.previewUrls.length) return;

    const deletedItem = this.previewUrls[index];

    // Handle existing backend images
    if (deletedItem.image_id) {
      this.deletedImages.push({
        image_id: deletedItem.image_id,
        image_url: deletedItem.image_url.replace(this.baseImageUrl, ''),
      });

      // Set the URL to empty for the deleted image in updatedImageUrls
      const updatedImage = this.updatedImageUrls.find(
        (img) => img.image_id === deletedItem.image_id
      );
      if (updatedImage) {
        updatedImage.image_url = ''; // Mark as deleted by setting URL to empty
      }
    }

    // Remove from preview
    this.previewUrls.splice(index, 1);

    // Handle new images separately
    if (!deletedItem.image_id) {
      const fileIndex = this.images.length - (this.previewUrls.length + 1);
      if (fileIndex >= 0) {
        this.images.splice(fileIndex, 1);
      }
    }

    // Reset current slide position
    this.currentSlide = Math.max(0, this.currentSlide - 1);
  }

  prevSlide(): void {
    if (this.previewUrls.length > 0) {
      this.currentSlide =
        (this.currentSlide - 1 + this.previewUrls.length) %
        this.previewUrls.length;
    }
  }

  nextSlide(): void {
    if (this.previewUrls.length > 0) {
      this.currentSlide = (this.currentSlide + 1) % this.previewUrls.length;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const formattedDate = this.datePipe.transform(
      this.pagecreationForm.value.date,
      'yyyy-MM-dd'
    );

    const formDataToSend = new FormData();

    // Append new images to FormData
    this.images.forEach((file) =>
      formDataToSend.append('activityImages', file)
    );

    formDataToSend.append('heading', this.pagecreationForm.value.heading);
    formDataToSend.append(
      'description',
      this.pagecreationForm.value.description
    );
    formDataToSend.append('date', formattedDate!);
    formDataToSend.append('parent_web_page_id', this.data.pageId);

    if (this.editflag) {
      formDataToSend.append(
        'image_urls',
        JSON.stringify(this.updatedImageUrls)
      );
      console.log('Form Data:', formDataToSend);
      const editId = this.data.editId;
      this.updatePage(editId, formDataToSend);
    } else {
      console.log('Form Data:', formDataToSend);
      this.createPage(formDataToSend);
    }
  }

  private createPage(pageData: FormData): void {
    this.apiServices.createActivitiesEventData(pageData).subscribe({
      next: (response) => {
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error creating page:', err),
    });
  }

  private updatePage(pageId: number, pageData: FormData): void {
    this.apiServices.updateActivityEvents(pageId, pageData).subscribe({
      next: (response) => {
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error updating activity:', err),
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
