<section class="p-3">
  <div class="position-relative pt-2">
    <div class="d-flex justify-content-between">
      <div>
        <h2>Apply Permission</h2>
      </div>
      <div class="cursor_pointer" (click)="Canceldailog()">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="form">
      <ng-container>
        <div class="container pt-3">
          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="row">
              <div *ngFor="let question of questions" [ngClass]="question?.position === 'FULL' ? 'col-sm-12 col-md-12 col-lg-12 col-12 mb-3': 'col-sm-6 col-md-6 col-lg-6 col-12 mb-3'">
                <app-question [question]="question" [form]="form"></app-question>
              </div>
            </div>
            <div class="form-row mt-3">
              <button mat-flat-button color="primary" class="w-100 px-5 submit_button" type="submit" [disabled]="!form.valid">
                Save
              </button>
            </div>
          </form>

          <!-- <div *ngIf="payLoad" class="form-row">
                      <strong>Saved the following values</strong><br>{{payLoad}}
                    </div> -->
        </div>
      </ng-container>
    </div>
  </div>
</section>