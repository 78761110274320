import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { CoursesService } from '../../../inputfileds/form_services/courses/courses.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddacademicregulationsComponent } from './addacademicregulations/addacademicregulations.component';
import { AcademicsService } from '../../../inputfileds/form_services/academics/academics.service';
import { ToastComponent } from '../../../components/toast/toast.component';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { ToastService } from '../../../Services/toast/toast.service';
import { SectionsService } from '../../../inputfileds/form_services/academics/sections/sections.service';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { PermissionService } from '../../../components/Authentication/services/permission.service';

export interface AcademicsView {
  index: number;
  id: number;
  organization_id: number;
  code: string;
  courses: { id: number; name: string }[];
  start_year: number;
  end_year: number;
}

@Component({
  selector: 'app-academicregulations',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatProgressSpinnerModule,
    CommonModule,
    ToastComponent,
    MatIconModule,
    PaginationComponent,
  ],
  providers: [QuestionControlService, ApiservicesService, SectionsService],
  templateUrl: './academicregulations.component.html',
  styleUrls: ['./academicregulations.component.scss'],
})
export class AcademicregulationsComponent implements OnInit {

  displayedColumns: string[] = ['code', 'courses', 'start_year', 'Sections', 'actions'];

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null | undefined;
  createsectionForm!: FormGroup;
  createsection: Inputfiledsbase<any>[] | null = [];
  createsection$: Observable<Inputfiledsbase<any>[]> | null | undefined;
  BatchData: any;
  sectionData: any;
  dataSource = new MatTableDataSource<AcademicsView>();
  selection = new SelectionModel<AcademicsView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  acadamicsList: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  courseData: any;
  total_count: any;
  paginationData: any;
  datasend: any;
  @ViewChild('createSectionDialog') createSectionDialog!: TemplateRef<any>;
  selectedregulationdata: any;
  actiondata: any;
  
  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private router: Router,
    private qcs: QuestionControlService,
    private toastService: ToastService,
    private dataService: SectionsService,
    private service: AcademicsService,
    private fb: FormBuilder,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    if (this.actiondata.view === false) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }
    this.questions$ = dataService.getRegulationstudentfilterForm({});
    this.questions$.subscribe(q => this.questions = q);
    // this.createsection$ = this.dataService.getCreatebatchSectionForm();
    // this.createsection$.subscribe(questions => this.createsection = questions);
    // this.createsectionForm = this.qcs.toFormGroup(this.createsection as Inputfiledsbase<string>[]);
  }

  ngOnInit(): void {
    this.getacadamicsdetails();
  }
  pagination(event: any) {
    this.paginationData = event;
    this.getacadamicsdetails();
  }

  getacadamicsdetails() {
    this.apiservice.getacadamicslist(this.paginationData).subscribe((res) => {
      this.acadamicsList = res.rows;
      this.total_count = res.total_count;
      this.dataSource = new MatTableDataSource<AcademicsView>(
        this.acadamicsList
      );
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

  openAddAcademicsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '80%';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    const dialogRef = this.dialog.open(
      AddacademicregulationsComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log('dialog result', result);
      if (result) {
        this.getacadamicsdetails();
        console.log('Dialog result:', result);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: AcademicsView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.index + 1
    }`;
  }

  showsemesterdetails(id: any) {
    this.router.navigate(['academicregulations/academicregulationsview'], { state: { data: id } });
  }

  filteraddbutton(event:any): any {
   const filterdata = event.filter((items: any) => items.is_selected == true)
   if(event.length == filterdata.length){
    return false
   }else{
    return true
   }
    }
  
  openCreateSectionDialog(event:any): void {
    console.log('selected data', event)
    this.selectedregulationdata = event;
    this.createsection$ = this.dataService.getCreatebatchSectionForm(event.sections);
    this.createsection$.subscribe(questions => this.createsection = questions);
    this.createsectionForm = this.qcs.toFormGroup(this.createsection as Inputfiledsbase<string>[]);
    // this.createsectionForm.get('section_id').valueChanges.subscribe(item => {
    //   this.units = item.units
    // })
   console.log("data sections", event.sections)

    const demodata = event.sections.filter((section: any) => section.is_selected == true);
    console.log("demo data sections", demodata)

    const filtetrdata=[];
    for (let i = 0; i < demodata.length; i++) {
      filtetrdata.push({
        id: demodata[i].id,
        name: demodata[i].name
        // value: demodata[i].id,
      });
    }
   const setdata = {'section_id' : filtetrdata}
   console.log("fliter data sections", filtetrdata)
    // this.createsectionForm.controls['section_id'].patchValue(filtetrdata);
    this.createsectionForm.setValue({
      section_id: filtetrdata,
      });
      // this.createsectionForm.addControl(
      //   'section_id',
      //  filtetrdata
      // );
      // this.createsectionForm.value.section_id = filtetrdata
    const dialogRef = this.dialog.open(this.createSectionDialog, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  closeDialog(event: any) {
    this.dialog.closeAll();
    // const dialogRef = this.dialog.open(this.createSectionDialog);
    // this.getSectionsDetails();
    this.getacadamicsdetails()
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialog.closeAll();
    }, 1000);
  }

  onSaveClick(): void {
    this.dialog.closeAll();
  }

  // backPage(): void {
  //   this.router.navigate(['academicregulations/academicregulations']).then(() => {
  //     this.BatchData;
  //   });
  // }
  
  onSubmit() {
    this.datasend = {
      organization_id: this.selectedregulationdata.organization_id,
      course_id: this.selectedregulationdata.course_id,
      academic_regulation_id: this.selectedregulationdata.id,
      academic_year_id: this.selectedregulationdata.academic_year_id,
      sections:  this.createsectionForm.value.section_id,
    };
    this.createAPi(this.datasend);
  }

  createAPi(event: any) {
    this.apiservice.createacadamicssections(event).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.toastService.showToast('success', 'Saved Successfully');
          this.closeDialog(true);
        } else if (res.status === false) {
          this.toastService.showToast('danger', res.message);
        }
      },
      (error) => {
        this.toastService.showToast('warning', `Error: ${error.message}`);
      }
    );
  }
}
