<section class="container py-4">
    <h2 mat-dialog-title>Add {{ this.data.title }} Documents</h2>
    <mat-dialog-content>
      <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
        <div class="questions">
          <div class="form-group">
            <label for="level">Level:</label>
            <select [(ngModel)]="selectedLevel" (ngModelChange)="onLevelSelected($event)" [ngModelOptions]="{standalone: true}">
              <option value="" disabled selected>Select Level</option>
              <option *ngFor="let level of levels" [value]="level">{{ level }}</option>
              <option value="Other">Other (Specify)</option>
            </select>
          
            <input
              type="text"
              *ngIf="showCustomInput"
              [(ngModel)]="customLevel"
              (input)="onCustomLevelInput()"
              placeholder="Enter custom level"
              [ngModelOptions]="{standalone: true}"
            />
          </div>
          
          
          <div *ngFor="let question of questions; trackBy: trackByQuestionKey">
            <app-question [question]="question" [form]="uploadForm"></app-question>
          </div>
        </div>
  
        <div
        class="upload-container"
        (click)="fileInput.click()"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        [class.dragging]="isDragging"
      >
      <input
      #fileInput
      hidden
      type="file"
      multiple
      (change)="documentInputChange($event)"
    />
      
    <ng-container *ngIf="selectedFiles.length === 0">
      <img src="../../../../../../assets/images/bulkupload_logo.svg" alt="Upload Banner" class="upload-icon" />
      <p class="upload-text">Drag & drop files here or click to upload</p>
    </ng-container>
    <ng-container *ngIf="selectedFiles.length > 0">
      <p class="upload-text">Click here to add more files</p>
    </ng-container>
      
        <!-- Display Selected File Names with Delete Option -->
        <ng-container *ngIf="selectedFiles.length > 0">
          <div class="file-list-container">
            <ul class="file-list">
              <li *ngFor="let file of selectedFiles; let i = index">
                {{ file.name }}
                <mat-icon class="delete-icon" (click)="removeSelectedFile(i, $event)">close</mat-icon>
              </li>
            </ul>
          </div>
        </ng-container>
      </div>
      

      </form>
    </mat-dialog-content>
  
    <mat-dialog-actions align="center">
      <button mat-raised-button (click)="onCancel()" class="add-doc">Cancel</button>
      <button mat-raised-button color="accent" class="add-more" type="submit" [disabled]="uploadForm.invalid" (click)="onSubmit()">Submit</button>
    </mat-dialog-actions>
  </section>