<section class="m-4">
  <div>
    <h2 class="m-0">Add Banners</h2>
  </div>
  <mat-divider></mat-divider>
  <form [formGroup]="bannersForm" class="pt-3">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="form-group">
          <app-question
            [question]="questions[0]"
            [form]="bannersForm"
          ></app-question>

          <!-- Centered Upload Box -->
          <div class="upload-container">
            <input
              #fileInput
              hidden="true"
              type="file"
              (change)="imageInputChange($event)"
            />
            <div
              class="upload-box"
              (click)="fileInput.click()"
            >
            <ng-container *ngIf="!selectedImageUrl">
              <img
                src="../../../../../../assets/images/bulkupload_logo.svg"
                alt="Upload Banner"
                class="upload-icon"
              />
            </ng-container>


              <!-- Display Selected Image -->
              <ng-container *ngIf="selectedImageUrl">
                <img
                  [src]="selectedImageUrl"
                  alt="Selected Image"
                  class="uploaded-image"
                />
              </ng-container>
            </div>
          </div>
          <div class="w-100">
             <app-question
            [question]="questions[1]"
            [form]="bannersForm"
          ></app-question>
          <app-question
            [question]="questions[2]"
            [form]="bannersForm"
          ></app-question>
          </div>
         
        </div>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="center" class="bg-white bottom-0 end-0">
    <div class="d-flex gap-2">
      <button
        mat-stroked-button
        (click)="closeDialog(false)"
        class="px-5"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        class="px-5 submit_button"
        type="submit"
        [disabled]="!bannersForm.valid"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </mat-dialog-actions>
</section>
