<h2 mat-dialog-title>{{ pageTitle }}</h2>
<mat-dialog-content>
  <div class="form-group">
    <div *ngFor="let question of questions; trackBy: trackByQuestionKey">
      <app-question [question]="question" [form]="pageForm"></app-question>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="onSubmit()"
    class="submit-button"
    aria-label="Submit the form"
  >
    {{ submitButtonText }}
  </button>
</mat-dialog-actions>