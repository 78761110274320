<section class="mx-3">
  <div class="d-flex justify-content-between align-items-center">
    <div class="page_header">
      <h3 class="m-0 page_header_title">Classes</h3></div>
    <div class="d-flex justify-content-center align-items-center bg-white date_filter mb-3">
      <button mat-icon-button class="chevron_left" (click)="previousDate()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-flat-button (click)="picker.open()" class="calendar-button">
        <span class="align-self-center">{{ selectedDate | date: 'dd MMMM yyyy' }}</span>
      </button>
      <button mat-icon-button class="chevron_right" (click)="nextDate()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <mat-form-field appearance="outline" class="hidden-datepicker">
        <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)" [(ngModel)]="selectedDate">
        <mat-datepicker-toggle class="datepicker input-group-text" [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="position-relative">
    @if (isLoadingResults || isRateLimitReached) {
    <div class="loading-shade">
      @if (isLoadingResults) {
      <mat-spinner class="mat_spinner"></mat-spinner>
      }
      @if (isRateLimitReached) {
      <div class="rate-limit-reached">
        GitHub's API rate limit has been reached. It will be reset in one minute.
      </div>
      }
    </div>
    }
  </div>
  <ng-container *ngIf="actiondata.list_view">
    <div class="card_container">
      <div class="row pt-3">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12 p-2" *ngFor="let class of filteredClasses">
          <mat-card class="item_card_classes h-100">
            <mat-card-header class="classes_card_header ps-3">
              <mat-card-title class="card_header_title">{{ class.start_time }}-{{ class.end_time }}</mat-card-title>
              <mat-card-subtitle class="card_header_subtitle">{{ class.subject }}</mat-card-subtitle>
              <!-- <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
                <div class="pt-1">
                  <mat-icon>more_vert</mat-icon>
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item >Edit</button>
                <button mat-menu-item >Delete</button>
              </mat-menu> -->
            </mat-card-header>
            <div (click)="actiondata.view ? openClasses(class) : '' ">
              <mat-card-content class="pt-2">
                <div class="mb-2">
                  <p class="m-0 card_text">{{ class.course_name }}</p>
                  <p class="m-0 card_subtext">{{ class.year }} Yrs, {{ class.section }} Sec</p>
                </div>
                <p class="">
                  Attendance Status:
                  <span [ngClass]="{
                    'text-success': class.attendance_status === 'Done',
                    'text-warning': class.attendance_status === 'Pending'
                  }">{{ class.attendance_status }}</span>
                </p>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-container>
</section>