<section class="mx-3">
    <div>
        <div>
            <div><h4>Requests Received </h4></div>
            <div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="color_card_background d-flex align-items-center" (click)="onclicktonavigate('PENDING')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.teamLeavesPendingCount}}</h1>
                                <small>Pending</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="color_card_background_2 d-flex align-items-center" (click)="onclicktonavigate('APPROVED')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.teamLeavesApprovedCount}}</h1>
                                <small>Approvals</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="color_card_background_3 d-flex align-items-center" (click)="onclicktonavigate('REJECTED')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.teamLeavesRejectedCount}}</h1>
                                <small>Declined</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <div><h4>My Requests</h4></div>
            <div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="color_card_background d-flex align-items-center" (click)="navigatetomyleaves('PENDING')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.myLeavesPendingCount}}</h1>
                                <small>Pending</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="color_card_background_2 d-flex align-items-center" (click)="navigatetomyleaves('APPROVED')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.myLeavesApprovedCount}}</h1>
                                <small>Approvals</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="color_card_background_3 d-flex align-items-center" (click)="navigatetomyleaves('REJECTED')">
                            <div class="px-3">
                                <h1 class="m-0">{{requestLeaveStats.myLeavesRejectedCount}}</h1>
                                <small>Declined</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>