import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { SemestersService } from '../../../../inputfileds/form_services/semesters/semesters.service';
import { AddcoursesComponent } from '../../addcourses/addcourses.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastService } from '../../../../Services/toast/toast.service';
import { ToastComponent } from '../../../../components/toast/toast.component';

export interface semestermodel {
  organization_id: number;
  course_id: number;
  semister: any;
  duration_in_months: any;
  year: Number;
}
@Component({
  selector: 'app-addsemesters',
  standalone: true,
  imports: [
    AddcoursesComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatExpansionModule,
    ToastComponent
  ],
  templateUrl: './addsemesters.component.html',
  styleUrl: './addsemesters.component.scss',
  providers: [QuestionControlService, SemestersService, ApiservicesService]
})

export class AddsemestersComponent {

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  semisterForm!: FormGroup;
  datesend!: semestermodel;
  errormessage: any;
  yearwisesemesterdata:any = [];
  yearsemester:any;
  step = 0;
  // @Input() courseData: any;
  courseData: any;
  @Output() backtocourseform: EventEmitter<any> = new EventEmitter();
  editflag: boolean = false;
  selectededit_id: any;
  remainingSemestersCount: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data : any,
    private dialogRef: MatDialogRef<AddsemestersComponent>,
    private apiservice: ApiservicesService,
    services: SemestersService,
    private router: Router,
    private qcs: QuestionControlService,
    private toast: ToastService,
  ) {
    this.courseData = data;
    this.questions$ = services.getSemestersForm();
    this.questions$.subscribe((q) => (this.questions = q));
  }

  ngOnInit(): void {
    this.remainingSemestersCount = this.courseData.number_of_semisters;
    this.semisterForm = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
  }

  getsemesteryearwise(yearsemester : any){
    const data ={
      "course_ID": this.courseData.id,
      "year": yearsemester
    }
    this.apiservice.getsemesteryearwise(data).subscribe((res: any) => {
            this.yearwisesemesterdata = res?.rows;
    })
  }

  backtocourse() {
    this.backtocourseform.emit();
  }
  
  closeDialog(event: any) {
    // this.dialogRef.close();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialogRef.close(event);
    }, 1000);
  }

  setStep(index: number, yearsemester: any) {
    this.step = index;
    this.yearsemester = yearsemester.year;
    this.getsemesteryearwise(this.yearsemester);
    this.semisterForm.reset();
  }

  editsemester(editdata: any) {
    this.editflag = true;
    this.selectededit_id = editdata.id;
    this.semisterForm.setValue({
      organization_id: editdata.organization_id,
      semister: editdata.semister,
      duration_in_months: editdata.duration_in_months,
    });

    // this.EditAPi(this.datesend, editdata.id)
  }

  deletesemester(deletedata: any) {
    this.apiservice.Deletesemester(deletedata.id).subscribe((res: any) => {
      if (res.code == 202 && res.status === true) {
        this.semisterForm.reset();
        this.getsemesteryearwise(this.yearsemester);
        // window.location.href = this.router.url;
        // this.closeDialog(true);
      } else {
        this.errormessage = res.message;
      }
    });
  }

  onSubmit() {
    this.datesend = this.semisterForm.getRawValue();
    this.datesend.course_id = parseInt(this.courseData.id);
    this.datesend.semister = parseInt(this.semisterForm.value.semister);
    this.datesend.duration_in_months = parseInt(
      this.semisterForm.value.duration_in_months
    );
    this.datesend.year = parseInt(this.yearsemester);
    this.createAPi(this.datesend);
  }

  createAPi(event: any) {
    this.apiservice.createsemester(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast('success', 'Saved Successfuly');
        this.semisterForm.reset();
        this.remainingSemestersCount = res.remainingSemestersCount;
        this.getsemesteryearwise(this.yearsemester);
      } else {
        this.toast.showToast('danger', `Error: ${res.message}`);
        this.errormessage = res.message;
      }
    });
  }

  EditAPi() {
    this.datesend = this.semisterForm.getRawValue();
    this.datesend.semister = parseInt(this.semisterForm.value.semister);
    this.datesend.duration_in_months = parseInt(this.semisterForm.value.duration_in_months);
    this.apiservice.Editsemester(this.datesend, this.selectededit_id).subscribe((res: any) => {
      if (res.code == 204 && res.status === true) {
        this.semisterForm.reset();
        this.editflag = false;
        this.getsemesteryearwise(this.yearsemester)
        this.toast.showToast(
          'success',
          'Updated Successfuly',);
      } else {
        this.errormessage = res.message;
      }
    })
  }
}
