<div [formGroup]="form">
    <!-- <div [ngSwitch]="question.controlType">

    <mat-form-field class="example-full-width me-2">
        <mat-label [attr.for]="question.key">{{question.label}}</mat-label>
        <input matInput *ngSwitchCase="'textbox'" [formControlName]="question.key"
        [id]="question.key" [type]="question.type">
        <select [id]="question.key" *ngSwitchCase="'dropdown'" [formControlName]="question.key">
            <option *ngFor="let opt of question.options" [value]="opt.key">{{opt.value}}</option>
          </select>
   
      </mat-form-field>
    </div> -->

    <div [ngSwitch]="question.controlType">

        <div *ngSwitchCase="'textbox'">
            <!-- <mat-form-field class="example-full-width w-100 me-2" appearance="outline"> -->
            <label *ngIf="question.type !== 'checkbox'" [attr.for]="question.key"
                class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <input matInput *ngSwitchCase="'textbox'" class="form-control" [ngClass]="{
                    'is-invalid': (!isValid && isValidt) }" [formControlName]="question.key"
                [id]="question.id == undefined ? question.key : question.id" [type]="question.type"
                [pattern]="question.patterns" minlength="{{question.minLength}}" maxlength="{{question.maxLength}}"
                [required]="question.required" />

            <!-- <input matInput *ngSwitchCase="'textbox'" class="form-control" [ngClass]="{
                        'is-invalid': (!isValid && isValidt) || patternError }" 
                        [formControlName]="question.key" [id]="question.id == undefined ? question.key : question.id" [type]="question.type"
                        [pattern]="question.patterns" minlength="{{question.minLength}}"
                        maxlength="{{question.maxLength}}" [required]="question.required" /> -->
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'number'" class="">
            <!-- <mat-form-field class="example-full-width w-100 me-2" appearance="outline"> -->
            <label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                class="form-label">{{question.label}}<span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <input matInput *ngSwitchCase="'number'" [ngClass]="{
                        'is-invalid': (!isValid && isValidt) || patternError }" [formControlName]="question.key"
                [id]="question.id == undefined ? question.key : question.id" [type]="question.type"
                [pattern]="question.patterns" minlength="{{question.minLength}}" maxlength="{{question.maxLength}}"
                [required]="question.required">
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'textarea'" class="">
            <!-- <mat-form-field class="example-full-width w-100 me-2" appearance="outline"> -->
            <label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <textarea class="form-control" matInput *ngSwitchCase="'textarea'" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [formControlName]="question.key" [id]="question.key"
                [type]="question.type" [pattern]="question.patterns" rows="1" minlength="{{question.minLength}}"
                maxlength="{{question.maxLength}}" [required]="question.required"> </textarea>
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'fileupload'" class="">
            <!-- <mat-form-field class="example-full-width w-100 me-2"> -->
            <div class="d-flex justify-content-between">
                <div class="pb-4">
                    <div>
                        <mat-label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                            class="form-label">{{question.label}}</mat-label>
                    </div>
                    <input *ngSwitchCase="'fileupload'" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [formControlName]="question.key" [id]="question.key" type="file"
                        [pattern]="question.patterns" minlength="{{question.minLength}}"
                        maxlength="{{question.maxLength}}" (change)="onSelectFile($event)"
                        [required]="question.required">
                </div>
                <div *ngIf="url">
                    <img [src]="url" width="80px" height="80px" />
                </div>
            </div>
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'dropdown'" class="">
            <!-- <mat-form-field class="w-100 me-2" appearance="outline"> -->
            <label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <select class="form-select" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [id]="question.id == undefined ? question.key : question.id"
                *ngSwitchCase="'dropdown'" [formControlName]="question.key"  (change)="onSelectionChange($event)"   [required]="question.required">
                <option selected disabled [value]="null">Choose Options</option>
                <option *ngFor="let opt of question.options" class="py-4" [value]="opt.value">{{opt.key}}</option>
            </select>
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'objectdropdown'" class="">
            <!-- <mat-form-field class="w-100 me-2" appearance="outline"> -->
            <label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <select class="form-select" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [id]="question.id == undefined ? question.key : question.id"
                *ngSwitchCase="'objectdropdown'" [formControlName]="question.key" [required]="question.required">
                <option selected disabled [value]="null">Choose Options</option>
                <option *ngFor="let opt of question.options" class="py-4" [value]="opt.id">{{opt.key}}</option>
            </select>
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'multiselect'" class="">
            <!-- <mat-form-field>
            <label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
            class="form-label m-0">{{question.label}} <span *ngIf="question.required" class="text_color_red"><sup>*</sup></span></label>
            <mat-select class="form-select" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [id]="question.id == undefined ? question.key : question.id" *ngSwitchCase="'multiselect'"
                    [formControlName]="question.key" [required]="question.required" multiple>
                <option selected disabled [value]="null">Choose Options</option>
                <option *ngFor="let opt of question.options" class="py-4" [value]="opt.id">{{opt.key}}</option>
            </mat-select>
          </mat-form-field> -->
            <!-- <mat-form-field> -->
            <mat-label *ngIf="question.type != 'checkbox'" [attr.for]="question.key"
                class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></mat-label>
            <mat-select class="form-select" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [id]="question.id == undefined ? question.key : question.id"
                *ngSwitchCase="'multiselect'" [formControlName]="question.key" [required]="question.required" multiple>
                <mat-option *ngFor="let opt of question.options" [value]="{id : opt.id, name: opt.key}">{{opt.key}}</mat-option>
            </mat-select>
            <!-- </mat-form-field> -->
        </div>
        <!-- <ng-container > -->
        <div *ngSwitchCase="'checkbox'" class="flex-row ">
            <mat-checkbox *ngSwitchCase="'checkbox'" appearance="outline" [ngClass]="{
                'is-invalid': !isValid && isValidt }" [formControlName]="question.key" [id]="question.key"
                type="checkbox" required>
                <!-- <label *ngIf="question.type === 'checkbox'" class="form-check-label mx-2" [attr.for]="question.key"> -->
                {{question.label}}
                <!-- </label> -->
            </mat-checkbox>
        </div>
        <!-- </ng-container> -->
        <div *ngSwitchCase="'datepicker'" class="">
            <!-- <mat-form-field class="w-100" appearance="outline"> -->
            <label class="form-label m-0">{{question.label}} <span *ngIf="question.required"
                    class="text_color_red"><sup>*</sup></span></label>
            <div class="input-group">
                <input class="form-control h-auto" [matDatepicker]='picker' [formControlName]="question.key"
                    [id]="question.id == undefined ? question.key : question.id" [type]="question.type"
                    class="form-control" [required]="question.required">
                <mat-datepicker-toggle class="datepicker input-group-text" [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker class="p-0"></mat-datepicker>
            </div>
            <small>MM/DD/YYYY</small>
            <!-- </mat-form-field> -->
        </div>
        <div *ngSwitchCase="'timepicker'" class="">
            <label>{{question.label}}
              <span *ngIf="question.required" class="text_color_red">
                <sup>*</sup>
              </span>
            </label>
            <input matInput 
                   type="text"
                   [format]="24" 
                   [ngxTimepicker]="picker" 
                   [formControlName]="question.key"
                   [id]="question.id == undefined ? question.key : question.id" 
                   class="form-control"
                   [required]="question.required">
            <ngx-material-timepicker #picker class="timepicker"></ngx-material-timepicker>
          </div>
          
    </div>
    <div *ngIf="isValidt" class="position-relative">
        <!-- <div class="errorMessage" *ngIf="!isValid">{{ question.label }} is required</div> -->
        <div class="fielderrorMessage"
            *ngIf="form.controls[question.key].errors?.['email'] && form.controls[question.key].touched">
            {{ question.label }} is not a valid email
        </div>
        <div class="fielderrorMessage"
            *ngIf="form.controls[question.key].errors?.['pattern'] && form.controls[question.key].touched">
            Please enter a valid {{ question.label }}
        </div>
        <!-- Add more error messages for different validation types as needed -->
        <div class="fielderrorMessage"
            *ngIf="form.controls[question.key].errors?.['required'] && form.controls[question.key].touched">
            Please enter {{ question.label }}
        </div>
    </div>
</div>