<div>
   <div>
    <!-- <div>
      <button mat-raised-button (click)="gettimetabledata()">
        View
      </button>
    </div> -->
    
    <ng-container>
      <div class="position-relative">
        @if (isLoadingResults || isRateLimitReached) {
          <div class="loading-shade">
            @if (isLoadingResults) {
              <mat-spinner class="mat_spinner"></mat-spinner>
            }
            @if (isRateLimitReached) {
              <div class="rate-limit-reached">
           gettimetabledata     GitHub's API rate limit has been reached. It will be reset in one minute.
              </div>
            }
          </div>
        }
        <div class="mt-2 table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="academic_year">
              <th mat-header-cell *matHeaderCellDef>Academic Year</th>
              <td mat-cell *matCellDef="let element"> {{element.academic_year}} </td>
            </ng-container>
            <ng-container matColumnDef="shift_id">
              <th mat-header-cell *matHeaderCellDef>Shift</th>
              <td mat-cell *matCellDef="let element"> {{element.shift_name}} </td>
            </ng-container>
            <ng-container matColumnDef="course_id">
              <th mat-header-cell *matHeaderCellDef>Course Name</th>
              <td mat-cell *matCellDef="let element"> {{element.course_name}} </td>
            </ng-container>
            <ng-container matColumnDef="semister_id">
              <th mat-header-cell *matHeaderCellDef>Semister</th>
              <td mat-cell *matCellDef="let element"> {{element.semister}} </td>
            </ng-container>
            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef>Year</th>
              <td mat-cell *matCellDef="let element"> {{element.year}} </td>
            </ng-container>
            <ng-container matColumnDef="section">
              <th mat-header-cell *matHeaderCellDef>Section</th>
              <td mat-cell *matCellDef="let element"> {{element.section_name}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> Actions </th>
              <td mat-cell *matCellDef="let row">
                <button mat-raised-button (click)="gettimetabledata(row)">
                  View
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data</td>
            </tr>
          </table>
        </div>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="showaddflag">
      <app-addtimetables></app-addtimetables>
    </ng-container> -->
   </div>
  </div>
  