import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class DesignationsService {

  department_drop_data: any = [];

  constructor(
    private dropdownservice: DropdownservicesService
  ) {
    this.loadDropdownData('DEPARTMENT', this.department_drop_data);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'DEPARTMENT') {
      observable = this.dropdownservice.getdepartmentdrop();
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      // for (let i = 0; i < res.length; i++) {
      res.forEach((items: any) => {
        let lookupname;
        if (code === 'DEPARTMENT') {
          lookupname = items?.department_name;
        } else {
          lookupname = items?.lookup_name;
        }
        dataArray.push({
          id: items.id,
          key: lookupname,
          value: items.id,
        });
      })
      // }
    });
  }

  getdesignationform() {
    const questions: Inputfiledsbase<string>[] = [

      new TextboxQuestion({
        key: 'name',
        label: 'Designation Name',
        required: true,
        order: 2
      }),

      new TextboxQuestion({
        key: 'designation_code',
        label: 'Designation Code',
        required: true,
        order: 3
      }),

      new DropdownQuestion({
        key: 'department_id',
        label: 'Department',
        options: this.department_drop_data,
        required: false,
        order: 4
      }),

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
