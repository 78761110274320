<section>
  <mat-dialog-content class="mat-typography">
    <div class="d-flex justify-content-between">
      <div>
        <h3 class="m-0 fw-bold">Add Weekoff</h3>
      </div>
      <div class="cursor_pointer" (click)="Canceldailog()">
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="close"
        ></mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="p-3">
      <div class="position-relative pt-2">
        <div class="form">
            <form (ngSubmit)="onSubmit()" [formGroup]="weekOffForm">
              <div class="row">
                <div *ngFor="let question of questions" class="mb-3 col-sm-12">
                  <app-question
                    [question]="question"
                    [form]="weekOffForm"
                  ></app-question>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="form-row">
      <button mat-raised-button (click)="Canceldailog()" class="px-5 cancel_button">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
        class="px-5 submit_button"
        type="submit"
        [disabled]="!weekOffForm.valid"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</section>
