<!-- <ng-container *ngIf="lookupdata.length > 0"> -->
<section class="mx-3">
    <app-toast></app-toast>
    <ng-container *ngIf="!showaddflag; else addForm">
        <div class="d-flex justify-content-between align-items-center">
            <div class="page_header">
                <h4 class="m-0 page_header_title">Leave Entitlements</h4>
            </div>
            <div>
                <button mat-raised-button color="primary" (click)="showadd()" class="px-5 add_button">Add</button>
            </div>
        </div>
        <div class="mt-2 table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- Checkbox Column -->
                <!-- <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-container> -->
                <ng-container matColumnDef="leave_name">
                    <th mat-header-cell *matHeaderCellDef>Leave Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.leave_name || '-'}} </td>
                </ng-container>
                <ng-container matColumnDef="designation">
                    <th mat-header-cell *matHeaderCellDef>Designation</th>
                    <td mat-cell *matCellDef="let element"> {{element.designation || '-'}} </td>
                </ng-container>
                <ng-container matColumnDef="maximum_allowed_leaves">
                    <th mat-header-cell *matHeaderCellDef> Maximum Allowed Leaves </th>
                    <td mat-cell *matCellDef="let element"> {{element.maximum_allowed_leaves || '-'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data</td>
                </tr>
            </table>
        </div>
    </ng-container>

    <ng-template #addForm>

        <div class="d-flex align-items-center">
            <div>
                <button mat-icon-button class="back_icon cancel_button me-2"
                    (click)="showadd()"><mat-icon>arrow_back</mat-icon></button>
            </div>
            <div>
                <h4 class="m-0">Add Leave Entitlements</h4>
            </div>
        </div>

        <div class="bg-white p-3 mt-2">
            <form [formGroup]="form">
                <div class="row">
                    <div *ngFor="let question of questions"
                        class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                        <app-question [question]="question" [form]="form"></app-question>
                    </div>
                </div>
                <div>
                    <div>
                        <h4 style="font-weight: 500;">Add Leaves</h4>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-8 col-md-8">
                            <div class="row">
                                <div *ngFor="let question of arrayquestions"
                                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <app-question [question]="question" [form]="Arrayform"></app-question>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 col-md-4 d-flex justify-content-end align-items-center">
                            <div>
                                <ng-container *ngIf="editeducationarrayflag">
                                    <button type="button" class="add_button" mat-raised-button color="primary"
                                        (click)="arraySubmit()" [disabled]="!Arrayform.valid">Add Details</button>
                                </ng-container>
                                <ng-container *ngIf="!editeducationarrayflag">
                                    <button type="button" class="update_button" mat-raised-button color="primary"
                                        (click)="editarrySubmit()" [disabled]="!Arrayform.valid">Update Details</button>
                                </ng-container>
                                <button type="button" mat-stroked-button class="ms-3"
                                    (click)="resetarrayform()">Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 mt-2">
                        <ng-container *ngIf="addformarry?.length > 0">
                            <div *ngFor="let arrayform of addformarry; let i=index">
                                <div class="card position-relative">
                                    <div class="m-2 px-2 d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">Designation : <span>{{arrayform.designation}} </span></p>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <p class="m-0">Maximum Allowed Leaves :
                                                <span>{{arrayform.maximum_allowed_leaves}} </span>
                                            </p>
                                        </div>
                                        <div class="text-end">
                                            <button mat-icon-button class="action_button" [matMenuTriggerFor]="menu"
                                                aria-label="edit">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item class="update_button"
                                                    (click)="editarrayform(arrayform, i)">
                                                    <span>Edit</span>
                                                </button>
                                                <button mat-menu-item class="delete_button"
                                                    (click)="deletearrayform(i)">
                                                    <span>Delete</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="form-row text-end">
                    <button mat-raised-button color="primary" class="px-5 submit_button" type="submit"
                        [disabled]="!form.valid || addformarry?.length == 0">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </ng-template>
</section>