import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { MultiSelectQuestion } from '../../question-multiselect';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';
import { Validators } from '@angular/forms';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class workService {

  shift_end_days: any = [];
  breaktypedata: any = [];
  organisationdata: any = [];
  designationdata: any = [];
  holidaysdata: any = [];
  weekoffdata: any = [];
  weekdays: any = [];
  leavetypedrop: any[] = [];

  // weekdays: { key: string; id: string; value: number; }[] = [
  //   { key: 'monday', id: 'Monday', value: 1 },
  //   { key: 'tuesday', id:'Tuesday', value: 2 },
  //   { key: 'wednesday', id:'Wednesday', value: 3 },
  //   { key: 'thursday', id: 'Thursday', value: 4 },
  //   { key: 'friday', id:'Friday', value: 5 },
  //   { key: 'saturday', id:'Saturday', value: 6 },
  //   { key: 'sunday', id: 'Sunday', value: 7 }
  // ];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.loadDropdownData('DESIGNATIONS', this.designationdata);
    this.loadDropdownData('HOLIDAY_TYPES', this.holidaysdata);
    this.loadDropdownData('WEEK_OFF_TYPES', this.weekoffdata);
    this.loadDropdownData('WEEKDAYS', this.weekdays);
    this.loadDropdownData('SHIFT_END_DAY', this.shift_end_days);
    this.loadDropdownData('LEAVE_TYPE', this.leavetypedrop);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'LEAVE_TYPE') {
      observable = this.dropdownservice.getleavetypedrop();
    } else if (code === 'DESIGNATIONS') {
      observable = this.dropdownservice.getdesignationsdrop();
    } else {
      observable = this.dropdownservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res: any[]) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'LEAVE_TYPE') {
          lookupname = res[i].leave_code;
        } else if (code === 'DESIGNATIONS') {
          lookupname = res[i].name;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  getaddshiftservices() {
    const questions: Inputfiledsbase<string>[] = [

      new TextboxQuestion({
        key: 'shift_name',
        label: 'Shift Name',
        type: 'text',
        required: true,
        order: 1,
      }),
      new TimepickerQuestion({
        key: 'shift_start_time',
        label: 'Start Time',
        type: 'time',
        required: true,
        order: 2
      }),
      new TimepickerQuestion({
        key: 'shift_end_time',
        label: 'End Time',
        type: 'time',
        required: true,
        order: 3
      }),
      new DropdownQuestion({
        key: 'shift_end_day_id',
        label: 'Shift End Day',
        type: 'dropdown',
        required: true,
        options: this.shift_end_days,
        order: 4,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getbreaktimeservices() {

    this.loadDropdownData('BREAK_TYPES', this.breaktypedata);

    const question: Inputfiledsbase<string>[] = [

      new DropdownQuestion({
        key: 'break_type_id',
        label: 'Break Name',
        type: 'dropdown',
        required: true,
        options: this.breaktypedata,
        order: 1,
      }),

      new TimepickerQuestion({
        key: 'break_start_time',
        label: 'Start Time',
        type: 'time',
        required: true,
        order: 2
      }),
      new TimepickerQuestion({
        key: 'break_end_time',
        label: 'End Time',
        type: 'time',
        required: true,
        order: 3
      })
    ];

    return of(question.sort((a, b) => a.order - b.order));
  }

  getWeekOffservices() {
    const question: Inputfiledsbase<string>[] = [

      // new DropdownQuestion({
      //   key: 'day',
      //   label: 'Day',
      //   required: true,
      //   type: 'dropdown',
      //   options: this.weekdays,
      //   order: 2,
      // }),

      new MultiSelectQuestion({
        key: 'weekoff_type_id',
        label: 'WeekOff Type',
        required: true,
        type: 'dropdown',
        options: this.weekoffdata,
        order: 3,
      })
    ];

    return of(question.sort((a, b) => a.order - b.order));
  }

  getHolidayservices() {
    const question: Inputfiledsbase<string>[] = [

      new DatepickerQuestion({
        key: 'date',
        label: 'Date',
        required: true,
        order: 2,
      }),

      new TextboxQuestion({
        key: 'holiday_name',
        label: 'Holiday Name',
        type: 'text',
        required: true,
        order: 3,
      }),

      new DropdownQuestion({
        key: 'holiday_type_id',
        label: 'Holiday Type',
        required: true,
        type: 'dropdown',
        options: this.holidaysdata,
        order: 4,
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        type: 'text',
        required: false,
        order: 5
      })
    ];

    return of(question.sort((a, b) => a.order - b.order));
  }
  getleavetypeform() {
    const questions: Inputfiledsbase<string>[] = [

      new TextboxQuestion({
        key: 'leave_name',
        label: 'Leave Name',
        required: true,
        patterns: '^[a-zA-Z]+$',
        order: 1,
      }),

      new TextboxQuestion({
        key: 'leave_code',
        label: 'Leave Code',
        required: true,
        patterns: '^[a-zA-Z]+$',
        order: 2,
      }),

      // new DropdownQuestion({
      //   key: 'designation_id',
      //   label: 'Designation',
      //   type: 'dropdown',
      //   options: this.designationdata,
      //   required: true,
      //   order: 3
      // }),

      // new TextboxQuestion({
      //   key: 'maximum_allowed_leaves',
      //   label: 'Allowed Leaves(Maximum)',
      //   required: false,
      //   type: 'number',
      //   order: 4
      // }),

      // new TextareaQuestion({
      //   key: 'description',
      //   label: 'Description',
      //   required: false,
      //   order: 4
      // }),

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getleave_entitlementsform() {
    const questions: Inputfiledsbase<string>[] = [

      // new TextboxQuestion({
      //   key: 'leave_code',
      //   label: 'Leave Code',
      //   required: true,
      //   patterns: '^[a-zA-Z]+$',
      //   order: 2,
      // }),

      new DropdownQuestion({
        key: 'leave_type_id',
        label: 'Leave Type',
        type: 'dropdown',
        options: this.leavetypedrop,
        required: true,
        order: 1
      }),

      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        required: true,
        order: 4,
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: 'End Date',
        required: true,
        order: 5,
      }),

      // new TextboxQuestion({
      //   key: 'maximum_allowed_leaves',
      //   label: 'Allowed Leaves(Maximum)',
      //   required: false,
      //   type: 'number',
      //   order: 4
      // }),

      // new TextareaQuestion({
      //   key: 'description',
      //   label: 'Description',
      //   required: false,
      //   order: 4
      // }),

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getleave_entitlementsArrayform() {
    const questions: Inputfiledsbase<string>[] = [

      new DropdownQuestion({
        key: 'designation_id',
        label: 'Designation',
        type: 'dropdown',
        options: this.designationdata,
        required: true,
        order: 2
      }),

      new TextboxQuestion({
        key: 'maximum_allowed_leaves',
        label: 'Maximum Allowed Leaves',
        required: true,
        patterns: '^[0-9]*$',
        order: 3,
      }),

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
