import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class CompoffService {

  organisationdata: any = [];

  constructor(private apiservice : ApiservicesService, private dropdownservice: DropdownservicesService) {
    // this.apiservice.getorganizationdrop().subscribe((res) => 
    //   {
    //     for(let i=0; i <= res.length; i++){
    //       this.organisationdata?.push({id:res[i].id, key: res[i].short_name, value:res[i].id})
    //     }
    //   }); 
   }
  getCompoffLeaves(){
    const questions: Inputfiledsbase<string>[] = [
      new TimepickerQuestion({
        key: 'time',
        label:'Time',
        required: true,
        order: 1
      }),
      new DatepickerQuestion({
        key: 'from_date',
        label: 'From Date',
        required: true,
        order: 2
      }),
      new TextareaQuestion ({
        key: 'reason_for_leave',
        label: 'Reason for Leave',
        required: true,
        order: 3
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
