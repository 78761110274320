<section style="height: 50vh;">
  <app-toast></app-toast>
    <mat-dialog-content class="mat-typography">
      <div class="d-flex align-items-center">
        <div>
          <button mat-icon-button class="back_icon me-2" (click)="closeDialog(true)"><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div>
          <h4 class="m-0">Add Section</h4>
        </div>
      </div>
      <mat-divider class="mt-2"></mat-divider>
      <div *ngIf="errormessage" class="mt-2">
        {{errormessage}}
      </div>
      <div class="mt-3">
        <form [formGroup]="sectionForm">
          <div class="row m-0">
            <div *ngFor="let question of questions"
              class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <app-question [question]="question" [form]="sectionForm"></app-question>
            </div>
            <!-- <div *ngIf="!editflag" class="col-sm-3 d-flex justify-content-end align-items-center">
              <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5" type="submit"
                [disabled]="!sectionForm.valid">
                Save
              </button>
            </div>
            <div *ngIf="editflag" class="col-sm-3 d-flex justify-content-end align-items-center">
              <button mat-raised-button color="primary" (click)="EditAPi()" class="px-5" type="submit"
                [disabled]="!sectionForm.valid">
                Edit
              </button>
            </div> -->
          </div>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="w-100 bg-white position-absolute bottom-0 end-0">
      <div class="form-row">
        <!-- <div *ngIf="!editflag" class="col-sm-3 d-flex justify-content-end align-items-center"> -->
          <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5" type="submit"
            [disabled]="!sectionForm.valid">Save</button>
        <!-- </div> -->
        <button mat-raised-button (click)="closeDialog(false)" class="px-5">
          Cancel
        </button>
        <!-- <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5" type="submit"
          [disabled]="!sectionForm.valid">
          Save
        </button> -->
      </div>
    </mat-dialog-actions>
  </section>