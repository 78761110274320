import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})
export class LookuptypeService {
  organisationdata: any = [];

  constructor(private apiservice : ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.dropdownservice.getorganizationdrop().subscribe((res) => 
    {
      // if (res.status === true || res.code === 200) {
      for(let i=0; i <= res.length; i++){
        this.organisationdata?.push({id:res[i].id, key: res[i].short_name, value:res[i].id})
      }
    // }
    // else{
    //   console.log("--->",res);
    // }
    }); 
}
  
  getlookuptypeform(){
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization ID',
        required: true,
        options: this.organisationdata,
        order: 1
      }),

      new TextboxQuestion ({
        key: 'lookup_type',
        label: 'Lookup Type Name',
        required: true,
        order: 2
      }),

      new TextboxQuestion ({
        key: 'lookup_type_displayname',
        label: 'Display Name',
        required: true,
        order: 3
      }),
      new TextareaQuestion({
        key: 'description',
        label: 'description',
        required: true,
        order: 4
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
