import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  @Input() role!: string;
  selectedrole: string | null | undefined;
  constructor (private route: ActivatedRoute){
    // window.location.reload()

  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe( params =>{
      this.selectedrole = params.get('role')
  })
  }
}
