import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DatepickerQuestion } from '../../question-datepicker';
import { TextboxQuestion } from '../../question-textbox';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownQuestion } from '../../question-dropdown';
import { MatCardImage } from '@angular/material/card';
import { FileuploadQuestion } from '../../question-fileuplaod';
import { TextareaQuestion } from '../../question-textarea';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor() { }
  getOrganizationservices(){
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'sub_organization_name',
        label: 'Sub Organization Name',
        type: 'text',
        required: true,
        order : 1,
      }),
      new TextboxQuestion({
        key: 'sub_organization_code',
        label: 'Sub Organization Code',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'contact_number',
        label: 'Contact Number',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'address',
        label: 'Address',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'land_mark',
        label: 'Land Mark',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'city',
        label: 'City',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'district',
        label: 'District',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'state_name',
        label: 'State',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'country_name',
        label: 'Country Name',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'pincode',
        label: 'Pincode',
        type: 'text',
        required: true,
        order : 2,
      }),
      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        type: 'datepicker',
        required: true,
        order: 3,
      }),
      // new DropdownQuestion({
      //   key: 'organization_id',
      //   label: 'Organization Id',
      //   required: true,
      //   options: [{key:"DBREAI", id:10, value: 10}],
      //   order: 4
      // }),
      
      // new FileuploadQuestion({
      //   key: 'logo_url',
      //   label: 'Uplaod Logo {PNG, JPEG,JPG Only}',
      //   required: true,
      //   type:'File',
      //   order: 13
      // })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getOrganizationpersonal_detailsservices(){
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'first_name',
        label: 'First Name',
        type: 'text',
        required: true,
        order : 1,
      }),
      new TextboxQuestion({
        key: 'last_name',
        label: 'Last Name',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'professional_email',
        label: 'Professional Email',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'phone_number',
        label: 'Phone Number',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type: 'text',
        required: true,
        order : 2,
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
