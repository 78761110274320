export class Inputfiledsbase<T> {
  value: T | undefined | null | any;
  id: string | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  disable: boolean | undefined;
  patterns: string;
  minLength: number | undefined;
  maxLength: number | undefined;
  type: string;
  options: { key: string; id: string | number; disable?: boolean; value: number }[];
  onChange: (value: any) => void = () => {};

  constructor(options: {
    value?: T | null;
    id?: string | number | undefined;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    disable?: boolean;
    type?: string;
    patterns?: string;
    minLength?: number;
    maxLength?: number;
    options?: { key: string; id: string | number; disable?: boolean; value: number}[];
    onChange?: (value: any) => void;
  } = {}) {
    this.value = options.value;
    this.id = options.key ? options.key.toString() : '';
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.patterns = options.patterns || '';
    this.minLength = options.minLength || undefined;
    this.maxLength = options.maxLength;
    this.options = options.options || [];
    this.onChange = options.onChange || this.onChange;
  }
}
